import gql from 'graphql-tag';

export const GET_SERVICES_NAMES = gql`
	query getServices($name: String, $perPage: Int) {
		getServices(name: $name, perPage: $perPage) {
			success
			errors {
				path
				message
			}
			data {
				name
				_id
				description
				documents
				recommendations
				nurse
			}
		}
	}
`;

export const GET_SERVICES = gql`
	query getServices($name: String, $perPage: Int, $page: Int, $servicefamily: String) {
		getServices(name: $name, perPage: $perPage, page: $page, servicefamily: $servicefamily) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				duration
        servicefamily
			}
		}
	}
`;

export const GET_SERVICE = gql`
	query getService($_id: ID!) {
		getService(_id: $_id) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				duration
				description
				documents
				recommendations
				nurse
				presentdoctor
				position
				quantity
				timeOut
				servicefamily
			}
		}
	}
`;

export const GET_SERVICE_RESOURCES = gql`
	query getServices($id: ID) {
		getResourcesOfService(id: $id) {
		success
		errors {
			path
			message
		}
		data {
			_id
			name
			resourceCode
		}
		}
	}
`
/**
 * AppoinmentDisponibility
 */
/* eslint-disable */
import React from 'react';
import CheckboxInput from '../../../components/general/checkboxInput';
import _ from 'lodash';
import moment from 'moment';
import './style.css';

const AppoinmentDisponibility = props => {
  const {
    service,
    selectedDay,
    appointments,
    changeSelectedDay,
    servicesAppointments,
    selectedCalendarService,
    handleselectedAppointmentsService,
    selectedAppointmentsService
  } = props;

  let morning = false,
    afternoon = false;
  if (servicesAppointments[service._id][selectedDay]) {
    morning = servicesAppointments[service._id][selectedDay].morning;
    afternoon = servicesAppointments[service._id][selectedDay].afternoon;
  }

  // console.log("selectedAppointmentsService :", selectedAppointmentsService);
  const timeDisabled = [];
  Object.keys(selectedAppointmentsService).filter(s => {
    if (service._id !== s) {
      timeDisabled.push(selectedAppointmentsService[s]);
    }
  });
  const disableInBlock = (timeDisabled, { time, service, ...obj }, s) => {


    // try {
    //   if (!_.isEmpty(selectedAppointmentsService)) {
    //     for (var serviceItem in servicesAppointments) {
    //       if (serviceItem !== selectedAppointmentsService) {
    //         return [...servicesAppointments[serviceItem][selectedDay].morning, ...servicesAppointments[serviceItem][selectedDay].afternoon].map((item) => {
    //           if (timeDisabled == time) {
    //             const pa = time;
    //             const pb = moment(time, 'x')
    //               .add(Number(obj.duration), 'minutes')
    //               .format('x');

    //             const pc = item.time;
    //             const pd = moment(item.time, 'x')
    //               .add(Number(item.duration), 'minutes')
    //               .format('x');

    //             if (
    //               pb - pc > 0 && pd - pa > 0
    //             ) {
    //               return true;
    //             }
    //           }

    //         });

    //       }
    //     }
    //   }

    //   // servicesAppointments.map((s) => {
    //   //   if (s != service._id) {
    //   //     console.log("TODO")
    //   //   }
    //   // })
    // } catch (err) {
    //   console.log(err)
    // }

    return _.indexOf(timeDisabled, time) > -1;
  }
  /*
  const isSelectedDay = morning && morning.length > 0 && morning.find((obj) => {
    return selectedAppointmentsService[service._id] === obj.time;
  });
  */
  const eachAppointment = selectedCalendarService.week ? selectedCalendarService : appointments[service._id];
  return (
    <div className="flex ml-1 availability-container">
      <div>
        {
          eachAppointment.week.map((day, index) => {
            let dayWeek = new Date(day.momentDay.toDate()).getTime();
            // console.log('dayWeek', dayWeek);
            dayWeek = new Date(dayWeek).setHours(0, 0, 0, 0);
            const selectedDayService = new Date(
              selectedAppointmentsService[service._id]
            ).setHours(0, 0, 0, 0);

            return (
              <div
                key={index}
                onClick={async () => await changeSelectedDay(dayWeek)}
                className={`appointment-days clickable ${dayWeek === selectedDay ? 'bg-icon white' : ''} ${selectedDayService === dayWeek && 'selected'}`}
              >
                {day.title}
              </div>
            );
          })
        }
      </div>
      {
        morning && morning.length > 0 && (
          <div className="">
            <div className="daySection">Mañana</div>
            <div
              className={`available-hours-container ${morning.length > 1 && 'lineVertical'}`}
            >
              {
                morning.map(obj => {
                  let checked = false;
                  if (selectedAppointmentsService[service._id] === obj.time) {
                    checked = true;
                  }

                  return (
                    <div
                      key={`hour${service._id}${obj.time}`}
                      className="appointment-hour"
                    >
                      <CheckboxInput
                        checked={checked}
                        disabled={disableInBlock(timeDisabled, obj, service)}
                        labelRight={true}
                        updateField={() => {
                          obj.service = service;
                          handleselectedAppointmentsService(obj);
                        }}
                        value={obj.time}
                        label={obj.hour}
                        name="hour"
                        id={`hour${service._id}${obj.time}`}
                      />
                    </div>
                  );
                })
              }
            </div>
          </div>
        )
      }
      {
        afternoon && afternoon.length > 0 && (
          <div className="">
            <div className="daySection">Tarde</div>
            <div
              className={`available-hours-container ${afternoon.length > 1 && 'lineVertical'}`}
            >
              {
                afternoon.map(obj => {
                  return (
                    <div
                      key={`hour${service._id}${obj.time}`}
                      className="appointment-hour"
                    >
                      <CheckboxInput
                        disabled={disableInBlock(timeDisabled, obj, service)}
                        checked={selectedAppointmentsService[service._id] === obj.time}
                        labelRight={true}
                        updateField={() => {
                          obj.service = service;
                          handleselectedAppointmentsService(obj);
                        }}
                        value={obj.time}
                        label={obj.hour}
                        name="hour"
                        id={`hour${service._id}${obj.time}`}
                      />
                    </div>
                  );
                })
              }
            </div>
          </div>
        )
      }
    </div>
  );
};
export default AppoinmentDisponibility;

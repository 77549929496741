import gql from 'graphql-tag';

export const CREATE_SPECIALTY = gql`
	mutation createSpecialty(
		$name: String!
	) {
		createSpecialty(
			name: $name
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const UPDATE_SPECIALITY = gql`
	mutation updateSpecialty(
		$_id: ID!
		$name: String!
	) {
		updateSpecialty(
			_id: $_id
			name: $name
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const DELETE_SPECIALTY = gql`
	mutation deleteSpecialty($_id: ID!) {
		deleteSpecialty(_id: $_id) {
			errors {
				message
				path
			}
			success
		}
	}
`;

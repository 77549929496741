import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import AppointmentSchedule from '../patientsModule/appointmentSchedule/appointmentSchedule';

const Reasign = (props) => {	// eslint-disable-line
  return (
    <Accordion accordion={false}>
      <AppointmentSchedule />
    </Accordion>
  );
};

export default Reasign;

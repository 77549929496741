import React, { useContext, useEffect, useState } from 'react';
import PatientForm from './PatientForm';
import ServiceForm from './ServiceForm';
import { context } from "../../../providers/patientProvider";
import { useBillingForm } from '../../../hooks/useBillingForm';

const BillingForm = () => {
    const tabs = [
        {
            label: 'Paciente',
            value: 1
        },
        {
            label: 'Servicio',
            value: 2
        },
    ];

    const [tab, setTab] = useState(1);

    const { 
        handleSetBillingData, 
        billingData, 
        appointmentsService,
        currentPatient,
        eps
    } = useContext(context);

    const {
        fillDataGeneral,
        identificationTypeSelect,
        userTypeSelect,
        genderSelect,
        territorialZone,
        incapacitySelect,
        etniaSelect,
        entitySelect,
        entityTypeSelect,
        stateSelect,
        atentionModalitySelect,
        maritalStatusSelect,
        educationLevelSelect,
        cities,
        citiesOrigin,
        costCenters,
        allowFillOriginCity,
        loadOptionsCountry,
        loadOptionsCities,
        loadOptionsCitiesOrigin,
        loadOptionsProfessions,
        handleGetRelatedEntityType,
        getAllSelects,
        getStates,
        getCupsCode,
        getCostCenters,
        getApointmentCostCenter
    } = useBillingForm({
        updateField: handleSetBillingData,
        codStateResidence: billingData.codDepartamentoResidencia,
        codeStateOrigin: billingData.codDepartamentoOrigen,
        codOriginCountry: billingData.codPaisOrigen,
        appointment: appointmentsService,
        eps
    });

    const isSelectedPatientForm = () => {
        const patientTab = tabs.find(t => t.label === 'Paciente').value;
        return tab === patientTab
    }

    const isSelectedServiceForm = () => {
        const serviceTab = tabs.find(t => t.label === 'Servicio').value;
        return tab === serviceTab
    }

    const handleSetInput = e => {
        const input = e.target;
        handleSetBillingData(input.name, input.value);
    }

    const handleSetSelect = (name, { value }) => {
        handleSetBillingData(name, value)
    }

    useEffect(() => {
        getAllSelects();
        getStates();
        getCupsCode();
        getCostCenters();
        getApointmentCostCenter();
        
        fillDataGeneral({ 
            currentPatient, 
            appointment: appointmentsService 
        });
    }, []);

    return (
        <>
            <div className="contentServices flex mt-1px">
                {tabs.map((t, i) => (
                    <div
                        key={i}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setTab(t.value)}
                        className={`cs_nav ${tab === t.value && 'select'}`}
                    >
                        <span> { t.label } </span>
                    </div>
                ))}
            </div>

            <form>
                {isSelectedPatientForm() && (
                    <PatientForm
                        handleSetInput={handleSetInput}
                        handleSetSelect={handleSetSelect}
                        identificationTypeSelect={identificationTypeSelect}
                        userTypeSelect={userTypeSelect}
                        genderSelect={genderSelect}
                        territorialZone={territorialZone}
                        incapacitySelect={incapacitySelect}
                        etniaSelect={etniaSelect}
                        entitySelect={entitySelect}
                        entityTypeSelect={entityTypeSelect}
                        stateSelect={stateSelect}
                        cities={cities}
                        citiesOrigin={citiesOrigin}
                        loadOptionsCountry={loadOptionsCountry}
                        loadOptionsCities={loadOptionsCities}
                        loadOptionsCitiesOrigin={loadOptionsCitiesOrigin}
                        handleGetRelatedEntityType={handleGetRelatedEntityType}
                        allowFillOriginCity={allowFillOriginCity}
                        maritalStatusSelect={maritalStatusSelect}
                        educationLevelSelect={educationLevelSelect}
                        loadOptionsProfessions={loadOptionsProfessions}
                    />
                )}
                
                {isSelectedServiceForm() && (
                    <ServiceForm 
                        handleSetInput={handleSetInput}
                        handleSetSelect={handleSetSelect}
                        atentionModalitySelect={atentionModalitySelect}
                        costCenters={costCenters}
                    />
                )}
            </form>
        </>
    );
}

export default BillingForm;

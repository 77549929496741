import React, { Component } from 'react';
import { PhysicianConsumer } from '../../../providers/physicianProvider';
import Physician from './index';

class PhysicianEditable extends Component {

  render () {
    return <PhysicianConsumer>{props => <Physician {...props} />}</PhysicianConsumer>;
  }

}

export default PhysicianEditable;


import moment from 'moment';
import { capitalizeWords, calculateAge } from "../../../utils/helpers";
import { iconSicor, stylesHeader, stylesFooter } from "./index";

export const templateHeaderpdf = ({ patient }) => {
  const namePatient= `${patient?.name} ${patient?.lastName}`;
  return `
  <!DOCTYPE html>
    <html>
    <head>
      ${stylesHeader()}
    </head>
    <body>
      <div class="content-header">
        <p id="pagination">Pagina <span class="pageNumber"></span> de <span class="totalPages"></span></p>
        <div id="logo">
          <img src=${iconSicor} alt="Logo sicor" width="100%"/>
        </div>
        <div class="detail-user">
          <div>
            <p>Paciente: <strong>${capitalizeWords(namePatient)}</strong></p>
            <p><strong>${calculateAge(patient?.birthDay)} Años - ${patient?.type_dni} ${patient?.dni}</strong></p>
          </div>
          <div>
            <p>Fecha: <strong>${moment(new Date()).format("DD/MM/YYYY")}</strong></p>
            <p>Tipo de examen: <strong>Telemonitoreo</strong></p>
          </div>
        </div>
      </div>
    </body>
  </html>
`;
};

export const templateFooterpdf = () => {
  return `
  <!DOCTYPE html>
  <html>
    <head>
      ${stylesFooter()}
    </head>
    <footer>
      El Poblado: Calle 19 No. 42-40  Medellin, Colombia
      <br />
      Conmutador: (574) 604 00 07  Whatsapp: (+57) 310 503 7489   
    </footer>
  </html>
  `;
};

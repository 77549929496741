/**
 * ResourcesList
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../general/textInput';
import SelectInput from '../../general/selectInput';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import {
  GET_RESOURCE,
  GET_RESOURCE_TYPES,
  GET_RESOURCE_SEDE
} from '../../../graphql/types/resourcesModule/queries';
import ResourcesTable from '../resourcesTable';
import BodyContainer from '../../../components/general/bodyContainer';

import './style.css';

class ResourcesList extends Component {
  filterTimeOut = null;
  constructor (props) {
    super(props);
    const initialState = {
      isLoader: false,
      filters: ['name', 'type', 'branch'],
      resources: [],
      filtered: false,
      finished: false,
      filter: {},
      page: 1,
      resourceLoad: true,
      filtersCollapsed: true,
      _resourceType: false,
      _sede: false,
      name: false,
      types: [],
      sedes: []
    };
    initialState.filters.map(filter => {
      return (initialState[filter] = '');
    });
    this.state = initialState;
  }

  toResource = id => {
    this.props.history.push(`/resources/${id}`);
  };

  scrolled = async o => {
    if (
      o.target.offsetHeight + o.target.scrollTop >=
      o.target.scrollHeight - 30
    ) {
      const { resources, resourceLoad, isLoader } = this.state;
      if (resourceLoad && !isLoader) {
        this.loadResources(resources);
      }
    }
  };

  loadResources = async (resources = [], time=0) => {
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);
    const { page } = this.state;
    const filter = this.buildFilters(this.state);
    this.filterTimeOut = setTimeout(() => {
      this.setState({ isLoader: true });
      this.props.client.query({
        query: GET_RESOURCE,
        variables: filter
      })
        .then(response => {
          const data =
            response.data.getResource.data.length > 0
              ? response.data.getResource.data
              : [];
          resources = page === 1? data: resources.concat(data);	// concat si la pagina es mayor a 1
          let { resourceLoad } = this.state;
          if (data.length < 30) {
            resourceLoad = false;
          }
          this.setState({ isLoader: false, resources, resourceLoad, finished: true, page: this.state.page + 1 });
        }).catch(()=>{
          this.setState({ isLoader: false, finished: true });
        });
    }, time);
  };
  buildFilters = state => {
    const filters = {};
    if (state.name) filters.name = state.name;
    if (state.page) filters.page = state.page;
    if (state._sede) filters._sede = state._sede;
    if (state._resourceType) filters._resourceType = state._resourceType;
    return filters;
  };
  loadTypes = async () => {
    this.setState({ isLoader: true });
    const {
      data: {
        getResourceType: { data: types }
      }
    } = await this.props.client.query({ query: GET_RESOURCE_TYPES });
    const sedeType = types.find(type => {
      return type.name === 'Sede';
    });
    const _resourceType = sedeType._id;
    const {
      data: {
        getResourceSede: { data: sedes }
      }
    } = await this.props.client.query({
      query: GET_RESOURCE_SEDE,
      variables: { _resourceType }
    });
    this.setState({ types, sedes, isLoader: false });
  };
  componentDidMount () {
    const { resources } = this.state;
    this.loadResources(resources, 0);
    this.loadTypes();
  }
  updateField = (name, { value }) => {
    this.setState({ [name]: value, finished: false, page: 1, resourceLoad: true }, () => this.loadResources([], 500));
  };

  render () {
    const { children } = this.props;
    const {
      _sede,
      sedes,
      types,
      isLoader,
      resources,
      filtersCollapsed,
      _resourceType,
      finished
    } = this.state;

    return (
      <BodyContainer isLoader={isLoader}>
        <div className={`resources-list contentListUser ${filtersCollapsed ? 'filters-collapsed' : ''}`} >
          <div className="flex j-c a-i-c search-container">
            <div className="search-field-icon">
              <TextInput
                iconBefore="search"
                value={this.state.name}
                type="text"
                name="name"
                label={
                  window.innerWidth < 780
                    ? 'Buscar'
                    : 'Ingresa nombre del recurso'
                }
                updateField={this.updateField}
              />
            </div>
            <div className="users-filters-toggle text-right ripple-gray">
              <span
                className="large material-icons clickable"
                onClick={this.toggleFilters}
              >
                toc
              </span>
            </div>
          </div>
          <div className="user-filters-container flex j-c a-i-c f-w">
            <div className="filter-field">
              <SelectInput
                label="Tipo de recurso"
                name="_resourceType"
                value={_resourceType}
                options={types}
                updateField={this.updateField}
              />
            </div>
            <div className="filter-field">
              <SelectInput
                value={_sede}
                name="_sede"
                label="Sede"
                options={sedes}
                updateField={this.updateField}
              />
            </div>
          </div>
          <ResourcesTable
            scrolled={this.scrolled}
            resources={resources}
            toResource={this.toResource}
            finished={finished}
          />
          { children }
        </div>
      </BodyContainer>
    );
  }
}
ResourcesList.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.element
};
ResourcesList.defaultProps = {
  children: []
};
export default withApollo(withRouter(ResourcesList));

/* eslint-disable */
import React, { Component } from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { context } from "../../../providers/dashboardProvider";

class EpsPolicyChart extends Component {

  static contextType = context;

  constructor (props) {
    super(props);    
  }

  render () {
    const { topReferralInsurance } = this.context;

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', 'red'];

    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h5 className='card-title'> EPS / Póliza </h5>
          </div>
        </div>
        <br />
        <div className="row mh300">
          {topReferralInsurance.length > 0 &&
            <ResponsiveContainer width={'100%'} height={250} >
              <PieChart>
                <Pie 
                  data={topReferralInsurance} 
                  dataKey="referrals" 
                  nameKey="name" 
                  cx="50%" 
                  cy="50%" 
                  outerRadius={100} 
                  fill="#8884d8"
                >
                  {topReferralInsurance.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={COLORS[index % COLORS.length]} 
                    />
                  ))}
                </Pie>
                <Legend />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>            
          }
        </div>
      </div>
    );
  }

}
export default EpsPolicyChart;
/**
 * HolydayModal
 */
import React from 'react';

const HolidayModal = props => {
  const { onConfirm, onCancel, message } = props;
  return (
    <div className={'modal-container'}>
      <div className="modal-body flex f-c a-i-c j-se text-center">
        <div className="modal-msg">
          <p>Este es un día festivo en Colombia</p>
          <p className="muted-text">Se celebra: {message}</p>
          <p>¿Deseas igualmente realizar la asignación?</p>
        </div>
        <div className="modal-actions">
          <button className="button button-sm bg-muted" onClick={onCancel}>
            No
          </button>
          <button className="button button-sm bg-icon ripple" onClick={onConfirm}>
            Si
          </button>
        </div>
      </div>
    </div>
  );
};
export default HolidayModal;

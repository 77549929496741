/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Form from './form';
import {
  GET_ROLES,
  GET_POSITIONS
} from '../../../graphql/types/usersModule/queries';
import {
  GET_RESOURCE,
  GET_RESOURCE_TYPES,
  GET_RESOURCE_SEDE
} from '../../../graphql/types/resourcesModule/queries';
import {
  CREATE_USER,
  UPDATE_USER
} from '../../../graphql/types/usersModule/mutations';
import { compose, graphql, withApollo } from 'react-apollo';
import {
  inputPastMax,
  skipHolidays,
  serieSkipsHolidays
} from '../../../utils/functions';
import SelectInput from '../../general/selectInput';
import TextInput from '../../general/textInput';
import moment from 'moment';
import {
  days,
  splitSerie,
  validateOverlap,
  validateOverlapSedes
} from '../../../utils/resources/resourcesUtils';
Date.prototype.toJSON = function(){ return moment(this).format(); }

class UserForm extends Component {
  state = {
    allRole: null,
    allPosition: null,
    currentUser: null,
    errUser: {},
    signatureErrors: [],
    popoverIsOpen: false,
    passwordModal: false,
    confirmationModal: false,
    error: false,
    success: false,
    message: '',
    date: moment()['_d'],
    currentDay: moment(),
    momentDay: null,
    repeatedDays: null,
    currentWeek: [],
    currentSede: false
  };

  handleChangeTab = currentSede => {
    this.setState({ currentSede });
    this.getCurrentWeek(this.state.currentDay, currentSede);
  };

  async componentDidMount () {
    /**
     * GET SEDE
     */
    const {
      data: {
        getResourceType: { data: types }
      }
    } = await this.props.client.query({ query: GET_RESOURCE_TYPES });
    const sedeType = types.find(type => {
      return type.name === 'Sede';
    });
    const _resourceType = sedeType._id;
    const {
      data: {
        getResourceSede: { data: sedes }
      }
    } = await this.props.client.query({
      query: GET_RESOURCE_SEDE,
      variables: { _resourceType }
    });

    const responseRoles = await this.props.client.query({
      query: GET_ROLES
    });
    const responsePosition = await this.props.client.query({
      query: GET_POSITIONS
    });

    const sedeWithSeries = await this.props.client.query({
        query: GET_RESOURCE,
        variables: {page: 1, _resourceType: "5b646ef2d08dfcfeca46788b"}
    })

    const sedeSeries = {}
    sedeWithSeries.data.getResource.data.forEach( sede => {

      sedeSeries[sede._id] = JSON.parse(sede.series).map( serie => {
        serie.finalDate = moment(serie.finalDate)
        serie.initialDate = moment(serie.initialDate)
        return serie
      })

    })

    const allRole = responseRoles.data.allRole.data;
    const allPosition = responsePosition.data.allPosition.data;
    /* *************************************************** */

    const { formData, disabledFields } = this.props;
    const currentUser = Object.assign({}, this.props.currentUser);

    currentUser.services = [];
    currentUser.series = {};

    if (this.props.currentUser){



      try{
        currentUser.services = JSON.parse(this.props.currentUser.services);
        currentUser.series = JSON.parse(this.props.currentUser.series);
      }catch(err){

      }

    }
    const services = currentUser.services || [];
    const series = currentUser.series || {};

    const sedeSelected = currentUser.series?
      Object.keys(currentUser.series).map(sedeId => {
        return sedes.find(sede => {
          return sede._id === sedeId;
        });
      })
      : [];

    sedeSelected.forEach(sede => {
      series[sede._id] = series[sede._id].map(serie => {
        return {
          ...serie,
          initialDate: moment(serie.initialDate),
          finalDate: moment(serie.finalDate)
        };
      });
    });

    const currentSede = sedeSelected.length > 0 ? sedeSelected[0] : false;

    const currentRole = currentUser._role ? currentUser._role.role : '';
    const _id = currentUser._role ? currentUser._role._id : '';
    let position = currentUser._position ? currentUser._position._id : '';

    currentUser._position = position;
    currentUser._role = _id;

    const { name, lastName, active } = currentUser;
    const userAttributes =
      name && lastName ? { name, lastName } : { active: true };
    currentUser.active = active !== undefined ? active : true;
    this.setState(
      {
        currentUser,
        currentRole,
        allRole,
        allPosition,
        formData,
        disabledFields,
        userAttributes,
        sedeSelected,
        currentSede,
        sedes,
        sedeSeries,
        series,
        services
      },
      () => {
        this.getCurrentWeek(this.state.currentDay, currentSede);
      }
    );
  }

  getCurrentWeek = (currentDay, currentSede) => {
    if (currentSede) {
      const currentWeek = [];
      let day = moment(currentDay).isoWeekday(1);
      for (var i = 0; i < 7; i++) {
        let stringDay = days[day.day()];
        currentWeek.push({
          momentDay: day.clone(),
          title: stringDay + day.format(' D'),
          intervals: this.state.series[currentSede._id]
            .filter(serie => {
              return (
                serie.initialDate.startOf('day').diff(day.clone()) <= 0 &&
                serie.finalDate.endOf('day').diff(day.clone()) >= 0 &&
                serie.repeatedDays[day.clone().day()]
              );
            })
            .map(serie => {
              return { interval: serie.interval, serieId: serie.serieId };
            }),
        });
        day.add(1, 'day');
      }
      this.setState({ currentWeek });
    }
  };

  addSerie = (initial, interval, repeatedDays, final, previousSeries, skip) => {
    let { sedeSelected, series, sedeSeries, currentSede } = this.state;
    const selectedSedes = sedeSelected.map(sede => {
      return sede._id;
    });
    let updatedSerie = [...series[this.state.currentSede._id]];
    const seriesToAdd = skipHolidays(initial, final, interval, repeatedDays);
    var BreakException = {
      message: 'An error ocurred validating Schedule intervals overlapping',
    };

    if (skip) {
      try {
        seriesToAdd.forEach(serie => {
          if (validateOverlap(series, selectedSedes, serie)  ) {
            updatedSerie = updatedSerie.concat(serie);
            series = {
              ...this.state.series,
              [this.state.currentSede._id]: updatedSerie
            };

            this.setState(
              {
                momentDay: serie.initialDate,
                interval: serie.interval,
                repeatedDays: serie.repeatedDays,
                endCurrentDay: serie.finalDate,
                series,
                userAttributes: { ...this.state.userAttributes, series },
                currentUser: { ...this.state.currentUser, series }
              },
              () => {
                this.getCurrentWeek(initial, this.state.currentSede);
              }
            );
          } else {
            throw BreakException;
          }
        });
      } catch (e) {
        if (e === BreakException) {

          this.setState(
            {
              series: previousSeries ? previousSeries : this.state.series,
              message: 'Los horarios no se deben sobreponer',
              error: true
            },
            () => {
              this.getCurrentWeek(initial, this.state.currentSede);
            }
          );
        } else {
          throw e;
        }
      }
    } else {
      let serie = {
        serieId: `serie-${Math.floor(Math.random() * 1000000) + 1}`,
        initialDate: initial,
        repeatedDays,
        interval,
        finalDate: final
      };

      if (validateOverlap(series, selectedSedes, serie)  ) {
        updatedSerie = updatedSerie.concat(serie);
        series = {
          ...this.state.series,
          [this.state.currentSede._id]: updatedSerie
        };

        this.setState(
          {
            momentDay: serie.initialDate,
            interval: serie.interval,
            repeatedDays: serie.repeatedDays,
            endCurrentDay: serie.finalDate,
            series,
            userAttributes: { ...this.state.userAttributes, series },
            currentUser: { ...this.state.currentUser, series },
          },
          () => {
            this.getCurrentWeek(initial, this.state.currentSede);
          }
        );
      } else {

        this.setState(
          {
            series: previousSeries ? previousSeries : this.state.series,
            message: 'Los horarios no se deben sobreponer',
            error: true
          },
          () => {
            this.getCurrentWeek(initial, this.state.currentSede);
          }
        );
      }
    }
  };

  deleteSerie = (
    id,
    { deleteOption, initialDate, finalDate, callback, interval, repeatedDays }
  ) => {
    const previousSeries = { ...this.state.series };
    let series;
    let oldSeries = this.state.series[this.state.currentSede._id];
    const skip = serieSkipsHolidays(id, oldSeries);
    if (deleteOption === 1) {
      series = splitSerie(id, oldSeries, initialDate, initialDate);
    } else if (deleteOption === 2) {
      let currentSerieFinal = oldSeries
        .filter(serie => {
          return serie.serieId === id;
        })
        .map(serie => {
          return serie.finalDate;
        })
        .reduce((acum, val) => {
          return acum.diff(val) > 0 ? acum : val;
        });
      series = splitSerie(id, oldSeries, initialDate, currentSerieFinal);
    } else {
      // console.log('deleteOption==========', initialDate, finalDate);
      series = splitSerie(id, oldSeries, initialDate, finalDate);
    }
    series = {
      ...this.state.series,
      [this.state.currentSede._id]: series
    };
    this.setState(
      {
        series,
        userAttributes: { ...this.state.userAttributes, series },
        currentUser: { ...this.state.currentUser, series },
      },
      () => {
        if (callback){
          callback(
            initialDate,
            interval,
            repeatedDays,
            finalDate,
            previousSeries,
            skip
          );
        }
        else { this.getCurrentWeek(initialDate, this.state.currentSede); }
      }
    );
  };

  editInterval = (
    id,
    initialHour,
    finalHour,
    initialDate,
    finalDate,
    deleteOption
  ) => {
    const currentInterval = `${initialHour}-${finalHour}`;
    const currentSerie = this.state.series[this.state.currentSede._id].filter(
      serie => {
        return serie.serieId === id;
      }
    )[0];
    const repeatedDays = currentSerie.repeatedDays;
    const endDate = deleteOption === 2 ? currentSerie.finalDate : finalDate;
    this.deleteSerie(id, {
      deleteOption,
      initialDate,
      finalDate: endDate,
      callback: this.addSerie,
      interval: currentInterval,
      repeatedDays
    });
  };

  onChange = async date => {
    const currentDay = moment(date);
    this.setState({ currentDay });
    await this.getCurrentWeek(currentDay, this.state.currentSede);
  };

  createUser = async e => {
    e.preventDefault();
    const { showSuccessAlert } = this.props;
    const { userAttributes, currentUser, currentRole } = this.state;
    if (await this.validate(currentUser)) {
      if (currentRole !== 'enfermera' && currentRole !== 'medico') {
        delete userAttributes._position;
        delete userAttributes.signature;
        delete userAttributes.services;
        delete userAttributes.series;
      } else {
        for(const key in userAttributes.series){
          // console.log(JSON.stringify(userAttributes.series[key]))
          userAttributes.series[key] = userAttributes.series[key].map((serie)=>{
            serie.initialDate = serie.initialDate.startOf("day").toDate()
            serie.finalDate = serie.finalDate.endOf("day").toDate()

            return serie
          })
          // console.log(JSON.stringify(userAttributes.series[key]))
        }

        userAttributes.series = JSON.stringify(userAttributes.series);
        // debugger
        userAttributes.services = JSON.stringify(userAttributes.services);
      }

      if (currentRole !== 'medico') {
        delete userAttributes.medical_record;
      }

      let variables = Object.assign({}, userAttributes);

      if (currentUser._id) {
        variables._id = currentUser._id;
      }
      const succesMessage = 'Usuario guardado exitosamente';
      // return false;

      const result = currentUser._id
        ? await this.props.updateUser({ variables })
        : await this.props.createUser({ variables });

      localStorage.removeItem('canExitEdit');
      localStorage.removeItem('clickSidebar');

      if (!currentUser._id) {
        if (result.data.createUser.success) {
          const _id = result.data.createUser.data._id;
          this.setState({ success: true }, () => {
            showSuccessAlert(succesMessage, _id);
          });
        } else {
          const message = `${result.data.createUser.errors[0].message}`;
          this.setState({ error: true, success: false, message });
        }
      } else {
        if (result.data.updateUser.success) {
          this.setState({ success: true }, () => {
            showSuccessAlert(succesMessage, true);
          });
        } else {
          const message = `${result.data.updateUser.errors[0].message}`;
          this.setState({ error: true, success: false, message });
        }
      }
    } else {
      const message = this.state.errorMessages[0];
      this.setState({ error: true, success: false, message });
    }
  };
  updateField = (name, { value, name: role }) => {

    localStorage.setItem('canExitEdit', true);
    value = name === 'active' ? value === 'true' : value;
    if (role) {
      let currentRole =
        role === 'medico' || role === 'enfermera' || role === 'administrador'
          ? role
          : '';
      this.setState({
        userAttributes: { ...this.state.userAttributes, [name]: value },
        currentUser: { ...this.state.currentUser, [name]: value },
        currentRole
      });
    } else {
      this.setState({
        userAttributes: { ...this.state.userAttributes, [name]: value },
        currentUser: { ...this.state.currentUser, [name]: value },
      });
    }
  };
  validate = async ({
    name,
    lastName,
    dni,
    email,
    mobile,
    active,
    _role,
    _position,
    medical_record,
    signature,
    phone,
    services
  }) => {
    this.clearErrors();
    const { currentRole } = this.state;
    const errors = [];
    const errorMessages = [];

    if (!name) errors.push('name');
    if (!lastName) errors.push('lastName');
    if (!dni) errors.push('dni');
    if (!mobile) errors.push('mobile');
    if (!email) errors.push('email');
    if (active === '') errors.push('active');
    if (!_role) errors.push('_role');

    if (currentRole === 'medico' || currentRole === 'enfermera') {
      if (!_position) errors.push('_position');
      // !signature && errors.push("signature");

      if (currentRole === 'medico') {
        if (!medical_record) errors.push('medical_record');
        if (this.state.sedeSelected.length === 0) {
          errors.push('sede');
          errorMessages.push('Debes escoger por lo menos una sede');
        }
        if (services.length === 0) {
          errors.push('services');
          errorMessages.push('Debes escoger por lo menos una prestación');
        }
      }
    }

    if (errors.length > 0) {
      errorMessages.push('Datos obligatorios sin completar');
    }

    if (mobile && mobile.length !== 10) {
      errors.push('mobile');
      errorMessages.push('El móvil debe tener 10 dígitos de longitud');
    }
    if (phone && phone.length !== 7) {
      errors.push('phone');
      errorMessages.push('El teléfono debe tener 7 dígitos de longitud');
    }

    this.setError(errors, errorMessages);
    return errors.length > 0 ? false : true;
  };
  setPositions = (ids, positions) => {
    let currentPositions = [];
    ids.map(item => {
      const i = _.find(positions, { _id: item._id });
      return i && currentPositions.push(i);
    });
    return currentPositions;
  };
  renderText = ({
    labelActive,
    input,
    name,
    disablePaste,
    optional,
    label
  }) => {
    const disabled = this.props.disabledFields? this.props.disabledFields[name]: false;
    const { errUser, currentUser } = this.state;
    const max = input.type === 'date' ? inputPastMax() : null;
    return (
      <div
        className={errUser[`${name}`] && 'wrong-input'}
        onFocus={this.clearErrors}
      >
        <TextInput
          iconAfter={disabled ? 'contrasena' : false}
          max={max}
          disabled={disabled}
          optional={optional}
          labelActive={labelActive}
          value={currentUser[name] ? currentUser[name] : ''}
          type={input.type}
          name={name}
          label={label}
          updateField={this.updateField}
          disablePaste={disablePaste && disablePaste}
        />
      </div>
    );
  };
  renderSelect = ({ labelActive, name, label, without_blank }, options) => {
    const { errUser, currentUser } = this.state;
    let disabled = this.props.disabledFields
      ? this.props.disabledFields[name]
      : false;
    if (name === '_role' && this.props.currentUser) {
      disabled = true;
    }

    return (
      <div
        className={errUser[`${name}`] && 'wrong-input'}
        onFocus={this.clearErrors}
      >
        <SelectInput
          without_blank={without_blank}
          iconAfter={disabled ? 'contrasena' : false}
          disabled={disabled}
          value={
            currentUser[name] !== undefined ? currentUser[name].toString() : ''
          }
          name={name}
          label={label}
          updateField={this.updateField}
          options={options}
        />
      </div>
    );
  };

  validateSignature = file => {
    let signatureErrors = [];
    const validFormats = {
      'image/jpeg': true,
      'image/jpg': true,
      'application/pdf': true
    };
    if (!validFormats[file.type]) signatureErrors.push('El tipo de archivo es inválido');
    if (file.size >= 2000000) signatureErrors.push('El tamaño del archivo no debe ser mayor a 2Mb');
    if (signatureErrors.length > 0) {
      this.setError(['signature'], this.state.errorMessages);
      this.setState({ signatureErrors });
      return false;
    } else {
      this.setState({ signatureErrors: [] });
      return true;
    }
  };

  uploadFile = async file => {
    if (this.validateSignature(file)) {
      const reader = new FileReader();

      reader.onload = e => {
        file = e.target.result;

        this.setState({
          signature: file,
          userAttributes: {
            ...this.state.userAttributes,
            signature: file
          },
          currentUser: {
            ...this.state.currentUser,
            signature: file
          }
        });
      };

      reader.readAsDataURL(file);      
    }
  };
  togglePasswordModal = () => {
    this.setState({ passwordModal: !this.state.passwordModal });
  };
  clearErrors = () => {
    this.setState({ errUser: {}, error: false, signatureErrors: [] });
  };
  closePopover = () => {
    this.setState({ popoverIsOpen: false });
  };
  showPopover = () => {
    this.setState({ popoverIsOpen: true });
  };
  setError = (errorArray, errorMessages) => {
    const err = { ...this.state.errUser };
    errorArray.map(error => {
      return (err[error] = true);
    });
    this.setState({ errUser: err, errorMessages });
  };

  cancelRedirect = () => {
    localStorage.removeItem('canExitEdit');
    localStorage.removeItem('clickSidebar');
    this.props.exitEdit(false, true);
  };

  toggleConfirmationModal = validate => {
    const confirmationModal = !this.state.confirmationModal;
    this.setState({ confirmationModal });
    if (!confirmationModal) {
      localStorage.removeItem('clickSidebar');
    }
    if (!localStorage.getItem('canExitEdit')) this.cancelRedirect();
  };

  handleSedeSelected = (sede, push) => {
    localStorage.setItem('canExitEdit', true);
    let series = this.state.series;
    let sedeSelected = this.state.sedeSelected || [];
    if (push) {
      sedeSelected.push(sede);
      series[sede._id] = [];
    } else {
      _.remove(sedeSelected, { _id: sede._id });
      delete series[sede._id];
    }
    const isActive = sedeSelected.find(s => {
      return s._id === this.state.currentSede._id;
    });

    const currentSede =
      this.state.sedeSelected.length === 0
        ? false
        : isActive || this.state.sedeSelected[0];

    const userAttributes = { ...this.state.userAttributes, series };
    this.setState({ sedeSelected, series, currentSede, userAttributes });
    this.getCurrentWeek(this.state.currentDay, currentSede);
  };

  servicesSelected = servicesSelected => {
    localStorage.setItem('canExitEdit', true);
    const userAttributes = {
      ...this.state.userAttributes,
      services: servicesSelected
    };
    this.setState({
      userAttributes,
      currentUser: { ...this.state.currentUser, services: servicesSelected },
      services: servicesSelected
    });
  };

  handleUpdateSwitchField = (field, value) => {
    value = this.state.currentUser[field];
    console.log(field, value);
    this.updateField(field, { value: !Boolean(value) })
  }

  render () {
    if (
      localStorage.getItem('canExitEdit') &&
      localStorage.getItem('clickSidebar') &&
      !this.state.confirmationModal
    ) {
      this.toggleConfirmationModal();
    }

    const {
      currentUser,
      formData,
      allRole,
      allPosition,
      errUser,
      popoverIsOpen,
      message,
      error,
      success,
      passwordModal,
      confirmationModal,
      signatureErrors,
      sedes,
      sedeSelected,
      currentSede,
      date,
      currentDay,
      momentDay,
      repeatedDays,
      currentWeek,
      series,
      services
    } = this.state;
    if (!allRole) {
      return <div />;
    }
    const popoverProps = {
      isOpen: popoverIsOpen,
      preferPlace: 'above',
      place: 'above',
      onOuterAction: () => this.closePopover(),
      body: [
        <div className="popover-container" key={1}>
          <p className="helper-msg">
            La imagen de la firma debe tener los siguientes requerimientos:
          </p>
          <ul>
            <li>Tipo de archivo: JPG</li>
            <li>Tamaño: 2 Megas (máximo)</li>
          </ul>
        </div>
      ]
    };
    return (
      <Fragment>
        <Form
          currentUser={currentUser}
          editInterval={this.editInterval}
          sedes={sedes}
          formData={formData}
          signatureErrors={signatureErrors}
          renderText={this.renderText}
          renderSelect={this.renderSelect}
          roles={allRole}
          positions={allPosition}
          errUser={errUser}
          popoverProps={popoverProps}
          pathname={this.props.location.pathname}
          passwordModal={passwordModal}
          togglePasswordModal={this.togglePasswordModal}
          showPopover={this.showPopover}
          uploadFile={this.uploadFile}
          cancelRedirect={this.cancelRedirect}
          handleSedeSelected={this.handleSedeSelected}
          servicesSelected={this.servicesSelected}
          sedeSelected={sedeSelected}
          message={message}
          error={error}
          clearErrors={this.clearErrors}
          success={success}
          toggleConfirmationModal={this.toggleConfirmationModal}
          createUser={this.createUser}
          confirmationModal={confirmationModal}
          setPositions={this.setPositions}
          handleChangeTab={this.handleChangeTab}
          currentSede={currentSede}
          fields={{
            date,
            currentDay,
            momentDay,
            repeatedDays,
            currentWeek,
            series
          }}
          getCurrentWeek={this.getCurrentWeek}
          addSerie={this.addSerie}
          deleteSerie={this.deleteSerie}
          onChange={this.onChange}
          services={services}
          handleUpdateSwitchField={this.handleUpdateSwitchField}
        />
      </Fragment>
    );
  }
}
UserForm.propTypes = {
  client: PropTypes.object,
  updateUser: PropTypes.func,
  createUser: PropTypes.func,
  currentUser: PropTypes.object,
  disabledFields: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default compose(
  graphql(CREATE_USER, { name: 'createUser' }),
  graphql(UPDATE_USER, { name: 'updateUser' })
)(withRouter(withApollo(UserForm)));

/* eslint-disable */
import React, { Component } from 'react';
import Top5Chart from './chart/top5chart';
import Top5ChartSpecialty from './chart/top5chartspecialty';
import BenefitChart from './chart/benefitchart';
import EpsPolicyChart from './chart/epspolicychart';
import ReferredUsersChart from './chart/referreduserschart';
import './dashboard.css';
import '../../assets/styles/bootstrap.min.css';
import client from '../../utils/apollo';
import { GET_SERVICESFAMILY } from '../../graphql/types/servicesFamilyModule/queries';
import { withRouter } from 'react-router-dom';
import { context } from '../../providers/dashboardProvider';
import PhysicianByReferralTable from './physicianByReferralTable';
import dashboardIcon from '../../assets/images/sidebar/ic-dashboard.svg';
import BodyContainer from "../../components/general/bodyContainer"

class dashboard extends Component {

  static contextType = context;

  constructor(props) {
    super(props);

    this.state = {
      serviceFamilies: []
    };
  }

  getServiceFamilies = async () => {
    let serviceFamilies;

    try {
      const result = await client.query({
        query: GET_SERVICESFAMILY
      });

      serviceFamilies = result.data.getServicesFamily.data;

    } catch (error) {
      serviceFamilies = [];
    }

    this.setState({ 'serviceFamilies':  serviceFamilies });
  };

  componentDidMount() {
    this.getServiceFamilies();

    this.context.search();
  }

  render() {
    const { serviceFamilies } = this.state;
    
    const { 
      loading,
      setFilter,
      search,
      filters: {
        dateFrom,
        dateTo,
        serviceFamily
      }
    } = this.context;

    return (
      <BodyContainer isLoader={loading}>
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper">
              <div className="page-title-heading">
                <div className="page-title-icon">
                  <img src={dashboardIcon} />
                </div>
                <div> Dashboard </div>
              </div>
              <div className="page-title-actions">
                <button 
                  type="button" 
                  data-toggle="tooltip" 
                  title="Exportar" 
                  data-placement="bottom" 
                  className="btn-shadow mr-3 btn btn-info" 
                  onClick={() => this.context.export()}
                >
                  Exportar 
                </button>

                <button 
                  type="button" 
                  data-toggle="tooltip" 
                  title="Exportar" 
                  data-placement="bottom" 
                  className="btn-shadow mr-3 btn btn-info" 
                  onClick={() => this.context.exportStickers()}
                >
                  Exportar Stickers
                </button>
              </div>   
            </div>

            <div className="row">
              <div className="cards no-shadow bg-transparent no-border rm-borders mb-3">
                <div className="cards">
                  <div className="col-md-12 col-lg-12">
                    <div className="row">

                      <div className="mb-2 mb-sm-0 col-lg-3">
                        Desde
                        <input 
                          name="desde" 
                          id="desde" 
                          type="date" 
                          className="form-control" 
                          value={dateFrom} 
                          onChange={e => setFilter('dateFrom', e.target.value)}
                        />
                      </div>

                      <div className="mb-2 mb-sm-0 col-lg-3">
                        Hasta
                        <input 
                          name="hasta" 
                          id="hasta" 
                          type="date" 
                          className="form-control" 
                          value={dateTo} 
                          onChange={e => setFilter('dateTo', e.target.value)}
                        />
                      </div>

                      <div className="col-md-3 col-lg-3">
                        Familia
                        <select 
                          className="mb-2 form-control"
                          onChange={e => setFilter('serviceFamily', e.target.value)}
                          value={serviceFamily}
                        >
                          <option value="">Seleccione</option>
                          {serviceFamilies.map(serviceFamily => (
                            <option 
                              key={serviceFamily._id} 
                              value={serviceFamily._id}
                            >
                              { serviceFamily.name }
                            </option>
                          ))}
                        </select>
                      </div>     

                      <div className="col-lg-3">
                        <button 
                          className="btn btn-info w-100" 
                          style={{marginTop: '21px'}}
                          onClick={() => search()}
                        >
                          Buscar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="main-card mb-3 cardz">
                      <PhysicianByReferralTable />
                    </div>
                    
                    <div className="main-card mb-3 cardz">
                      <BenefitChart />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="main-card mb-3 cardz">
                      <Top5Chart />
                    </div>
                    
                    <div className="main-card mb-3 cardz">
                      <Top5ChartSpecialty />
                    </div>

                    <div className="main-card mb-3 cardz">
                      <EpsPolicyChart />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                {/* <div className="main-card mb-3 cardz">
                  <ReferredUsersChart
                    loading='false'
                    data={referredusers}
                  >
                  </ReferredUsersChart>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </BodyContainer>     
    );
  }
}

export default withRouter(dashboard);

/**
 * PatientForm
 */
/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// my components
import ModalPhoto from '../modalPhoto';
import PatientFormData from '../../../utils/patients/patientFormData';

import Cities from '../../../utils/cities/citiesWithDepartment';

import './style.css';
import { useBillingForm } from '../../../hooks/useBillingForm';

const PatientForm = (props) => {
  const acumDni = [];
  const {
    eps,
    photo,
    image,
    modalPhoto,
    savePatient,
    stateScanner,
    errorMessage,
    programTypes,
    getScreenshot,
    successMessage,
    currentPatient,
    showModalPhoto,
    isExecuteScanner,
    toggleConfirmationModal,
    renderText,
    renderRadio,
    renderSelect,
    renderTypeHead,
    renderTextarea,
    renderSelectSearch,

    onLoadScanner,

    canUpdatePatient
  } = props;

  const { contactPreference } = currentPatient;
  const inputsScanner = ['dni', 'name', 'gender', 'type_dni', 'birthDay', 'lastName'];
  let acumPatternDni = '';
  let typeDniSelected;

  const [professions, setProfessions] = useState([]);

  const {
    loadOptionsProfessions,
    getAllSelects
  } = useBillingForm({
    updateField: {}
  });

  const handleLoadOptionsProfessions = async () => {
    const professions = await loadOptionsProfessions();
    setProfessions(professions);
  }

  return (
    <Fragment>
      { showModalPhoto && <ModalPhoto modalPhoto={modalPhoto} getScreenshot={getScreenshot} /> }
      <div className="patient-form">
        <div className="patient-fields grid-wrapper">
          {
            PatientFormData['parte1'].map((field, index) => {
              let beforeObservations = null;
              if (!field.input){
                let style = {};
                if (field.type === 'separator') style={ display: 'none' };
                return (
                  <Fragment key={index}>
                    <div className="formTitle" style={style}>{field.label}</div>
                  </Fragment>
                );
              }

              if (inputsScanner.indexOf(field.name) !== -1 && isExecuteScanner){ field.disabled = true; }
              if (field.name === 'observations') {
                let iconScanner = 'icon-scan_id';
                if (stateScanner === 'search'){ iconScanner = 'icon-reloj'; }
                else if (stateScanner === 'loaded'){ iconScanner = 'icon-check'; }
                else if (stateScanner === 'error'){ iconScanner = 'icon-scan_id error'; }
                beforeObservations = currentPatient &&
                  currentPatient._id && (
                  <div key={index} className={`flex f-r contentIconPhotoPatient col3 ${photo.camera || photo.photo? 'isphoto': ''}`}>
                    <div className="flex f-r a-i-c " onClick={onLoadScanner}>
                      <div className="flex j-c a-i-c iconPhotoPatient">
                        <span className={iconScanner} />
                      </div>
                      <span>
                          Escanear
                        <br /> Cédula
                      </span>
                    </div>
                    <div className="flex f-r a-i-c" onClick={() => modalPhoto(true)}>
                      <div
                        className="flex j-c a-i-c iconPhotoPatient"
                        style={
                          photo
                            ? {
                              backgroundImage: photo.camera
                                ? `url(${photo.photo})`
                                : `url(data:image/gif;base64,${image})`
                            }
                            : {}
                        }
                      >
                        <span className={`icon-photo ${photo.camera? 'default': 'white'}`} />
                      </div>
                      <span>Foto</span>
                    </div>
                  </div>
                );
              }
              else if (field.name === 'type_dni'){
                field.classLabel = 'active';
                typeDniSelected = (field.input.options).find((item) => item._id === currentPatient['type_dni']);
                
                acumDni.push(
                  <Fragment key="field-type-dni">{ renderSelect(field, field.input.options) }</Fragment>
                );
                return;
              }
              else if (field.name === 'dni'){
                if (typeDniSelected && typeDniSelected.pattern) field.pattern = typeDniSelected.pattern;
                acumDni.push(
                  <Fragment key="field-dni">{ renderText(field) }</Fragment>
                );
                return (
                  <div key={`dni-${index}`} className="cell-form dni col1">
                    {acumDni}
                  </div>
                );
              }
              else if (field.name === 'phone' || field.name === 'mobile'){
                field.optional = (field.name === contactPreference)? false: true;
              }              
              return (
                <Fragment key={field.name}>
                  {field.input.name === 'text' && renderText(field)}
                  {field.input.name === 'select' && renderSelect(field, field.input.options)}
                  {field.input.name === 'textarea' && [beforeObservations, renderTextarea(field, field.input.options)]}
                </Fragment>
              );
            })
          }
          {
            currentPatient && currentPatient._id &&
              PatientFormData['parte2'].map((field, index) => {
                if (!field.input){
                  let style = {};
                  if (field.type === 'separator') style={ display: 'none' };
                  return (
                    <Fragment key={index}>
                      <div className="formTitle" style={style}>{field.label}</div>
                    </Fragment>
                  );
                }
                if (inputsScanner.indexOf(field.name) !== -1 && isExecuteScanner){ field.disabled = true; }
                if (field.name === 'eps' && eps) {
                  field.input.options = eps;
                }
                else if (field.name === 'programType'){ field.input.options = programTypes; }                
                
                return (
                  <Fragment key={field.name}>
                    {field.input.name === 'text' && renderText(field)}
                    {field.input.name === 'radio' && renderRadio(field)}
                    {field.input.name === 'select' && renderSelect(field, field.input.options)}
                    {field.input.name === 'textarea' && renderTextarea(field, field.input.options)}
                    {field.input.name === 'typehead' && renderTypeHead(field, Cities)}
                    {field.input.name === 'selectSearch' && renderSelectSearch(field)}
                  </Fragment>
                );
              })
          }
        </div>
      </div>
      <div className="user-form-actions flex f-c a-i-c j-c mb-1 mt-1">
        { errorMessage && <p className="text-center error-msg">{errorMessage}</p> }
        { successMessage && <p className="text-center success-msg">{successMessage}</p> }
        <div className="text-center flex j-c">
          <button
            type="reset"
            className="button save-user button-sm bg-muted"
            onClick={toggleConfirmationModal}
          >
            Cancelar
          </button>
          {canUpdatePatient && 
            <button
              className="button ripple save-user button-sm bg-icon"
              type="buttom"
              onClick={savePatient}
            >
              Guardar
            </button>
          }
        </div>
      </div>
    </Fragment>
  );
};

PatientForm.propTypes = {
  eps: PropTypes.array,
  photo: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  location: PropTypes.object,
  modalPhoto: PropTypes.func,
  savePatient: PropTypes.func,
  stateScanner: PropTypes.oneOf(['default', 'search', 'loaded', 'error']),
  errorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  programTypes: PropTypes.array,
  getScreenshot: PropTypes.func,
  successMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  currentPatient: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  showModalPhoto: PropTypes.bool,
  isExecuteScanner: PropTypes.bool,
  image: PropTypes.func,
  toggleConfirmationModal: PropTypes.func,

  renderText: PropTypes.func,
  renderRadio: PropTypes.func,
  renderSelect: PropTypes.func,
  renderTypeHead: PropTypes.func,
  renderTextarea: PropTypes.func,

  onLoadScanner: PropTypes.func
};

export default PatientForm;

/**
 * FormTextArea
 */

import React from 'react';
import ProptTypes from 'prop-types';

import './style.css';

const FormTextArea = ({
  name,
  rows,
  label,
  value,
  state,
  cols,
  onChange,
  classLabel,
  styleLabel,
  placeholder,
  styleTextArea,
  classTextArea
}) => {
  const objTextArea = {
    name,
    value,
    style: styleTextArea,
    className: `font-14 ${classTextArea}`,
    placeholder
  };

  if (rows) objTextArea.row = rows;
  if (cols) objTextArea.cols = cols;
  if (state) objTextArea[state] = state;

  return (
    <div className={`form-textarea textarea-${name}`}>
      {
        label &&
          <div className="container-label">
            <label htmlFor={name} className={`${state} ${classLabel}`} style={styleLabel}>{label}</label>
          </div>
      }
      <textarea
        {...objTextArea}
        onChange={e => onChange(e, name)}
      />
    </div>
  );
};

FormTextArea.propTypes = {
  state: ProptTypes.string, // ['enable', 'disable', 'hiden', 'readonly'],
  rows: ProptTypes.number,
  name: ProptTypes.string,
  label: ProptTypes.string,
  value: ProptTypes.string,
  classLabel: ProptTypes.string,
  styleLabel: ProptTypes.object,
  onChange: ProptTypes.func,
  placeholder: ProptTypes.string,
  classTextArea: ProptTypes.string,
  styleTextArea: ProptTypes.object
};

FormTextArea.defaultProps = {
  state: 'enable',
  onChange: ()=>{},
  classLabel: '',
  styleLabel: {},
  placeholder: '',
  classTextArea: '',
  styleTextArea: {}
};

export default FormTextArea;

/**
 * UserInfo
 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import Calendar from "react-calendar/dist/entry.nostyle";
import moment from "moment";
import ScheduleWeek from "../../../components/resourcesModule/scheduleWeek";
import { days } from "../../../utils/resources/resourcesUtils";
import { withApollo } from "react-apollo";
import { GET_SERVICES } from "../../../graphql/types/servicesModule/queries";
import {
  GET_RESOURCE_TYPES,
  GET_RESOURCE_SEDE
} from "../../../graphql/types/resourcesModule/queries";

import "./style.css";

class UserInfo extends Component {
  state = {
    date: moment()["_d"],
    currentDay: moment(),
    currentResource: false,
    series: [],
    services: []
  };

  componentDidMount = async () => {
    const {
      data: {
        getResourceType: { data: types }
      }
    } = await this.props.client.query({ query: GET_RESOURCE_TYPES });
    const sedeType = types.find(type => {
      return type.name === "Sede";
    });
    const _resourceType = sedeType._id;
    const {
      data: {
        getResourceSede: { data: sedes }
      }
    } = await this.props.client.query({
      query: GET_RESOURCE_SEDE,
      variables: { _resourceType }
    });

    const { currentUser } = this.props;
    if (currentUser) {
      const role = this.props.currentUser._role.role;
      if (role === "medico" || role === "enfermera") {
        let series = {};
        if (currentUser.series) {
          try {
            series = JSON.parse(currentUser.series);
            const userSedes = Object.keys(series).map(sedeId => {
              return sedes.find(sede => {
                return sede._id === sedeId;
              });
            });

            userSedes.forEach(sede => {
              series[sede._id] = series[sede._id].map(serie => {
                return {
                  ...serie,
                  initialDate: moment(serie.initialDate),
                  finalDate: moment(serie.finalDate)
                };
              });
            });
            const currentSede = userSedes[0];
            this.setState(
              { series, sedes, userSedes, currentSede },
              this.parseServices
            );
          } catch (e) {
            series = [];
            this.parseServices();
          }
        } else {
          series = [];
          this.parseServices();
        }
      }
    }
  };

  parseServices = async () => {
    const role = this.props.currentUser._role.role;
    if (role === "medico" || role === "enfermera") {
      const {
        data: {
          getServices: { data: allServices }
        }
      } = await this.props.client.query({
        query: GET_SERVICES,
        variables: { perPage: 500 }
      });
      let services = this.props.currentUser.services || "[]";
      while (typeof services !== "object") {
        services = JSON.parse(services);
      }

      let parsedServices = services.map(serviceId => {
        // debugger
        let scopeDataService = allServices.filter(service => {
          return service._id === serviceId._id;
        });
        return scopeDataService[0] ? scopeDataService[0].name : "";
      });
      // 5b74aee31937443c69aa7189
      //5b747bc11937443c69aa715b

      this.setState(
        {
          services: parsedServices
        },
        () => {
          this.getCurrentWeek(this.state.currentDay);
        }
      );
    }
  };

  getCurrentWeek = currentDay => {
    if (!this.state.currentSede) {
      return false;
    }
    const currentWeek = [];
    const day = moment(currentDay).isoWeekday(1);
    for (var i = 0; i < 7; i++) {
      const stringDay = days[day.day()];

      currentWeek.push({
        momentDay: day.clone(),
        title: stringDay + day.format(" D"),
        intervals: this.state.series[this.state.currentSede._id]
          .filter(serie => {
            return (
              serie.initialDate.startOf("day").diff(day.clone()) <= 0 &&
              serie.finalDate.endOf("day").diff(day.clone()) >= 0 &&
              serie.repeatedDays[day.clone().day()]
            );
          })
          .map(serie => {
            return { interval: serie.interval, serieId: serie.serieId };
          })
      });
      day.add(1, "day");
    }
    this.setState({ currentWeek });
  };

  onChange = async date => {
    const currentDay = moment(date);
    this.setState({ currentDay });
    await this.getCurrentWeek(currentDay);
  };

  renderServices = () => {
    return this.state.services.map((service, index) => {
      return (
        <div key={index} className="resource-service">
          {service}
        </div>
      );
    });
  };

  handleChangeTab = sede => {
    this.setState({ currentSede: sede }, () => {
      this.getCurrentWeek(this.state.currentDay);
    });
  };

  _componentFieldName = (field, currentUser) => {
    if (field.name === "_role" || field.name === "_position") {
      return (
        <div className="flex j-e a-i-c">
          {currentUser[field.name] ? currentUser[field.name].name : ""}
        </div>
      );
    } else if (field.name === "active") {
      return currentUser.active === true ? (
        <div className="flex j-e a-i-c">activo</div>
      ) : (
        <div className="flex j-e a-i-c">inactivo</div>
      );
    } else {
      return (
        <div className="flex j-e a-i-c">
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
          >
            {currentUser[field.name]}
          </span>
        </div>
      );
    }
  };

  render() {
    const {
      currentDay,
      date,
      currentWeek,
      services,
      userSedes,
      currentSede
    } = this.state;
    const { currentUser, userFields, editUser } = this.props;

    if (userFields === null) {
      return <p className="text-center mt-2">Cargando usuario...</p>;
    }
    return (
      <div>
        <div className="userInfo-container flex f-c f-w a-i-c">
          {userFields.map((field, index) => {
            return (
              <div className="flex j-s a-i-c user-field" key={index}>
                <div className="flex j-s a-i-c">{field.label}</div>
                {this._componentFieldName(field, currentUser)}
              </div>
            );
          })}
        </div>
        <Accordion accordion={false}>
          {(currentUser._role.role === "medico" ||
            currentUser._role.role === "enfermera") &&
            services.length > 0 && (
              <AccordionItem uuid="uniqueItem-services" expanded={true}>
                <AccordionItemTitle
                  className="accordion__title accordion__title--animated u-position-relative ripple-title"
                  onClick={() => {}}
                >
                  <span>Servicios</span>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <div className="text-center font-14 resource-services flex j-c f-w">
                    {this.renderServices()}
                  </div>
                </AccordionItemBody>
              </AccordionItem>
            )}
          {(currentUser._role.role === "medico" ||
            currentUser._role.role === "enfermera") &&
            currentWeek && (
              <Fragment>
                <div className="contentServices flex">
                  {userSedes.map((sede, i) => (
                    <div
                      key={i}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleChangeTab(sede)}
                      className={`cs_nav ${userSedes.length === 1 &&
                        "tabOne"} ${
                        currentSede._id === sede._id
                          ? "select ripple-title"
                          : "ripple"
                      }`}
                    >
                      <span>{sede.name}</span>
                    </div>
                  ))}
                </div>

                <AccordionItem uuid="uniqueItem-calendar" expanded={true}>
                  <AccordionItemTitle
                    className="accordion__title accordion__title--animated u-position-relative accordion_title_user_service ripple-title"
                    onClick={() => {}}
                  >
                    <span>Calendario</span>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <div className="flex f-w j-sb">
                      <Calendar
                        // formatShortWeekday={value =>
                        // 	['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]
                        // }
                        onChange={this.onChange}
                        value={date}
                      />
                      <ScheduleWeek
                        currentWeek={currentWeek}
                        currentDay={
                          days[currentDay.day()] + currentDay.format(" D")
                        }
                        ResourceDate={currentDay}
                      />
                    </div>
                  </AccordionItemBody>
                </AccordionItem>
              </Fragment>
            )}
        </Accordion>

        <div className="text-center mt-2 mb-1">
          <button
            className="button ripple save-user button-sm bg-icon edit-button"
            onClick={e => editUser(currentUser._id, e)}
          >
            Editar perfil
          </button>
        </div>
      </div>
    );
  }
}
UserInfo.propTypes = {
  client: PropTypes.object,
  editUser: PropTypes.func,
  userFields: PropTypes.array,
  currentUser: PropTypes.object
};
export default withRouter(withApollo(UserInfo));

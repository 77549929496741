import { withApollo } from 'react-apollo';
import React from 'react';
import { GET_SERVICE_RESOURCES } from '../../../graphql/types/servicesModule/queries';

class ServiceResourcesDetail extends React.Component {

  constructor(props) {
    super(props)
    this.state = { isLoading: true, isError: false, resources: null, bigestResourceSize: 0 }
  }

  componentDidMount = async () => {
    try {
      const { serviceId } = this.props;
      const response = (await this.props.client.query({ query: GET_SERVICE_RESOURCES, variables: { id: serviceId } })).data.getResourcesOfService
      const { preparedResources: resources, bigestResourceSize } = this.prepareData(response.data);
      this.setState({ resources, bigestResourceSize, isLoading: false });
    } catch (e) {
      console.log(e)
      this.setState({ isError: true, isLoading: false });
    }
  }

  convertToTitleName = (name) => {
    const capitalizeName = (name[0].toUpperCase() + name.slice(1))
    const replaceUnderscores = capitalizeName.replace(/_/g, ' ')
    return replaceUnderscores;
  }

  prepareData = (resources) => {

    const sortFunction = (a, b) => a.name.toUpperCase() < b.name.toUpperCase ? -1 : 1
    const profesionales = resources.filter(resource => resource.resourceCode === 'doctor').map(resource => ({ ...resource, link: `/users/${resource._id}` })).sort((a, b) => sortFunction(a, b));
    const equipo = resources.filter(resource => resource.resourceCode === 'equipo').map(resource => ({ ...resource, link: `/resources/${resource._id}` })).sort((a, b) => sortFunction(a, b));
    const sede = resources.filter(resource => resource.resourceCode === 'sede').map(resource => ({ ...resource, link: `/resources/${resource._id}` })).sort((a, b) => sortFunction(a, b));
    const espacio_de_trabajo = resources.filter(resource => resource.resourceCode === 'espacioTrabajo').map(resource => ({ ...resource, link: `/resources/${resource._id}` })).sort((a, b) => sortFunction(a, b));

    const bigestResourceSize = Math.max(profesionales.length, equipo.length, sede.length, espacio_de_trabajo.length)

    const preparedResources = {
      profesionales,
      equipo,
      sede,
      espacio_de_trabajo
    }

    return { preparedResources, bigestResourceSize };
  }

  renderResource = (resources, resourceName, numberOfRows) => (
    Array(numberOfRows).fill().map((_, index) => {
      const existResource = index < resources[resourceName].length
      const resource = existResource ? resources[resourceName][index] : { name: 'Sin recurso', link: '#' }
      return (
        <tr key={index} >
          <td style={{ display: "flex", justifyContent: "center" }}>
            {existResource && <a style={{ color: "#689AB5", textDecoration: "underline" }} href={resource.link} >{resource.name}</a>}
            {!existResource && resource.name}
          </td>
        </tr>
      )
    })
  )

  render() {
    const { isLoading, isError, resources, bigestResourceSize } = this.state
    if (isError) return <p>Error</p>
    if (isLoading) return <p>Cargando...</p>;
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        padding: "0 40px 0 40px",

      }}>
        {['profesionales', 'equipo', 'sede', 'espacio_de_trabajo'].map((resourceName, index) => (
          <table key={index} className="list" style={index === 0 ? {borderLeft: "solid black 1px", borderRight: "solid black 1px"} : {borderRight: "solid black 1px"}} >
            <thead>
              <tr>
                <th>{this.convertToTitleName(resourceName)}</th>
              </tr>
            </thead>

            <tbody>
              {this.renderResource(resources, resourceName, bigestResourceSize)}
            </tbody>
          </table>

        ))}
      </div>

    )
  }
}

export default withApollo(ServiceResourcesDetail);

import gql from 'graphql-tag';

export const GET_USER_HEADER = gql`
	query getUser($_id: ID!) {
		getUser(_id: $_id) {
			data {
				name
				lastName
				phone
				_role {
					_id
					name
          permission
          queries
				}
			}
			success
			errors {
				path
				message
			}
		}
	}
`;
export const GET_USER_SERVICE = gql`
	query getUserService($services: String) {
		getUserService(services: $services) {
			data {
				_position {
					_id
					name
					position
				}
			}
			success
			errors {
				path
				message
			}
		}
	}
`;
export const GET_USER_DOCTORS = gql`
	query getUserDoctors($doctors: String!) {
		getUserDoctors(doctors: $doctors) {
			data {
				_id
				name
				lastName
			}
			success
			errors {
				path
				message
			}
		}
	}
`;
export const GET_USER = gql`
	query getUser($_id: ID!) {
		getUser(_id: $_id) {
			data {
				_id
				birthDay
				username
				address
				name
				lastName
				dni
				phone
				mobile
				email
				active
				signature
				series
				services
				_role {
					_id
					name
					role
				}
				medical_record
				_position {
					_id
					name
				}
				canApproveMaps
				canPublishMaps
			}
			success
			errors {
				path
				message
			}
		}
	}
`;
export const VERIFY_TOKEN_QUERY = gql`
	query verifyTokenMail($token: String!) {
		verifyTokenMail(token: $token) {
			success
		}
	}
`;

export const GET_ROLES = gql`
	query getRoles {
		allRole {
			success
			data {
				_id
				name
				role
				position {
					_id
				}
			}
		}
	}
`;
export const GET_POSITIONS = gql`
	query getRoles {
		allPosition {
			success
			data {
				_id
				name
				position
			}
		}
	}
`;

export const ALL_USERS = gql`
	query allUsers(
		$page: Int
		$_role: ID
		$_position: ID
		$active: Boolean
		$name: String
		
	) {
		allUsers(
			page: $page
			_role: $_role
			_position: $_position
			active: $active
			name: $name
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				fullName
				dni
				email
				mobile
				_position {
					name
				}
				_role {
					name
				}
				active
			}
		}
	}
`;
export const CURRENTLOGIN = gql`
	query getcurrentLogin {
		getcurrentLogin @client {
			status
		}
	}
`;

export const USER_HISTORY = gql`
	query allHistoryUser(
		$_user: ID!
		$days: Int
		$action: String
		$page: Int
		$fullName: String
	) {
		allHistoryUser(
			_user: $_user
			days: $days
			action: $action
			page: $page
			fullName: $fullName
		) {
			success
			errors {
				path
				message
			}
			data {
				_patient_edit {
					fullName
				}
				_user_edit {
					name
					lastName
				}
				description
				action
				date_created
			}
		}
	}
`;

export const SHOW_ALERT = gql`
	query showAlert {
		showAlert @client {
			status
		}
	}
`;

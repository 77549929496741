import { elapsedTime, initialDate, finalDate } from "../../../utils/helpers";

export const informationGeneral = ({informationGeneralPatient}) => {
  const { 
    size,
    weight,
    bmi,
    status,
    physicalActivity,
    pregnant,
    smoke,
    nameEquipment,
    equipmentReference,
    dateInitTimestamp,
    dateInit,
  }= informationGeneralPatient;

  return `
    <div class="salto-pagina information-general" style="width: 96.5%">
      <p>Talla (cms): <strong>${size ?? ""}</strong></p>
      <p>Peso (Kg): <strong>${weight ?? ""}</strong></p>
      <p>BMI: <strong>${bmi ?? ""}</strong></p>
      ${status?`<p>status: <strong>${status}</strong></p>`:`<p style="display:none"></p>`}
      <p>Actividad fisica: <strong>${physicalActivity ?? ""}</strong></p>
      <p>Fuma: <strong>${smoke ?? ""}</strong></p>
      ${pregnant && pregnant ==="Si" ?`<p>Embarazada: <strong>Si</strong></p>`:`<p style="display:none"></p>`}
      <p>Equipo: <strong>${nameEquipment ?? ""}</strong></p>
      <p>Referencia: <strong>${equipmentReference ?? ""}</strong></p>
      <p>Dias transcuridos: <strong>${elapsedTime(dateInitTimestamp) ?? ""}</strong></p>
      <div style="grid-column: span 3/span 3;display: grid; grid-template-columns: repeat(2,1fr);">
        <p>Fecha inicio: <strong>${initialDate(dateInit) ?? ""}</strong></p>
        <p>Fecha final: <strong>${finalDate(dateInit) ?? ""}</strong></p>
      </div>
    </div>
    `;
  };

import React, { Component } from 'react';
import { PatientConsumer } from '../../../providers/patientProvider';

import PatientAppointment from './index';

class AppointmentSchedule extends Component {
  render () {
    return (
      <PatientConsumer>
        {props => <PatientAppointment {...props} />}
      </PatientConsumer>
    );
  }
}

export default AppointmentSchedule;

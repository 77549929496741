import axios from "axios"

// eslint-disable-next-line
const generatePdf = process.env.REACT_APP_GENERATE_PDF_URL;
// eslint-disable-next-line
const generatePdfLocal = process.env.REACT_APP_GENERATE_PDF_URL_LOCAL;
const urlResultados = process.env.REACT_APP_API_URL_RESULTADOS;

export const fetchWithTokenPDF = ( {endpoint, data, method = 'GET', blob = false, content=false} ) => {

  const url = `${generatePdf}/${ endpoint }`
  const token = localStorage.getItem('refreshToken') || ''
  const config = {
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type' : !content ? 'application/json' : content
      },
      ...(blob ? { responseType: 'blob' } : {})
  }

  switch (method) {
      case "POST":
          return axios.post( url, data, config )
      
      case "GET":
          return axios.get( url, config )

      default:
          return new Error("Debe enviar un metodo válido");
  }
};

export const fetchWithTokenResults = ( {endpoint, data, method = 'GET', blob = false, content=false} ) => {
  
  const url = `${urlResultados}/${ endpoint }`
  const config = {
      headers: {
          'Content-Type' : !content ? 'application/json' : content
      },
      ...(blob ? { responseType: 'blob' } : {})
  }

  switch (method) {
      case "POST":
          return axios.post( url, data, config )
  
      case "PUT":
          return axios.put( url, data, config )
      
      case "DELETE":
          return axios.delete( url, config )
      
      case "PATCH":
          return axios.patch( url, data, config )
      
      case "GET":
          return axios.get( url, config )

      default:
          return new Error("Debe enviar un metodo válido");
  }
}

/**
 * TextAreaInput component
 */
import React from 'react';

const TextAreaInput = ({ name, value, updateField, rows }) => {
  return (
    <div className="textArea-container">
      <textarea
        className="font-14"
        rows={rows}
        name={name}
        value={value}
        onChange={e => {
          updateField(name, { value: e.target.value, aux: null });
        }}
      />
    </div>
  );
};

export default TextAreaInput;

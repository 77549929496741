/**
 * User
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';

// my components
import UserForm from '../../../components/usersModule/userForm';
import UserInfo from '../../../components/usersModule/userInfo';
import ActionHistory from '../../../components/usersModule/actionHistory';
import BodyContainer from '../../../components/general/bodyContainer';
import { GET_USER } from '../../../graphql/types/usersModule/queries';

import UserFormData from '../../../utils/users/userFormData.json';
import UserInfoData from '../../../utils/users/userInfoData.json';
import 'react-accessible-accordion/dist/fancy-example.css';

class User extends Component {
  state = {
    edit: false,
    newUser: false,
    isLoader: true,
    currentUser: undefined,
    invalidUser: false,
    successMessage: ''
  };

  componentDidMount = async () => {
    await this.getCurrentUser();
    const edit = /(\/edit)/.test(this.props.location.pathname);
    this.setState({ edit, isLoader: false });
  };

  getCurrentUser = async () => {
    if (this.props.match.params.userId) {
      this.setState({ isLoader: true });
      const result = await this.props.client.query({
        query: GET_USER,
        variables: {
          _id: this.props.match.params.userId
        }
      });
      const currentUser = result.data.getUser.data;

      if (result.data.getUser.success) {
        this.setState({ currentUser, invalidUser: false });
      } else {
        this.setState({ invalidUser: true });
      }
    } else {
      const newUser = /(new)/g.test(this.props.location.pathname);
      this.setState({ newUser });
    }
  };

  editUser = _id => {
    this.props.history.push(`/users/${_id}/edit`);
    this.setState({ edit: true });
  };

  exitEdit = (wait, cancel) => {
    let newPath = this.props.location.pathname;
    if (/(\/new)/.test(newPath)) {
      newPath = newPath.replace(/(\/new)/, '');
    } else {
      newPath = newPath.replace(/(\/edit)/, '');
    }
    if (wait){ setTimeout(() => { this.props.history.push(newPath); }, 2500); }
    else { this.props.history.push(newPath); }

    if (!cancel) this.getCurrentUser();
  };

  showSuccessAlert = (successMessage, wait) => {
    this.setState({ successMessage });
    this.exitEdit(wait);
    setTimeout(() => {
      this.setState({ successMessage: '' });
    }, 2000);
  };

  render () {
    const currentUser = this.props.match.params.userId? this.state.currentUser: undefined;
    const userFields = currentUser !== undefined? UserInfoData[this.state.currentUser._role.role]: null;
    const { edit, newUser, invalidUser, successMessage, isLoader } = this.state;
    if (invalidUser) {
      return <h2 className="text-center error-msg mt-2">El usuario no existe</h2>;
    }
    return (
      <BodyContainer isLoader={isLoader}>
        <Accordion accordion={false}>
          <AccordionItem uuid="uniqueItem-edit-create-user" expanded={true}>
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>{currentUser ? 'Editar usuario' : 'Crear usuario'}</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody className="noPadding">
              {
                edit || newUser?
                  <UserForm
                    formData={UserFormData}
                    currentUser={currentUser}
                    exitEdit={this.exitEdit}
                    showSuccessAlert={this.showSuccessAlert}
                  />
                  :
                  <UserInfo
                    currentUser={currentUser}
                    editUser={this.editUser}
                    userFields={userFields}
                  />
              }
              {
                successMessage &&
                  <div className="success-msg">
                    <p className="text-center success-msg">
                      <span className="icon-check mr-02" />
                      {successMessage}
                    </p>
                  </div>
              }
            </AccordionItemBody>
          </AccordionItem>
          {
            (!newUser && currentUser) &&
              <AccordionItem uuid="uniqueItem-history">
                <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
                  <span>Historial</span>
                  <div className="accordion__arrow" role="presentation" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <ActionHistory _user={currentUser._id} />
                </AccordionItemBody>
              </AccordionItem>
          }
        </Accordion>
      </BodyContainer>
    );
  }
}
User.propTypes = {
  match: PropTypes.object,
  client: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};
export default withRouter(withApollo(User));

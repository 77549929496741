import React, { Component, createContext } from "react";
import { compose, graphql, withApollo } from "react-apollo";
import { GET_INSTITUTION } from "../graphql/types/institution/queries";
import {
    CREATE_INSTITUTION, DELETE_INSTITUTION, UPDATE_INSTITUTION
  } from '../graphql/types/institution/mutations';
import { GET_CITIES } from '../graphql/types/city/queries';
import _ from 'lodash';

const context = createContext();
const { Consumer, Provider } = context;

class InstitutionProvider extends Component {
    constructor () {
        super();
        
        this.state = {
            cities: [],
            institutions: [],
            totalInstitutions: 0,
            pagination: {
                page: 1,
                perPage: 10
            },
            filters: {
                search: ''
            },
            sort: {
                direction: 1,
                field: 'name'
            },
            form: {
                name: '',
                address: '',
                city: ''
            },
            showModal: false,
            editionMode: false,
            deleteVerification: false,
            deleteConfirmation: false,
            institutionIdToDelete: null
        }
    }

    getInstitutions = async () => {    
        const { filters, pagination, sort } = this.state;

        try {
            let apiResponse = await this.props.client.query({
                query: GET_INSTITUTION,
                variables: {
                    filters,
                    pagination,
                    sort
                }
            });
          
            apiResponse = apiResponse.data.getInstitution;

            const { data: institutions, total: totalInstitutions } = apiResponse;

            this.setState({ 
                institutions,
                totalInstitutions
            });
    
        } catch (error) {}        
    }

    createInstitution = async () => {
        const response = await this.props.createInstitution({
            variables: this.getFormData()
        });

        return response;
    }

    updateInstitution = async () => {
        const response = await this.props.updateInstitution({
            variables: this.getFormData()
        });

        return response;
    }

    deleteInstitution = async () => {
        const response = await this.props.deleteInstitution({
            variables: {
                _id: this.state.institutionIdToDelete
            }
        });

        this.setState({
            deleteVerification: false,
            physicianIdToDelete: null,       
            deleteConfirmation: true
        })

        return response;
    }

    handleDeleteVerification = async institutionId => {
        this.setState({
          deleteVerification: true,
          institutionIdToDelete: institutionId
        });
      }
  
    handleCancelDelete = () => {
        this.setState({
            deleteVerification: false,
            physicianIdToDelete: null
        });
    }

    handleAcceptDelete = () => {
        this.setState({
            deleteConfirmation: false
        });
    }

    setPaginationData = (field, value) => {
        this.setState({ 
            pagination: {
                ...this.state.pagination,
                [field]: value
            }
        });
    }

    handlePageChange = page => {
        this.setPaginationData('page', page);
    }
    
    handleRowsChange = (perPage, page) => {
        this.setPaginationData('perPage', perPage);
    }

    setFilter = (field, value) => {
        this.setState({
          filters: {
            ...this.state.filters,
            [field]: value
          }
        });
    }

    handleSort = ({ sortField }, direction) => {
        this.setState({
          sort: {
            field: sortField,
            direction: direction === 'asc' ? 1 : -1
          }
        });
    }

    setField = (field, value) => {
        this.setState({
          form: {
            ...this.state.form,
            [field]: value
          }
        });
    }

    openModal = () => {
        this.setState({ showModal: true });
    }
    
    closeModal = () => {
        this.setState({ 
            editionMode: false,
            showModal: false
        });

        this.cleanFormData();
    }

    setFormData = data => {
        const { form, cities } = this.state;
        const { city } = data;

        this.setState({
            form: {
                ...form,
                ...data,
                ...(city ? { city: cities.find(c => c.label === data.city) } : {})
            },
        })
    }

    getFormData = () => {
        const { form } = this.state;

        return {
            ...form,
            city: form.city.value
        }
    }

    cleanFormData = () => {
        this.setFormData({
            name: '',
            address: '',
            city: ''
        });
    }

    setEditionMode = value => {
        this.setState({ editionMode: value })
    }

    getCities = async () => {
        let cities;
    
        try {
          const queryResponse = await this.props.client.query({
            query: GET_CITIES,
            variables: {}
          });
    
          cities = queryResponse.data.getCities.data;
    
          cities = cities.map(c => ({
            label: c.name,
            value: c._id
          }));
    
        } catch(error) {
          cities = [];
        }
    
        this.setState({ cities });
    }

    componentDidUpdate(prevProps, prevState) {
        const { sort, pagination, filters } = prevState;
        const { sort: newSort, pagination: newPagination, filters: newFilters } = this.state;
    
        if (
            !_.isEqual(filters, newFilters) ||
            !_.isEqual(pagination, newPagination) ||
            !_.isEqual(sort, newSort)
        ) {
          this.getInstitutions();
        }
    }

    render = () => (
       <Provider
            value= {{
                ...this.state,
                getInstitutions: this.getInstitutions,
                createInstitution: this.createInstitution,
                updateInstitution: this.updateInstitution,
                deleteInstitution: this.deleteInstitution,
                handleDeleteVerification: this.handleDeleteVerification,
                handleCancelDelete: this.handleCancelDelete,
                handleAcceptDelete: this.handleAcceptDelete,
                handlePageChange: this.handlePageChange,
                handleRowsChange: this.handleRowsChange,
                setFilter: this.setFilter,
                handleSort: this.handleSort,
                setField: this.setField,
                setFormData: this.setFormData,
                openModal: this.openModal,
                closeModal: this.closeModal,
                setEditionMode: this.setEditionMode,
                getCities: this.getCities,
                cleanFormData: this.cleanFormData,
            }}
       >
        { this.props.children }
       </Provider> 
    )
    
}

export {
    context,
    Consumer
};

export default compose(
    graphql(CREATE_INSTITUTION, { name: 'createInstitution' }),
    graphql(UPDATE_INSTITUTION, { name: 'updateInstitution' }),
    graphql(DELETE_INSTITUTION, { name: 'deleteInstitution' })
)(withApollo(InstitutionProvider));
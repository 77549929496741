/**
 * ServicesList
 */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../general/textInput';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { GET_SERVICES } from '../../../graphql/types/servicesModule/queries';
import { GET_SERVICESFAMILY } from '../../../graphql/types/servicesFamilyModule/queries';
import ServicesTable from '../servicesTable';

// my components
import BodyContainer from '../../../components/general/bodyContainer';
import Select from 'react-select';
import './style.css';

class ServicesList extends Component {
  filterTimeOut = null;
  constructor(props) {
    super(props);
    const initialState = {
      page: 1,
      name: false,
      servicefamily: false,
      filter: {},
      filters: ['name'],
      isLoader: true,
      services: [],
      finished: false,
      filtered: false,
      servicesLoad: true,
      servicesFamilies: []
    };
    initialState.filters.map(filter => {
      return (initialState[filter] = '');
    });
    this.state = initialState;
  }

  toServices = id => {
    this.props.history.push(`/services/${id}`);
  };

  loadServices = async (services = [], time) => {
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);

    const filter = this.buildFilters(this.state);

    this.filterTimeOut = setTimeout(() => {
      this.setState({ isLoader: true });
      this.props.client
        .query({
          query: GET_SERVICES,
          variables: filter
        })
        .then(response => {
          const data = response.data.getServices.data.length > 0 ? response.data.getServices.data : [];
          services = services.concat(data);
          let { servicesLoad } = this.state;
          if (data.length < 30) {
            servicesLoad = false;
          }
          this.setState({ services, servicesLoad, finished: true, page: this.state.page + 1, isLoader: false });
        })
        .catch(() => {
          this.setState({ isLoader: false });
        });
    }, time);

    this.props.client
      .query({
        query: GET_SERVICESFAMILY,
        variables: {}
      })
      .then(response => {
        this.setState({ servicesFamilies: response.data.getServicesFamily.data });
      })
      .catch(() => {
        console.log('error')
      });
  };

  buildFilters = state => {
    const filters = {};

    if (state.servicefamily) filters.servicefamily = state.servicefamily;

    if (state.name) filters.name = state.name;

    if (state.page) filters.page = state.page;

    if (state._sede) filters._sede = state._sede;

    if (state._resourceType) filters._resourceType = state._resourceType;

    return filters;

  };

  componentDidMount() {
    const { services } = this.state;
    this.loadServices(services, 0);
  }

  updateField = (name, { value }) => {
    this.setState({ [name]: value, finished: false, page: 1, servicesLoad: true }, () => this.loadServices([], 700));
  };

  render() {
    const { children } = this.props;
    const { services, filtersCollapsed, finished, isLoader, servicesFamilies } = this.state;

    return (
      <BodyContainer isLoader={isLoader}>
        <div className={`services-list contentListUser ${filtersCollapsed ? 'filters-collapsed' : ''}`} >
          <div className="flex j-c a-i-c search-container">
            <div className="prestaciones-title">Lista de prestaciones</div>
            <div className="search-field-icon">
              <div style={{ width: '50%', position: 'relative', float: 'left', marginTop: '7px' }}>
                <select
                  onChange={(e) => this.updateField('servicefamily', e.target)}
                  className='minimal'
                >
                  <option value="false">Familia...</option>

                  {servicesFamilies.map((opt) => (
                    <option value={opt._id} key={opt._id}>{opt.name}</option>
                  ))}
                </select>
              </div>

              <div className='searchByName' style={{ width: '50%', position: 'relative', float: 'left' }}>
                <TextInput
                  iconAfter="search"
                  value={this.state.name}
                  type="text"
                  name="name"
                  label={
                    window.innerWidth < 780
                      ? 'Por nombre...'
                      : 'Por nombre...'
                  }
                  updateField={this.updateField}
                />
              </div>
            </div>

            <div className="prestaciones-new">
              {children}
            </div>

            <div className="users-filters-toggle text-right ripple-gray">
              <span
                className="large material-icons clickable"
                onClick={this.toggleFilters}
              >
                toc
              </span>
            </div>
          </div>

          <div className="user-filters-container flex j-c a-i-c f-w">
            <div className="filter-field" />
            <div className="filter-field" />
          </div>

          <ServicesTable
            services={services}
            servicesFamilies={servicesFamilies}
            toService={this.toServices}
            finished={finished}
          />
        </div>
      </BodyContainer>
    );
  }
}

ServicesList.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.element
};

ServicesList.defaultProps = {
  children: []
};

export default withApollo(withRouter(ServicesList));

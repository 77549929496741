/**
 * AvailabilitySchedule
 */
import React, { Fragment, Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion';
import PatientsList from '../../components/patients/patientsList';

import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import Patient from '../patientsModule/patient/patient';
import _ from 'lodash';
import Info from '../../components/patients/patientsInfo/patientsInfo';

import Card from '../patientsModule/appointmentSchedule//card';
import DataAppointment from '../patientsModule/appointmentSchedule/dataAppointment';

class AvailabilitySchedule extends Component {
  state = { modalInfo: false };
  showModal = (modalInfo, service) => {
    this.setState({ modalInfo, service });
  };
  componentDidMount = () => {
    if (Object.keys(this.props.appointmentsService).length === 0) {
      if (document.location.pathname.indexOf('schedule') > -1) {
        this.props.history.replace('/availability');
      }
    }
  };
  _handleShowNewPatientAvailability = async show => {

    await this.props.initCurrentPatient();
    this.props.handleShowNewPatientAvailability(show);
  };
  render () {
    const { modalInfo, service } = this.state;

    const {
      appointmentsService,
      dataAppointmentsService,
      handleappointmentsInfo,
      setAppointmentInfoObservation,
      appointmentsInfo,
      appointmentsInfoObservation,
      sede,
      entityService,
      selectedServices,
      eps,
      blockResource,
      errSchedule,
      errSaveAppointment,
      selectedTypeHeadPatient,
      handleShowModalSchedule,
      saveBookAppointment,
      patientAppointment: currentPatient,
      showNewPatientAvailability
    } = this.props;
    const noSave = Object.keys(errSaveAppointment);
    const entity = _.find(eps, { _id: entityService });
    let documents;
    let recommendations;
    if (service) {
      documents = appointmentsService[service].service.documents;
      recommendations = appointmentsService[service].service.recommendations;
    }
    return (
      <div className="content_availability">
        <Accordion accordion={false}>
          <AccordionItem uuid="uniqueItem-availability-patient" expanded={true}>
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>Asignar paciente</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody>
              <div className="contentPatientAssign">
                {
                  currentPatient &&
                    <div className="contentInfoPatient">
                      <Info assign={true} {...currentPatient} />
                    </div>
                }
                <PatientsList
                  handleShowNewPatientAvailability={
                    this._handleShowNewPatientAvailability
                  }
                  perPage={5}
                  assign={true}
                  selectedTypeHeadPatient={selectedTypeHeadPatient}
                />
              </div>
            </AccordionItemBody>
          </AccordionItem>

          { showNewPatientAvailability && <Patient /> }
          <AccordionItem uuid="uniqueItem-availability" expanded={true}>
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>Citas</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody className="noPadding">
              <div className="availability_schedule ">
                <div className="flex">
                  {
                    Object.keys(appointmentsService).map((key, index) => {
                      let doctor = false;
                      let observation = '';

                      if (appointmentsInfoObservation[key]) observation = appointmentsInfoObservation[key];
                      if (appointmentsInfo[key] && appointmentsInfo[key].doctorObj) doctor = appointmentsInfo[key].doctorObj;
                      if (appointmentsService[key] && !appointmentsService[key].service._id) {
                        appointmentsService[key].service = _.find(
                          selectedServices,
                          {
                            _id: appointmentsService[key].service
                          }
                        );
                      }
                      return (
                        <div
                          key={`ms-${index}`}
                          className="HousrService flex f-c"
                        >
                          <h6>{appointmentsService[key].service.label}</h6>
                          <Card
                            doctor={doctor}
                            sede={sede}
                            entity={entity}
                            service={appointmentsService[key]}
                            errSaveAppointment={errSaveAppointment[key]}
                          />
                          <DataAppointment
                            infoHide={true}
                            observation={observation}
                            blockResource={blockResource}
                            appointmentsInfo={appointmentsInfo[key]}
                            handleappointmentsInfo={handleappointmentsInfo}
                            setAppointmentInfoObservation={setAppointmentInfoObservation}
                            service={appointmentsService[key]}
                            doctors={dataAppointmentsService.doctors}
                            spaces={dataAppointmentsService.spaces}
                          />
                          <div className="text-center">
                            <button
                              className="button button-sm bg-icon"
                              onClick={() => this.showModal(!modalInfo, key)}
                            >
                              Recomendaciones
                            </button>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
                <p
                  className="text-center error-msg"
                  style={{ marginBottom: 0 }}
                >
                  {errSchedule.path &&
                    appointmentsService[errSchedule.path] &&
                    appointmentsService[errSchedule.path].service &&
                    `${appointmentsService[errSchedule.path].service.name}: `}

                  {errSchedule.msg && `${errSchedule.msg}`}
                </p>
              </div>
            </AccordionItemBody>
          </AccordionItem>
        </Accordion>
        <div className="modal-actions mt-1 flex j-c">
          <button
            className="button button-sm bg-muted"
            onClick={() => handleShowModalSchedule(false)}
          >
            Cancelar
          </button>
          <button
            className="button button-sm bg-icon ripple"
            onClick={() => {
              if (noSave.length === 0) {
                saveBookAppointment();
              }
            }}
          >
            Agendar
          </button>
        </div>
        {
          modalInfo && service &&
            <Fragment>
              <div className="modal-container" />
              <div className="availability_scheduleModalinfo modalSchedule modal-body flex f-c text-center">
                <div className="modal-header ripple-title">
                  <p>Recomendaciones</p>
                  <div
                    className="closeModal"
                    onClick={() => this.showModal(!modalInfo)}
                  >
                    ×
                  </div>
                </div>
                <div className="modal-msg">
                  <div className="IA_docuemnts_recommendations">
                    <div>
                      <h4 style={{ margin: 0 }}>Documentos para la cita:</h4>
                      <p
                        style={{ marginTop: 0 }}
                        dangerouslySetInnerHTML={{ __html: documents }}
                      />
                    </div>
                    <div>
                      <h4 style={{ margin: 0 }}>
                        Recomendaciones para el paciente:
                      </h4>
                      <p
                        style={{ marginTop: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: recommendations
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
        }
      </div>
    );
  }
}

export default withApollo(withRouter(AvailabilitySchedule));

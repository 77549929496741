/**
 * Resource
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';

// my components
import ResourceForm from '../../../components/resourcesModule/resourceForm';
import ServicesWrapper from '../../../components/servicesModule/servicesOptions/servicesWrapper';
import CalendarWeek from '../../../components/general/calendarWeek';
import ConfirmationModal from '../../../components/general/confirmationModal';

import BodyContainer from '../../../components/general/bodyContainer';

import './style.css';

class Resource extends Component {
  state = {
    confirmationModal: false
  };

  componentDidMount = () => {
    if (!this.props.currentResource && !/(\/new)/.test(this.props.location.pathname)){
      this.props.getCurrentResource(this.props.match.params.id);
    }
    if (/(\/new)/.test(this.props.location.pathname)){
      this.props.getCurrentWeek();
    }
  };

  toggleConfirmationModal = () => {
    const confirmationModal = !this.state.confirmationModal;
    this.setState({ confirmationModal });
    if (!confirmationModal) {
      localStorage.removeItem('clickSidebar');
    }
    if (!localStorage.getItem('canExitEdit')) this.cancelRedirect();
  };

  cancelRedirect = () => {
    localStorage.removeItem('canExitEdit');
    localStorage.removeItem('clickSidebar');
    this.exitResource();
  };

  exitResource = () => {
    this.props.history.push(`/resources/${this.props.match.params.id ? this.props.match.params.id : ''}`);
  };

  render () {

    if (
      localStorage.getItem('canExitEdit') &&
      localStorage.getItem('clickSidebar') &&
      !this.state.confirmationModal
    ) {
      this.toggleConfirmationModal();
    }
    const { confirmationModal } = this.state;

    const {
      isLoader,
      currentWeek,
      currentDay,
      date,
      currentType,
      name,
      sede,
      active,
      quantity,
      success,
      error,
      completeSelectedServices,
      currentService,
      successMessage,
      errorMessage,
      resourceErrors,
      specialSchedule,
      currentResource,
      selectedServices,
      updateField,
      clearErrors,
      onChange,
      addSerie,
      deleteSerie,
      saveResource,
      getCurrentWeek,
      handleChangeTab,
      disableSubmit,
      editInterval,
      max,
      timeOut
    } = this.props;
    return (
      <BodyContainer isLoader={isLoader}>
        {
          confirmationModal &&
            <ConfirmationModal
              onConfirm={this.cancelRedirect}
              onCancel={this.toggleConfirmationModal}
            />
        }
        <Accordion accordion={false}>
          <AccordionItem uuid="uniqueItem-create-resource" expanded={true}>
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>Crear Recurso</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody>
              <ResourceForm
                currentType={currentResource ? currentResource._resourceType : {}}
                updateField={updateField}
                name={name}
                sede={sede}
                quantity={quantity}
                active={active}
                specialSchedule={specialSchedule}
                resourceErrors={resourceErrors}
                clear={clearErrors}
                timeOut={timeOut}
              />
            </AccordionItemBody>
          </AccordionItem>
          {
            (currentType.name === 'Espacio de trabajo' || currentType.name === 'Equipo') &&
              <AccordionItem uuid="uniqueItem-work-space" expanded={true}>
                <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
                  <span>Prestaciones</span>
                  <div className="accordion__arrow" role="presentation" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <div className={`text-center font-14 ${resourceErrors['services'] ? 'wrong-input' : ''}`} >
                    <label>
                      Selecciona todas la prestaciones que puedan realizarce en este espacio de trabajo
                    </label>
                  </div>
                  <ServicesWrapper max={currentType.name === 'Espacio de trabajo' ? max : false} edit={true} />
                </AccordionItemBody>
              </AccordionItem>
          }
          {
            (currentType.name === 'Sede' || (currentType.name === 'Espacio de trabajo' && specialSchedule && selectedServices.length > 0)) &&
              <Fragment>
                <div className="contentServices flex mt-1px">
                  {
                    completeSelectedServices.map((service, i) =>
                      <div
                        key={i}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleChangeTab(service) }
                        className={`cs_nav ${selectedServices.length === 1 && 'tabOne'} ${currentService._id === service._id && 'select'}`}
                      >
                        <span>{service.name}</span>
                      </div>
                    )
                  }
                </div>
                <AccordionItem uuid="uniqueItem-work-calendar" expanded={true}>
                  <AccordionItemTitle
                    onClick={()=>{}}
                    className="accordion__title accordion_title_user_service accordion__title--animated u-position-relative ripple-title"
                  >
                    <span>Calendario laboral</span>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <CalendarWeek
                      fields={{
                        date,
                        currentDay,
                        currentWeek
                      }}
                      getCurrentWeek={getCurrentWeek}
                      addSerie={addSerie}
                      deleteSerie={deleteSerie}
                      editInterval={editInterval}
                      onChange={onChange}
                    />
                  </AccordionItemBody>
                </AccordionItem>
              </Fragment>
          }
          <div className="user-form-actions flex f-c a-i-c j-c mb-1 mt-1">
            { error && <p className="text-center error-msg">{errorMessage}</p> }
            {
              success &&
                <p className="text-center success-msg">
                  <span className="icon-check mr-02" />
                  {successMessage}
                </p>
            }
            <div className="text-center flex j-c">
              <button
                type="reset"
                className="button save-user button-sm bg-muted"
                onClick={success? this.cancelRedirect: this.toggleConfirmationModal}
              >
                Cancelar
              </button>
              <button
                type="submit"
                onClick={saveResource}
                disabled={disableSubmit}
                className={`button ripple save-user button-sm ${disableSubmit ? 'bg-muted' : 'bg-icon'}`}
              >
                Guardar
              </button>
            </div>
          </div>
        </Accordion>
      </BodyContainer>
    );
  }
}
Resource.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  currentWeek: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  getCurrentWeek: PropTypes.func,
  currentResource: PropTypes.object,
  getCurrentResource: PropTypes.func
};
export default compose()(withApollo(withRouter(Resource)));

import React, { useMemo, useState, useContext } from "react";
import FormNotify from "../../general/formNotify";
import {useDropzone} from 'react-dropzone'
import _ from 'lodash';
import { context } from '../../../providers/patientProvider';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};  

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const mimeType = 'application/pdf';

const PatientResultForm = ({ 
    handleVisibilityResultForm, 
    selectedReferringPhysician, 
    idSelectedReferringPhysician,
    readerPhysician,
    makerPhysician
}) => {
    const patientContext = useContext(context);
    
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDrop = acceptedFiles => {
        const [file] = acceptedFiles;

        setFile(file);
    }

    const {
        getRootProps, 
        getInputProps, 
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ 
        accept: { [mimeType]: [] },
        multiple: false,
        onDrop
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
        // eslint-disable-next-line
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const hasFile = () => {
        return !_.isEmpty(file);
    }

    const getFile = () => {
        return URL.createObjectURL(new Blob([file], { type: mimeType }))
    }

    const resetFile = () => {
        setFile(null);
    }

    const handleSendResult = async () => {
        setLoading(true);
        await patientContext.sendResult({
            file,
            selectedReferringPhysician,
            idSelectedReferringPhysician,
            readerPhysician,
            makerPhysician,

        })
        setLoading(false);
    }

    return (
        <FormNotify 
            styleModalCenter={{ width: '50%', height: '90vh' }}
            title="Resultado"
            onCloseNotify={() => handleVisibilityResultForm()}
        >
            {
                hasFile() 
                ?
                    <>
                        <button
                            className="btn btn-warning"
                            onClick={() => resetFile()}
                            type="button"
                            disabled={loading}
                        >
                            Reemplazar archivo
                        </button>

                        <button
                            className="btn btn-success mb-2"
                            onClick={() => handleSendResult()}
                            type="button"
                            disabled={loading}
                        >
                            Enviar resultado
                        </button>

                        <object
                            aria-label="File"
                            type={mimeType}
                            data={getFile()}
                            style={{ width: '100%', height: '100vh' }}
                        />                        
                    </>
                :
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Seleccione el archivo que contiene el resultado</p>
                    </div>
                    
            }
        </FormNotify>
    );
}

export default PatientResultForm;
/* eslint-disable */
import React, { Component } from 'react';
import { Treemap, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { context } from "../../../providers/dashboardProvider";

class BenefitChart extends Component {

  static contextType = context;

  constructor (props) {
    super(props);
  }

  render () {
    const { topReferralServices } = this.context;

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        return (
          <div className="treemap-custom-tooltip" style={{ backgroundColor: 'white', padding: '10px' }}>
            <p>
              {`${payload[0].payload.name}`}
            </p>
            <p style={{ color: '#16aaff' }}>
              Referidos: {payload[0].value} 
            </p>
          </div>
        );
      }
      return null;
    };

    const COLORS = ['#8889DD', '#9597E4', '#8DC77B', '#A5D297', '#E2CF45', '#F8C12D'];

    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h5 className='card-title'> Prestaciones </h5>
          </div>
        </div>
        <br />
        <div className="row mh300">
          {topReferralServices.length > 0 &&
            <ResponsiveContainer width={'100%'} height={200} >
              <Treemap
                data={topReferralServices}
                dataKey="referrals"
                ratio={4 / 3}
                stroke="#fff"
                fill="#16aaff"
              >
                <Tooltip content={<CustomTooltip />} />
              </Treemap>
            </ResponsiveContainer>
          }
        </div>
      </div>
    );
  }

}
export default BenefitChart;
/* eslint-disable */
import './styles.css';
import React, { Component, Fragment } from 'react';
import { UPDATE_DOCTORNOTE, UPDATE_NURSENOTE } from '../../../graphql/types/ampa/mutations';
import { compose, graphql, withApollo } from 'react-apollo';
import BodyContainer from '../../../components/general/bodyContainer';
import FormTextArea from '../../../components/general/formTextArea';
import ReactApexChart from 'react-apexcharts';
import { formatArray } from './formatArray';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import FormLoader from "../../../components/general/formLoader";
import { templateHeaderpdf, templateFooterpdf, templateBodyPdf } from "../pdf";
import { fetchWithTokenPDF, fetchWithTokenResults } from "../../../utils/fetch";
import { initialDate, avarageTaks, parseTakes, getAverage } from "../../../utils/helpers"

class ViewAmpa extends Component {
    state = {
        loading:false,
        isLoader: false,
        _id: '',
        diastolic: [],
        sistolic: [],
        frequency: [],
        dateInit: '',
        dateInitTimestamp: '',
        nursingNotes: this.props.nursingNotes,
        doctorNotes: this.props.doctorNotes,
        takes: parseTakes(this.props.takes),
        avarageTakesPatient: avarageTaks({avarage:parseTakes(this.props.takes)}),
        series: [
            {
                name: "High - 2013",
                data: [28, 29, 33, 36, 32]
            },
            {
                name: "Low - 2013",
                data: [12, 11, 14, 18, 17]
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                }
            },
            colors: ["#FF1654", "#247BA0", "#b50024"],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                opacity: 1,
                type: 'solid'
            },
            title: {
                text: 'Telemonitoreo',
                align: 'left'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'], 
                    opacity: 0.5
                },
            }
            ,
            markers: {
                size: 1
            },
            xaxis: {
                categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan']
            },
            yaxis: {
                title: {
                    text: 'Temperature'
                },
                min: 5,
                max: 40
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        },
    }
    async componentWillMount() {
        const { _id, dateInit, dateInitTimestamp } = this.props;
        this.setState({ _id, dateInit, dateInitTimestamp });
        this.graphFunction()

    }
    async componentDidMount() {
        this.tableFunction();
    }
    updateTextArea = (e) => {
        const state = this.state;
        const { value, name } = e.target;
        this.setState({ [name]: value, state });
        this.tableFunction();
    }
    updateAmpaNurseNote = () => {
        this.props.changeNotes({name:"nursingNotes",value:this.state.nursingNotes})
        this.setState({ isLoader: true })
        const _id = this.props.location.pathname.split('/')[3]
        const { nursingNotes } = this.state;
        this.props.updateNurseNote({
            variables: {
                _id,
                nursingNotes
            }
        }).then((result) => {
            const state = this.state;
            this.setState({ isLoader: false, state })
            this.tableFunction();
        })
    }
    updateAmpaDoctorNote = () => {
        this.props.changeNotes({name:"doctorNotes",value:this.state.doctorNotes})
        this.setState({ isLoader: true })
        const _id = this.props.location.pathname.split('/')[3]
        const { doctorNotes } = this.state;
        this.props.updateDoctorNote({
            variables: {
                _id,
                doctorNotes
            }
        }).then((result) => {
            const state = this.state;
            this.setState({ isLoader: false, state })
            this.tableFunction();
        })
    }
    graphFunction = () => {
        let { takes } = this.props;

        var dataset = [];
        var dataset2 = [];
        var dataset3 = [];
        var categories1 = []
        let takesMutable = [...takes];
        takesMutable.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
        takes = [...takesMutable];
        takes.map((d, i) => {
            if (d) {
                dataset.push({ x: moment(d.timestamp, 'x').format(), y: Number(d.diastolic) == 0 ? null : Number(d.diastolic) });
                dataset2.push({ x: moment(d.timestamp, 'x').format(), y: Number(d.sistolic) == 0 ? null : Number(d.sistolic) });
                dataset3.push({ x: moment(d.timestamp, 'x').format(), y: Number(d.frequency) == 0 ? null : Number(d.frequency) });
            }
        });
        let series2 = [
            { name: 'PA Sistólica', data: dataset2 },
            { name: 'PA Diastólica', data: dataset },
            { name: 'Frecuencia Cardiaca', data: dataset3 }            
        ]
        let options2 = {
            colors: ["#294863", "#247BA0", "#b50024"],
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: function (timestamp) {
                        return moment(timestamp, 'x').format("DD HH:mm:ss")
                    }
                }
            },
            markers: {
                size: 4,
            },
        }
        this.setState({ series: series2, options: options2 })
    }
    tableFunction = async () => {
        const { dateInit, takes } = this.state;
        const dataPrepared = formatArray(takes, dateInit);
    }
    irAmpa = () => {
        const dataBase = window.location.origin == 'http://localhost:3001';
        var token = this.props.token;
        var otherWindow = window.open();
        otherWindow.opener = null;
        if (dataBase) {
            otherWindow.location = 'http://localhost:8080/core.html?' + token;
        } else {
            otherWindow.location = `https://${ process.env.REACT_APP_URL_AMPA }/core.html?` + token;
        }
    }
    mediaParams = (takes) => {
        const sistolic = [];
        const diastolic = [];
        const frequency = [];
        takes.map((d) => {
            sistolic.push(d.sistolic);
            diastolic.push(d.diastolic);
            frequency.push(d.frequency);
        });
        return (<div className="file" key="media123123">
            <div className="borderleft">
                Media
            </div>
            <div className="borderleft">
                {getAverage(sistolic)}
            </div>
            <div className="borderleft">
                {getAverage(diastolic)}
            </div>
            <div className="borderleft">
                {getAverage(frequency)}
            </div>
        </div>);


    }
    generatePDF = async () =>{
        this.setState({loading:true})
        const patient = this.props.patient;
        const informationGeneralPatient={
          _id: this.props._id,
          takes: this.props.takes,
          token: this.props.token,
          medicines: this.props.medicines,
          enfermera: this.props.enfermera,
          medico: this.props.medico,
          dateInit: this.props.dateInit,
          dateInitTimestamp: this.props.dateInitTimestamp,
          size: this.props.size,
          weight: this.props.weight,
          bmi: this.props.bmi,
          status: this.props.status,
          physicalActivity: this.props.physicalActivity,
          pregnant: this.props.pregnant,
          smoke: this.props.smoke,
          liquor: this.props.liquor,
          liquorFrequency: this.props.liquorFrequency,
          hardLiquor: this.props.hardLiquor,
          wine: this.props.wine,
          beer: this.props.beer,
          nursingNotes: this.props.nursingNotes,
          doctorNotes: this.props.doctorNotes,
          nameEquipment: this.props.nameEquipment,
          equipmentReference:this.props.equipmentReference,
          }
        const margins = { top: 83, right: 35, bottom: 43, left: 35 };
        const resPdf = await fetchWithTokenPDF({ 
          endpoint: "api/pdf/generate-pdf-front", 
          data: { 
            templateHeader: templateHeaderpdf({patient}), 
            templateFooter: templateFooterpdf(), 
            templateBody: await templateBodyPdf({informationGeneralPatient}),
            domainPdf:  `${this.props.patient.type_dni}_${this.props.patient.dni}.pdf` ,
            margins, 
          }, 
          method: "POST",
          blob: true,  
        });
        if(resPdf.data){
            const blob = new Blob([resPdf.data], { type: 'application/pdf' });
            const dataPublish ={
                usuario_documento_tipo: this.props?.patient?.type_dni,
                usuario_documento: this.props.patient.dni,
                usuario_nombre: `${this.props.patient.name.toUpperCase()} ${this.props.patient.lastName.toUpperCase()}`,
                medico_nombre: `${this.props.medico.name} ${this.props.medico.lastname}`,
                medico_id: this.props.medico._id,
                tipo_examen_id: "32",
                fecha_examen: initialDate(this.props.dateInit),
                archivo:blob,  
            } 
            const pdfUrl = URL.createObjectURL(blob);
            window.open(pdfUrl);
            return await fetchWithTokenResults({
                endpoint: `wp-json/mapa/v1/resultados`,
                method: "POST",
                data:dataPublish,
                content:"multipart/form-data"
                }).then(()=>{
                  this.setState({loading:false})
                })
        }
        return this.setState({loading:false})
      }
    render() {

        const { takes, medicines, enfermera, medico, size,
            weight,
            bmi,
            status,
            physicalActivity,
            pregnant,
            smoke,
            liquor,
            liquorFrequency,
            hardLiquor,
            wine,
            beer,
            nameEquipment,
            equipmentReference } = this.props;
        const { dateInit, dateInitTimestamp, nursingNotes, isLoader, doctorNotes, avarageTakesPatient:{ sistolic, diastolic, frecuency } } = this.state;

        const status_c = (tiem) => {
            var a = new Date(moment(Number(tiem)).format("YYYY-MM-DD"));
            const d = Number(moment().diff(moment(a), 'days')) >= 6 ? 6 : Number(moment().diff(moment(a), 'days'));
            return d
        }
        const days_d = status_c(dateInitTimestamp);

        return (<Fragment>
            {this.state.loading && <FormLoader />}

            <div id="ampaView" >
                <BodyContainer isLoader={isLoader}>
                    <div></div>
                </BodyContainer>
               
                    <div className="sectionGeneral">
                        <div className="information-general">
                            
                            <p className="titleinformacion title">Información general</p>
                            <table className="table table-striped ">
                                <tbody>
                                    <tr>
                                        <th> Talla </th>
                                        <td> { size ?? ""} </td>
                                    </tr>
                                    <tr>
                                        <th> Peso </th>
                                        <td> { weight ?? "" } </td>
                                    </tr>
                                    <tr>
                                        <th> Bmi </th>
                                        <td> { bmi ?? "" } </td>
                                    </tr>
                                    <tr>
                                        <th> status </th>
                                        <td> { status ?? "" } </td>
                                    </tr>
                                    <tr>
                                        <th> Fuma </th>
                                        <td> { smoke ?? "" } </td>
                                    </tr>
                                    <tr>
                                        <th> Actividad Fisica </th>
                                        <td> { physicalActivity ?? "" } </td>
                                    </tr>  
                                    {pregnant && (
                                        <tr>
                                            <th>Embarazada</th>
                                            <td>{pregnant}</td>
                                        </tr>
                                    )}    
                                    <tr>
                                        <th> Licor </th>
                                        <td> { liquor ?? "" } </td>
                                    </tr> 
                                    {liquor.toLowerCase() !=="no" && (
                                        <tr>
                                            <th>Frecuencia </th>
                                            <td>{liquorFrequency}</td>
                                        </tr>
                                    ) }
                                    {liquor.toLowerCase() !=="no" && (
                                        <tr>
                                            <th>Tipo de licor</th>
                                            <td>
                                                {wine && ' Vino' }
                                                {beer && ' Cerveza'}
                                                {hardLiquor && ' Licor Fuerte'}
                                            </td>
                                        </tr>
                                    )}           
                                    <tr>
                                        <th>Equipo </th>
                                        <td> { nameEquipment ?? "" } </td>
                                    </tr>
                                    <tr>
                                        <th>Referencia </th>
                                        <td> { equipmentReference ?? "" } </td>
                                    </tr>
                                    <tr>
                                        <th>Estado</th>
                                        <td>{days_d >= 6 ? (<span className='end'>Terminado</span>) : (<span className='progress'>En Progreso</span>)}</td>
                                    </tr>
                                    <tr>
                                        <th> Días trascurridos </th>
                                        <td> {days_d > 6 ? 6 : days_d} </td>
                                    </tr>
                                    <tr>
                                        <th>Fecha Inicio </th>
                                        <td>{moment(dateInit).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th> Fecha Final </th>
                                        <td>{moment(dateInit).add(5, 'days').format('DD/MM/YYYY')}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className="takes-patient" >
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th colSpan="2" className="text-center">Sistólica</th>
                                        <th colSpan="2" className="text-center">Diástolica</th>
                                        <th colSpan="2" className="text-center">Frecuencia</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-center">🌞</td>
                                        <td className="text-center">🌒</td>
                                        <td className="text-center">🌞</td>
                                        <td className="text-center">🌒</td>
                                        <td className="text-center">🌞</td>
                                        <td className="text-center">🌒</td>
                                    </tr>
                                    {this.state.takes.map(item=>(
                                        <tr key={item?.day}>
                                            <th className="text-center">Día {item?.day}</th>                                        
                                            <td className="text-center">{item?.sistolicDay}</td>
                                            <td className="text-center">{item?.sistolicNight}</td>
                                            <td className="text-center">{item?.diastolicDay}</td>
                                            <td className="text-center">{item?.diastolicNight}</td>
                                            <td className="text-center">{item?.frequencyDay}</td>
                                            <td className="text-center">{item?.frequencyNight}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <th className="text-center">Media</th>
                                        <th colSpan="2" className="text-center">{sistolic ?? ""}</th>
                                        <th colSpan="2" className="text-center">{diastolic ?? ""}</th>
                                        <th colSpan="2" className="text-center">{frecuency ?? ""}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className='w100' style={{paddingRight:15}}>
                        <div id="chart">
                            {takes && (
                                <ReactApexChart
                                    options={{ 
                                        ...this.state.options,
                                        chart: {
                                        ...this.state.options.chart,
                                        toolbar: {
                                            show: false
                                        }
                                        }
                                    }}
                                    series={this.state.series}
                                    type="line" 
                                    height={350} 
                                />
                            )}
                        </div>
                    </div>
                    <div className="aditionalInfo">
                        <div>
                            <div>
                                <div>
                                    <label><b className="title"> Medico(a) remitente:</b> </label>
                                </div>
                                <div>
                                    <b>Nombres: </b>{`${medico.name} ${medico.lastname}`}
                                </div>
                                <div className="no-print">

                                    <b>Celular: </b>{medico.mobil}
                                </div>

                            </div>
                            <div>
                                <div>
                                    <label><b className="title"> Enfermero(a):</b> </label>
                                </div>
                                <div>
                                    <b>Nombres: </b>{`${enfermera.name} ${enfermera.lastname}`}
                                </div>
                                <div className="no-print">
                                    <b>Celular: </b>{enfermera.mobil}
                                </div>
                            </div>
                        </div>

                        {medicines.length > 0 && (
                            <div style={{marginTop:20}}>
                                <p className="title">Medicamentos</p>
                                <table className="table table-striped ">
                                    <tbody>
                                        <tr key="title">
                                            <th>Nombre</th>
                                            <th className="text-center">Frecuencia</th>
                                            <th className="text-center">Cantidad</th>
                                            <th className="text-center">Dosis Plena</th>
                                        </tr>
                                        {medicines.map((item, idx) => (
                                            <tr key={idx}>
                                                <td>{item?.nameMedicine} {idx}</td>
                                                <td className="text-center">{item?.duration}</td>
                                                <td className="text-center">{item?.doseUnits}</td>
                                                <td className="text-center">{item?.full.toLowerCase() !== "false" ? "Si": "No"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div>
                            <FormTextArea
                                label='Notas de enfermeria'
                                name="nursingNotes"
                                value={nursingNotes}
                                cols={38}
                                rows={6}
                                onChange={this.updateTextArea}
                                classTextArea='notas'
                            ></FormTextArea>
                            <button onClick={this.updateAmpaNurseNote}>Actualizar Nota Enfermeria</button>
                        </div>
                        <div>
                            <FormTextArea
                                label='Notas de doctor'
                                name="doctorNotes"
                                value={doctorNotes}
                                cols={38}
                                rows={6}
                                onChange={this.updateTextArea}
                                classTextArea='notas'
                            ></FormTextArea>
                            <button onClick={this.updateAmpaDoctorNote}>Actualizar Nota Doctor</button>
                        </div>
                    </div>
               
                    <button onClick={this.generatePDF} style={{marginLeft:"10px 0 10px 10px"}} className="button ripple button-sm bg-icon"> Generar pdf</button>
                    &nbsp;
                    <button onClick={this.irAmpa} className="button ripple button-sm bg-icon">
                        <span className="icon-patients mr-02"></span>
                        Telemonitoreo
                    </button>
            </div >
        </Fragment>
        );
    }
}

export default compose(
    graphql(UPDATE_NURSENOTE, { name: "updateNurseNote" }),
    graphql(UPDATE_DOCTORNOTE, { name: "updateDoctorNote" })
)
    (withApollo(withRouter(ViewAmpa)));

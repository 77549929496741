/* eslint-disable */
import React, { Component } from 'react';
// import ServicesList from '../../../components/servicesModule/servicesList';
import { Link } from 'react-router-dom';

class Resources extends Component {
  render () {
    return (
      <div>
        <div className="users-title ripple-title">Lista de Ampas</div>
      </div>
    );
  }
}

export default Resources;

import React, { Component } from 'react';
import InstitutionsList from './institutions';
import Top5ChartSpecialty from './chart/top5chartspecialty';
import './style.css';
import '../../assets/styles/bootstrap.min.css';
import client from '../../utils/apollo';
import { GET_SERVICES } from '../../graphql/types/servicesModule/queries';
import ModalNew from './modal';
import { Consumer } from "../../providers/institutionProvider";
import { context } from "../../providers/institutionProvider";
import institutionsIcon from '../../assets/images/sidebar/ic-instituciones.svg';

class instituciones extends Component {

  static contextType = context;

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  dataservice = async () => {
    try {
      const result = await client.query({
        query: GET_SERVICES,
        variables: { perPage: 100 }
      });

      this.state.servicedata = result.data.getServices.data;

    } catch (error) {
      console.log('error:', error);
    }
  }

  componentDidMount() {
    this.dataservice();
  }

  render() {
    const { openModal, showModal, closeModal } = this.context;

    const specialty = [
      {
        specialty: 'In Care',
        value: 586
      },
      {
        specialty: 'Geomed',
        value: 420
      },
      {
        specialty: 'Clinica cardio VID',
        value: 386
      },
      {
        specialty: 'TOrre Intermedica',
        value: 174
      },
      {
        specialty: 'Sura Industriales',
        value: 59
      }
    ];

    return (
      <Consumer>
        {props => (         
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <img alt='Instituciones' src={institutionsIcon} />
                  </div>
                  <div>Instituciones
                  </div>
                </div>
                <div className="page-title-actions">

                  <div className="d-inline-block dropdown">
                    <button
                      onClick={openModal}
                      type="button" 
                      data-toggle="dropdown" 
                      aria-haspopup="true" 
                      aria-expanded="false" 
                      className="btn-shadow dropdown-toggle btn btn-info"
                    >
                      Agregar Nueva
                      <span className="btn-icon-wrapper pr-2 opacity-7">
                        <i className="nav-link-icon lnr-inbox"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className='row' style={{ marginTop: `18px` }}>
                <div className="col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="main-card mb-3 cardz">
                        <InstitutionsList />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <button 
                        type="button" 
                        data-toggle="tooltip" 
                        title="" 
                        data-placement="bottom" 
                        className="btn-shadow mr-3 btn btn-warning" 
                        data-original-title="Example Tooltip"
                      >
                        {props.totalInstitutions} Instituciones
                      </button>
                      
                      <div className="cards no-shadow bg-transparent no-border rm-borders mb-3">
                        <div className="cards">
                          <div className="col-md-12 col-lg-12">
                            <div className="row">
                              <div className="mb-3  mb-sm-0 col-lg-3">

                                <select className="mb-2 form-control">
                                  <option>2019</option>
                                  <option>2020</option>
                                  <option>2021</option>
                                  <option>2022</option>
                                </select>
                              </div>

                              <div className="col-md-4 col-lg-4">

                                <select className="mb-2 form-control">
                                  <option>Mensual</option>
                                  <option>Trimestral</option>
                                </select>
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <button 
                                  type="button" 
                                  data-toggle="tooltip" 
                                  title="" 
                                  data-placement="bottom" 
                                  className="btn-shadow mr-3 btn btn-info" 
                                  data-original-title="Example Tooltip"
                                >
                                  Filtrar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="main-card mb-3 cardz">
                        <Top5ChartSpecialty
                          loading='false'
                          data={specialty}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ModalNew
              modal={showModal}
              closeModal={closeModal}
            />
          </div>
        )}
      </Consumer>
    );
  }
}
export default instituciones;

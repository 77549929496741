import React, { Component } from 'react';
import { ServiceConsumer } from '../../../providers/serviceProvider';
import ServiceInfo from './index';

class ServiceProfile extends Component {
  render () {
    return (
      <ServiceConsumer>{props => <ServiceInfo {...props} />}</ServiceConsumer>
    );
  }
}

export default ServiceProfile;

/**
 * SkipHolidaysModal
 */
import React from 'react';

const SkipHolidaysModal = props => {
  const { onAssign, onExclude } = props;
  return (
    <div className={'modal-container'}>
      <div className="modal-body flex f-c a-i-c j-se text-center">
        <div className="modal-msg">
          <p>En el rango escogido existen días festivos</p>

          <p>
            ¿Deseas excluir los días festivos o asignar el horario igualmente?
          </p>
        </div>
        <div className="modal-actions">
          <button
            className="button button-sm bg-muted"
            onClick={() => {
              onAssign(false);
            }}
          >
            Asignar
          </button>
          <button
            className="button button-sm bg-icon ripple"
            onClick={() => {
              onExclude(true);
            }}
          >
            Excluir
          </button>
        </div>
      </div>
    </div>
  );
};
export default SkipHolidaysModal;

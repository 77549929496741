/* eslint-disable */
import './style.css';

import PropTypes from 'prop-types';
/**
 * TimeLine
 */
import React from 'react';
import assets from '../../../assets';

const TimeLine = ({ steps, onClick, name }) => {
  let lastState = '';
  return (
    <div className={`time-line time-line-${name}`}>
      <div className="time-line-start">
        <div className="top"></div>
        <div></div>
      </div>

      {
        steps.map((item, index) => {
          let {
            title,			/** @param {title} string */
            asset,			/** @param {asset} string options [radioButtonGray, radioButtonAqua, radioButtonRed]*/
            visible,		/** @param {visible} boolean */
            footer,			/** @param {footer} component */
            pointClass	/** @param {pointClass} string add class in parent row */
          } = item;

          if(item.state && item.state !== item.name){
            title = item.state
          }

          if(((title.toLowerCase()  == 'repetir' && footer) || title.toLowerCase()  != 'repetir' ) && lastState.toLowerCase()  != 'repetir' && lastState.toLocaleLowerCase() !== title.toLowerCase()){
          lastState = title;
            return (
              <div key={index} className={`time-line-child ${pointClass? pointClass: ''}`}>
                <div className="top">
                  <div className="time-line-title" title={title}>
                    { title && visible? title: '\u00A0'}
                  </div>
                </div>
                <div className="time-line-footer">
                  { footer && visible? footer: '\u00A0'}
                </div>
                {
                  visible && <img src={asset? assets[asset]: assets.radioButtonGray} onClick={()=>onClick(item, index)} alt="radio-button" />
                }
              </div>
            );
            }
        })
      }
      <div className="time-line-end">
        <div className="top"></div>
        <div></div>
      </div>
    </div>
  );
};
TimeLine.propTypes = {
  name: PropTypes.string,
  steps: PropTypes.array,
  onClick: PropTypes.func
};

TimeLine.defaultProps = {
  name: '',
  steps: [],
  onClick: ()=>{}
};

export default TimeLine;

import React, { Component } from 'react';
import EspecialtyList from './specialties';
import ModalNew from './modal';
import Top5ChartSpecialty from './chart/top5chartspecialty';
import './style.css';
import '../../assets/styles/bootstrap.min.css';
import client from '../../utils/apollo';
import { GET_SERVICES } from '../../graphql/types/servicesModule/queries';
import { context } from "../../providers/specialtyProvider";
import specialtiesIcon from '../../assets/images/sidebar/ic-especialidades.svg';

class especialidades extends Component {

  static contextType = context;

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  getServices = async () => {
    try {
      const result = await client.query({
        query: GET_SERVICES,
        variables: { perPage: 100 }
      });

      this.state.servicedata = result.data.getServices.data;
    } catch (error) {}
  }

  closeModalSpecialty = () => {
    this.setState({ modalSpecial: !this.state.modalSpecial });
    this.setState({ reloadData: true });
  }

  changeReloadData = () => {
    this.setState({ reloadData: false });
  }

  quantitySpecialties = (quantity) => {
    this.setState({ SpecifyQuantity: quantity });
  }

  editSpecialities = async (data) => {
    this.setState({dataEdit: data})
    this.setState({modalSpecial: true})
  }

  componentDidMount() {
    // this.getServices();
  }

  render() {
    const { 
      closeModal, 
      openModal, 
      showModal, 
      totalSpecialties
    } = this.context;

    const specialty = [
      {
        specialty: 'Anestesiología',
        value: 586
      },
      {
        specialty: 'Medicina General',
        value: 420
      },
      {
        specialty: 'Cirujano General',
        value: 386
      },
      {
        specialty: 'Cargiologia',
        value: 174
      },
      {
        specialty: 'Internista',
        value: 59
      }
    ];

    return (
      <div className="app-main__inner">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <img alt='Especialidades' src={specialtiesIcon} />
              </div>
              <div>Especialidades</div>
            </div>
            <div className="page-title-actions">

              <div className="d-inline-block dropdown">
                <button 
                  onClick={openModal}
                  type="button" 
                  data-toggle="dropdown" 
                  aria-haspopup="true" 
                  aria-expanded="false" 
                  className="btn-shadow dropdown-toggle btn btn-info"
                >
                  Agregar Nueva
                  <span className="btn-icon-wrapper pr-2 opacity-7">
                    <i className="nav-link-icon lnr-inbox"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className='row' style={{ marginTop: `18px` }}>
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="main-card mb-3 cardz">
                    <EspecialtyList
                      loading="false"
                      closeModal={() => this.closeModal()}
                      quantitySpecialties={(quantity) => this.quantitySpecialties(quantity)}
                      reloadData={this.state.reloadData}
                      changeReloadData={()=> this.changeReloadData()}
                      editSpecialities={this.editSpecialities}
                    />
                  </div>

                </div>
                <div className="col-lg-6">
                  <button 
                    type="button" 
                    data-toggle="tooltip" 
                    title="" 
                    data-placement="bottom" 
                    className="btn-shadow mr-3 btn btn-warning" 
                    data-original-title="Example Tooltip"
                  >
                    { totalSpecialties } Especialidades
                  </button>

                  <div className="cards no-shadow bg-transparent no-border rm-borders mb-3">
                    <div className="cards">
                      <div className="col-md-12 col-lg-12">
                        <div className="row">
                          <div className="mb-3  mb-sm-0 col-lg-3">

                            <select className="mb-2 form-control">

                              <option>2019</option>
                              <option>2020</option>
                              <option>2021</option>
                              <option>2022</option>

                            </select>
                          </div>

                          <div className="col-md-4 col-lg-4">
                            <select className="mb-2 form-control">
                              <option>Mensual</option>
                              <option>Trimestral</option>
                            </select>
                          </div>

                          <div className="col-md-4 col-lg-4">
                            <button 
                              type="button" 
                              data-toggle="tooltip" 
                              title="" 
                              data-placement="bottom" 
                              className="btn-shadow mr-3 btn btn-info" 
                              data-original-title="Example Tooltip"
                            >
                              Filtrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="main-card mb-3 cardz">
                    <Top5ChartSpecialty
                      loading='false'
                      data={specialty}
                    >
                    </Top5ChartSpecialty>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <ModalNew
            show={showModal}
            closeModal={closeModal}
          />

        </div>
      </div>
    );
  }
}

export default especialidades;

import React, { Fragment } from 'react';
import Webcam from 'react-webcam';

class ModalPhoto extends React.Component {
  state = { option: 0, photo: false };
  setRef = webcam => {
    this.webcam = webcam;
  };
  capture = () => {
    const photo = this.webcam.getScreenshot();
    this.setState({ photo, option: 3 });
    // this.props.getScreenshot(photo);
  };
  savePhoto = () => {
    const { photo } = this.state;
    this.props.modalPhoto(false);
    this.props.getScreenshot(photo);
  };
  optionPhoto = option => {
    this.setState({ option });
  };
  addImage = async e => {

    var file = e.target.files[0],
      imageType = /image.*/;

    if (!file.type.match(imageType)) return;

    const reader = new FileReader();
    reader.onload = this.fileOnload;
    await reader.readAsDataURL(file);
  };
  fileOnload = e => {
    const photo = e.target.result;
    this.setState({ photo, option: 3 });
    // this.props.getScreenshot(photo);
  };
  render () {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user'
    };
    const { modalPhoto } = this.props;
    const { option, photo } = this.state;
    return (
      <Fragment>
        <div
          className='backgroundModalPhotoPatient'
          onClick={() => modalPhoto(false)}
        />
        <div
          className='modalPhotoPatient'
          style={
            photo || option === 2 ? { height: '560px' } : { height: '350px' }
          }
        >
          <div className="mHeader flex j-sb">
            <span>Actualizar foto</span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => modalPhoto(false)}
            >
              ✖
            </span>
          </div>
          {option <= 1 && (
            <div className="optionPhoto flex f-w">
              <div
                className="selectOption flex j-c f-c a-i-c"
                onClick={() => this.optionPhoto(2)}
              >
                <div className="flex j-c a-i-c iconPhotoPatient">
                  <span className="icon-photo " />
                </div>
                <span className="">Capturar foto</span>
              </div>
              <div
                className="selectOption flex f-c j-c a-i-c"
                onClick={() => this.optionPhoto(1)}
              >
                <div className="flex j-c a-i-c iconPhotoPatient">
                  <span className="icon-image " />
                </div>
                <span className="">Subir foto</span>
                <input
                  type="file"
                  className="filePhoto"
                  title="Elige un archivo para subir"
                  accept="image/*"
                  onChange={this.addImage}
                />
              </div>
            </div>
          )}
          {(photo || option === 2) && (
            <div className="modalBody flex f-c a-i-c">
              {option === 1 ||
                (option === 3 && (
                  <img src={photo} className="photoSelect" alt="" />
                ))}
              {option === 2 && (
                <Webcam
                  audio={false}
                  height={450}
                  ref={this.setRef}
                  screenshotFormat="image/jpeg"
                  width={700}
                  videoConstraints={videoConstraints}
                />
              )}
            </div>
          )}
          <div className="flex j-c a-i-c" style={{ marginTop: '25px' }}>
            {option <= 1 && (
              <button
                className="button  button-sm bg-muted"
                onClick={() => modalPhoto(false)}
              >
                Cancelar
              </button>
            )}
            {option > 1 && (
              <button
                className="button  button-sm bg-muted"
                onClick={() => {
                  this.setState({ option: 0, photo: false });
                }}
              >
                Volver
              </button>
            )}
            {option === 2 && (
              <button
                className="button  button-sm bg-icon"
                onClick={this.capture}
              >
                Capturar
              </button>
            )}
            {option === 3 && (
              <button
                className="button  button-sm bg-icon"
                onClick={() => this.savePhoto()}
              >
                Guardar
              </button>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ModalPhoto;

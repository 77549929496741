import React from 'react';
import Calendar from 'react-calendar/dist/entry.nostyle';
import CheckboxInput from '../../general/checkboxInput';

const repeatedDaysSection = ({
  currentDay,
  onChange,
  updateField,
  date,
  repeatedDays
}) => {
  const repetitonOptions = [
    { id: 'd1', label: 'Lunes', value: 1 },
    { id: 'd2', label: 'Martes', value: 2 },
    { id: 'd3', label: 'Miércoles', value: 3 },
    { id: 'd4', label: 'Jueves', value: 4 },
    { id: 'd5', label: 'Viernes', value: 5 },
    { id: 'd6', label: 'Sábado', value: 6 },
    { id: 'd0', label: 'Domingo', value: 0 },
    { id: 'd0_6', label: 'Lunes a sábado', value: 7 }
  ];

  return (
    <div className="modal-msg-repetition flex j-sa f-w mb-1 mt-1">
      <div className="repetitionDays">
        <span className="header text-bold mb-1">Frecuencia</span>
        <div className="text-left flex f-c f-w check-options mt-1">
          {repetitonOptions.map((day, index) => {
            return (
              <CheckboxInput
                checked={
                  day.value !== 7
                    ? repeatedDays[day.value]
                    : repeatedDays[1] === true &&
                      repeatedDays[2] === true &&
                      repeatedDays[3] === true &&
                      repeatedDays[4] === true &&
                      repeatedDays[5] === true &&
                      repeatedDays[6] === true
                }
                key={index}
                id={`option-${index}`}
                label={day.label}
                name="repetitionDay"
                value={day.value}
                updateField={updateField}
              />
            );
          })}
        </div>
      </div>

      <div className="calendar-input">
        <div className="mb-1">
          <span className="header text-bold mr-03">Hasta</span>
          <input
            className="ml-03 text-center"
            type="text"
            readOnly
            value={currentDay.format('DD/MM/YYYY')}
          />
        </div>
        <Calendar
          onChange={onChange}
          value={date}
        />
      </div>
    </div>
  );
};

export default repeatedDaysSection;

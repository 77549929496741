import plusGrey from './plus-grey.png';
import plusGreen from './plus-green.png';
import plusBlue from './plus-blue.png';
import addUserBlue from './add-user-blue.png';
import addUserWhite from './add-user-white.png';
import radioButtonRed from './radio-button-red.png';
import radioButtonGray from './radio-button-gray.png';
import radioButtonAqua from './radio-button-aqua.png';

export default {
  plusGrey,
  plusGreen,
  plusBlue,
  addUserBlue,
  addUserWhite,
  radioButtonRed,
  radioButtonAqua,
  radioButtonGray
};

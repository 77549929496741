/**
 * SelectInput
 */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './style.css';

class OptionsTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: props.disabled,
      value: props.value,
      updateField: props.updateField
    };
  }
  classOption = (value, option) => {
    if (value == option) {
      return `selected${value} options_`;
    } else {
      return 'options_';
    }

  }
  updateField_ = (option) => {
    this.setState({ value: option });
    const { updateField } = this.state;
    const { name } = this.props;
    // console.log(name, option)
    updateField(
      name,
      {
        value: option
      }
    );
  }
  render() {
    const {
      name,
      label,
      options
      // ,disabled: propDisabled,
    } = this.props;
    const { value } = this.state;
    // let disabled = stateDisable;
    return (
      <Fragment>
        <div id={name} className="containerOptionalTwo">
          <div className="labelOptions"> {label}</div>
          <div className="optionsTwo">
            <div onClick={() => { this.updateField_('Si') }} className={this.classOption(value, options[0])} >{options[0]}</div>
            <div onClick={() => { this.updateField_('No') }} className={this.classOption(value, options[1])} >{options[1]}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}
OptionsTwo.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  iconAfter: PropTypes.string,
  classLabel: PropTypes.string,
  iconBefore: PropTypes.string,
  labelActive: PropTypes.bool,
  updateField: PropTypes.func,
  without_blank: PropTypes.bool
};
OptionsTwo.defaultProps = {
  options: [],
  classLabel: '',
  labelActive: false,
  without_blank: false
};
export default OptionsTwo;

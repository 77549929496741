/**
 *  Schedule
 */
/* eslint-disable */
import React, { Component, Fragment} from 'react';
// import { Link } from 'react-router-dom';
import './style.css';
import SelectInput from '../../../components/general/selectInput'
import salaCheckboxes from './salaCheckboxes';
import Checkbox from './checkboxComponent'
import estadoCheckboxes from './estadoCheckboxes'

class FilterComponent extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          checkedItems: new Map(),
        }
    
        this.handleChange = this.handleChange.bind(this);
      }
    
      handleChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
      }
  
    render() {
        return (
        <div className="filter">

            <div className="users-title" style={{justifyContent: "space-between",fontSize:'1.2rem'}}>
            Filtros
            </div>

            <form className="saved-filters">
            <br/>
            <SelectInput
                    className="select-input"
                    type="text"
                    label="Filtros guardados"
                    name="status"
                    options={[
                    { _id: 'suggested', name: 'Sugerido' },
                    { _id: 'confirmado', name: 'confirmado' },
                    { _id: 'disabled', name: 'Disabled' }
                    ]}
                    labelActive={true}
                />

                <SelectInput
                type="text"
                label="Sede"
                name="status"
                options={[
                    { _id: 'suggested', name: 'Sugerido' },
                    { _id: 'confirmado', name: 'confirmado' },
                    { _id: 'disabled', name: 'Disabled' }
                ]}
                labelActive={true}
                />

                <div className="filter-labels">
                    <span>Sala</span>
                </div>

                <div className="checkbox-container">
                    {
                    salaCheckboxes.map(item => (
                        <div className="checkbox-item">
                            <label key={item.key}>
                                <Checkbox name={item.name} checked={this.state.checkedItems.get(item.name)} onChange={this.handleChange} />
                                {item.name}
                            </label>
                        </div>
                    ))
                    }
                </div>

                
                <SelectInput
                    type="text"
                    label="Profesional"
                    name="status"
                    options={[
                        { _id: 'suggested', name: 'Sugerido' },
                        { _id: 'confirmado', name: 'confirmado' },
                        { _id: 'disabled', name: 'Disabled' }
                    ]}
                    labelActive={true}
                    />

                <SelectInput
                type="text"
                label="Prestación"
                name="status"
                options={[
                    { _id: 'suggested', name: 'Sugerido' },
                    { _id: 'confirmado', name: 'confirmado' },
                    { _id: 'disabled', name: 'Disabled' }
                ]}
                labelActive={true}
                />

                <div className="filter-labels">
                    <span>Estado</span>
                </div>

                <div className="checkbox-container">
                    {
                    estadoCheckboxes.map(item => (
                        <div className="checkbox-item">
                            <label key={item.key}>
                                <Checkbox name={item.name} checked={this.state.checkedItems.get(item.name)} onChange={this.handleChange} />
                                {item.name}
                            </label>
                        </div>
                    ))
                    }
                </div>
                <div className="save-btn">
                    <button className="button button-sm bg-icon blue-btn">
                        Guardar
                    </button>
                </div>

            </form>
        </div>
    );
  }
}
export default FilterComponent;

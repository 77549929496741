import React, { createContext, Component } from 'react';
import { GET_PHYSICIANS_BY_REFERRAL, GET_TOP_REFERRAL_INSURANCE, GET_TOP_REFERRAL_PHYSICIANS, GET_TOP_REFERRAL_SERVICES, GET_TOP_REFERRAL_SPECIALTIES } from '../graphql/types/appointment/queries';
import { withApollo } from 'react-apollo';
import _ from 'lodash';
import moment from 'moment';
import download from 'downloadjs';
import { getSessionTokens } from '../utils/helpers';

export const context = createContext();

const { Consumer, Provider } = context;

class DashboardProvider extends Component {

    constructor() {
        super();

        this.state = {
            filters: {
                dateFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                serviceFamily: ''
            },
            pagination: {
                page: 1,
                perPage: 10
            },
            physicians: [],
            totalPhysicians: 0,
            topReferralPhysicians: [],
            topReferralSpecialties: [],
            topReferralInsurance: [],
            topReferralServices: [],
            loading: false
        }
    }

    getPhysicians = async () => {
        const { filters, pagination } = this.state;

        let responseQuery = await this.props.client.query({
            query: GET_PHYSICIANS_BY_REFERRAL,
            variables: {
                filters,
                pagination
            }
        });

        responseQuery = responseQuery.data.getPhysiciansByReferral;

        this.setState({
            physicians: responseQuery.data,
            totalPhysicians: responseQuery.total
        });
    }

    setPaginationData = (field, value) => {
        this.setState({ 
            pagination: {
                ...this.state.pagination,
                [field]: value
            }
        });
    }

    handlePageChange = page => {
        this.setPaginationData('page', page);
    }
    
    handleRowsChange = (perPage, page) => {
        this.setPaginationData('perPage', perPage);
    }

    setFilter = (field, value) => {
        this.setState({
          filters: {
            ...this.state.filters,
            [field]: value
          }
        });
    }

    getTopReferralPhysicians = async() => {
        const { filters } = this.state;

        let responseQuery = await this.props.client.query({
            query: GET_TOP_REFERRAL_PHYSICIANS,
            variables: {
                filters
            }
        });

        responseQuery = responseQuery.data.getTopReferralPhysicians.data;

        this.setState({
            topReferralPhysicians: responseQuery
        })
    }

    getTopReferralSpecialties = async() => {
        const { filters } = this.state;

        let responseQuery = await this.props.client.query({
            query: GET_TOP_REFERRAL_SPECIALTIES,
            variables: {
                filters
            }
        });

        responseQuery = responseQuery.data.getTopReferralSpecialties.data;

        this.setState({
            topReferralSpecialties: responseQuery
        })
    }

    getTopReferralInsurance = async() => {
        const { filters } = this.state;

        let responseQuery = await this.props.client.query({
            query: GET_TOP_REFERRAL_INSURANCE,
            variables: {
                filters
            }
        });

        responseQuery = responseQuery.data.getTopReferralInsurance.data;

        this.setState({
            topReferralInsurance: responseQuery
        })
    }
    
    getTopReferralServices = async() => {
        const { filters } = this.state;

        let responseQuery = await this.props.client.query({
            query: GET_TOP_REFERRAL_SERVICES,
            variables: {
                filters
            }
        });

        responseQuery = responseQuery.data.getTopReferralServices.data;

        this.setState({
            topReferralServices: responseQuery
        })
    }

    export = async () => {
        this.handleLoader();

        const url = `${ process.env.REACT_APP_URL_AGENDAMIENTO }`;
        const params = new URLSearchParams(this.state.filters);

        const response = await fetch(`${url}/schedule/export-physicians-dashboard?${params}`);

        const blob = await response.blob();
        download(blob, 'report.xlsx');

        this.handleLoader();
    }

    exportStickers = async () => {
        this.handleLoader();

        const url = `${ process.env.REACT_APP_URL_AGENDAMIENTO }`;
        const params = new URLSearchParams(this.state.filters);

        const response = await fetch(`${url}/schedule/export-physicians-stickers?${params}`, {
            headers: {
                'x-token': getSessionTokens().token
            }
        });

        const blob = await response.blob();
        download(blob, 'stickers.pdf');

        this.handleLoader();
    }

    search = async () => {
        this.handleLoader();

        Promise.all([
            this.getPhysicians(),
            this.getTopReferralPhysicians(),
            this.getTopReferralSpecialties(),
            this.getTopReferralInsurance(),
            this.getTopReferralServices()
        ]).then(() => this.handleLoader());
    }

    handleLoader = () => {
        this.setState(prevState => ({
            loading: !prevState.loading
        }));
    }

    async componentDidUpdate(prevProps, prevState) {
        const { pagination } = prevState;
        const { pagination: newPagination } = this.state;
    
        if (!_.isEqual(pagination, newPagination)) {
            this.handleLoader();

            await this.getPhysicians();

            this.handleLoader();
        }
    }

    render () {
        return ( 
            <Provider
                value={{
                    ...this.state,
                    getPhysicians: this.getPhysicians,
                    handlePageChange: this.handlePageChange,
                    handleRowsChange: this.handleRowsChange,
                    setFilter: this.setFilter,
                    getTopReferralPhysicians: this.getTopReferralPhysicians,
                    getTopReferralSpecialties: this.getTopReferralSpecialties,
                    getTopReferralInsurance: this.getTopReferralInsurance,
                    getTopReferralServices: this.getTopReferralServices,
                    export: this.export,
                    exportStickers: this.exportStickers,
                    search: this.search
                }}
            >
                { this.props.children }
            </Provider>
        );
    }        
}

export const DashboardConsumer = Consumer;

export default withApollo(DashboardProvider);
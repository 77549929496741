/* eslint-disable */
import React, { Fragment, Component } from 'react';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';
import _, { debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { context } from '../../../providers/specialtyProvider';
import Alert from '../../../components/general/alert';

class EspecialtyList extends Component {

  static contextType = context;

  constructor(props) {    
    super(props);

    this.state = {
      columns: [
        {
          name: 'Nombre',
          selector: row => row.name,
          sortable: true,
          sortField: 'name'
        },
        {
          key: "action",
          text: "Action",
          className: "action",
          width: 100,
          align: "left",
          sortable: false,
          cell: row =>  (
            <>
              <button
                className="btn btn-sm"
                onClick={() => this.handleSpecialtyEdition(row)}
              >
                <span className="clickable icon-edit"></span>
              </button>

              <button
                className="btn btn-sm"
                onClick={() => this.context.handleDeleteVerification(row._id)}
              >
                <span className="clickable icon-delete"></span>
              </button>
            </>
          )          
        }
      ],
    };
  }

  handleSpecialtyEdition = data => {
    this.context.setFormData(data);
    this.context.openModal();
    this.context.setEditionMode(true);
  }

  debouncedSearch = debounce(value => {
    this.context.setFilter('name', value)
  }, 300);

  handleSearch = e => {
    this.debouncedSearch(e.target.value);
  }

  handleDelete = async () => {
    await this.context.deleteSpecialty();
    
    await this.context.getSpecialties();
  }

  componentDidMount() {
    this.context.getSpecialties();
  } 

  render() {
    const { columns } = this.state;

    const {
      deleteVerification,
      deleteConfirmation,
      handleAcceptDelete,
      handleCancelDelete,
      handlePageChange,
      handleRowsChange,
      handleSort,
      pagination: {
        perPage
      },
      specialties,
      totalSpecialties      
    } = this.context;

    const myNewTheme = {
      rows: {
        fontSize: '50px',
      },
    };

    return (
      <Fragment>
        <div className="card-body">
          <div className="row">            
            <div className="col-12">
              <input 
                name="filter" 
                id="filter" 
                type="text" 
                className="form-control" 
                placeholder='Buscar' 
                onChange={this.handleSearch} 
              />
            </div>
          </div>

          <div className="row mh700 mb-10">
            <DataTable
              columns={columns}
              customTheme={myNewTheme}
              data={specialties}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsChange}
              onSort={handleSort}
              pagination
              paginationPerPage={perPage}
              paginationServer
              paginationTotalRows={totalSpecialties}
              rowStyle={{ alignItems: "stretch" }}
              sortServer
              style={{ display: "flex", alignItems: "center" }}
              width={9 * 150}
            />
          </div>
        </div>

        <Alert
          show={deleteVerification}
          title='Estas seguro?'
          message='Es irreversible'
          confirmButtonText='Eliminar'
          confirmButtonAction={() => this.handleDelete()}
          cancelButtonText='Cancelar'
          cancelButtonAction={() => handleCancelDelete()}
        />
        
        <Alert
          show={deleteConfirmation}
          title='Accion exitosa'
          message='Institucion eliminada satisfactoriamente'
          confirmButtonText='Aceptar'
          confirmButtonAction={() => handleAcceptDelete()}
        />
      </Fragment>
    );
  }
}

EspecialtyList.propTypes = {
  client: PropTypes.object,
};

export default withRouter(withApollo(EspecialtyList));

/**
 * Patients
 */
import React from 'react';

// my components
import '../../../icomoon/style.css';
import { Link } from 'react-router-dom';
import PatientsList from '../../../components/patients/patientsList';

const Patients = () => {
  return (
    <div className="patients">
      <div className="users-title ripple-title">Lista de pacientes</div>
      <PatientsList>
        <div className="text-center pt-1 pb-1 flex a-i-c j-c">
          <Link to="/patients/new">
            <button className="button ripple save-user button-sm bg-icon" type="submit">
              <span className="icon-patients mr-02" />
              Nuevo paciente
            </button>
          </Link>
        </div>
      </PatientsList>
    </div>
  );
};
// <Link to="/patients/new">

export default Patients;

/* eslint-disable */
import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';

class Suggest extends Component {
  constructor (props) {
    super(props);
    props = { data: {}, onChange: ()=>{} };
    this.state = { phIds: [] };
  }
  render () {
    const { data, onChange } = this.props;
    const { fullname, specialty, mobile, email, status, identificacion, _id } = data;
    return (

      <div className="row-ph"
        style={
          this.state.isHighlighted?
            { outlineStyle:'solid', outlineColor:'#3d99ca', boxShadow:'2px 2px 5px #3d99ca' }
            :{ outlineStyle:'none' }}
      >
        <div className="files">
          <div className="border-gray-fk">
            <div className="label"> Nombre</div>
            <div className="value">{fullname}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Especialidad</div>
            <div className="value"> {specialty}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Estado </div>
            <div className="value">{status ? status : '--'}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Móvil </div>
            <div className="value">{mobile ? mobile : '--'}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Identificación</div>
            <div className="value">{identificacion ? identificacion : '--'}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> E-mail</div>
            <div className="value">{email ? email : '--'}</div>
          </div>
        </div>
        <div>
          <div className="ch-ph">
            <input id={_id} name="confirmed" type="checkbox" onChange={element => {
              onChange(element.currentTarget);
              this.setState(prevState=> ({ isHighlighted: !prevState.isHighlighted }));
            }}
            ></input>
          </div>
        </div>
      </div >
    );
  }

}
Suggest.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func
};

export default Suggest;


import React, { Component } from 'react';
import ScheduleWeek from '../../../components/resourcesModule/scheduleWeek';
import Calendar from 'react-calendar/dist/entry.nostyle';
import { days } from '../../../utils/resources/resourcesUtils';

class CalendarWeek extends Component {
  propTypes: {
    fields: PropTypes.object.isRequired,
  }
  render () {
    const {
      fields: { date, currentDay, currentWeek },
      addSerie,
      deleteSerie,
      onChange,
      editInterval,
      clearErrors
    } = this.props;
    return (
      <div className="flex f-w j-sb">
        <Calendar
          // formatShortWeekday={value =>
          // 	['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]
          // }
          onChange={onChange}
          value={date}
        />
        <ScheduleWeek
          edit={true}
          currentSede={this.props.currentSede}
          addInterval={addSerie}
          deleteSerie={deleteSerie}
          editInterval={editInterval}
          currentWeek={currentWeek}
          currentDay={days[currentDay.day()] + currentDay.format(' D')}
          ResourceDate={currentDay}
          clearErrors={clearErrors}
        />
      </div>
    );
  }
}

export default CalendarWeek;

/**
 * Patients
 */
import React from 'react';

// my components
import '../../../icomoon/style.css';

import PatientsList from '../../../components/ampas/ampasList';

const Patients = () => {
  return (
    <div className="patients">
      <div className="users-title ripple-title">Lista de Telemonitoreos</div>
      <PatientsList>
        <div className="text-center pt-1 pb-1 flex a-i-c j-c">
        </div>
      </PatientsList>
    </div>
  );
};
// <Link to="/patients/new">

export default Patients;

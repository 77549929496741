/* eslint-disable */

export const deleteRepetitionOptions = [
  { value: 1,
    label: 'Solo este horario especial' },
  {
    value: 2,
    label: 'Este horario especial + todos los horarios de la misma serie'
  },
  { value: 3,
    label: 'Este horario especial hasta la siguiente fecha' }
];
export const specialScheduleOptions = [
  { value: true, label: 'Sí' },
  { value: false, label: 'No' }
];

export const days = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sabado'
];


// Esta función separa la serie excluyendo el intervalo que se eliminó y devuelve todas las series modificadas.
export const splitSerie = (id, series, initial, final) => {

  let initialFlag = false;
  let finalFlag = false;
  let splitedSerie;
  const filtered = series.filter(serie => {
    return serie.serieId !== id;
  });
  const splited = series
    .filter(serie => {
      return serie.serieId === id;
    })
    .map(serie => {
      if (initialFlag === false) {
        // Check if initial is inside of the serie
        if (
          serie.initialDate
            .clone()
            .startOf('day')
            .diff(initial.clone().startOf('day')) <= 0 &&
          serie.finalDate
            .clone()
            .startOf('day')
            .diff(initial.clone().startOf('day')) >= 0
        ) {
          initialFlag = true;

          // Check if final is inside of the serie
          if (
            serie.finalDate.startOf('day').diff(final.clone().startOf('day')) >=
            0
          ) {
            finalFlag = true;
            // Check if initial and final are the same than serie.initialDate and serie.finalDate respectively
            if (
              serie.initialDate
                .clone()
                .startOf('day')
                .diff(
                  initial.clone().startOf('day')
                  //  .toDate()
                ) === 0 &&
              serie.finalDate
                .clone()
                .startOf('day')
                .diff(
                  final.clone().startOf('day')
                  //  .toDate()
                ) === 0
            ) {
              splitedSerie = [];
            } else if (
              // Check if initial is the same than serie.initialDate
              serie.initialDate
                .clone()
                .startOf('day')
                .diff(
                  initial
                    .clone()
                    .startOf('day')
                    .toDate()
                ) === 0
            ) {
              splitedSerie = [
                {
                  serieId: serie.serieId,
                  initialDate: final
                    .clone()
                    .add(1, 'day')
                    .startOf('day'),
                  repeatedDays: serie.repeatedDays,
                  interval: serie.interval,
                  finalDate: serie.finalDate.clone().startOf('day')
                }
              ];
            } else if (
              // Check if final is the same than serie.finalDate
              serie.finalDate
                .clone()
                .startOf('day')
                .diff(
                  final.clone().startOf('day')
                  //  .toDate()
                ) === 0
            ) {
              splitedSerie = [
                {
                  serieId: serie.serieId,
                  initialDate: serie.initialDate.clone(),
                  repeatedDays: serie.repeatedDays,
                  interval: serie.interval,
                  finalDate: initial
                    .clone()
                    .subtract(1, 'day')
                    .startOf('day')
                }
              ];
            } else {
              // Interval is completely inside serie
              splitedSerie = [
                {
                  serieId: serie.serieId,
                  initialDate: serie.initialDate.clone(),
                  repeatedDays: serie.repeatedDays,
                  interval: serie.interval,
                  finalDate: initial
                    .clone()
                    .subtract(1, 'day')
                    .endOf('day')
                },
                {
                  serieId: serie.serieId,
                  initialDate: final
                    .clone()
                    .add(1, 'day')
                    .startOf('day'),
                  repeatedDays: serie.repeatedDays,
                  interval: serie.interval,
                  finalDate: serie.finalDate.clone().startOf('day')
                }
              ];
            }
          } else {
            // Interval begins in serie but doesnt end inside it
            if (
              // Check if initial is the same than serie.initialDate
              serie.initialDate
                .clone()
                .startOf('day')
                .diff(
                  initial
                    .clone()
                    .startOf('day')
                    .toDate()
                ) === 0
            ) {
              splitedSerie = [];
            } else {
              splitedSerie = [
                {
                  serieId: serie.serieId,
                  initialDate: serie.initialDate.clone().startOf('day'),
                  repeatedDays: serie.repeatedDays,
                  interval: serie.interval,
                  finalDate: initial
                    .clone()
                    .subtract(1, 'day')
                    .startOf('day')
                }
              ];
            }
          }
        }
      } else if (initialFlag === true && finalFlag === false) {
        // If final is before to serie.finalDate
        if (
          serie.finalDate.startOf('day').diff(final.clone().startOf('day')) > 0
        ) {
          finalFlag = true;
          // If final is before serie.initialDay
          if (
            serie.initialDate
              .startOf('day')
              .diff(final.clone().startOf('day')) > 0
          ) {
            splitedSerie = null;
          } else {
            splitedSerie = [
              {
                serieId: serie.serieId,
                initialDate: final
                  .clone()
                  .add(1, 'day')
                  .startOf('day'),
                repeatedDays: serie.repeatedDays,
                interval: serie.interval,
                finalDate: serie.finalDate.clone().startOf('day')
              }
            ];
          }
        } else {
          splitedSerie = [];
        }
      } else {
        splitedSerie = null;
      }
      //  return splitedSerie;
      return splitedSerie ? splitedSerie : [serie];
    });
  return filtered.concat(...splited);
};

// Esta función verifica si la serie que va a ser agregada se sobrepone con otra ya existente.
export const validateOverlap = (
  series,
  selectedCollections,
  { initialDate, interval, repeatedDays, finalDate }
) => {
  var BreakException = {
    message: 'An error ocurred validating Schedule intervals overlapping'
  };

  try {
    // recorrer colecciones (prestaciones en usuarios y sedes en recursos)
    selectedCollections.forEach(collection => {
      // recorrer cada colección (prestación o recurso)
      series[collection].forEach(serie => {
        // recorrer cada uno de los días en que se repite la serie
        Object.keys(serie.repeatedDays).forEach(day => {
          // Verificar si la serie a agregar incluye el día que se está analizando en la iteración
          if (serie.repeatedDays[day] && repeatedDays[day]) {

             //Verificar si la fecha de inicio y finalización de la serie a agregar se sobrepone con la que se está analizando en la iteración
            let pa = serie.initialDate.format("x")
            let pb = serie.finalDate.format("x")
            let pc = initialDate.format("x")
            let pd = finalDate.format("x")

            // se verifica si las fechas de ambas las series se cruzan
            if (pb - pc >= 0 && pd - pa >= 0) {
              let {
                initial: existingInitial,
                final: existingFinal
                } = getHoursFromInterval(serie.interval);
                let {
                  initial: newInitial,
                  final: newFinal
                } = getHoursFromInterval(interval);

                existingInitial = hourInMinutes(existingInitial);
                existingFinal = hourInMinutes(existingFinal);
                newInitial = hourInMinutes(newInitial);
                newFinal = hourInMinutes(newFinal);

                // se verifica si la hora de ambas series se cruzan
                if (existingFinal - newInitial > 0 && newFinal - existingInitial > 0) {
                  throw BreakException;
                }
            }

          }
        });
      });
    });
    return true;
  } catch (e) {
    if (e === BreakException) {
      console.log('BreakException');
      return false;
    } else {
      throw e;
    }
  }
};


export const validateOverlapSedes = (
  series,
  selectedCollections,
  { initialDate, interval, repeatedDays, finalDate }
) => {
  var BreakException = {
    message: 'An error ocurred validating Schedule intervals overlapping'
  };

  try {
    /*TODO:
       * Si dos series son consecutivas (en fecha) deben ser unidas
     */

    // recorrer colecciones (prestaciones en usuarios y sedes en recursos)
    selectedCollections.forEach(collection => {
      // recorrer cada colección (prestación o recurso)

      let valid = false;
      let x;

        series[collection].forEach(serie => {
          Object.keys(serie.repeatedDays).forEach(day => {

            if (serie.repeatedDays[day] && repeatedDays[day]) {

              let existingInitialDate = serie.initialDate.format("x")
              let existingFinalDate = serie.finalDate.format("x")
              let newInitialDate = initialDate.format("x")
              let newFinalDate = finalDate.format("x")

              if (newInitialDate >=  existingInitialDate &&  newFinalDate <= existingFinalDate) {
                let {
                  initial: existingInitial,
                  final: existingFinal
                } = getHoursFromInterval(serie.interval);
                let {
                  initial: newInitial,
                  final: newFinal
                } = getHoursFromInterval(interval);

                existingInitial = hourInMinutes(existingInitial);
                existingFinal = hourInMinutes(existingFinal);
                newInitial = hourInMinutes(newInitial);
                newFinal = hourInMinutes(newFinal);

                if (( newInitial >= existingInitial && newFinal <= existingFinal )) {
                  valid = true
                }
              }
            }
          })
      });

      if (!valid) {
        throw BreakException;
      }
    });

    return true;

  } catch (e) {
    if (e === BreakException) {
      return false;
    } else {
      throw e;
    }
  }
};

const getHoursFromInterval = interval => {
  const intervalArray = interval.split('-');
  let initial = intervalArray[0];
  initial = to24Format(initial);
  let final = intervalArray[1];
  final = to24Format(final);
  return { initial, final };
};

export const to24Format = hour => {
  const hour24 = (parseInt(hour.match(/^\d{1,2}/), 10) + 12).toString();
  hour = /\s(AM)/.test(hour)
    ? hour.replace(/\s(AM)/, '')
    : hour.split(':')[0] === '12' && /\s(PM)/.test(hour)
      ? hour.replace(/\s(PM)/, '')
      : hour.replace(/\s(PM)/, '').replace(/^\d{1,2}/, hour24);
  return hour;
};

const hourInMinutes = hour => {
  const hourArray = hour.split(':');
  const hours = parseInt(hourArray[0], 10);
  const minutes = parseInt(hourArray[1], 10);
  return hours * 60 + minutes;
};

export const add0ToHour = hour => {
  let newHour = parseInt(hour.split(':')[0], 10);
  if (newHour < 10) {
    newHour = '0' + newHour.toString();
  }
  return hour.replace(/^\d{1,2}/, newHour);
};

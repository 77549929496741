/* eslint-disable */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import moment from 'moment';
import PatientFormData from '../../../utils/patients/patientFormData';
import SmsResultados from './SmsResultados'
import 'moment/locale/es';
import { GENDERS } from '../../../utils/constants';

moment.locale('es');

const PatientInfo = currentPatient => {
  const {
    assign,
    _id,
    dni,
    name,
    email,
    phone,
    photo,
    mobile,
    gender,
    lastName,
    type_dni,
    birthDay,
    epsPatient,
    profileComplete,
    flag,
    programTypePatient,
    handleProfileComplete,
    handleBiofirma,
    createPatientRFunction,
    user,
    image,
    show,
    authorized,
    classM
  } = currentPatient;
  let render_authorized = false;
  try {
    if (authorized) {
      render_authorized = true;
    }
  } catch (err) {
  }
  
  const titleDate = moment(birthDay);
  let className1 = 'contentImgPatient profileComplete';
  let className2 = 'contentImgPatient';
  if (flag === 'complicated') {
    className1 = `${className1} complicated`;
    className2 = `${className2} complicated`;
  }

  let img;
  if(image == undefined || image == ''){
    img = 'url(https://thumbs.dreamstime.com/b/vector-de-icono-perfil-usuario-s%C3%ADmbolo-retrato-avatar-logo-la-persona-forma-plana-silueta-negra-aislada-sobre-fondo-blanco-196482128.jpg)'
  }else{
    img = `url(data:image/gif;base64,${image})`
  }

  return profileComplete ? (
    <Fragment>
      <div
        className={className1}
        style={{
          margin: 'auto',
          backgroundImage: photo && `url(data:image/gif;base64,${image})`
        }}
      />
      <div
        className="cpatientInfo  flex  f-w j-s"
      >
        {flag === 'complicated' ? '⚠️' : null}
      </div>

      <div
        className="cpatientInfo  flex  f-w j-s"
        style={profileComplete && { paddingBottom: '40px' }}
      >

        {PatientFormData['parte1'].map(field => {
          if (!field.input) return;
          let value = currentPatient[field.name];
          if (field.name === 'dni') field.label = field.label2;
          else if (field.name === 'type_dni') {
            value = _.find(field.input.options, {
              _id: currentPatient[field.name]
            });
            value = value.name;
          }
          return (
            <div
              key={`row-part1-${field.name}`}
              className="flex j-s a-i-c user-field"
            >
              <div className="flex j-s a-i-c">{field.label}</div>
              <div className="flex j-e a-i-c">
                <span>{value}</span>
              </div>
            </div>
          );
        })}

        {
          PatientFormData['parte2'].map(field => {
            if (!field.input) return;

            let value = currentPatient[field.name];

            if (field.name === 'attendant' || field.name === 'companion') {
              value = _.find(field.input.options, {
                _id: currentPatient[field.name]
              });

              value = value && value.name;
            } else if (field.name === 'eps') {
              value = epsPatient && epsPatient.nombre ? epsPatient.nombre : '';
            } else if (field.name === 'programType') {
              value =
                programTypePatient && programTypePatient.name
                  ? programTypePatient.name
                  : '';
            }

            return (
              <div
                key={`row-part2-${field.name}`}
                className={`flex j-s a-i-c user-field ${field.name}`}
              >
                <div className="flex j-s a-i-c">{field.label}</div>
                <div className="flex j-e a-i-c">
                  <span>{value}</span>
                </div>
              </div>
            );
          })
        }



        <div className="moreInfoPatient">
          <Link to={`/patients/${_id}/edit`}>
            <span style={{ color: 'var(--message-color)' }}>Editar perfil</span>
          </Link>
        </div>
      </div>

    </Fragment>
  ) : (
      <div className="cpatientInfo  flex f-r a-i-c">
        <div className="containerPhoto flex pinfo dline">
          <div
            className={className1}
            style={{
              backgroundImage: img
            }}
          />

          <div className="contentImgName flex f-r">
            <div className="flex f-c j-c">
              {flag === 'complicated' ? '⚠️' : null}
              <span title={`${name} ${lastName}`}>{`${name} ${lastName}`}</span>
              <span>
                {type_dni}: {dni}
              </span>
              <span>
                {birthDay ? `${titleDate.format('D [de] MMMM [de] YYYY')} (${moment().diff(titleDate, 'years')})` : ''}
              </span>
            </div>
          </div>
        </div>
        <div className="flex f-c j-c pinfo dline">
          <span>
            {mobile} / {phone}
          </span>
          <span>{email}</span>
          {gender && (
            <span>
              {GENDERS[gender]}
            </span>
          )}
        </div>
        <div className="flex f-c j-c  pinfo dline">
          <span>EPS {epsPatient && epsPatient.nombre}</span>
          <span>
            Convenio:
          <span style={{ color: '#000' }}> Ninguno</span>
          </span>
          <span>&nbsp;</span>
        </div>
        <div className="flex f-c j-c pinfo">
          <span>Autorizados</span>
          {
            render_authorized === true ? (
              authorized.map(authorized => {
                return (
                  <Fragment key={`${Math.random()}`}>
                    <span>{authorized.names}</span>
                    <span>{authorized.type_document} {authorized.document}</span>
                    <span>{authorized.results ? 'RC' : ''} {authorized.hc ? 'HC' : ''}</span>
                  </Fragment>

                );
              })
            ) : ""}

        </div>
        {
          !assign &&
          <div className="moreInfoPatient">
          <Link to={`/ampa/${_id}/new`}>
            <span> Nuevo Telemonitoreo &nbsp; &nbsp;</span>
          </Link>
            <span onClick={createPatientRFunction}>
              <SmsResultados data={ currentPatient }></SmsResultados>
              Sincronizar Usuario Resultados
            </span>
            <span onClick={handleBiofirma}>
              Firmar Autorización
            </span>

            <span onClick={handleProfileComplete}>Perfil completo</span>
            <Link to={`/patients/${_id}/edit`}>
              <span>Editar perfil</span>
            </Link>

          </div>

        }

      </div>
    );
};

export default PatientInfo;

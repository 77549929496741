import React, { Component } from 'react';
import { withApollo } from "react-apollo";
import { GET_APPOINTMENTS } from "../../../graphql/types/scheduleModule/queries";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

const localizer = momentLocalizer(moment)


class RoomSchedule extends Component {

    constructor(props) {
        super(props)
        this.state = { appointments: [], isError: false, isLoading: true }
    }

    customEvent = (event) => {
        return {
            style: {
                background: "#0B6183"
            }
        }
    }

    customSlotPropGetter = () => {
        return {
            style: {
                background: 'white'
            }
        }
    }

    componentDidMount = async () => {
        const { workSpaceId, date } = this.props
        const day = (new Date(date.replace(/-/g, '/'))).getTime();
        try {
            const response = await this.props.client.query({ query: GET_APPOINTMENTS, variables: { space: workSpaceId, lastState: JSON.stringify(["agendada", "confirmada", "llego", "recepcion", "sala", "consultorio", "examen"]), day} });
            const appointments = this.tranformAppointmentsToCalendar(response.data.getAppointment.data)
            this.setState({ appointments, isLoading: false })
        } catch (error) {
            this.setState({ isLoading: false, isError: true })
        }
    }

    tranformAppointmentsToCalendar = (appointments) => {
        const { workSpaceId, date } = this.props

        const defaultDate = new Date(moment(date).toDate())
        const resourceMap = [
            {
                resourceId: workSpaceId,
            }
        ]

        const events = appointments.map(appointment => ({
            id: appointment._id,
            resourceId: workSpaceId,
            title: appointment.service.name,
            start: new Date(parseInt(appointment.time)),
            end: new Date(parseInt(appointment.time) + appointment.duration * 60000)
        }))

        return { events, resourceMap, defaultDate }

    }


    render() {
        const { isError, isLoading, appointments } = this.state;

        if (isError) return <p>Error</p>
        if (isLoading) return <p>Cargando...</p>

        return (

            <Calendar
                culture={'en'}
                events={appointments.events}
                toolbar={false}
                localizer={localizer}
                defaultView={Views.DAY}
                views={['day']}
                step={30}
                defaultDate={appointments.defaultDate}
                resources={appointments.resourceMap}
                slotPropGetter={this.customSlotPropGetter}
                eventPropGetter={this.customEvent}
                resourceIdAccessor="resourceId"
            />

        )
    }
}

export default withApollo(RoomSchedule)
import React, { Component, Fragment } from 'react';
import { isHoliday, areThereHolidays } from '../../../utils/functions';
import HolidayModal from '../holidayModal';
import RepetitionModal from '../repetitionModal';
import DeleteRepetitionModal from '../deleteRepetitionModal';
import EditSeriesModal from '../editSeriesModal';
import SkipHolidaysModal from '../skipHolidaysModal';
import { ResourceConsumer } from '../../../providers/resourceProvider';
import './style.css';

class Interval extends Component {
  state = {
    deleteRepetitionModal: false,
    repetitionModal: false,
    holidayModal: false,
    holidayConfirmed: false,
    holidayMessage: '',
    editionModal: false,
    openSkipHolidaysModal: false,
    serieInformation: []
  };

  closeHolidayModal = () => {
    this.setState({ holidayModal: false });
  };
  closeRepetitionModal = () => {
    this.setState({ repetitionModal: false, holidayConfirmed: false });
  };

  allowHolidays = () => {
    this.closeHolidayModal();
    this.setState({ holidayConfirmed: true });
    this.openRepetitionModal();
  };

  openHolidayModal = holidayMessage => {
    this.setState({ holidayModal: true, holidayMessage });
  };

  holidaysInRange = (initialDate, finalDate, repeatedDays, endCurrenday) => {
    this.closeRepetitionModal();
    this.setState({ serieInformation: [initialDate, finalDate, repeatedDays, endCurrenday] }, () => {

      if (areThereHolidays(	this.props.resourceDay, endCurrenday, repeatedDays).length > 0) this.openSkipHolidaysModal();
      else this.addInterval(false);

    });
  }

  addInterval = (skip) => {
    const [initialDate, finalDate, repeatedDays, endCurrenday] = this.state.serieInformation;
    const currentInterval = `${initialDate}-${finalDate}`;
    this.props.addInterval(
      this.props.resourceDay,
      currentInterval,
      repeatedDays,
      endCurrenday,
      null,
      skip
    );
    this.closeSkipHolidaysModal();
  };

  validateHoliday = () => {
    if (this.props.edit) {
      const holidayMessage = isHoliday(this.props.resourceDay);

      if (holidayMessage && (!this.props.interval && !this.state.holidayConfirmed)) this.openHolidayModal(holidayMessage);
      else this.openRepetitionModal();
    }
  };

  openRepetitionModal = () => {
    if (this.validateHoliday) this.setState({ repetitionModal: true });
  };

  openDeleteRepetitionModal = e => {
    e.stopPropagation();
    this.setState({ deleteRepetitionModal: true });
  };

  openSkipHolidaysModal = () => {
    this.setState({ openSkipHolidaysModal: true });
  }

  closeSkipHolidaysModal = () => {
    this.setState({ openSkipHolidaysModal: false });
  }

  closeDeleteRepetitionModal = () => {
    this.setState({ deleteRepetitionModal: false });
  };

  openEditionModal = () => {
    const { interval, edit } = this.props;
    if (interval && edit) this.setState({ editionModal: true });
  };
  closeEditionModal = () => {
    this.setState({ editionModal: false });
  };

  render () {
    const {
      edit,
      interval,
      resourceDay,
      deleteSerie,
      editInterval
    } = this.props;
    const {
      holidayModal,
      holidayMessage,
      repetitionModal,
      deleteRepetitionModal,
      editionModal,
      openSkipHolidaysModal
    } = this.state;
    return (
      <ResourceConsumer>
        {({ clearErrors }) => (
          <div>
            {holidayModal && (
              <HolidayModal
                onCancel={this.closeHolidayModal}
                onConfirm={this.allowHolidays}
                message={holidayMessage}
              />
            )}
            {openSkipHolidaysModal && (
              <SkipHolidaysModal
                onAssign={this.addInterval}
                onExclude={this.addInterval}
              />
            )}
            {repetitionModal && (
              <RepetitionModal
                onCancel={this.closeRepetitionModal}
                onConfirm={this.holidaysInRange}
                resourceDay={resourceDay}
              />
            )}
            {editionModal && (
              <EditSeriesModal
                onCancel={this.closeEditionModal}
                onConfirm={this.closeEditionModal}
                resourceDay={resourceDay}
                editInterval={editInterval}
                interval={interval}
              />
            )}
            {deleteRepetitionModal && (
              <DeleteRepetitionModal
                serieId={interval.serieId}
                onCancel={this.closeDeleteRepetitionModal}
                onConfirm={this.closeDeleteRepetitionModal}
                resourceDay={resourceDay}
                deleteSerie={deleteSerie}
              />
            )}
            <div className="interval-cell">
              <div
                className={`interval-container flex f-c j-sa a-i-c ${
                  isHoliday(resourceDay) || resourceDay.day() === 0
                    ? 'bg-zebra-shade'
                    : ''
                } ${interval ? 'interval-filled' : ''}`}
                onClick={() => {
                  if (editInterval) this.openEditionModal();
                  if (edit) {
                    if (clearErrors) clearErrors();
                    else this.props.clearErrors();
                  }
                }}
              >
                {interval ? (
                  <Fragment>
                    <div className="flex j-c a-i-c f-w">
                      <div>{interval.interval.split('-')[0]} -</div>
                      <div> {interval.interval.split('-')[1]}</div>
                    </div>
                    {edit && (
                      <div
                        className="text-center muted-text pt-02"
                        onClick={(e) => {
                          this.openDeleteRepetitionModal(e);
                          if (edit) {
                            if (clearErrors) clearErrors();
                            else this.props.clearErrors();
                          }
                        }}
                      >
                        Eliminar
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <div
                    className="empty-interval"
                    onClick={() => {
                      this.validateHoliday();
                      if (edit) {
                        if (clearErrors) clearErrors();
                        else this.props.clearErrors();
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </ResourceConsumer>
    );
  }
}

export default Interval;

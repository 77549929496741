import React, { Component } from 'react';
import { PatientConsumer } from '../../providers/patientProvider';
import ReasignSchedule from './reasign';

class ReasignConsumer extends Component {
  render () {
    return (
      <PatientConsumer {...this.props}>
        {props => <ReasignSchedule {...this.props} {...props} />}
      </PatientConsumer>
    );
  }
}

export default ReasignConsumer;

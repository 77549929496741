import React, { Component } from 'react';
import './style.css';
import moment from 'moment';
import Calendar from 'react-calendar/dist/entry.nostyle';
import TextAreaInput from '../../general/textAreaInput';
import { deleteRepetitionOptions } from '../../../utils/resources/resourcesUtils';
import CheckboxInput from '../../general/checkboxInput';

class DeleteRepetitionModal extends Component {
  state = {
    date: this.props.resourceDay['_d'],
    currentDay: this.props.resourceDay,
    deleteDescription: '',
    showDatesAlert: false,
    deleteOption: 1,
    errorMessage: 'Debes elegir una fecha posterior',
    error: false
  };

  deleteIntervals = serieId => {
    if (this.validate()) {
      const { deleteOption, deleteDescription, currentDay } = this.state;
      this.clearErrors();
      this.props.deleteSerie(serieId, {
        deleteOption,
        deleteDescription,
        finalDate: currentDay,
        initialDate: this.props.resourceDay
      });
      this.props.onCancel();
    } else {
      this.setState({ error: true });
    }
  };

  clearErrors = () => {
    this.setState({ error: false });
  }

  validate = () => {
    return this.state.deleteOption === 3 ? this.state.currentDay.diff(this.props.resourceDay) >= 0 : true;
  }

  onChange = date => {
    let currentDay = moment(date);
    this.setState({ currentDay });
  };

  updateField = (name, { value, aux: day, radioName }) => {
    if (radioName === 'deleteOption') {
      this.setState({ [radioName]: day });
    } else if (radioName === 'repetitionDay') {
      this.setState({
        repeatedDays: { ...this.state.repeatedDays, [day]: value }
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  render () {
    const { onCancel, serieId } = this.props;
    const { currentDay, date, deleteOption, errorMessage, error } = this.state;
    return (
      <div className="modal-container">
        <div className="modal-body-repetition delete-repetition flex f-c a-i-c j-sb text-center">
          <div className="text-center bg-icon password-modal-title ripple-title">
            Eliminar horario especial
          </div>
          <div className="delete-options text-left flex f-c">
            {deleteRepetitionOptions.map((option, index) => {
              return (
                <CheckboxInput
                  checked={option.value === deleteOption}
                  type="radio"
                  key={index}
                  id={`option-${index}`}
                  label={option.label}
                  name="deleteOption"
                  value={option.value}
                  updateField={this.updateField}
                />
              );
            })}
          </div>

          {this.state.deleteOption === 3 && (
            <div className="calendar-input" onClick={this.clearErrors}>
              <div className="mb-1">
                <span className="header text-bold mr-03">Hasta</span>
                <input
                  className="ml-03 text-center"
                  type="text"
                  readOnly
                  value={currentDay.format('DD/MM/YYYY')}
                />
              </div>
              <Calendar
                onChange={this.onChange}
                value={date}
              />
            </div>
          )}

          <p className="modal-section">
            Ingrese a continuación los motivos de eliminación
          </p>

          <TextAreaInput
            rows={10}
            name="deleteDescription"
            value={this.state.deleteDescription}
            updateField={this.updateField}
          />

          <div className="modal-actions mb-1">
            <button className="button button-sm bg-muted" onClick={onCancel}>
              Cancelar
            </button>
            <button
              className="button button-sm bg-icon ripple"
              onClick={() => {
                this.deleteIntervals(serieId);
              }}
            >
              Guardar
            </button>
          </div>
          {error && <p className="text-center error-msg">{errorMessage}</p>}
        </div>
      </div>
    );
  }
}

export default DeleteRepetitionModal;

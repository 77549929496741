import React from 'react';
import ResourcesList from '../../../components/resourcesModule/resourcesList';
import { Link } from 'react-router-dom';

const Resources = () => {
  return (
    <div>
      <div className="users-title ripple-title">Lista de recursos</div>
      <ResourcesList>
        <div className="text-center pt-1 pb-1 flex a-i-c j-c">
          <Link to="/resources/new">
            <button className="button ripple save-user button-sm bg-icon" type="submit">
              Nuevo recurso
            </button>
          </Link>
        </div>
      </ResourcesList>
    </div>
  );
};

export default Resources;

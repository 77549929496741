export const stylesHeader = () =>{
  return `
  <style type="text/css">
    #header {
      padding: 0 30px;
    }
    #pagination {
      margin: 0;
      padding: 0;
      text-align: right;
    }
    .content-header {
      width: 100%;
      font-family:Arial, Helvetica, sans-serif;
      padding: 5px 0px 0px;
      -webkit-print-color-adjust: exact;
      vertical-align: middle;
      font-size: 9px;
      display: inline-block;
    }
    #logo {
      margin: 0px auto;
      width:70px
    }
    .detail-user{
      display: grid;
      grid-template-columns: repeat(5,1fr);
      grid-column-gap: 5px;
      color: #444;
      border-top: 2px solid #CCC;
      padding-top: 3px;
    }
    .detail-user > div:first-child {
      grid-column: 1/4;
    }
    .detail-user > div:last-child {
      grid-column: 4/6;
    }
    .detail-user > div:first-child p, .detail-user > div:last-child p {
      margin: 0;
      padding: 0;
      font-size: 10px;
    }  
  </style>
  `
};

export const stylesFooter = () => {
  return `
  <style type="text/css">
    #footer {
      padding-bottom: 5px ;
    }
    footer {
      width: 100%;
      color: #444;
      font-family:Arial, Helvetica, sans-serif;
      background-color: #f7f7f7;
      padding: 3px 0px;
      -webkit-print-color-adjust: exact;
      vertical-align: middle;
      font-size: 9px;
      font-weight: 100;
      margin: 0px 30px;
      display: inline-block;
      text-align: center;
      border-top: 1.3px solid #274560;
      }
  </style>
  `
};

export const stylesBody = () => {
  return`
    <style>
      body {
        background-color: #fff;
        margin:0;
        padding:0;
      }
      h3 {
        width: 98.5%;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        background-color: #274560;
        padding: 5px;
        border-radius: 5px;
        margin: 10px 0 5px 0;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 15px;
        float: left;
        text-align: center
      }
      h5 {
        margin: 0;
        padding: 0;
      }
      .box-grafica {
        width: 98%;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
        height: 350px;
        overflow: hidden;
      }
      .box-grafica img {
        height:100%;
        object-fit:fill;
      }
      .box-har {
        padding: 0px 10px 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        line-height: 15px;
        color: #444;
        float: left;
        word-wrap: break-word;
      }
      .box-har b {
        color: #004964;
        font-size: 13px;
        line-height: 20px;
        font-family: 'Rubik', sans-serif;
      }
      .salto-pagina {
        page-break-inside: avoid;
      }
      .nueva-pagina {
        page-break-before: always;
        break-inside: avoid;
      }
      .information-general {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        row-gap: 5px;
        padding: 10px 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        line-height: 12px;
        color: #444;
        float: left;
        margin-top: 20px;
      }
      .information-general p {
        margin: 0;
      }

      #table-full {
        width: 100%;
        border: 1px solid #ccc;
        border-left: none;
      }
      #table-full th {
        color: #274560;
        font-family: 'Rubik', sans-serif;
        font-size: 13px;
        padding: 8px;
        border-left: 1px solid #ccc;
      }
      #table-full td {
        padding: 5px 8px;
        border-left: 1px solid #ccc;
        font-family: 'Rubik', sans-serif;
        color: #444;
        font-size: 13px;
        line-height: 18px;
      }
      
      #table-full tbody tr:nth-child(odd) {
        background-color: #efefef;
      }
      .head-medicines_new tr th:nth-child(1) {
        text-align: left;
      }
      .body-medicines_new tr td:not(:first-child) {
        text-align: center;
      }

      .body-meditions tr td:nth-child(1) {
        font-family: 'Rubik', sans-serif !important;
        font-weight: 600;
      }
      .body-meditions tr td:not(:first-child) {
        text-align: center;
      }
      .content-notes{
        display:flex;
        flex-direction:column; 
        row-gap:7px;
        margin:20px 0;
      }
      .detail-medic {
        width:100%;
        font-family: 'Rubik', sans-serif;
        color: #444;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items: center;
        page-break-inside:avoid;
      }
      .detail-medic p{
        margin: 0;
        font-size:14px;
      }
      .content-firm-physician{
        width:120px;
      }
    </style>
  `
}
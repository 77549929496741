/* eslint-disable */
import moment from 'moment';
import { getAverage } from '../../../utils/helpers';

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

// Group by color as key to the person array

function getGreetingTime(m) {
  var g = null; //return g

  if (!m || !m.isValid()) { return; } //if we can't find a valid or filled moment, we return.

  var split_afternoon = 12 //24hr time to split the afternoon
  var split_evening = 17 //24hr time to split the evening
  var currentHour = parseFloat(m.format("HH"));

  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    g = "tarde";
  } else if (currentHour >= split_evening) {
    g = "tarde";
  } else {
    g = "mañana";
  }

  return g;
}

export const formatArray = (array, dateInit) => {
  const data = array.map((e) => {
    let m = moment(e.timestamp);
    e = { ...e, getGreetingTime: `${m.format("DDMMYYYY")}` };
    return e;
  })

  const response = groupBy(data, 'getGreetingTime');
}

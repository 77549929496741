export default {
  defaults: {
    currentLogin: {
      status: false,
      __typename: 'currentLogin'
    }
  },

  resolvers: {
    Mutation: {
      showAlert: (_, { status }, { cache }) => {
        localStorage.setItem('clickSidebar', true);
        const data = {
          esitEdit: {
            status,
            __typename: 'showAlert'
          }
        };
        cache.writeData({ data });
        return true;
      },
      setInfoHeader: (_, { name }, { cache }) => {
        const data = {
          currentLogin: {
            name,
            __typename: 'infoHeader'
          }
        };
        cache.writeData({ data });
        return true;
      },
      setCurrentLogin: (_, { login }, { cache }) => {
        localStorage.setItem('status', login);
        const data = {
          currentLogin: {
            status: login,
            __typename: 'currentLogin'
          }
        };
        cache.writeData({ data });
        return login;
      },
      toggleSidebar: (_, { collapsed }, { cache }) => {
        localStorage.setItem('collapsed', collapsed);
        const data = {
          toggleSidebar: {
            collapsed,
            __typename: 'toggleSidebar'
          }
        };
        cache.writeData({ data });
        return collapsed;
      }
    }
  }
};

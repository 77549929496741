/**
 * ScheduleProvider
 */
import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withApollo, compose } from "react-apollo";
// import { graphql, withApollo, compose } from 'react-apollo';

const { Consumer, Provider } = createContext({ currentService: false });

class ScheduleProvider extends Component {
  state = {};
  render() {
    return (
      <Provider
        value={{
          ...this.state
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}
ScheduleProvider.propTypes = {
  children: PropTypes.element
};
export default compose()(withRouter(withApollo(ScheduleProvider)));
// graphql(CREATE_SERVICE, { name: 'createService' }),
// graphql(UPDATE_SERVICE, { name: 'updateService' }),
// graphql(DELETE_SERVICE, { name: 'deleteService' })
export const ScheduleConsumer = Consumer;

/**
 * CalendarModal
 */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Modal = ({
  children,
  isOpenModal,
  styleModalCenter,
  onClose,
  title
}) => (
  isOpenModal &&
  <div className='modal bd-example-modal-lg' tabIndex='-1' role='dialog' style={{ display: isOpenModal ? 'block' : 'none' }}>
    <div className='modal-dialog modal-lg' role='document'>
      <div className='modal-content'>
        <div className="modal-bodyr" style={styleModalCenter}>

          <div className="modal-header">
            {title}
            <span onClick={() => onClose()}>X</span>
          </div>

          {children && children}
        </div>
      </div>
    </div>
  </div>

);

Modal.propTypes = {
  children: PropTypes.element,
  isOpenModal: PropTypes.bool,
  styleModalCenter: PropTypes.object,
  title: PropTypes.string,
  onClose: PropTypes.func
};

Modal.defaultProps = {
  isOpenModal: true,
  styleModalCenter: {}
};

export default Modal;

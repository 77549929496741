/**
 * ResourcesTable
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ResourcesTable = props => {
  const { resources, toResource, scrolled, finished } = props;
  return (
    <div className="table-container" onScroll={scrolled}>
      {
        finished ?
          <Fragment>
            {
              resources.length > 0 ?
                <table className="list users-list">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Tipo de recurso</th>
                      <th>Sede</th>
                      <th>Cantidad</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      resources.map((resource, index) => {
                        return (
                          <tr
                            className="list-item clickable ripple-title"
                            key={index}
                            onClick={() => toResource(resource._id)}
                          >
                            <td>{resource.name}</td>
                            <td className="flex j-sb a-i-c">
                              { resource._resourceType && resource._resourceType.name }
                              { resource.specialSchedule && <i className="icon-reloj large success-msg" /> }
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              { resource._sede ? resource._sede.name : 'NA' }
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              { Number.isInteger(resource.quantity)? resource.quantity: 'NA' }
                            </td>
                            <td>{resource.active ? 'Activo' : 'Inactivo'}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                :
                <p className="text-center">No se encontraron recursos</p>
            }
          </Fragment>
          :
          <p className="text-center">Cargando recursos...</p>
      }
    </div>
  );
};
ResourcesTable.propTypes = {
  finished: PropTypes.bool,
  scrolled: PropTypes.func,
  resources: PropTypes.array,
  toResource: PropTypes.func
};
export default ResourcesTable;

import React, { Component } from 'react';
import { ResourceConsumer } from '../../../providers/resourceProvider';
import Resource from './index';

class ResourceEditable extends Component {
  render () {
    return (
      <ResourceConsumer>{props => <Resource {...props} />}</ResourceConsumer>
    );
  }
}

export default ResourceEditable;

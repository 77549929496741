import { avarageTaks, parseTakes } from "../../../utils/helpers";

const rowsTableMedicines = ({medicines}) => {
  const rows = medicines.map((item) => {
    const row = `
      <tr>
        <td>${item?.nameMedicine ?? ""}</td>
        <td>${item?.duration ?? ""}</td>
        <td>${item?.doseUnits}</td>
        <td>${item?.full ? "Si" : "No"}</td>
      </tr>
    `;
    return row;
  });
  return rows.join("\n")
};

const rowsTableMeditions = ({cellsTablePromedy}) => {
  const rows = cellsTablePromedy.map((item) => {
    const row = `
      <tr>
        <td>Día ${item?.day ?? ""}</td>
        <td>${item?.sistolicDay ?? ""}</td>
        <td>${item?.sistolicNight}</td>
        <td>${item?.diastolicDay ?? ""}</td>
        <td>${item?.diastolicNight ?? ""}</td>
        <td>${item?.frequencyDay ?? ""}</td>
        <td>${item?.frequencyNight ?? ""}</td>
      </tr>
    `;
    return row;
  });
  return rows.join("\n")
};

const rowAvarageTask = ({avarageTaskPatient}) =>{
  return`
    <tr>
      <td colspan="1">Media</td>
      <td colspan="2"><strong>${avarageTaskPatient?.sistolic}</strong></td>
      <td colspan="2"><strong>${avarageTaskPatient?.diastolic}</strong></td>
      <td colspan="2"><strong>${avarageTaskPatient?.frecuency}</strong></td>
    </tr>
  `
};

export const tableMedicines = ({medicines})=>{
  if(medicines.length === 0){
    return `<div></div>`
  }
  return `
  <div class="salto-pagina">
    <h3>Medicamentos</h3>
    <table id="table-full" cellspacing="0" >
      <thead class="head-medicines_new">
        <tr>
          <th>Nombre</th>
          <th>Frecuencia</th>
          <th>Cantidad</th>
          <th>Dosis plena</th>
        </tr>
      <thead>
      <tbody class="body-medicines_new">
        ${rowsTableMedicines({medicines})}
      </tbody>
    </table>
  </div>
  `;
};

export const tablePromedy = ({takes}) =>{
  const hiddenElement =`<span style="visibility:hidden">_</span>`
  const cellsTablePromedy = parseTakes(takes);
  const avarageTaskPatient = avarageTaks({avarage: cellsTablePromedy})

  return `
  <div style="page-break-after: always; ">
    <h3>Mediciones</h3>
    <table id="table-full" cellspacing="0">
      <thead class="head-meditions">
        <tr>
          <th colspan="1"></th>
          <th colspan="2" style="border-bottom: 1px solid #ccc !important;">Sistólica</th>
          <th colspan="2" style="border-bottom: 1px solid #ccc !important;">Diástolica</th>
          <th colspan="2" style="border-bottom: 1px solid #ccc !important;">Frecuencia</th>
        </tr>
        <tr>
          <th></th>
          <th colspan="1">${hiddenElement}Dia${hiddenElement}</th>
          <th colspan="1">Noche</th>
          <th colspan="1">${hiddenElement}Dia${hiddenElement}</th>
          <th colspan="1">Noche</th>
          <th colspan="1">${hiddenElement}Dia${hiddenElement}</th>
          <th colspan="1">Noche</th>
        </tr>
      </thead>
      <tbody class="body-meditions">
        ${rowsTableMeditions({cellsTablePromedy})}
        ${rowAvarageTask({avarageTaskPatient})}
      </tbody>
    </table>
  </div>
`;
};
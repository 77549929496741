import gql from 'graphql-tag';

export const GET_RESOURCE = gql`
	query getResource(
		$_id: ID
		$_resourceType: ID
		$_sede: ID
		$page: Int
		$name: String
		$services: String
	) {
		getResource(
			_resourceType: $_resourceType
			_sede: $_sede
			page: $page
			name: $name
			_id: $_id
			services: $services
		) {
			data {
				_id
				name
				quantity
				services
				series
				specialSchedule
				active
				_sede {
					_id
					name
				}
				_resourceType {
					_id
					name
				}
			}
			success
			errors {
				message
				path
			}
		}
	}
`;

export const GET_RESOURCE_TYPES = gql`
	query getResourceType {
		getResourceType {
			success
			errors {
				path
				message
			}
			data {
				name
				code
				_id
				calendar
			}
		}
	}
`;

export const GET_RESOURCE_SEDE = gql`
	query getResourceSede($_resourceType: ID!) {
		getResourceSede(_resourceType: $_resourceType) {
			success
			errors {
				path
				message
			}
			data {
				name
				_id
				calendar
			}
		}
	}
`;

export const GET_APPOINTMENTS = gql`
	query getAppointment(
		$lastState: String
		$states: [String]
		$space: ID,
		$sede: ID,
    	$family: ID,
		$doctor: ID,
		$patient: ID,
		$service: ID,
		$perPage: Int,
		$page: Int,
		$time: String
		$day: String
		$extra: Boolean
	) {
		getAppointment(
			lastState: $lastState
			states: $states
			space: $space
			sede: $sede
      		family: $family
			doctor: $doctor
			patient: $patient
			service: $service
			perPage: $perPage
			page: $page
			time: $time
			day: $day
			extra: $extra
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				time
				duration
				day
				extra
				doctor {
					_id
					name
					lastName
				}
				space {
					_id
					name
				}
				sede {
					_id
					name
				}
				service {
					_id
					description
					documents
					name
					nurse
					recommendations
				}
				state {
					_idAppointments
					state
					observations
					date_created
          leeValue{
            _id
          }
          realizaValue{
            _id
          }
				}
				entity {
					_id
					nombre
					status
					creadoPor
				}
				patient {
					_id
					dni
					name
					lastName
					fullName
				}
			}
		}
	}
`;

export const ALL_USERS = gql`
	query allUsers(
		$page: Int
		$_role: ID
		$_position: ID
		$active: Boolean
		$name: String
		) {
			allUsers(
			page: $page
			_role: $_role
			_position: $_position
			active: $active
			name: $name
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				fullName
				dni
				email
				_position {
					name
				}
				_role {
					name
				}
				active
			}
		}
	}
`;

export const GET_SERVICES = gql`
	query getServices($name: String, $perPage: Int, $page: Int) {
		getServices(name: $name, perPage: $perPage, page: $page) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				duration
			}
		}
	}
`;

/**
 * AppointmentCancel
 */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

// my components
import FormNotify from '../../general/formNotify';
import SelectInput from '../../general/selectInput';
import moment from 'moment';
import 'moment/locale/es';

import './style.css';
moment.locale('es');

class AppointmentCancel extends React.Component {

  updateField = (name, { value }) => {
    const { onChangeObservation } = this.props;
    onChangeObservation(value);
  }
  render() {
    const {
      appointment,
      onCloseNotify,
      valueObservation,
      onChangeObservation,
      indexCardAppointment,
      saveStateAppointments
    } = this.props;
    /*
    let titleDate = '';
    if (appointment.time){
      titleDate = moment(parseInt(appointment.time)).format('dddd D [de] MMMM [/] hh:mm:ss A');
    }
    */
    return (
      <FormNotify
        title="Cancelación de cita"
        onCloseNotify={onCloseNotify}
        styleModalCenter={{ width: 500, height: 180 }}
        buttons={[
          {
            text: 'Cancelar cita',
            btnClass: 'btn-alert',
            onClick: () => {
              if (valueObservation === '') return;
              saveStateAppointments(appointment, 'cancelada', valueObservation, true, indexCardAppointment);
            }
          }
        ]}
      >
        <SelectInput
          type="text"
          label="Observaciones"
          updateField={this.updateField}
          value={valueObservation}
          name="observation-timeline"
          options={[
            { _id: 'Falta equipo (Sicor)', name: 'Falta equipo (Sicor)' },
            { _id: 'Daño equipo (Sicor)', name: 'Daño equipo (Sicor)' },
            { _id: 'Ausencia especialista (Sicor)', name: 'Ausencia especialista (Sicor)' },
            { _id: 'Error de agendamiento (Sicor)', name: 'Error de agendamiento (Sicor)' },
            { _id: 'Se adelanto (Sicor)', name: 'Se adelanto (Sicor)' },
            { _id: 'Ausencia auxiliar (Sicor)', name: 'Ausencia auxiliar (Sicor)' },
            { _id: 'Recursos (Sicor)', name: 'Recursos (Sicor)' },
            { _id: 'Horario (Paciente)', name: 'Horario (Paciente)' },
            { _id: 'No cumple indicaciones (Paciente)', name: 'No cumple indicaciones (Paciente)' },
            { _id: 'Pico y placa ó pico y cédula (Paciente)', name: 'Pico y placa ó pico y cédula (Paciente)' },
            { _id: 'Muerte (Paciente)', name: 'Muerte (Paciente)' },
            { _id: 'Viaje (Paciente)', name: 'Viaje (Paciente)' }
          ]}
        />
      </FormNotify>
    );
  }
}
AppointmentCancel.propTypes = {
  appointment: PropTypes.object,
  onCloseNotify: PropTypes.func,
  valueObservation: PropTypes.string,
  onChangeObservation: PropTypes.func,
  indexCardAppointment: PropTypes.number,
  saveStateAppointments: PropTypes.func
};
export default AppointmentCancel;

import React, { useContext } from 'react';
import { context } from "../../../providers/patientProvider";
import FormInput from '../../general/formInput';
import SelectInput from '../../general/selectInput';
import { SelectGeneral } from '../../general/selectGeneral';

export const BasicDataForm = ({ 
    handleSetSelect,
    handleSetInput,
    identificationTypeSelect = [],
    genderSelect = [],
    maritalStatusSelect = [],
    educationLevelSelect = []
}) => {

    const { billingData } = useContext(context);

    return (
        <div className='row' style={{ rowGap: '1rem' }}>
            <div className="col-md-6 mt-2">
                <SelectGeneral
                    name="tipoDocumentoIdentificacion"
                    label="Tipo documento"
                    options={identificationTypeSelect}
                    value={billingData?.tipoDocumentoIdentificacion?.name ?? billingData?.tipoDocumentoIdentificacion}
                    updateField={handleSetSelect}
                />
            </div>
            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label="Número documento"
                    name='NumDocumentoIdentificacion'
                    onChange={handleSetInput}
                    value={billingData?.NumDocumentoIdentificacion}
                />
            </div>

            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label='Primer Nombre'
                    name='nombre1'
                    onChange={handleSetInput}
                    value={billingData?.nombre1}
                />
            </div>

            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label='Segundo Nombre'
                    name='nombre2'
                    onChange={handleSetInput}
                    value={billingData?.nombre2}
                />
            </div>

            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label='Primer Apellido'
                    name='apellido1'
                    onChange={handleSetInput}
                    value={billingData?.apellido1}
                />
            </div>
            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label='Segundo Apellido'
                    name='apellido2'
                    onChange={handleSetInput}
                    value={billingData?.apellido2}
                />
            </div>
            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label='Fecha de nacimiento'
                    name='fechaNacimiento'
                    onChange={handleSetInput}
                    type='date'
                    value={billingData?.fechaNacimiento}
                />
            </div>
            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label='Email'
                    name='Email'
                    onChange={handleSetInput}
                    value={billingData?.Email}
                    type="email"
                />
            </div>

            <div className="col-md-6 mt-2 custom-input">
                <FormInput
                    label='Celular'
                    name='Celular'
                    onChange={handleSetInput}
                    value={billingData?.Celular}
                />
            </div>
            <div className="col-md-6 mt-2">
                <SelectInput
                    label='Sexo'
                    name='codSexo'
                    options={genderSelect}
                    updateField={handleSetSelect}
                    value={billingData?.codSexo}
                    classNameGeneral="p-0 m-0"
                    className="index-input"
                />
            </div>

            <div className="col-md-6 mt-2 custom-input">               
                <SelectGeneral
                    name="EstadoCivil"
                    label="Estado Civil"
                    options={maritalStatusSelect}
                    value={billingData?.EstadoCivil}
                    updateField={handleSetSelect}
                />
            </div>
            <div className="col-md-6 mt-2">
                <SelectGeneral
                    name="NivelEscolaridad"
                    label="Nivel de Escolaridad"
                    options={educationLevelSelect}
                    value={billingData?.NivelEscolaridad}
                    updateField={handleSetSelect}
                />                
            </div>
        </div>
    )
}

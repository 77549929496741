import React, { Component } from 'react';
import { ResourceConsumer } from '../../../providers/resourceProvider';
import ServicesOptions from './index';

class ServicesWrapper extends Component {
  render () {
    return (
      <ResourceConsumer>{props => <ServicesOptions {...props} {...this.props} />}</ResourceConsumer>
    );
  }
}

export default ServicesWrapper;

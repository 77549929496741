/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import Router from './Router';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import client from './utils/apollo';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router />
  </ApolloProvider>,
  document.getElementById('root')
);
registerServiceWorker();
/**
 * FormLoader
 */
import React from 'react';

// my components
import FormModal from '../formModal';

import './style.css';

const FormLoader = ()=> (
  <FormModal>
    <div className="form-loader"></div>
  </FormModal>
);
export default FormLoader;

import React, { useState } from 'react';

const Accordion = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onItemClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="my-3">
            {items.map((item, index) => (
                <div className="custom-accordion-item" key={index}>
                    <div className="custom-accordion-title d-flex align-items-center justify-content-between" onClick={() => onItemClick(index)}>
                        <div>
                        <h1>{item.title}</h1>
                        {item?.subtitle}
                        </div>
                        <span className={`custom-accordion-icon ${activeIndex === index ? 'rotate' : ''}`}>&#9658;</span>
                    </div>
                    <div className={`custom-accordion-content ${activeIndex === index ? 'open' : ''}`}>
                        <div className='custom-accordion-content-item'>
                            {item.content}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;

/**
 * FormInput
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../icomoon/style.css';
import './style.css';

class FormInput extends Component {
  constructor (props){
    super(props);
    this.state = {
      focus: false,
      active: props.labelActive? true: false
    };
  }
  _toggleActive = () => {
    this.setState({ active: true, focus: true });
  };
  _isEmpty = e => {
    const { labelActive } = this.props;
    if (!labelActive && e.target.value === ''){
      this.setState({ active: false, focus: false });
    }
    else {
      this.setState({ focus: false });
    }
  };
  render () {
    const { active, focus } = this.state;
    const {
      max,
      type,
      name,
      style,
      label,
      value,
      onPaste,
      readOnly,
      onChange,
      disabled,
      iconAfter,
      mutedText,
      iconBefore
    } = this.props;

    const labelClass1 = iconBefore? 'with-icon': '';
    const labelClass2 = active && focus? 'label-active': '';
    const labelClass3 = value && value !== ''? 'label-input-full': 'empty';
    return (
      <div className="form-input" style={style}>
        { iconBefore && <span className={'before-icon icon-' + iconBefore} /> }
        <label htmlFor={name} className={`${labelClass1} ${labelClass2} ${labelClass3}`}>
          {label}
          { mutedText && <span className="muted-text">{mutedText}</span> }
        </label>
        <input
          id={name}
          max={max}
          type={type}
          name={name}
          value={value}
          onBlur={this._isEmpty}
          onFocus={this._toggleActive}
          onPaste={onPaste}
          disabled={disabled}
          onChange={onChange}
          readOnly={readOnly}
          className={`form-input-box ${labelClass3} ${active || value !== '' ? 'filled' : ''}`}
          autoComplete="off"
        />
        { iconAfter && <span className={'after-icon icon-' + iconAfter} /> }
      </div>
    );
  }
}
FormInput.propTypes = {
  max: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.string,
  label: PropTypes.string,
  onPaste: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  iconAfter: PropTypes.string,
  mutedText: PropTypes.string,
  iconBefore: PropTypes.string,
  labelActive: PropTypes.bool
};
FormInput.defaultProps = {
  style: {},
  value: '',
  readOnly: false,
  onChange: ()=>{},
  iconAfter: '',
  iconBefore: ''
};
export default FormInput;

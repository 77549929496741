/* eslint-disable */
import React, { Fragment, Component, createContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo, compose } from 'react-apollo';
import SimpleReactValidator from 'simple-react-validator';
import {
  CREATE_SPECIALTY, UPDATE_SPECIALITY
} from '../../../graphql/types/specialty/mutations';
import { context } from "../../../providers/specialtyProvider";

class Modal extends Component {

  static contextType = context;

  constructor(props) {
    super(props);

    this.state = {};

    this.validator = new SimpleReactValidator({
      messages: {
        default: 'El campo :attribute es obligatorio',
        min: 'El campo :attribute debe tener mínimo 5 caracteres',
        max: 'El campo :attribute debe tener máximo 120 caracteres'
      },
      element: message => <div className="alert alert-danger small p-2 mt-2">{message}</div>
    });
  }

  validateForm = async () => {
    if (this.validator.allValid()) {

      if (this.context.editionMode) {
        await this.context.updateSpecialty();
      } else {
        await this.context.createSpecialty();
      }
      
      this.context.getSpecialties();

      this.props.closeModal();

      if (this.props.onSuccess)
        this.props.onSuccess();

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const {
      form: {
        name
      },
      setField
    } = this.context;

    return (
      <Fragment>
        <div className='modal' tabIndex='-1' role='dialog' style={{ display: this.props.show ? 'block' : 'none' }}>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>

              <div className='modal-bodyr'>
                <h5>Nueva Especialidad</h5>

                <form className="mt-3" id="miformulario">
                  <div className="row">
                    <div className="col-12 mb-3">
                      Nombre <br></br>
                      <input
                        name="nameSpecialty"
                        id="nameSpecialty"
                        type="text"
                        className="form-control"
                        style={{ width: '100%' }}
                        value={name}
                        onChange={e => setField('name', e.target.value)}
                      />
                      {this.validator.message('Nombre', name, 'required|min:1|max:120')}
                    </div>                    
                  </div>
                </form>
              </div>

              <div className='modal-footer'>
                <button 
                  onClick={(e) => this.props.closeModal()}
                  type='button' 
                  className='btn btn-secondary' 
                  data-dismiss='modal'
                >
                  Cerrar
                </button>

                <button 
                  onClick={(e) => this.validateForm(e)}
                  type='button' 
                  className='btn btn-primary'
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

}

Modal.propTypes = {
  client: PropTypes.object,
};

export default compose(
  graphql(CREATE_SPECIALTY, { name: 'createSpecialty' }),
  graphql(UPDATE_SPECIALITY, { name: 'updateSpecialty' })
)(withRouter(withApollo(Modal)));

/**
 * ServiceInfo
 */

/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';

import { withApollo, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// my components
import BodyContainer from '../../../components/general/bodyContainer';
import FormNotify from '../../../components/general/formNotify';

import './style.css';

class ServiceInfo extends Component {
  state = {
    isLoader: true,
    isModalDelete: false
  }
  componentDidMount = async () => {
    await this.props.getCurrentService(this.props.match.params.id);
    this.setState({ isLoader: false });
  };

  _editService = _id => {
    this.props.history.push(`/services/${_id}/edit`);
  };

  _toggleModalDelete = isModalDelete => {
    this.setState({ isModalDelete });
  }

  _deleteService = async _id => {
    const { deleteService } = this.props;
    const response = await deleteService(_id);
    if (response.state) this.props.history.goBack();
  }

  render () {
    const { currentService } = this.props;
    const { isLoader, isModalDelete } = this.state;
    const componentError = currentService === undefined ? <p className="text-center">Cargando Prestación...</p>
      : <p className="text-center">La prestación no existe</p>;
    return (
      <BodyContainer isLoader={isLoader} id="service-info">
        {
          currentService ?
            <Fragment>
              <Accordion accordion={false} className="accordion service-info-accordion">
                <AccordionItem uuid="uniqueItem-presentation" expanded={true}>
                  <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
                    <span>Prestación</span>
                    <div className="accordion__arrow" role="presentation" />
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    {
                      currentService &&
                        <div className="service-info-container flex f-r j-c">
                          <div className="user-field resource-field flex f-c">
                            <div className="">Prestación</div>
                            <div className="">{currentService.name}</div>
                          </div>
                          <div className="user-field resource-field flex f-c duration">
                            <div className="">Duración</div>
                            <div className="">
                              <span className="time">
                                {currentService.duration &&
                                  Math.floor(currentService.duration / 60)}
                              </span>
                              <span>Hora</span>
                              <span className="time">
                                { currentService.duration && currentService.duration % 60 }
                              </span>
                              <span>Minutos</span>
                            </div>
                          </div>
                        </div>
                    }
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem
                  uuid="uniqueItem-information-presentation"
                  expanded={true}
                >
                  <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
                    <span>Información de la prestación</span>
                    <div className="accordion__arrow" role="presentation" />
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    {
                      currentService &&
                        <div className="service-info-container doc flex f-r j-c">
                          <div className="user-field resource-field flex f-c">
                            <div className="">Descripción</div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  currentService.description &&
                                  currentService.description.replace(/(?:\r\n|\r|\n|\↵)/g, '<br />')
                              }}
                            />
                          </div>
                          <div className="user-field resource-field flex f-c">
                            <div className="">Lista de documentos para la cita</div>
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html:
                                  currentService.documents &&
                                  currentService.documents.replace(/(?:\r\n|\r|\n|\↵)/g, '<br />')
                              }}
                            />
                          </div>
                          <div className="user-field resource-field flex f-c">
                            <div className="">Recomendaciones para el paciente</div>
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html:
                                  currentService.recommendations &&
                                  currentService.recommendations.replace(/(?:\r\n|\r|\n|\↵)/g, '<br />')
                              }}
                            />
                          </div>
                        </div>
                    }
                  </AccordionItemBody>
                </AccordionItem>
              </Accordion>
              <div className="text-center flex j-c pt-1 pb-1 bg-default">
                <button
                  type="submit"
                  className="button ripple save-user button-sm bg-icon"
                  onClick={() => this._editService(currentService._id) }
                >
                  Editar
                </button>
                <button
                  type="submit"
                  className="button ripple-btn-alert save-user button-sm btn-alert"
                  onClick={() => this._toggleModalDelete(true) }
                >
                  Eliminar
                </button>
              </div>
            </Fragment>
            : componentError
        }
        {
          isModalDelete &&
            <FormNotify
              title='Aviso!'
              message=' Confirme si desea eliminar el servicio.'
              buttons={[
                {
                  text: 'Cancelar',
                  btnClass: 'btn-gray',
                  onClick: ()=>this._toggleModalDelete(false)
                },
                {
                  text: 'Aceptar',
                  btnClass: 'btn-alert',
                  onClick: ()=>this._deleteService(currentService._id)
                }
              ]}
              onCloseNotify={ ()=>this._toggleModalDelete(false) }
            />
        }
      </BodyContainer>
    );
  }
}
ServiceInfo.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  currentService: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  getCurrentService: PropTypes.func
};
export default compose()(withApollo(withRouter(ServiceInfo)));

/* eslint-disable */
import React, { Fragment, Component, createContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import SimpleReactValidator from 'simple-react-validator';
import { context } from "../../../providers/institutionProvider";

class Modal extends Component {

  static contextType = context;

  constructor(props) {
    super(props);

    this.state = {};

    this.validator = new SimpleReactValidator({
      messages: {
        default: 'El campo :attribute es obligatorio',
        min: 'El campo :attribute debe tener mínimo 5 caracteres',
        max: 'El campo :attribute debe tener máximo 120 caracteres'
      },
      element: message => <div className="alert alert-danger small p-2 mt-2">{message}</div>
    });
  }

  validateForm = async () => {
    if (this.validator.allValid()) {

      if (this.context.editionMode) {
        await this.context.updateInstitution();
      } else {
        await this.context.createInstitution();
      }
      
      this.context.getInstitutions();

      this.props.closeModal();

      if (this.props.onSuccess)
        this.props.onSuccess();

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.context.getCities();
  }

  render() {
    const {
      cities,
      setField,
      form: {
        name,
        city,
        address
      }
    } = this.context;

    return (
      <Fragment>
        <div className='modal' tabIndex='-1' role='dialog' style={{ display: this.props.modal ? 'block' : 'none' }}>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-bodyr'>
                <h5>Nueva Institución</h5>

                <form className="form-inline mt-3" id="miformulario">
                  <div className="row">

                    <div className="col-sm-12 col-md-6 mb-3">
                      Nombre<br></br>
                      <input
                        name="nameInstitution"
                        id="nameInstitution"
                        type="text"
                        className="form-control"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          setField('name', e.target.value)
                        }}
                        value={name}
                      />
                      {this.validator.message('Nombre', name, 'required|min:5|max:120')}
                    </div>

                    <div className="col-sm-12 col-md-6">
                      Ciudad<br></br>                     
                      <Select2
                        name="city"
                        isSearchable={true}
                        isMulti={false}
                        options={cities}
                        value={city}
                        onChange={(e) => setField('city', e)}
                      />
                      {this.validator.message('Ciudad', city, 'required')}
                    </div>

                    <div className="col-12">
                      Dirección<br></br>
                      <input
                        name="address"
                        id="address"
                        type="text"
                        className="form-control"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          setField('address', e.target.value)
                        }}
                        value={address}
                      />
                      {this.validator.message('Dirección', address, 'required|min:5|max:120')}
                    </div>
                  </div>
                </form>
              </div>
              <div className='modal-footer'>
                <button 
                  onClick={(e) => this.props.closeModal()} 
                  type='button' 
                  className='btn btn-secondary' 
                  data-dismiss='modal'
                >
                  Cerrar
                </button>

                <button 
                  onClick={this.validateForm} 
                  type='button' 
                  className='btn btn-primary'
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Modal.propTypes = {
  client: PropTypes.object,
};

export default withRouter(withApollo(Modal));
/**
 * Router
 */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { graphql, compose, withApollo } from 'react-apollo';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import decode from 'jwt-decode';
import Login from './containers/general/login';
import Unauthorized from './containers/general/unauthorized';
import User from './containers/usersModule/user';
import Users from './containers/usersModule/users';
import Resources from './containers/resourcesModule/resources';

import Schedule from './containers/scheduleModule_old/schedule';
import Notifications from './containers/scheduleModule/schedule';

import Core2 from './containers/core2Module/core2';

import ServiceEditable from './containers/servicesModule/service/serviceEditable';
import AmpaEditable from './containers/ampaModule/ampa/ampaEditable';
import AmpaReport from './containers/ampaModule/ampa/report';
import PhysicianEditable from './containers/physicianModule/physician/physicianEditable';
import Services from './containers/servicesModule/services';
import Physicians from './containers/physicianModule/physicians';
import MergePh from './containers/mergePhModule';
import Ampa from './containers/ampaModule/ampas/ampas';
import Ampas from './containers/ampaModule/ampas';
import Reports from './containers/reportsModule/report';
import dashboard from './containers/dashboard/';
import especialidades from './containers/specialty2/';
import instituciones from './containers/institution/';

import Doctor from './containers/doctor/';

import Header from './components/general/header';
import Sidebar from './components/general/sidebar';
import Logout from './components/general/logout';
import Profile from './containers/usersModule/profile';
import './Router.css';

import ResetPassword from './containers/general/resetPassword';
import PasswordRecovery from './containers/general/PasswordRecovery';
import { getUserFromToken } from './utils/functions';
import {
  CURRENTLOGIN,
  GET_USER_HEADER
} from './graphql/types/usersModule/queries';
import { TOGGLE_SIDEBAR } from './graphql/types/usersModule/mutations';
import ResourceProfile from './components/resourcesModule/resourceInfo/resourceProfile';
import ResourceEditable from './containers/resourcesModule/resource/resourceEditable';
import ResourceProvider from './providers/resourceProvider';
import ServiceProvider from './providers/serviceProvider';
import PhysicianProvider from './providers/physicianProvider';
import ServiceProfile from './components/servicesModule/servicesInfo/serviceProfile';
import PatientProvider from './providers/patientProvider';
import ScheduleProvider from './providers/scheduleProvider';
import InstitutionProvider from './providers/institutionProvider';
import SpecialtyProvider from './providers/specialtyProvider';
import DashboardProvider from './providers/dashboardProvider';

import Patients from './containers/patientsModule/patients';

import Patient from './containers/patientsModule/patient/patient';
import PatientProfile from './components/patients/patientsInfo/patientProfile';

import Reasign from './containers/reasign/';
import ReasignSchedule from './containers/reasign/consumer';

import Availability from './containers/availability/';
import AvailabilitySchedule from './containers/availability/availability';

import { createBrowserHistory } from "history";
import { ROUTES_TO_HIDE_TEMPLATE } from './utils/constants';
import PhysicianLoginHistoryProvider from './providers/physicianLoginHistoryProvider';
import PhysicianLoginHistory from './containers/physicianLoginHistory';
import CupsList from './containers/billingModule/cups';
import BillingProvider from './providers/billingProvider';

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  let isValid = true;
  try {
    isValid = decode(token);
  } catch (e) {
    return false;
  }
  return isValid;
};

const isAuthorized = (user, path) => {
  return true; //BORRAR DESPUES DE LAS PRUEBAS - ROGERIO
  if (!user) return true;
  return JSON.parse(user._role.permission)[path] !== undefined;
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = rest.user;
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated()) return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        if (!isAuthorized(user, rest.path.split('/')[1])) return <Redirect to={{ pathname: "/unauthorized", state: { from: props.location } }} />
        return <Component {...rest} {...props} />
      }
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Redirect
            to={{
              pathname: "/patients",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
};

const getUserId = getUserFromToken;

class Router extends Component {
  state = {
    user: false,
    permission: null,
    routerDom: [
      { path: "/users/:userId/edit", module: "users", component: User },
      { path: "/users/new", module: "users", component: User },
      { path: "/users/:userId", module: "users", component: User },
      { path: "/users", module: "users", component: Users },
      { path: "/profile/edit", module: "users", component: Profile },
      { path: "/profile", module: "users", component: Profile },
      {
        path: "/reports",
        exact: true,
        module: "reports",
        component: Reports
      },     
      {
        path: "/mergeph",
        exact: true,
        module: "mergeph",
        component: MergePh
      },
      {
        path: "/ampa",
        exact: true,
        module: "ampa",
        component: Ampas
      },
      {
        path: "/services",
        exact: true,
        module: "services",
        component: Services
      },
      {
        path: "/resources",
        exact: true,
        module: "resources",
        component: Resources
      },
      {
        path: "/patients",
        exact: true,
        module: "patients",
        component: Patients
      },
      {
        path: "/ampa",
        exact: true,
        module: "ampa",
        component: Ampa
      },
      {
        path: "/notifications",
        module: "notifications",
        exact: true,
        component: Notifications
      },
      {
        path: "/schedule",
        module: "schedule",
        exact: true,
        component: Schedule
      },
      {
        path: "/core2",
        module: "core2",
        exact: true,
        component: Core2
      }
    ],
    routerPhysicianProvider: [
      {
        path: "/medicosremitentes",
        exact: true,
        module: "medicosremitentes",
        component: Physicians
      },
      {
        path: "/medicosremitentes/:id/edit",
        module: "medicosremitentes",
        exact: true,
        component: PhysicianEditable
      },
      {
        path: "/medicosremitentes/new",
        module: "medicosremitentes",
        exact: true,
        component: PhysicianEditable
      },
      {
        path: "/medicosremitentes/:id",
        module: "medicosremitentes",
        exact: true,
        component: PhysicianEditable
      },
      {
        path: '/dashboard',
        exact: true,
        module: "medicosremitentes",
        component: dashboard
      },
      {
        path: '/doctor/:action',
        exact: true,
        module: "medicosremitentes",
        component: Doctor
      },
      {
        path: '/doctor',
        exact: true,
        module: "medicosremitentes",
        component: Doctor
      },      
      {
        path: '/instituciones',
        exact: true,
        module: 'instituciones',
        component: instituciones
      },
      {
        path: '/especialidades',
        exact: true,
        module: 'especialidades',
        component: especialidades
      }, 
      {
        path: '/loginhistory',
        exact: true,
        module: 'medicosremitentes',
        component: PhysicianLoginHistory
      },
      {
        path: '/cups',
        exact: true,
        module: 'medicosremitentes',
        component: CupsList
      }
    ],
    routerAmpaProvider: [
      {
        path: "/ampa/report/:patientId/:ampaId",
        module: "ampa",
        exact: true,
        component: AmpaReport
      },
      {
        path: "/ampa/:id/new",
        module: "ampa",
        exact: true,
        component: AmpaEditable
      },
      {
        path: "/ampa/new",
        module: "ampa",
        exact: true,
        component: AmpaEditable
      },
      {
        path: "/ampa/:id/:_id",
        module: "ampa",
        exact: true,
        component: AmpaEditable
      },      
    ],
    routerServiceProvider: [
      {
        path: "/services/:id/edit",
        module: "services",
        exact: true,
        component: ServiceEditable
      },
      {
        path: "/services/new",
        module: "services",
        exact: true,
        component: ServiceEditable
      },
      {
        path: "/services/:id",
        module: "services",
        exact: true,
        component: ServiceProfile
      }
    ],
    routerResourceProvider: [
      {
        path: "/resources/:id/edit",
        module: "resources",
        exact: true,
        component: ResourceEditable
      },
      {
        path: "/resources/new",
        module: "resources",
        exact: true,
        component: ResourceEditable
      },
      {
        path: "/resources/:id",
        module: "resources",
        exact: true,
        component: ResourceProfile
      }
    ],
    routerPatientProvider: [
      {
        path: "/patients/:id/edit",
        module: "patients",
        exact: true,
        component: Patient
      },

      {
        path: "/patients/:id",
        module: "patients",
        exact: true,
        component: PatientProfile
      },
      {
        parh: "/patients/new",
        module: "patients",
        exact: true,
        component: Patient
      },
      {
        path: "/availability",
        module: "availability",
        exact: true,
        component: Availability
      },
      {
        path: "/reasign",
        module: "reasign",
        exact: true,
        component: Reasign
      }
    ],
    routerScheduleProvider: []
  };

  toggleSidebar = async () => {
    const collapsed =
      localStorage.collapsed && localStorage.collapsed === "true"
        ? false
        : true;
    this.props
      .toggleSidebar({ variables: { collapsed } })
      .then(() => this.setState({ collapsed }))
      .catch(() => this.setState({ collapsed }));
  };
  async componentDidMount() {
    this.getPermission();
  }
  getPermission = async () => {
    if (localStorage.token) {
      var { userId: _id } = getUserId();
      const response = await this.props.client.query({
        query: GET_USER_HEADER,
        variables: { _id }
      });

      if (response.data.getUser.success) {
        const {
          _role: { permission }
        } = response.data.getUser.data;
        try {
          this.setState({ user: response.data.getUser.data, permission });
        } catch (err) {
          console.log(err);
        }
      } else {
        localStorage.clear();
      }
    }
  };

  switchPage = rol => {

  };

  canShowTemplate = () => {
    let response = false;

    let currentRoute = createBrowserHistory();
    currentRoute = currentRoute.location.pathname;

    const hideTemplate = ROUTES_TO_HIDE_TEMPLATE.some(route => currentRoute.indexOf(route) !== -1);

    if (this.state.user && localStorage.status && !hideTemplate) {
      response = true;
    }

    return response;
  }

  render() {
    const {
      user,
      permission,
      routerDom,
      routerServiceProvider,
      routerAmpaProvider,
      routerPhysicianProvider,
      routerPatientProvider,
      routerResourceProvider,
      routerScheduleProvider
    } = this.state;
    const status =
      localStorage.status && localStorage.status === "true" ? true : false;
    const collapsed =
      localStorage.collapsed && localStorage.collapsed === "true"
        ? true
        : false;
    let permissionKey = [];
    if (status) {
      var { userId } = getUserId();
    }
    if (!user) {
      this.getPermission();
    }

    if (permission) permissionKey = Object.keys(JSON.parse(permission));

    let rol_by_user = true;
    const classParentSwitch = !status ? "withoutMargin" : "";
    
    return (
      <BrowserRouter>
        <div className={userId ? "home-container" : null}>
          {this.canShowTemplate() && (
            <Fragment>
              <Header client={this.props.client} userInfo={user} />
              <Sidebar permissionKey={permissionKey} />
            </Fragment>
          )}

          <div
            className={`content ${collapsed ? "collapsed-content" : classParentSwitch
              }`}
          >
            <div className="disable-background" onClick={this.toggleSidebar} />
            <Switch>
              {permissionKey.length === 0 ? null : (
                <PrivateRoute
                  user={user}
                  exact
                  path="/"
                  component={this.switchPage(user.rol)}
                />
              )}

              <LoginRoute path="/reset/:resetToken" component={ResetPassword} />
              <LoginRoute path="/(login|)" component={Login} />
              <LoginRoute path="/confirm-account/:resetToken" component={ResetPassword} />
              <LoginRoute path="/forgot" component={PasswordRecovery} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/unauthorized" component={Unauthorized} />

              {routerDom.map((data, key) => {
                return (
                  <PrivateRoute
                    user={user}
                    key={key}
                    exact={data.exact}
                    path={data.path}
                    component={data.component}
                  />
                );
              })}
              <ResourceProvider>
                <ServiceProvider>
                  <PatientProvider>
                    <ScheduleProvider>
                      <PhysicianProvider>
                        <InstitutionProvider>
                          <SpecialtyProvider>
                            <PhysicianLoginHistoryProvider>
                              <DashboardProvider>
                                <BillingProvider>
                                  <Switch>
                                    {routerResourceProvider.map((data, key) => {
                                      return permissionKey.indexOf(
                                        data.path.split("/")[1]
                                      ) < 0 ? null : (
                                        <PrivateRoute
                                          user={user}
                                          key={key}
                                          path={data.path}
                                          component={data.component}
                                        />
                                      );
                                    })}
                                    
                                    {routerPhysicianProvider.map((data, key) => {
                                      return permissionKey.indexOf(
                                        data.module
                                      ) < 0 ? null : (
                                        <PrivateRoute
                                          user={user}
                                          key={key}
                                          exact={data.exact}
                                          path={data.path}
                                          component={data.component}
                                        />
                                      );
                                    })}

                                    {routerAmpaProvider.map((data, key) => {
                                      return permissionKey.indexOf(
                                        data.path.split("/")[1]
                                      ) < 0 ? null : (
                                        <PrivateRoute
                                          user={user}
                                          key={key}
                                          exact={data.exact}
                                          path={data.path}
                                          component={data.component}
                                        />
                                      );
                                    })}

                                    {routerServiceProvider.map((data, key) => {
                                      return permissionKey.indexOf(
                                        data.path.split("/")[1]
                                      ) < 0 ? null : (
                                        <PrivateRoute
                                          user={user}
                                          key={key}
                                          exact={data.exact}
                                          path={data.path}
                                          component={data.component}
                                        />
                                      );
                                    })}
                                    <PrivateRoute
                                      user={user}
                                      exact={true}
                                      path="/patients/new"
                                      component={Patient}
                                    />
                                    <PrivateRoute
                                      user={user}
                                      exact={true}
                                      path="/patients/:id/edit"
                                      component={Patient}
                                    />
                                    <PrivateRoute
                                      user={user}
                                      exact={true}
                                      path="/patients/:id"
                                      component={PatientProfile}
                                    />
                                    <PrivateRoute
                                      user={user}
                                      exact={true}
                                      path="/availability"
                                      component={Availability}
                                    />
                                    <PrivateRoute
                                      user={user}
                                      exact={true}
                                      path="/availability/schedule"
                                      component={AvailabilitySchedule}
                                    />
                                    <PrivateRoute
                                      user={user}
                                      exact={true}
                                      path="/reasing"
                                      component={ReasignSchedule}
                                    />
                                    {routerScheduleProvider.map((data, key) => {
                                      return (
                                        <PrivateRoute
                                          user={user}
                                          key={key}
                                          exact={data.exact}
                                          path={data.path}
                                          component={data.component}
                                        />
                                      );
                                    })}
                                    <Route render={() => <h1>404 - Not Found</h1>} />
                                  </Switch>
                                </BillingProvider>
                              </DashboardProvider>
                            </PhysicianLoginHistoryProvider>
                          </SpecialtyProvider>
                        </InstitutionProvider>
                      </PhysicianProvider>
                    </ScheduleProvider>
                  </PatientProvider>
                </ServiceProvider>
              </ResourceProvider>
              <Route render={() => <h1>404 - Not Found</h1>} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
Router.propTypes = {
  client: PropTypes.object,
  user: PropTypes.object,
  toggleSidebar: PropTypes.func
};
export default compose(
  graphql(CURRENTLOGIN, { name: "currentLogin" }),
  graphql(TOGGLE_SIDEBAR, { name: "toggleSidebar" })
)(withApollo(Router));

export const actionsDictionary = {
  new_user: 'Creación de usuario',
  edit_user: 'Edición de usuario',
  active_user: 'Activación de usuario',
  inactive_user: 'Inactivación de usuario',
  new_patient: 'Creación de paciente',
  edit_patient: 'Edición de paciente',
  active_patient: 'Activación de paciente',
  inactive_patient: 'Inactivación de paciente'
};

export const actionsList = [
  { _id: 'new_user', name: 'Nuevo usuario' },
  { _id: 'edit_user', name: 'Edición de usuario' },
  { _id: 'active_user', name: 'Activación de usuario' },
  { _id: 'inactive_user', name: 'Inactivación de usuario' },
  { _id: 'new_patient', name: 'Nuevo paciente' },
  { _id: 'edit_patient', name: 'Edición de paciente' },
  { _id: 'active_patient', name: 'Activación de paciente' },
  { _id: 'inactive_patient', name: 'Inactivación de paciente' }
];

export const dateFilters = [
  { _id: 1, name: 'Hoy' },
  { _id: 8, name: 'Últimos 8 días' },
  { _id: 15, name: 'Últimos 15 días' },
  { _id: 30, name: 'Últimos 30 días' }
];

import React, { Component } from 'react';
import HeatMap from "react-heatmap-grid";

class ReferredUsersChart extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      title: 'Usuarios Remitidos',
      data: [],
      xLabels: [
        'Enero', '', '', '',
        'Febrero', '', '', '',
        'Marzo', '', '', '',
        'Abril', '', '', '',
        'Mayo', '', '', '',
        'Junio', '', '', '',
        'Julio', '', '', '',
        'Agosto', '', '', '',
        'Septiembre', '', '', '',
        'Octrobre', '', '', '',
        'Noviembre', '', '', '',
        'Diciembre', '', '', ''
      ],
      yLabels: [
        'Lun-', 'Mar-', 'Mie-', 'Jue-', 'Vie-', 'Sab-'
      ]
    };
  }

  componentDidMount () {
    this.setData();
  }

  setData = (() => {
    if (typeof this.props.data !== 'undefined' && this.props.data.length > 0) {
      this.setState({ 'data': this.props.data });
    }
  });

  render () {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h5 className='card-title'>{this.state.title}</h5>
          </div>
        </div>
        <br />
        <div className="row mh300">
          <div className="col-md-12 col-lg-12">
            {this.state.data.length > 0 ?
              <HeatMap
                background="green"
                xLabels={this.state.xLabels}
                yLabels={this.state.yLabels}
                data={this.state.data}
              />
              :
              <span>Sin datos</span>
            }
          </div>
        </div>
      </div>
    );
  }

}
export default ReferredUsersChart;
import gql from 'graphql-tag';

export const CREATE_PHYSICIAN = gql`
	mutation createPhysician(
		$fullname: String
		$name: String!
		$lastname: String
		$specialty: String
		$institution: String
		$address: String
		$office: String
		$phone: String
		$email: String
		$medicalrecord: String
		$status: String
		$mobile: String
		$identificacion: String
	) {
		createPhysician(
			name: $name
			lastname: $lastname
			fullname: $fullname
			specialty: $specialty
			institution : $institution
			address: $address
			office: $office
			phone: $phone
			mobile: $mobile
			medicalrecord: $medicalrecord
			email: $email
			status: $status
			identificacion: $identificacion
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name,
				lastname,
				fullname,
				specialty,
				address,
				phone,
				mobile,
				email,
				status
			}
		}
	}
`;

export const MERGE_PHYSICIAN = gql`
mutation mergeData(
	$phIds: [ID!]
  	$phId: String
  ){
	mergeData(
		phIds: $phIds
		phId: $phId
	) {
		success
	}
  }
  `;
export const CREATE_REFERRING_PHYSICIAN = gql`
	mutation createReferringPhysician(
		$_idAppointment: ID
		$_idPhysician: ID
	) {
		createReferringPhysician(
			_idAppointment: $_idAppointment
			_idPhysician: $_idPhysician
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
			}
		}
	}
`;

// export const UPDATE_PHYSICIAN = gql`
// 	mutation updatePhysician(
// 		$_id: ID!
// 		$name: String!
// 		$lastname: String!
// 		$specialty: String!
// 		$status: String!
// 		$email: String
// 		$address: String
// 		$phone: String
// 		$mobile: String
// 		$identificacion: String
// 		$medicalrecord: String
//         $office: String
//         $institution: String
// 	) {
// 		updatePhysician(
// 			_id: $_id
// 			name: $name
// 			lastname: $lastname
// 			specialty: $specialty
// 			status: $status
// 			email: $email
// 			address: $address
// 			phone: $phone
// 			mobile: $mobile
// 			identificacion: $identificacion
// 			medicalrecord: $medicalrecord
//         	office: $office
//         	institution: $institution
// 		) {
// 			success
// 			errors {
// 				path
// 				message
// 			}
// 		}
// 	}
// `;

export const CREATE_NEW_PHYSICIAN = gql`
	mutation createPhysician(
		$city: String
		$confirm: Boolean!
		$dni: String
		$email: String 
		$fullname: String!
		$gender: String!
		$institutions: [String]
		$insurance: [String]
		$medicalRecord: String
		$mobile: String
		$phone: String
		$shipping: ShippingInput
		$specialties: [String],
		$observations: String
	) {
		createPhysician(
			city: $city
			confirm: $confirm
			dni: $dni
			email: $email
			fullname: $fullname
			gender: $gender
			institutions: $institutions
			insurance: $insurance
			medicalRecord: $medicalRecord
			mobile: $mobile
			phone: $phone
			shipping: $shipping
			specialties: $specialties
			observations: $observations
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const UPDATE_PHYSICIAN = gql`
mutation updatePhysician(
	$_id: ID!
	$city: String
	$confirm: Boolean!
	$dni: String
	$email: String 
	$fullname: String!
	$gender: String!
	$institutions: [String]
	$insurance: [String]
	$medicalRecord: String
	$mobile: String
	$phone: String
	$shipping: ShippingInput
	$specialties: [String]
	$observations: String
) {
	updatePhysician(
		_id: $_id
		city: $city
		confirm: $confirm
		dni: $dni
		email: $email
		fullname: $fullname
		gender: $gender
		institutions: $institutions
		insurance: $insurance
		medicalRecord: $medicalRecord
		mobile: $mobile
		phone: $phone
		shipping: $shipping
		specialties: $specialties
		observations: $observations
	) {
		success
		errors {
			path
			message
		}
	}
}
`;

export const DELETE_PHYSICIAN = gql`
	mutation deletePhysician ($_id: ID!) {
		deletePhysician(_id: $_id) {
			success
		}
	}
`;

/**
 * DataAppointment component
 */
/* eslint-disable */
import React from 'react';
import _ from 'lodash';

// components
import FormTextArea from '../../../components/general/formTextArea';
import SelectInput from '../../../components/general/selectInput';

const DataAppointment = ({
  spaces,
  doctors,
  service,
  infoHide,
  observation,
  blockResource,
  appointmentsInfo,
  handleappointmentsInfo,
  setAppointmentInfoObservation
}) => {

  let {
    service: { documents, recommendations, nurse }
  } = service;
  documents = documents && documents.replace(/(?:\r\n|\r|\n|\↵)/g, '<br />');
  recommendations = recommendations && recommendations.replace(/(?:\r\n|\r|\n|\↵)/g, '<br />');

  let s = [];

  for (let i = 0; i < service.space.length; i++) {
    s.push(_.find(spaces, { _id: service.space[i] }));
  }

  // guardamos el space que escojimos en el aaray que pinta los espacios
  // en el select
  if (appointmentsInfo && appointmentsInfo.spaceObj) {
    if (!_.find(s, { _id: appointmentsInfo.spaceObj._id })) {
      s.push(appointmentsInfo.spaceObj);
    }
  }

  // eliminar el doctor o el espacio que a sido asignado con otro agendador
  if (service && service.service._id) {
    // service: service.service._id,
    const block = _.find(blockResource, {
      time: String(service.time)
    });
    if (block) {
      if (block.space) {
        _.remove(s, { _id: block.space });
      }
    }
  }

  let appointments = Object.assign({}, service);
  appointments.service = service.service._id;
  delete appointments.doctors;
  delete appointments.space;

  return (
    <div className="infoAppo ">

      <div className="flex f-r f-w">
        <SelectInput
          selectOneDefault={true}
          without_blank={true}
          name="space"
          label="Salas"
          updateField={(name, { value }, obj) =>
            handleappointmentsInfo(appointments, name, value, obj)
          }
          value={appointmentsInfo ? appointmentsInfo['space'] : ''}
          options={[{}, ...s]}
        />

        <FormTextArea
          name="observations"
          label="Observación"
          onChange={(e) => setAppointmentInfoObservation(appointments, e.target.value)}
          value={observation}
        />
      </div>

      {
        !infoHide &&
        <div className="IA_docuemnts_recommendations">
          <div>
            <h4 style={{ margin: 0 }}>Documentos para la cita:</h4>
            <p
              style={{ marginTop: 0 }}
              dangerouslySetInnerHTML={{ __html: documents }}
            />
          </div>
          <div>
            <h4 style={{ margin: 0 }}>Recomendaciones para el paciente:</h4>
            <p
              style={{ marginTop: 0 }}
              dangerouslySetInnerHTML={{ __html: recommendations }}
            />
          </div>

        </div>
      }
    </div>
  );
};

export default DataAppointment;

/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";

import "./style.css";
import Popover from "react-popover";
import { graphql, withApollo, compose } from "react-apollo";
import { firstNameFirstLastName } from "../../../utils/functions";
import { withRouter } from "react-router-dom";
import {
  TOGGLE_SIDEBAR,
  STATUS_LOGIN,
  SHOW_ALERT
} from "../../../graphql/types/usersModule/mutations";


class Header extends Component {
  state = {
    user: false,
    popoverIsOpen: false
  };

  togglePopover = () => {
    this.setState({ popoverIsOpen: !this.state.popoverIsOpen });
  };

  closePopover = () => {
    this.setState({ popoverIsOpen: false });
  };
  logOut = async () => {
    this.props.history.push("/logout");
  };

  toProfile = e => {
    e.stopPropagation();
    if (!localStorage.canExitEdit) {
      this.closePopover();
      this.props.history.push("/profile");
    } else {
      this.props
        .showAlert({
          variables: { status: true }
        })
        .then(() => {})
        .catch(() => {});
    }
  };

  toggleSidebar = async () => {
    const collapsed =
      localStorage.collapsed && localStorage.collapsed === "true"
        ? false
        : true;
    this.props
      .toggleSidebar({
        variables: { collapsed }
      })
      .then(() => {})
      .catch(() => {});
  };

  render() {
    let { userInfo: user } = this.props;

    let popoverProps = null;
    if (user) {
      var {
        name,
        lastName,
        phone,
        _role: { name: role }
      } = user;
      const {
        name: fname,
        lastName: flastName,
        initialLetter
      } = firstNameFirstLastName(name, lastName);

      const { popoverIsOpen } = this.state;

      popoverProps = {
        isOpen: popoverIsOpen,
        preferPlace: "below",
        place: "below",
        onOuterAction: () => this.closePopover(),
        body: [
          <div className="modal-profile" key={1}>
            <div className="flex j-se a-i-c profile-info">
              <div className="name-initials ripple clickable flex a-i-c j-c">
                {initialLetter}
              </div>
              <div className="flex f-c j-s ml-03">
                <span className="popover-name">
                  <strong>{`${name} ${lastName}`}</strong>
                </span>
                <span className="popover-role">{role}</span>
                <span className="popover-phone">{phone}</span>
              </div>
            </div>
            <div className="flex j-sb actions">
              <button
                className="button ripple button-sm bg-primary profile-button"
                onClick={this.toProfile}
              >
                Ver perfil
              </button>
              <button
                className="button ripple button-sm bg-primary logout-button"
                onClick={this.logOut}
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        ]
      };
    }
    return (
      <div className="header-bar flex j-sb a-i-c">
        <div className="mobile-toggle">
          <i
            className="users-filters-toggle large material-icons clickable ripple-gray"
            onClick={() => setTimeout(this.toggleSidebar, 300)}
          >
            dehaze
          </i>
        </div>
        <img src={require("../../../assets/logo.png")} alt="Logo Sicor" />
        <div
          className="logged-user flex clickable"
          onClick={this.togglePopover}
        >
          <div className="logged-info flex f-c a-i-e">
            <span className="header-msg user-name font-14">
              {name && `${name} ${lastName}`}
            </span>
            <span className="helper-msg user-role">{role}</span>
          </div>
          {popoverProps && (
            <Popover {...popoverProps}>
              <div className="name-initials ripple flex a-i-c j-c font-14">
                {name && `${name[0] + lastName[0]}`}
              </div>
            </Popover>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(STATUS_LOGIN, { name: "status_login" }),
  graphql(TOGGLE_SIDEBAR, { name: "toggleSidebar" }),
  graphql(SHOW_ALERT, { name: "showAlert" })
)(withApollo(withRouter(Header)));

Header.propTypes = {
  test: PropTypes.string
};

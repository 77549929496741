/* eslint-disable */
import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import DoctorForm from './doctor';
import DoctorsList from './doctors';

class Doctor extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      identity: '',
      // successReload: false,
      action: 'list',
      form: {
        'active': true,
        'names': '',
        'surnames': '',
        'e_mail': '',
        'gender': 'mujer',
        'mobile': '',
        'phone': '',
        'identity': '',
        'medical_identity': '',
        'city': '',
        'epspoliza': {
          'particular': true,
          'eps': false,
          'poliza': true
        },
        'specialty': '',
        'institution': '',
        'default_institution': '',
        'clinic': '',
        'extra_address': '',
        'extra_clinic': ''
      }
    };
  }

  handleSuccessFormAction = () => {
    this.props.history.push("/medicosremitentes");
  }

  componentDidMount = () => {
    let { action } = this.props.match.params;

    if (action) {
      action = action.trim();

      if (action === 'new') {
        this.setState({ action: 'new' });
      } else {
        this.setState({ action: 'edit' });
        this.setState({ identity: action });
      }
    } else {
      this.setState({ action: 'list' });
    }
  }

  render() {
    const { action, identity } = this.state;
    return (
      <Fragment>
        <div className="app-main__inner">
          <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div>
                { action === 'new' && 'Nuevo Médico Remitente' }
                { action === 'edit' && 'Editar Médico Remitente' }
                { action === 'list' && 'Medicos remitentes' }
              </div>
            </div>
          </div>
        </div>
            
            {
              action == 'new' && 
                <DoctorForm
                  onSuccess={this.handleSuccessFormAction}
                />              
            }
            {
              action == 'list' &&              
                <DoctorsList />             
            }
            {
              action == 'edit' &&
                <DoctorForm
                  identity={identity}
                  onSuccess={this.handleSuccessFormAction}
                />
            }
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Doctor);

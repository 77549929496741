import React, { useContext } from "react";
import Select2 from 'react-select';
import { context } from "../../../providers/billingProvider";

const CupsForm = () => {
    const { 
        setCupsFormDataField, 
        services, 
        securers, 
        saveCups,
        updateCups,
        getCups,
        hideCupsForm,
        resetCupsForm,
        cupsFormData
    } = useContext(context);

    const { service, eps, code, _id } = cupsFormData;

    const handleSaveCups = async () => {
        await saveCups();
        await getCups();
        hideCupsForm();
        resetCupsForm();
    }

    const handleUpdateCups = async () => {
        await updateCups();
        await getCups();
        hideCupsForm();
        resetCupsForm();
    }

    return (
        <form>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='form-group'>
                        <label htmlFor='service'>Servicio</label>
                        <Select2
                            isSearchable={true}
                            isMulti={false}
                            options={services}
                            value={service}
                            onChange={(e) => setCupsFormDataField('service', e)}
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='service'>EPS</label>
                        <Select2
                            isSearchable={true}
                            isMulti={false}
                            options={securers}
                            value={eps}
                            onChange={(e) => setCupsFormDataField('eps', e)}
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='service'>Codigo</label>
                        <input 
                            className="form-control"
                            type="text" 
                            id="" 
                            onChange={(e) => setCupsFormDataField('code', e.target.value)}
                            value={code}
                        />
                    </div>

                    <div className="form-group">
                        <button 
                            className="btn btn-primary"
                            onClick={() => {_id ? handleUpdateCups() : handleSaveCups()}}
                            type="button"
                        >
                            { _id ? 'Actualizar' : 'Guardar' }
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CupsForm;
/**
 * ListObservations
 */

import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const ListObservations = ({ list, title }) => {
  return (
    <div className="list-observations">
      {
        title && <div className="title ripple-title">{title}</div>
      }
      <div className="body">
        {
          list.map((item, index) => {
            let {
              title,					/** @param {title} string */
              dateTime,				/** @param {dateTime} string */
              nameUser,				/** @param {nameUser} string */
              pointClass,			/** @param {pointClass} string add class in parent row */
              observations		/** @param {observations} string */
            } = item;
            if(item.state && item.state !== item.name){
              title = item.state
            }
            return (
              observations && observations !== '' &&
                <div key={`row-list-${index}`} className={`row-list ${pointClass? pointClass: ''}`}>
                  <div className="subtitle">{title}</div>
                  { observations && <div className="text">{observations}</div> }
                  <div className="info-save">
                    { nameUser } ({ dateTime })
                  </div>
                </div>
            );
          })
        }
      </div>
    </div>
  );
};

ListObservations.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string
};

ListObservations.defaultProps = {
  list: []
};

export default ListObservations;

/**
 * appointmentsReasign
 */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion';

// components
import FormInput from '../../general/formInput';
import FormTextArea from '../../general/formTextArea';
import PatientsList from '../../patients/patientsList';

import './style.css';

class appointmentsReasign extends Component {
  state = {
    isNewPatient: false,
    isViewList: true,
    isFormPatient: false,
    newDni: '',
    newName: '',
    newPhone: '',
    newMobile: '',
    newTypeDni: '',
    newLastName: '',
    newObservation: ''
  }
  // onClick new patient
  _handleShowNewPatientAvailability = async show => {
    this.setState({ isFormPatient: true, isNewPatient: true });
    // await this.props.initCurrentPatient();
  };
  _onChangeInputState = (param, value) => {
    if (!this.state.isNewPatient && param !== 'newObservation') return;
    this.setState({ [param]: value });
  }
  _selectedTypeHeadPatient = (...params) => {
    this.setState({ isFormPatient: true, isNewPatient: false });
    const { selectedTypeHeadPatient } = this.props;
    selectedTypeHeadPatient(...params);
  }
  _hideForm = () => {
    this.setState({ isFormPatient: false, isNewPatient: false });
  }
  render (){
    let {
      newDni,
      newName,
      newPhone,
      newMobile,
      newTypeDni,
      newLastName,
      newObservation
    } = this.state;
    const {
      isViewList,
      isNewPatient,
      isFormPatient
    } = this.state;

    const { appointment, currentPatient, patientAppointment, saveAppointementReasign } = this.props;
    const { dni, name: nameUser, lastName, phone, mobile } = currentPatient;
    const { time, service: { name: nameAppointment } } = appointment;
    const titleDate = time? moment(parseInt(time)).format('dddd D [de] MMMM [/] hh:mm:ss A'): '';

    // si se muestra el formulario de usuario y no es usuario nuevo
    if (isFormPatient && !isNewPatient && patientAppointment) {
      newDni = patientAppointment.dni;
      newName = patientAppointment.name;
      newPhone = patientAppointment.phone;
      newMobile = patientAppointment.mobile;
      newTypeDni = patientAppointment.type_dni;
      newLastName = patientAppointment.lastName;
    }

    return (
      <AccordionItem uuid="uniqueItem-appointment-reassign" expanded={true}>
        <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
          <span>Reasignación de cita</span>
          <div className="accordion-title-close close-accordion" onClick={this._onToggleVisibleHistory} >X</div>
        </AccordionItemTitle>
        <AccordionItemBody className="accordion-body-history">
          <div className="flex f-w">
            <div className="appointment-reasign">
              <div>{`${nameAppointment} - ${titleDate}`}</div>
              <div>Asignado a</div>
              <div className="old-field">
                <div>
                  <FormInput
                    name="old-nit"
                    label="Identificación"
                    value={dni}
                  />
                </div>
                <div>
                  <FormInput
                    name="old-name"
                    label="Nombres"
                    value={nameUser}
                  />
                </div>
                <div>
                  <FormInput
                    name="old-lastname"
                    label="Apellidos"
                    value={lastName}
                  />
                </div>
                <div>
                  <FormInput
                    name="old-phone"
                    label="Teléfono"
                    value={phone}
                  />
                </div>
                <div>
                  <FormInput
                    name="old-mobile"
                    label="Móvil"
                    value={mobile}
                  />
                </div>
              </div>
              <div>Reasignar a</div>
              {
                isViewList &&
                  <div className={`container-list ${isFormPatient? 'list-hide': ''}`}>
                    <PatientsList
                      handleShowNewPatientAvailability={
                        this._handleShowNewPatientAvailability
                      }
                      perPage={5}
                      assign={true}
                      callbackField={this._hideForm}
                      selectedTypeHeadPatient={this._selectedTypeHeadPatient}
                    />
                  </div>
              }
              <div>
                {
                  isFormPatient &&
                    <Fragment>
                      <div className="new-field">
                        <div>
                          <FormInput
                            name="new-nit"
                            label="Identificación"
                            value={newDni}
                            onChange={(e)=>this._onChangeInputState('newDni', e.target.value)}
                          />
                        </div>
                        <div>
                          <FormInput
                            name="new-name"
                            label="Nombres"
                            value={newName}
                            onChange={(e)=>this._onChangeInputState('newName', e.target.value)}
                          />
                        </div>
                        <div>
                          <FormInput
                            name="new-lastname"
                            label="Apellidos"
                            value={newLastName}
                            onChange={(e)=>this._onChangeInputState('newLastName', e.target.value)}
                          />
                        </div>
                        <div>
                          <FormInput
                            name="new-phone"
                            label="Teléfono"
                            value={newPhone}
                            onChange={(e)=>this._onChangeInputState('newPhone', e.target.value)}
                          />
                        </div>
                        <div>
                          <FormInput
                            name="new-mobile"
                            label="Móvil"
                            value={newMobile}
                            onChange={(e)=>this._onChangeInputState('newMobile', e.target.value)}
                          />
                        </div>
                        <div>
                          <FormTextArea
                            label="Observaciones"
                            value={newObservation}
                            onChange={(e)=>this._onChangeInputState('newObservation', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="footer-newassign text-center mt-2">
                        <button className="button button-sm btn-gray validate-appointments ripple-gray">Cancelar</button>
                        <button
                          className="button button-sm bg-icon validate-appointments ripple"
                          onClick={()=> {
                            const newPatient = !isNewPatient? patientAppointment: {
                              dni: newDni,
                              name: newName,
                              phone: newPhone,
                              mobile: newMobile,
                              lastName: newLastName,
                              type_dni: newTypeDni
                            };
                            saveAppointementReasign(appointment, isNewPatient, newPatient, newObservation);
                          }}
                        >Reasignar</button>
                      </div>
                    </Fragment>
                }
              </div>
            </div>
          </div>
        </AccordionItemBody>
      </AccordionItem>
    );
  }
}
export default appointmentsReasign;

import gql from 'graphql-tag';

export const CREATE_RESOURCE = gql`
	mutation createResource(
		$name: String!
		$quantity: Int
		$_sede: ID
		$series: String
		$services: String
		$_resourceType: ID
		$specialSchedule: Boolean
		$active: Boolean
		$timeOut: Int    
	) {
		createResource(
			name: $name
			quantity: $quantity
			series: $series
			_sede: $_sede
			services: $services
			_resourceType: $_resourceType
			specialSchedule: $specialSchedule
			active: $active
			timeOut: $timeOut      
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const UPDATE_RESOURCE = gql`
	mutation updateResource(
		$_id: ID!
		$name: String
		$quantity: Int
		$_sede: ID
		$series: String
		$services: String
		$_resourceType: ID
		$specialSchedule: Boolean
		$active: Boolean
		$timeOut: Int
	) {
		updateResource(
			_id: $_id
			name: $name
			quantity: $quantity
			series: $series
			_sede: $_sede
			services: $services
			_resourceType: $_resourceType
			specialSchedule: $specialSchedule
			active: $active
			timeOut: $timeOut
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const DELETE_RESOURCE = gql`
	mutation deleteResource($_id: ID!) {
		deleteResource(_id: $_id) {
			success
			errors {
				path
				message
			}
		}
	}
`;

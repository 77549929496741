import React, { useEffect, useState } from 'react'

export const SelectGeneral = ({
    disabled = false,
    name,
    label,
    value = "",
    options = [],
    updateField = () => {}
}) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if( value ){
      return setIsActive(true)
    }
    setIsActive(false)
  }, [value])
  
  return (
    <div>
        <div className={`form-group p-0 m-0`}>
          <div className={`form-inline input-field select-field index-input`}>
            <select
              disabled={disabled}
              onChange={e => {
                updateField(name, {
                  name: name,
                  value: e.target.value
              })
              }}
              onFocus={() => setIsActive(true)}
              onBlur={() => {
                if( value ){
                  return setIsActive(true)
                }
                setIsActive(false)
              }}
              className="input-box"
              value={value || ''}
              name={name}
            >
              <option value=""></option>
              {
                options?.map( (item, index) => (
                  <option key={index} value={item.value}>{item.name}</option>
                ) )
              }
            </select>
            <label
              htmlFor={name}
              className={ isActive && 'active-label active' }
            >
              {label}{' '}
            </label>
          </div>
        </div>
      </div>
  )
}

import gql from 'graphql-tag';
// {
//     query:
//       findImage(name:  "RSvKaQnaE-1568404926269.jpg") {
//       data{
//         base64
//         __typename
//       }
//       success
//     }
//     __typename
//     }

export const FIND_IMAGE = gql`
query findImage($name: String!) {
    findImage(name: $name) {
        success
        errors {
            path
            message
        }
        data {
            base64
        }
    }
}
`;
import gql from 'graphql-tag';

export const GET_AMPAS_TAKE = gql`
query getAmpasTake($patient:String, $_id: ID!){
    getAmpasTake( patient: $patient, _id: $_id){
        success
        errors {
            path
            message
        }
        data {
            size
            weight
            bmi
            physicalActivity
            pregnant
            smoke
            liquor
            status
            liquorFrequency
            hardLiquor
            wine
            beer
            nursingNotes
            nameEquipment
            equipmentReference
            dateInit
            doctorNotes
            dateInitTimestamp
            token
            medico{
                _id
                lastname
                name
                mobil
            }
            enfermera{
                lastname
                name
                mobil
            }
            medicines {
                _id
                nameMedicine
                duration
                doseUnits
                full
            }
            Take {
                _id
                take
                created
                timestamp
                sistolic
                diastolic
                frequency
            }
          
        }
    }
}
`;
export const GET_AMPAS = gql`
	query getAmpas($name: String, $perPage: Int, $page: Int) {
		getAmpas(name: $name, perPage: $perPage, page: $page) {
			success
			errors {
				path
				message
			}
			data {
                dni
                type_dni
                name
                lastName
                phone
                status
                mobile
                fullName
                Ampas{
                    _id
                    dateInit
                    dateEnd
                    status
                    size
                    weight
                    bmi
                    physicalActivity
                    pregnant
                    smoke
                    liquor
                    liquorFrequency
                    hardLiquor
                    wine
                    beer
                    nursingNotes
                    doctorNotes
                    nameEquipment
                    equipmentReference
                    idNurseManager
                    token
                    patient
                }
                
			}
		}
	}
`;
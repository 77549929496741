/**
 * PatientsTable
 */
/* eslint-disable */
import React, { Fragment, Component } from 'react';
import PropType from 'prop-types';

import './style.css';
import { Link } from 'react-router-dom';

class PatientsTable extends Component {
  render () {
    const { patients, toProfile, scrolled, finished } = this.props;
    return (
      <div className="table-container" onScroll={scrolled}>
        {finished ? (
          <Fragment>
            {patients && patients.length > 0 ? (
              <table className="list users-list">
                <thead>
                  <tr>
                    <th>Nombres</th>
                    <th>Identificación</th>
                    <th>Teléfono</th>
                  </tr>
                </thead>
                <tbody>
                  {patients.map((patient, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => toProfile(patient._id)}
                        className="list-item clickable ripple-title"
                      >
                        <td>{patient.fullName}</td>
                        <td>{patient.dni}</td>
                        <td>{patient.phone}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className="text-center">No se encontraron paciente</p>
            )}
          </Fragment>
        ) : (
          !this.props.assign && (
            <p className="text-center">Cargando paciente...</p>
          )
        )}
      </div>
    );
  }
}

PatientsTable.propTypes = {
  patients: PropType.array,
  toProfile: PropType.func,
  scrolled: PropType.func,
  finished: PropType.bool
};

export default PatientsTable;

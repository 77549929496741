/* eslint-disable */
import React, { Component } from 'react'
import uniqid from 'uniqid';
import { getUrlHost, alphaNumericSort } from '../../../utils/functions'
import download from 'downloadjs';

import './style.css';

class TreeFolderStructure extends Component {

    constructor(props) {
        super(props)
        this.state = { path: [props.tree.name], currentTree: props.tree }

    }

    getSubTree = (route, tree) => {
        const step = route[0];
        const nextSteps = route.slice(1)
        const isFinalStep = (nextSteps.length === 0)

        if (step === undefined) return tree;

        for (const child of tree.children) {
            if (child.name === step) {
                return isFinalStep ? child : this.getSubTree(nextSteps, child)
            }
        }

    }

    upadtePath = (newPath) => {
        const { tree, path } = this.props;
        if (path === newPath) return;

        const currentTree = this.getSubTree(newPath.slice(1), tree)
        this.setState({ path: newPath, currentTree })
    }

    download = async (path, fileName) => {
        const url = `${ process.env.REACT_APP_URL_AGENDAMIENTO }`
        try {
            const response = await fetch(`${url}/report/download/${localStorage.getItem('token')}/${localStorage.getItem('refreshToken')}/${path}`)
            const blob = await response.blob();
            download(blob, fileName)
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { currentTree, path } = this.state;
        return (
            <div className="report-container">
                <FolderPath path={path} upadtePath={this.upadtePath} ></FolderPath>
                <TreeItem tree={currentTree} upadtePath={this.upadtePath} path={path} download={this.download}></TreeItem>
            </div>
        )
    }
}

const FolderPath = ({ path, upadtePath }) => (
    <div className="path">
        {path.map((route, index) => <span key={uniqid()} onClick={() => upadtePath(path.slice(0, index + 1))} >{route}/</span>)}
    </div>
)

var sortFolders = (a, b) => {
    if (a.type === 'directory' && b.type === 'file') return -1
    if (a.type === 'file' && b.type === 'directory') return 1
    return alphaNumericSort(a, b)
}

const TreeItem = ({ tree, upadtePath, path, download }) => {
    return (
        <ul className="tree-item">
            {tree.children.sort(sortFolders).map((child, index) =>
                (child.type === 'directory') ?
                    <li key={uniqid()} onClick={() => upadtePath(path.concat(child.name))}>
                        <span className="icon-folder-open icon-report "></span>{child.name}
                    </li>
                    :
                    <li key={uniqid()} onClick={() => download(child.path, child.name)} ><span className="icon-file-excel icon-report">
                    </span>{child.name}
                        <span className="icon-box-add download-report"></span>
                    </li>
            )}
        </ul>
    )
}

export default TreeFolderStructure;

/**
 * Card component
 */
import React from 'react';
import { formatDateAppointment } from '../../../utils/functions';
const Card = ({ service, sede, entity, doctor, errSaveAppointment }) => {
  const {
    service: { name },
    time
  } = service;
  const date = formatDateAppointment(time);
  return (
    <div>
      <div className="card flex f-c">
        <span className="title-service">{name}</span>
        <span>{date}</span>
        <span>{sede}</span>
        <span>{doctor && `${doctor.name} ${doctor.lastName}`}</span>
        <span>{entity && entity.nombre}</span>
      </div>
      <p className="text-center error-msg" style={{ marginBottom: 0 }}>
        {errSaveAppointment && errSaveAppointment[0].message}
      </p>
    </div>
  );
};
export default Card;
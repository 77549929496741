import React, { Component } from 'react';
import { ServiceConsumer } from '../../../providers/serviceProvider';
import Service from './index';

class ServiceEditable extends Component {
  
  render () {
    return <ServiceConsumer>{props => <Service {...props} />}</ServiceConsumer>;
  }
  
}

export default ServiceEditable;

import React, { Component, Fragment } from 'react';
import _ from 'lodash';
class PhysicianServices extends Component {
  renderCircles() {
    const { history_2 } = this.props;
    if (!_.isEmpty(history_2)) {
      // console.log(history_2.data);
      return history_2.data.map((d) => {
        return <Fragment>
          <div className="service-cnt">
            <div className="circle-white">{d.cnt}</div>
            <div className="service-text">{d.name}</div>
          </div>
        </Fragment>
      });
    }
  }
  render() {

    return (
      <Fragment>
        <div className="title-services">
        Cantidad de Prestaciones Remitidas:
          </div>
        <div className="grid-container-services">

            {this.renderCircles()}
          </div>
      </Fragment>
    );
  }

}

export default PhysicianServices;


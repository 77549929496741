import gql from 'graphql-tag';

export const GET_SERVICESFAMILY_NAMES = gql`
	query getServicesFamily($name: String, $perPage: Int) {
		getServices(name: $name, perPage: $perPage) {
			success
			errors {
				path
				message
			}
			data {
				name
				_id
				description
				active
			}
		}
	}
`;

export const GET_SERVICESFAMILY = gql`
	query getServicesFamily($name: String, $perPage: Int, $page: Int) {
		getServicesFamily(name: $name, perPage: $perPage, page: $page) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				description
				active
			}
		}
	}
`;

export const GET_SERVICEFAMILY = gql`
	query getServiceFamily($_id: ID!) {
		getServiceFamily(_id: $_id) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				description
				active
			}
		}
	}
`;

export const GET_SERVICEFAMILY_ALL = gql`
	query getServiceFamilyAll($name: String) {
		getServiceFamilyAll(name: $name) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
			}
		}
	}
`;

export const GET_SERVICEFAMILY_RESOURCES = gql`
	query getServicesFamily($id: ID) {
		getResourcesOfServiceFamily(id: $id) {
		success
		errors {
			path
			message
		}
		data {
			_id
			name
      description
      active
		}
		}
	}
`

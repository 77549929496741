import gql from 'graphql-tag';

export const GET_MEDICAMENTOS = gql`
	query allMedicamentos($name:String) {
		allMedicamentos(name: $name) {
			data {
				grupo
        		presentacion
        		nombre
        		dosis_plena
        		unidades
        		id
			}
		}
	}
`;

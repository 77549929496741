/**
 * ScheduleList
 */
/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

// my components
import FormInput from "../../general/formInput";
import FormTypeHead from "../../general/formTypeHead";
import BodyContainer from "../../general/bodyContainer";
import ScheduleTable from "../shceduleTable";
import SelectInput from "../../general/selectInput";

// Ocupación salas
import Modal from '../../../components/general/modal';
import RoomScheduleFilters from '../../../components/scheduleModule_old/roomScheduleFilters';

// query graphql
import {
  ALL_USERS,
  GET_RESOURCE,
  GET_SERVICES,
  GET_APPOINTMENTS,
  GET_RESOURCE_SEDE,
  GET_RESOURCE_TYPES
} from "../../../graphql/types/scheduleModule/queries";
import { GET_SERVICESFAMILY } from '../../../graphql/types/servicesFamilyModule/queries';

// json
import stateAppointment from "../../../utils/appointment/stateAppointmentOther";

import "./style.css";

import moment from 'moment';
import { BASE_PARENTS_STATES, appointmentStates } from '../../../utils/constants';
import FormMultipleSelect from "../../general/formMultipleSelect";

// const
const CANT_SCHEDULE_LIST = 30;
const ID_ROLE_DOCTOR = "5b646ce1d08dfcfeca467765"; // this id for doctor role

class ScheduleList extends Component {

  resourceSede;
  resourceSala;
  filterTimeOut;

  state = {
    page: 1,
    isLoader: false,
    isLastPage: false,
    appointments: [],
    filtersCollapsed: false,

    sede: "",
    date: moment().format("YYYY-MM-DD"),
    sala: "",
    states: [],
    doctor: "",
    family: "",

    serviceId: "",
    serviceName: "",

    optionsSede: [],
    optionsSala: [],
    optionsFamily: [],
    optionsService: [],
    openModalRooms: false
  };

  getDefaultStatesFilters = () => {
    const defaultStates = stateAppointment.map(s => {
      if (
        s.name !== appointmentStates.canceled &&
        s.name !== appointmentStates.repeat &&
        s.name !== appointmentStates.nullified
      ) {
        return {
          label: s.title,
          value: s.name
        }
      }
    })
    .filter(s => s);

    return defaultStates;
  }

  openRoomSchedule = () => {
    this.setState({ openModalRooms: true })
  }

  closeRoomSchedule = () => {
    this.setState({ openModalRooms: false })
  }

  async componentDidMount() {
    // this._onLoadSchedule([], 1);
    const resorceType = await this._getResourceType();
    const { resourceSede, resourceSala } = resorceType;
    this.resourceSala = resourceSala;
    this.resourceSede = resourceSede;

    // array options sede
    const alloptionsSede = await this._getResourceSede(resourceSede._id);
    const optionsSede = [alloptionsSede[1]];
    // default first sede
    const sede = optionsSede[0]._id;

    const optionsSala = await this._getResourceSala(resourceSala._id, sede);
    const optionsDoctor = await this._getUsersDoctor();

    const optionsFamily = await this._getServicesFamily();

    this.setState({ 
      optionsSede, 
      sede, 
      optionsSala, 
      optionsDoctor, 
      optionsFamily,
      states: this.getDefaultStatesFilters()
    });

    const urlParams = new URLSearchParams(window.location.search);
    const show_filters = urlParams.get('show_filters');

    if (show_filters) {
      if (localStorage.getItem('scheduling_filters')) {
        let scheduling_filters = JSON.parse(localStorage.getItem('scheduling_filters'));

        Object.keys(scheduling_filters).forEach(key => {
          if (scheduling_filters[key] != '') {
            this.setState({ [key]: scheduling_filters[key] })


          }
        });
      }
    } else {
      window.localStorage.removeItem('scheduling_filters');
    }

    this._onLoadSchedule([], 1);
  }

  _getResourceType = async () => {
    let data;
    const response = await this.props.client.query({
      query: GET_RESOURCE_TYPES
    });
    if (response && response.data && response.data.getResourceType) {
      data = {};
      const arrayData = response.data.getResourceType.data;
      arrayData.forEach(type => {
        if (type.name === "Sede" || type.name === "Espacio de trabajo") {
          const index = type.name === "Sede" ? "resourceSede" : "resourceSala";
          data[index] = type;
        }
      });
    }
    return data;
  }

  _getResourceSede = async _resourceType => {
    let sedes;
    const response = await this.props.client.query({
      query: GET_RESOURCE_SEDE,
      variables: { _resourceType }
    });
    if (response && response.data && response.data.getResourceSede)
      sedes = response.data.getResourceSede.data;
    return sedes;
  }

  _getResourceSala = async (_resourceType, _sede) => {
    let salas;
    const response = await this.props.client.query({
      query: GET_RESOURCE,
      variables: { _resourceType, _sede }
    });
    if (response && response.data && response.data.getResource)
      salas = response.data.getResource.data;
    return salas;
  }

  _getUsersDoctor = async () => {
    let users = [];
    const response = await this.props.client.query({
      query: ALL_USERS,
      variables: {
        _role: ID_ROLE_DOCTOR,
        active: true
      }
    });
    if (
      response &&
      response.data &&
      response.data.allUsers &&
      response.data.allUsers.data
    )
      users = response.data.allUsers.data;
    return users;
  }

  _getServicesFamily = async () => {
    let families = [];

    const servicesFamily = await this.props.client.query({
      query: GET_SERVICESFAMILY
    })

    families = servicesFamily.data.getServicesFamily.data;

    return families;
  }

  _convertTimestamp = date => {
    const arrayDate = date.split("-");
    const newDate = arrayDate[1] + "/" + arrayDate[2] + "/" + arrayDate[0];
    return new Date(newDate).getTime(); // will alert 1330210800000
  }

  _searchInParents = async name => {
    let dataReturn = []
    BASE_PARENTS_STATES.map((opt) => {
      if (opt.name.toLowerCase().indexOf(name.toLowerCase()) != -1) {
        dataReturn.push(opt)
      }
    })
    return dataReturn
  }

  _getServices = async name => {
    let services = [];


    const searchParents = await this._searchInParents(name)



    const variables = {
      name,
      page: 1,
      perPage: 10
    };
    const response = await this.props.client.query({
      query: GET_SERVICES,
      variables
    });
    if (
      response &&
      response.data &&
      response.data.getServices &&
      response.data.getServices.data
    )
      Object.assign(services, response.data.getServices.data)
    Object.assign(services, searchParents)

    return services;
  }

  /**
   * _onFilterField
   * @param {name} string name field
   * @param {options} object options input
   */
  _onFilterField = (name, { value }) => {

    // Guardar los filtros en el local storage
    let new_scheduling_filters = {};
    let scheduling_filters = localStorage.getItem("scheduling_filters");

    if (scheduling_filters != null) {
      new_scheduling_filters = JSON.parse(scheduling_filters);
    }

    new_scheduling_filters[name] = value;
    localStorage.setItem('scheduling_filters', JSON.stringify(new_scheduling_filters));


    const updateState = { [name]: value, page: 1, isLastPage: false };

    if (name === "sede") {
      updateState.sala = "";
      updateState.optionsSala = [];
    }

    this.setState(updateState, async () => {
      await this._onLoadSchedule();
      if (name === "sede") {
        const optionsSala = await this._getResourceSala(
          this.resourceSala._id,
          value
        );
        this.setState({ optionsSala });
      }
    });
  }

  /**
   * _onFilterField
   * @param {value} string name field
   */
  _onFilterDate = e => {

    // Guardar los filtros en el local storage
    let new_scheduling_filters = {};
    let scheduling_filters = localStorage.getItem("scheduling_filters");

    if (scheduling_filters != null) {
      new_scheduling_filters = JSON.parse(scheduling_filters);
    }

    new_scheduling_filters['date'] = e.target.value;
    localStorage.setItem('scheduling_filters', JSON.stringify(new_scheduling_filters));

    const date = e.target.value;
    const updateState = { date, page: 1, isLastPage: false };
    this.setState(updateState, async () => {
      await this._onLoadSchedule();
    });
  }

  /**
   * _onScroll
   * @param {input} object target input
   */
  _onScroll = async o => {
    if (
      o.target.offsetHeight + o.target.scrollTop >=
      o.target.scrollHeight - 30
    ) {
      const { appointments, isLoader, isLastPage, page } = this.state;
      if (!isLoader && !isLastPage) {
        this.setState({ isLoader: true });
        this._onLoadSchedule(appointments, page + 1);
      }
    }
  }

  _filterByState = selectedStates => {
    this._onFilterField('states', { value: selectedStates });
  }

  /**
   * _onLoadSchedule
   * @param {appointment} array appointments list
   * @param {page} int number page
   * @param {time} int number value timeOut
   */
  _onLoadSchedule = async (appointments = [], page = 1, time = 0) => {

    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);
    const { date, sede, sala, states, family, doctor, serviceId: service } = this.state;
    const order = "desc";
    const variables = { page, perPage: CANT_SCHEDULE_LIST, order };

    if (sede !== "") variables.sede = sede;
    if (sala !== "") variables.space = sala;
    if (family !== "") variables.family = family;
    if (service) variables.service = service;
    if (doctor !== "") variables.doctor = doctor;
    if (states !== "") variables.states = states.map(s => s.value);
    if (date !== "") variables.day = date;

    this.filterTimeOut = setTimeout(async () => {
      this.setState({ isLoader: true });
      const response = await this.props.client.query({
        query: GET_APPOINTMENTS,
        variables
      });
      const dataResponse = response.data.getAppointment.data;

      // valiadate last page
      if (dataResponse.length === 0) {
        this.props.numberResults(appointments.length)
        this.setState({
          page,
          isLoader: false,
          isLastPage: true,
          appointments
        });
        return;
      }

      // before last page
      appointments =
        page === 1 ? dataResponse : appointments.concat(dataResponse);

      this.setState({
        page,
        isLoader: false,
        appointments
      });

      this.props.numberResults(appointments.length)
    }, time);
  }

  // onchange select service (typehead)
  _onFilterService = async options => {
    let serviceId = '';
    let serviceName = '';

    if (!_.isEmpty(options)) {
      serviceId = options[0]._id;
      serviceName = options[0].name;
    }

    this._onFilterField('serviceId', { value:  serviceId});
    this._onFilterField('serviceName', { value:  serviceName});
  }

  // onkeyup input filter service (typehead)
  _onInputFilterService = async value => {
    const optionsService = await this._getServices(value);
    this.setState({
      optionsService,
      serviceName: "",
      serviceId: ""
    });
  }

  _getStateOptions = () => {
    return stateAppointment.map(sa => ({
      label: sa.title,
      value: sa.name
    }))
  }

  render() {
    const {
      isLoader,
      appointments,
      filtersCollapsed,

      sede,
      date,
      sala,
      states,
      doctor,
      family,

      serviceId,
      serviceName,

      optionsSede,
      optionsSala,
      optionsFamily,
      optionsDoctor,
      optionsService,
      openModalRooms
    } = this.state;

    return (
      <BodyContainer isLoader={isLoader} {...this.props}>
        {openModalRooms &&
          <Modal isOpenModal={openModalRooms} onClose={() => this.closeRoomSchedule()} title="Calendario de citas" >
            <RoomScheduleFilters></RoomScheduleFilters>

          </Modal>}
        <div
          className={`schedule-list contentListUser ${filtersCollapsed ? "filters-collapsed" : ""
            }`}
        >
          <div className="schedule-list-head mt-3">
            <div className="container-input-schedule-list">
              <SelectInput
                name="sede"
                label="Sede"
                value={sede}
                options={optionsSede}
                updateField={this._onFilterField}
              />
            </div>

            <div className="container-input-schedule-list">
              <FormInput
                type="date"
                name="date"
                label="Fecha"
                style={{ maxWidth: 200 }}
                value={date}
                onChange={this._onFilterDate}
              />
            </div>

            <div className="container-input-schedule-list">
              <SelectInput
                name="sala"
                label="Sala"
                value={sala}
                options={optionsSala}
                updateField={this._onFilterField}
              />
            </div>

            <div className="container-input-schedule-list">
              <SelectInput
                name="family"
                label="Familia"
                value={family}
                options={optionsFamily}
                updateField={this._onFilterField}
              />
            </div>

            <div className="container-input-schedule-list">
              <FormTypeHead
                name="filter-prestacion-history"
                label="Prestación"
                style={{ maxWidth: 200 }}
                options={optionsService}
                multiple={!1}
                onChange={this._onFilterService}
                onInputChange={this._onInputFilterService}
                selectedOptions={
                  serviceName !== ""
                    ? [{ name: serviceName, id_: serviceId }]
                    : []
                }
              />
            </div>

            <div className="container-input-schedule-list">
              <SelectInput
                name="doctor"
                label="Profesional"
                value={doctor}
                options={optionsDoctor}
                updateField={this._onFilterField}
              />
            </div>            

            <button className="button button-sm bg-icon rooms-schedule-button" onClick={() => this.openRoomSchedule()}>
              <span className="icon-schedule large ripple-gray icon-button" />
              Ocupación salas
            </button>            
          </div>

          <div className="schedule-list-head">
            <div className="mw-100">
              <FormMultipleSelect
                options={this._getStateOptions()}
                value={states}
                onChange={this._filterByState}
                placeholder="Estados"
              />
            </div>
          </div>
         
          <ScheduleTable
            user={this.props.user}
            onScroll={this._onScroll}
            appointments={appointments}
          />
        </div>
      </BodyContainer>
    );
  }
}

ScheduleList.propTypes = {
  client: PropTypes.object
};

export default withApollo(withRouter(ScheduleList));

/**
 * Service
 */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo, compose } from 'react-apollo';
import MultipleTextInput from '../../../components/general/multipleTextInput';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';

import ServiceForm from '../../../components/servicesModule/serviceForm';
import ServiceInformation from '../../../components/servicesModule/serviceInformation';
import ConfirmationModal from '../../../components/general/confirmationModal';
import CheckboxInput from '../../../components/general/checkboxInput';
import { specialScheduleOptions } from '../../../utils/resources/resourcesUtils';

import './style.css';

class Service extends Component {
  state = {
    confirmationModal: false
  };

  componentDidMount = async () => {
    if (!this.props.currentService && this.props.match.params.id) {
      this.props.getCurrentService(this.props.match.params.id);
    }
  };

  toggleConfirmationModal = () => {
    const confirmationModal = !this.state.confirmationModal;
    this.setState({ confirmationModal });
    if (!confirmationModal) localStorage.removeItem('clickSidebar');
    if (!localStorage.getItem('canExitEdit')) this.cancelRedirect();
  };

  cancelRedirect = () => {
    localStorage.removeItem('canExitEdit');
    localStorage.removeItem('clickSidebar');
    this.exitService();
  };

  exitService = () => {
    this.props.history.push(
      `/services/${this.props.match.params.id ? this.props.match.params.id : ''}`
    );
  };

  render() {
    if (
      localStorage.getItem('canExitEdit') &&
      localStorage.getItem('clickSidebar') &&
      !this.state.confirmationModal
    ) {
      this.toggleConfirmationModal();
    }

    const {
      nurse,
      error,
      success,
      timeOut,
      position,
      quantity,
      updateField,
      clearErrors,
      saveService,
      errorMessage,
      disableSubmit,
      presentdoctor,
      presentdoctorsSelected,
      updatePresentDoctorSelected,
      successMessage,
      currentService
    } = this.props;


    const { confirmationModal, presentDoctorValue = '', doctorsSelected = [] } = this.state;
    return (
      <div>
        {
          confirmationModal &&
          <ConfirmationModal
            onCancel={this.toggleConfirmationModal}
            onConfirm={this.cancelRedirect}
          />
        }
        <Accordion accordion={false}>
          <AccordionItem uuid="uniqueItem-presentation" expanded={true}>
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>Prestación</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody>
              <ServiceForm
                currentService={currentService ? currentService : {}}
                updateField={updateField}
                quantity={quantity}
                timeOut={timeOut}
                values={this.props}
                clear={clearErrors}
              />
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem
            uuid="uniqueItem-information-presentation"
            expanded={true}
          >
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>Información de la prestación</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody>
              <div className="flex f-w j-sb">
                <ServiceInformation
                  currentService={currentService ? currentService : {}}
                  updateField={updateField}
                  values={this.props}
                />
              </div>
            </AccordionItemBody>
          </AccordionItem>
          {
            currentService &&
            <AccordionItem
              uuid="uniqueItem-resouces-presentation"
              expanded={true}
            >
              <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
                <span>Recursos para la prestación</span>
                <div className="accordion__arrow" role="presentation" />
              </AccordionItemTitle>
              <AccordionItemBody>
                <div
                  className="text-center"
                  style={{ width: '80%', margin: 'auto' }}
                >
                  Recuerda crear todas las Prestaciones, asignarlas a los
                  recursos humanos y espacios de trabajo. Finalmente regresa
                  para elegir los recursos necesarios para esta prestación. Este
                  paso debes realizarlo después de actualizar los recursos
                  humanos y los espacios de trabajo adecuadamente.
                  </div>
                <div className="additionalFields flex f-w mt-2">
                  <div>
                    <div className="flex j-se a-i-c">
                      <label className="muted-text active">
                        Enfermera presente
                        </label>
                      {
                        specialScheduleOptions.map((option, index) => {
                          // let checked = option.label === 'Si'? false: true; // default value
                          // if(nurse !== null){
                          //   checked = nurse === false? option.value === nurse: option.value === true;
                          // }
                          return (
                            <div key={index} onFocus={clearErrors}>
                              <CheckboxInput
                                // checked={checked}
                                checked={nurse === null ? ''
                                  : nurse === false
                                    ? option.value === nurse
                                    : option.value === true}
                                type="radio"
                                updateField={updateField}
                                value={option.value}
                                label={option.label}
                                name="nurse"
                                id={`nurse-${index}`}
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className="doctor-present">

                    <MultipleTextInput
                      type="text"
                      name="name"
                      label="medico presente"
                      placeholder="Recurso humano presente"
                      maxValues={10}
                      value={presentDoctorValue}
                      options={position}
                      updateField={() => { }}
                      labelActive={true}
                      selectOption={(option) => { updatePresentDoctorSelected(option) }}
                      updateSelected={(value) => this.setState({ presentDoctorValue: value })}
                      selectedOptions={presentdoctorsSelected}
                    />

                  </div>
                </div>
              </AccordionItemBody>
            </AccordionItem>
          }
          <div className="user-form-actions flex f-c a-i-c j-c mb-1 mt-1">
            {error && <p className="text-center error-msg">{errorMessage}</p>}
            {
              success &&
              <p className="text-center success-msg">
                <span className="icon-check mr-02" />
                {successMessage}
              </p>
            }
            <div className="text-center flex j-c">
              <button
                type="reset"
                className="button save-user button-sm bg-muted"
                onClick={success ? this.cancelRedirect : this.toggleConfirmationModal}
              >
                Cancelar
              </button>
              <button
                disabled={disableSubmit}
                className={`button ripple save-user button-sm ${disableSubmit ? 'bg-muted' : 'bg-icon'}`}
                type="submit"
                onClick={saveService}
              >
                Guardar
              </button>
            </div>
          </div>
        </Accordion>
      </div>
    );
  }
}
Service.propTypes = {
  nurse: PropTypes.bool,
  error: PropTypes.bool,
  match: PropTypes.object,
  timeOut: PropTypes.number,
  success: PropTypes.bool,
  history: PropTypes.object,
  quantity: PropTypes.number,
  position: PropTypes.array,
  clearErrors: PropTypes.func,
  updateField: PropTypes.func,
  saveService: PropTypes.func,
  errorMessage: PropTypes.string,
  disableSubmit: PropTypes.bool,
  presentdoctor: PropTypes.string,
  successMessage: PropTypes.string,
  currentService: PropTypes.object,
  getCurrentService: PropTypes.func
};
export default compose()(withApollo(withRouter(Service)));

/**
 * ServiceForm PhysicianModules
 */
 /* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectInput from '../../../components/general/selectInput';
import TextInput from '../../../components/general/textInput';

// import { minutesList, hoursList } from '../../../utils/services/servicesUtils';

import './style.css';

class ServiceForm extends Component {
  state = {
    focusedName: false,
    focusedLastName: false,
    focusedMobile: false,
    focusedMedicalrecordSicor: false
  };

  toggleFocus = (value) => {
    if (String(value)) {
      const _focus = `focused${value}`;
      console.log({ value });
      this.setState({ [_focus]: !this.state[_focus] });
    }
  };

  render() {
    let { values: { specialty, institution, medicalrecord, office, name, lastname, email, phone, address, status, identificacion, mobile } } = this.props;
    const {
      clear,
      values: { currentServices, serviceErrors },
      updateField,
      currentService
    } = this.props;

    if (!name && currentService.name) name = currentService.name;
    if (!address && currentService.address) address = currentService.address;
    if (!email && currentService.email) email = currentService.email;
    if (!phone && currentService.phone) phone = currentService.phone;
    if (!mobile && currentService.mobile) mobile = currentService.mobile;
    if (!lastname && currentService.lastname) lastname = currentService.lastname;
    // if (!active && currentService.active) active = currentService.active;
    if (!status && currentService.status) status = currentService.status;
    if (!identificacion && currentService.identificacion) identificacion = currentService.identificacion;
    if (!specialty && currentService.specialty) specialty = currentService.specialty;
    if (!institution && currentService.institution) institution = currentService.institution;
    if (!office && currentService.office) office = currentService.office;
    if (!medicalrecord && currentService.medicalrecord) medicalrecord = currentService.medicalrecord;

    const { focusedName, focusedMedicalrecordSicor, focusedLastName } = this.state;
    return (
      <div className="container-resources-form flex j-c">
        <div className="">
          <div
            className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Name');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Nombres"
              updateField={updateField}
              value={name}
              name="name"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.name}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
          <div
            className={`p-relative ${serviceErrors['lastname'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('LastName');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Apellidos"
              updateField={updateField}
              value={lastname}
              name="lastname"
            />
            {
              currentServices.length > 0 && focusedLastName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.lastname}</div>;
                    })
                  }
                </div>
              )
            }
          </div>

          <div
            className={`p-relative ${serviceErrors['email'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Email');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="E-mail"
              updateField={updateField}
              value={email}
              name="email"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.email}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
          <div
            className={`p-relative ${serviceErrors['mobile'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Mobile');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Movil"
              updateField={updateField}
              value={mobile}
              name="mobile"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.mobile}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
        </div>
        <div className="">
          <div
            className={`p-relative ${serviceErrors['phone'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Phone');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Télefono"
              updateField={updateField}
              value={phone}
              name="phone"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.phone}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
          <div
            className={`p-relative ${serviceErrors['address'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Address');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Dirección de trabajo"
              updateField={updateField}
              value={address}
              name="address"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.address}</div>;
                    })
                  }
                </div>
              )
            }
          </div>

          <div
            className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Status');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <SelectInput
              type="text"
              label="Estado"
              updateField={updateField}
              value={status}
              name="status"
              options={[
                { _id: 'suggested', name: 'Sugerido' },
                { _id: 'confirmado', name: 'confirmado' },
                { _id: 'disabled', name: 'Disabled' }
              ]}
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.status}</div>;
                    })
                  }
                </div>
              )
            }
          </div>

          <div
            className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Identificacion');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Identificación"
              updateField={updateField}
              value={identificacion}
              name="identificacion"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.identificacion}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
        </div>
        <div className="">
          <div
            className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Specialty');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <SelectInput
              type="text"
              label="Especialidad"
              updateField={updateField}
              value={specialty}
              options={[
                { _id: '', name: '' },
                { _id: 'Médica Cardióloga', name: 'Médica Cardióloga' },
                { _id: 'Médica Endocrinóloga', name: 'Médica Endocrinóloga' },
                { _id: 'Médica Especialista en Medicina del Deporte', name: 'Médica Especialista en Medicina del Deporte' },
                { _id: 'Médica General', name: 'Médica General' },
                { _id: 'Médica Ginecóloga y Obstetra', name: 'Médica Ginecóloga y Obstetra' },
                { _id: 'Médica Internista', name: 'Médica Internista' },
                { _id: 'Medica internista nefrologa ', name: 'Medica internista nefrologa ' },
                { _id: 'Médica Neuróloga', name: 'Médica Neuróloga' },
                { _id: 'Médico Cardiólogo', name: 'Médico Cardiólogo' },
                { _id: 'Médico Cardiólogo y Ecocardiografista', name: 'Médico Cardiólogo y Ecocardiografista' },
                { _id: 'Médico Cardiólogo y Electrofisiólogo', name: 'Médico Cardiólogo y Electrofisiólogo' },
                { _id: 'Médico Deportólogo', name: 'Médico Deportólogo' },
                { _id: 'Médico Endocrinólogo', name: 'Médico Endocrinólogo' },
                { _id: 'Médico General', name: 'Médico General' },
                { _id: 'Médico Internista', name: 'Médico Internista' },
                { _id: 'Médico Neurólogo', name: 'Médico Neurólogo' },
                { _id: 'Médico Neurólogo Vascular', name: 'Médico Neurólogo Vascular' }
              ]}
              name="specialty"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.specialty}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
          <div
            className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Specialty');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Oficina"
              updateField={updateField}
              value={office}
              name="office"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.office}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
          <div
            className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Specialty');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Registro Médico"
              updateField={updateField}
              value={medicalrecord}
              name="medicalrecord"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.medicalrecord}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
          <div
            className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
            onFocus={() => {
              this.toggleFocus('Specialty');
              clear();
            }}
            onBlur={this.toggleFocus}
          >
            <TextInput
              type="text"
              label="Institucion"
              updateField={updateField}
              value={institution}
              name="institution"
            />
            {
              currentServices.length > 0 && focusedName && (
                <div className="existing-services">
                  {
                    currentServices.map((service, index) => {
                      return <div key={index}>{service.institution}</div>;
                    })
                  }
                </div>
              )
            }
          </div>
        </div>

      </div>
    );
  }
}
ServiceForm.propTypes = {
  clear: PropTypes.func,
  values: PropTypes.object,
  updateField: PropTypes.func,
  currentService: PropTypes.object
};
export default ServiceForm;

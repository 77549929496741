/**
 * FormNotify
 */
import React from 'react';
import PropTypes from 'prop-types';

// my components
import FormModal from '../formModal';

import './style.css';

class FormNotify extends React.Component{
  state = {
    isOpenModal: true
  }
  _closeWindow = () => {
    const { onCloseNotify } = this.props;
    if (onCloseNotify){
      onCloseNotify();
      return;
    }
    this.setState({ isOpenModal: false });
  }
  render (){
    const {
      title,
      message,
      buttons,
      children,
      styleBody,
      alingFooter,
      styleContainer,
      styleModalCenter
    } = this.props;
    const {
      isOpenModal
    } = this.state;
    return (
      isOpenModal &&
        <FormModal isOpenModal={isOpenModal} styleModalCenter={styleModalCenter}>
          <div className="form-notify" style={styleContainer}>
            {
              title &&
                <div className="row-title ripple-title">
                  <div className="title">{title}</div>
                  <div className="icon-close" onClick={this._closeWindow}>X</div>
                </div>
            }
            { message && <div className="message">{message}</div> }
            { children && <div className="body" style={styleBody}>{children}</div> }
            {
              (buttons.length > 0) &&
                <div className={`footer ${alingFooter}`}>
                  {
                    buttons.map((button, index)=>{
                      const text = button.text? button.text: '';
                      const onClick = button.onClick? button.onClick: ()=>{};
                      const btnClass = button.btnClass? ` ${button.btnClass} ripple-${button.btnClass}`: ' btn-success ripple-btn-success';
                      const parentClass = button.parentClass? ' ' + button.parentClass: '';

                      return (
                        <div key={index} className={`container-btn-success${parentClass}`}>
                          <button type="buttom" className={`button button-sm${btnClass}`} onClick={()=>setTimeout(()=>onClick(), 300)} >
                            {text}
                          </button>
                        </div>
                      );
                    })
                  }
                </div>
            }
          </div>
        </FormModal>
    );
  }
}
FormNotify.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  message: PropTypes.string,
  children: PropTypes.element,
  styleBody: PropTypes.object,
  alingFooter: PropTypes.string,
  onCloseNotify: PropTypes.func,
  styleContainer: PropTypes.object,
  styleModalCenter: PropTypes.object
};
FormNotify.defaultProps = {
  buttons: [],
  styleBody: {},
  alingFooter: 'center',
  styleContainer: {},
  styleModalCenter: {}
};
export default FormNotify;

export const AUTH_TOKEN = 'token';
export const AUTH_REFRESH_TOKEN = 'refreshToken';

export const TILTEST_PHYSICIAN = {
  'realiza': [
    { value: '5b749dda1937443c69aa716f', label: 'Ana Maria Posada Cano' },
    { value: '5b749b091937443c69aa716c', label: 'Juan José Osorio Muñoz' },
    { value: '66c62b2b7f11f229a8f07c1f', label: 'Duván Alexis Gómez Castro' },
  ] ,
  'lee': [
    { value: '5b75dfd51937443c69aa71d0', label: 'Dagnóvar Aristizábal Ocampo' },
    { value: '66185c40376326632d0d8090', label: 'Fair clavijo' }
  ]
};

export const ESFUERZO_PHYSICIAN = {
  'realiza': [
    { value: '5b749dda1937443c69aa716f', label: 'Ana Maria Posada Cano' },
    { value: '5b749b091937443c69aa716c', label: 'Juan José Osorio Muñoz' },
    { value: '6297b31c0c310f4a983ef55c', label: 'Luis Alfonso Fajardo Andrade' },
    { value: '5b74a9f31937443c69aa7183', label: 'Carlos José Jaramillo Gómez' },
    { value: '66185c40376326632d0d8090', label: 'Fair Clavijo' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Fernández' },
    { value: '66c62b2b7f11f229a8f07c1f', label: 'Duván Alexis Gómez Castro' },
    { value: '66c62f437f11f229a8f09b1a', label: 'Juan Carlos Quiceno Noguera' }
  ] ,
  'lee': [
    { value: '5b7496fd1937443c69aa7168', label: 'Camilo Madrid Muñoz' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Fernández Ruiz' },
    { value: '5cb787b7959b671101072388', label: 'Jaime López Torres' },
    { value: '5b74a9f31937443c69aa7183', label: 'Carlos José Jaramillo Gómez' },
    { value: '5b74a8df1937443c69aa717f', label: 'Luz Adriana Ocampo Aristizábal' },
    { value: '5b7498821937443c69aa716a', label: 'Jaime Alexander Londoño Cadavid' },
    { value: '6297b31c0c310f4a983ef55c', label: 'Luis Alfonso Fajardo Andrade' },
    { value: '5fb444ec4346b0343c39c75f', label: 'Leidy Carolina Pemberthy Lopez' },
    { value: '66185c40376326632d0d8090', label: 'Fair Clavijo' }
  ]
};

export const ECO_PHYSICIAN = {
  'realiza': [
    { value: '5b7496fd1937443c69aa7168', label: 'Camilo Madrid Muñoz' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Fernández Ruiz' },
    { value: '5cb787b7959b671101072388', label: 'Jaime López Torres' },
    { value: '5b7498821937443c69aa716a', label: 'Jaime Alexander Londoño Cadavid' },
    { value: '5b74a8df1937443c69aa717f', label: 'Luz Adriana Ocampo Aristizábal' },
    { value: '5b75b4751937443c69aa71a2', label: 'Ángela María Valencia Carmona' },
    { value: '6297b4f20c310f4a983ef573', label: 'Sandra Janet Ochoa Holguín' },
    { value: '6255d0d1bf91bb77ffcd340e', label: 'Angela María Arango Gómez' },
    { value: '6633e0ede21eae98d60d8dbf', label: 'Isabel Cristina Cardona Berrio' }
  ] ,
  'lee': [
    { value: '5b7496fd1937443c69aa7168', label: 'Camilo Madrid Muñoz' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Fernández Ruiz' },
    { value: '5cb787b7959b671101072388', label: 'Jaime López Torres' },
    { value: '5b74a8df1937443c69aa717f', label: 'Luz Adriana Ocampo Aristizábal' },
    { value: '5b7498821937443c69aa716a', label: 'Jaime Alexander Londoño Cadavid' }
  ]
};

export const EKG_PHYSICIAN = {
  'realiza': [
    { value: '5b7496fd1937443c69aa7168', label: 'Camilo Madrid Muñoz' },
    { value: '5b758c711937443c69aa7191', label: 'Juan Carlos Uribe Osorio' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Leon Fernandez Ruiz' },
    { value: '5b75b05a1937443c69aa7195', label: 'Carlos Restrepo Jaramillo' },
    { value: '66185c40376326632d0d8090', label: 'Fair clavijo' }
  ],
  'lee': [
    { value: '5b7496fd1937443c69aa7168', label: 'Camilo Madrid Muñoz' },
    { value: '5b758c711937443c69aa7191', label: 'Juan Carlos Uribe Osorio' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Leon Fernandez Ruiz' },
    { value: '5b75b05a1937443c69aa7195', label: 'Carlos Restrepo Jaramillo' },
    { value: '66185c40376326632d0d8090', label: 'Fair clavijo' }
  ]
}

export const STRESS_PHYSICIAN = {
  'realiza': [
    { value: '5b7496fd1937443c69aa7168', label: 'Camilo Madrid Muñoz' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Leon Fernandez Ruiz' },
    { value: '5b74a8df1937443c69aa717f', label: 'Luz Adriana Ocampo Aristizábal' }
  ],
  'lee': [
    { value: '5b7496fd1937443c69aa7168', label: 'Camilo Madrid Muñoz' },
    { value: '5b74ad621937443c69aa7187', label: 'Ricardo Leon Fernandez Ruiz' },
    { value: '5b74a8df1937443c69aa717f', label: 'Luz Adriana Ocampo Aristizábal' }
  ]
}

export const BASE_PARENTS_STATES = [{
    _id : "61a8eca32d06c16b2b6e17b7",
    name : "Eco estrés",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "5b6dcfac07cf0170ad65e49a",
    name : "Prueba de esfuerzo",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "5b7325b907cf0170ad65e4c4",
    name : "EKG",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "5b732aa207cf0170ad65e4cc",
    name : "Tilt Test",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "61a8eca32d06c16b2b6e17bc",
    name : "Mapa",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "61a8eca32d06c16b2b6e17bd",
    name : "Holter",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "61a8eca32d06c16b2b6e17be",
    name : "NA",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "6142625a5df46f5b6bfeb0ea",
    name : "Eco doppler",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "6142632c5df46f5b6bfeb0ee",
    name : "Eco Doppler EPS",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  },
  {
    _id : "61a8eca32d06c16b2b6e17b9",
    name : "Dúplex",
    description : "mapa exam",
    date_created : "2021-12-02T14:46:19.913Z",
    active : true
  }];

export const ROUTES_TO_HIDE_TEMPLATE = ['ampa/report'];

export const GENDERS = {
  M: 'Masculino',
  F: 'Femenino',
  O: 'Otro'
}

export const PHYSICIAN_STATUS = [
  {
    label: 'Confirmado',
    value: 'confirmed'
  },
  {
    label: 'Propuesto',
    value: 'proposed'
  }
]
export const CANCELATION_APPOINTMENT_OPTIONS = {
  Sicor: [
    { value: 'Cita Sicor', label: 'Cita Sicor' },
    { value: 'Falta de equipo', label: 'Falta de equipo' },
    { value: 'Daño equipo', label: 'Daño equipo' },
    { value: 'Ausencia especialista', label: 'Ausencia especialista' },
    { value: 'Error de agendamiento', label: 'Error de agendamiento' },
    { value: 'Se adelantó', label: 'Se adelantó' },
  ],
  Paciente: [
    { value: 'Horario', label: 'Horario' },
    { value: 'No cumple indicaciones', label: 'No cumple indicaciones' },
    { value: 'Paciente no asiste a la cita', label: 'Paciente no asiste a la cita' },
    { value: 'Pico y placa', label: 'Pico y placa' },
    { value: 'Viaje', label: 'Viaje' },
    { value: 'Ya se realizó el examen', label: 'Ya se realizó el examen' },
    { value: 'No puede devolver el equipo al día siguiente', label: 'No puede devolver el equipo al día siguiente' },
    { value: 'Motivos personales', label: 'Motivos personales' }
  ]
};

export const ANULATION_APPOINTMENT_OPTIONS = {
  Sicor: [
    { value: 'Se agendó el examen equivocado', label: 'Se agendó el examen equivocado' },
    { value: 'Falta de equipo', label: 'Falta de equipo' },
    { value: 'Daño equipo', label: 'Daño equipo' },
    { value: 'Ausencia especialista', label: 'Ausencia especialista' }
  ],
  Paciente: [
    { value: 'Se agendó el examen equivocado', label: 'Se agendó el examen equivocado' },
    { value: 'No se realiza por condiciones médicas', label: 'No se realiza por condiciones médicas' },
    { value: 'La entidad no lo cubre', label: 'La entidad no lo cubre' },
    { value: 'Falta de acompañante', label: 'Falta de acompañante' },
    { value: 'Falta de ayuno', label: 'Falta de ayuno' },
    { value: 'Paciente llega tarde', label: 'Paciente llega tarde' },
    { value: 'Al explicar consentimiento informado decide no realizarlo', label: 'Al explicar consentimiento informado decide no realizarlo' },
    { value: 'No puede devolver el equipo al día siguiente', label: 'No puede devolver el equipo al día siguiente' },
  ]
};

export const appointmentStates = {
	scheduled		  : 'agendada',
	canceled		  : 'cancelada',
	confirmed		  : 'confirmada',
	rescheduled		: 'reprogramada',
	reassigned		: 'reasignada',
	reception		  : 'recepcion',
	attention		  : 'atencion',
	delivered		  : 'entregado',
  room          : 'sala',
  office        : 'consultorio',
  present       : 'presente',
  repeat        : 'repetir',
  nullified     : 'anulada'
};

import React, { useContext } from 'react';
import { context } from "../../../providers/patientProvider";
import FormInput from '../../general/formInput';
import { SelectGeneral } from '../../general/selectGeneral';
import Accordion from './Accordion';
import AsyncInput from './AsyncInput';
import { BasicDataForm } from './BasicDataForm';

const PatientForm = ({
    handleSetInput,
    handleSetSelect,
    identificationTypeSelect,
    userTypeSelect,
    genderSelect,
    territorialZone,
    incapacitySelect,
    etniaSelect,
    entitySelect,
    entityTypeSelect,
    stateSelect,
    maritalStatusSelect,
    educationLevelSelect,
    cities,
    citiesOrigin,
    loadOptionsCountry,
    loadOptionsCities,
    loadOptionsCitiesOrigin,
    loadOptionsProfessions,
    handleGetRelatedEntityType,
    allowFillOriginCity
}) => {

    const { 
        billingData, 
    } = useContext(context);

    const handleSetEntity = (name, data) => {
        handleSetSelect(name, data);
        handleGetRelatedEntityType(data.value);
    }

    return (
        <>
            <Accordion items={[
                {
                    title: 'Datos básicos',
                    subtitle: 'Nombre, número de documento, tipo de usuario, fecha de nacimiento, sexo, email...',
                    content:
                        <BasicDataForm
                            handleSetInput={handleSetInput}
                            handleSetSelect={handleSetSelect}
                            identificationTypeSelect={identificationTypeSelect}
                            genderSelect={genderSelect}
                            maritalStatusSelect={maritalStatusSelect}
                            educationLevelSelect={educationLevelSelect}
                        />
                }
            ]} />
            <div className='row' style={{ rowGap: '1rem' }}>
                <div className="col-md-12 mt-2">
                    <SelectGeneral
                        label='Tipo usuario'
                        name='tipoUsuario'
                        options={userTypeSelect}
                        updateField={handleSetSelect}
                        value={billingData?.tipoUsuario}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <SelectGeneral
                        label='Departamento Residencia'
                        name='codDepartamentoResidencia'
                        options={stateSelect}
                        updateField={handleSetSelect}
                        value={billingData?.codDepartamentoResidencia}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <AsyncInput
                        functionToCall={(inputText) => loadOptionsCities(inputText)}
                        defaultOptions={cities}
                        placeholder='Municipio residencia'
                        ms={0}
                        disabled={!billingData.codDepartamentoResidencia}
                        name="codMunicipioResidencia"
                        value={billingData?.codMunicipioResidencia}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <SelectGeneral
                        label='Zona Territorial Residencia'
                        name='codZonaTerritorialResidencia'
                        options={territorialZone}
                        updateField={handleSetSelect}
                        value={billingData?.codZonaTerritorialResidencia}
                    />
                </div>
                
                <div className="col-md-6 mt-2">
                    <SelectGeneral
                        label='Incapacidad'
                        name='incapacidad'
                        options={incapacitySelect}
                        updateField={handleSetSelect}
                        value={billingData?.incapacidad}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <AsyncInput
                        functionToCall={(inputText) => loadOptionsCountry(inputText)}
                        placeholder='País Origen'
                        name="codPaisOrigen"
                        value={billingData?.codPaisOrigen}
                    />
                </div>

                {allowFillOriginCity && (
                    <>
                        <div className="col-md-6 mt-2">
                            <SelectGeneral
                                label='Departamento Origen'
                                name='codDepartamentoOrigen'
                                options={stateSelect}
                                updateField={handleSetSelect}
                                value={billingData?.codDepartamentoOrigen}
                            />
                        </div>

                        <div className="col-md-6 mt-2">
                            <AsyncInput
                                functionToCall={(inputText) => loadOptionsCitiesOrigin(inputText)}
                                defaultOptions={citiesOrigin}
                                placeholder='Municipio Origen'
                                ms={0}
                                disabled={!billingData.codDepartamentoOrigen}
                                name="codMunicipioOrigen"
                                value={billingData?.codMunicipioOrigen}
                            />
                        </div>
                    </>
                )}

                <div className="col-md-6 mt-2 custom-input">
                    <FormInput
                        label='Dirección'
                        name='Direccion'
                        onChange={handleSetInput}
                        value={billingData?.Direccion}
                    />
                </div>                

                <div className="col-md-6 mt-2 custom-input">
                    <SelectGeneral
                        label='Entidad Facturación'
                        name='EntidadFacturacion'
                        options={entitySelect}
                        updateField={(name, data) => handleSetEntity(name, data)}
                        value={billingData?.EntidadFacturacion}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <SelectGeneral
                        label='Tipo Entidad'
                        name='TipoEntidad'
                        options={entityTypeSelect}
                        updateField={handleSetSelect}
                        value={billingData?.TipoEntidad}
                    />
                </div>     

                <div className="col-md-6 mt-2">
                    <AsyncInput
                        functionToCall={(inputText) => loadOptionsProfessions(inputText)}
                        placeholder='Profesion'
                        name="Profesion"
                        value={billingData?.Profesion}
                    />
                </div>           
            </div>
        </>
    )
}

export default PatientForm;

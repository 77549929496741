/**
 * @flow
 * FormInputSelect
 */
import React, { Component } from 'react';
import './style.css';

type PropTypes = {
  name: string,
  style: Object,
  label: string,
  value: string | number,
  options: Array<Object>,
  onChange: ()=>void,
  mutedText: string,
  iconAfter: string,
  iconBefore: string,
  classLabel: string,
  labelActive: boolean,
  defaultText: string,
  defaultBlank: boolean,
  optionFieldId: string,
  optionFieldText: string
};

class FormInputSelect extends Component <PropTypes> {
  constructor (props) {
    super(props);
    this.state = {
      focus: false,
      active: (props.labelActive)? true: false
    };
  }
  _toggleActive = () => {
    this.setState({ active: true, focus: true });
  };
  _isEmpty = e => {
    const { labelActive } = this.props;
    if (!labelActive && e.target.value === ''){
      this.setState({ active: false, focus: false });
    }
    else {
      this.setState({ focus: false });
    }
  };
  _rowOptions = (options, optionFieldId, optionFieldText) => {
    if (!options[0]) return;
    if (typeof options[0] === 'string' || typeof options[0] === 'number'){
      return options.map((item, index) => <option key={item} value={index}>{item}</option>);
    }

    return options.map((item, index) => <option key={item[optionFieldId]} value={index}>{item[optionFieldText]}</option>);
  }
  render () {
    const {
      focus,
      active
    } = this.state;
    const {
      name,
      style,
      label,
      value,
      options,
      onChange,
      disabled,
      iconAfter,
      mutedText,
      iconBefore,
      classLabel,
      defaultText,
      defaultBlank,
      optionFieldId,
      optionFieldText
    } = this.props;

    const labelClass1 = iconBefore? 'with-icon': '';
    const labelClass2 = active && focus? 'label-active': '';
    const labelClass3 = value && value !== ''? 'label-input-full': 'empty';
    return (
      <div className="form-input-select" style={style}>
        {iconBefore !== '' ? <span className={'icon-' + iconBefore} /> : null}
        <label htmlFor={name} className={`${labelClass1} ${labelClass2} ${labelClass3} ${classLabel}`}>
          {label}
          { mutedText && <span className="muted-text">{mutedText}</span> }
        </label>
        <select
          id={name}
          ref={input => this.nameInput = input }
          name={name}
          value={value}
          onBlur={this._isEmpty}
          onFocus={this._toggleActive}
          disabled={disabled}
          onChange={e => {
            var index = e.target.value;
            onChange({
              e,
              name,
              item: options[index],
              index
            });
          }}
          className={`form-input-box ${active || value !== '' ? 'filled' : ''}`}
        >
          { defaultBlank && <option value="">{defaultText}</option> }
          { this._rowOptions(options, optionFieldId, optionFieldText) }
        </select>
        { iconAfter !== '' && <span className={'after-icon-select icon-' + iconAfter} /> }
      </div>
    );
  }
}
FormInputSelect.defaultProps = {
  style: {},
  value: '',
  options: [],
  onChange: ()=>{},
  iconAfter: '',
  iconBefore: '',
  classLabel: '',
  defaultText: '',
  defaultBlank: true,
  optionFieldId: '_id',
  optionFieldText: 'name'
};
export default FormInputSelect;

import React from 'react';

const FormWSwitch = ({ label, value, name, onChange }) => {
    return (
        <>
            <div class="form-check form-switch">
                <label class="form-check-label" htmlFor="approveMap">
                    { label }
                </label>
                <input
                    class="form-check-input"
                    id="approveMap"
                    type="checkbox"
                    checked={value}
                    onChange={() => onChange(name, value)}
                />
            </div>
        </>
    )
}

export default FormWSwitch;
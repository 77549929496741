import moment from 'moment';
import { signatureDrDagnovar } from "./icons";
import { stylesBody, informationGeneral, tableMedicines, tablePromedy } from "./index";
import { convertTagBase64 } from "../../../utils/helpers";

export const templateBodyPdf = async({ informationGeneralPatient }) => {
  const { medicines, medico, doctorNotes, enfermera, nursingNotes, takes } = informationGeneralPatient;
  const testGraph = convertTagBase64()
  return `
  <!DOCTYPE html>
  <html>
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet">
      <title>PDF Sicor</title>
      ${stylesBody()}
    </head>
    <body>
      <section>

        ${informationGeneral({informationGeneralPatient})}
        ${tableMedicines({medicines})}

        <div>
          <h3>Frecuencias cardíacas</h3>
          <div class="box-grafica">
            <img src="data:image/svg+xml;base64,${testGraph}" width="100%" />
          </div>
        </div>

        ${tablePromedy({takes})}

        <div class="box-har nueva-pagina content-notes" style= "width:96.5%;">
          <b>Notas</b>
          <div>
            <h5 style="font-size:12px">Medic@: <span>${medico?.name} ${medico?.lastname}</span></h4>
            <p style="margin: 0; padding:0;">Notas Medic@:</p>
            ${doctorNotes ?? ""}
          </div>
          <div>
            <h5 style="font-size:12px">Enfermer@: <span>${enfermera?.name} ${enfermera?.lastname}</span></h4>
            <p style="margin: 0; padding:0;">Notas enfermer@:</p>
            ${nursingNotes ?? ""}
          </div>
        </div>

        <div class="detail-medic">
          <p>Revisión: Dagnóvar Aristizábal Ocampo (Cardiólogo)</p>
          <div class="content-firm-physician">
            <img src=${signatureDrDagnovar} alt="firma" width="100%"/>
          </div>
          <p>Firmado digitalmente, Fecha: ${moment(new Date()).format("DD/MM/YYYY")} </p>
          <p>Registro: RM 7260-87</p>
        </div>

      </section>
</body>
</html>


  `
};
import React, { Component } from 'react';
import { PatientConsumer } from '../../../providers/patientProvider';
import PatientInfo from './index';

class PatientProfile extends Component {
  render () {
    return (
      <PatientConsumer>{props => <PatientInfo {...props} user={this.props.user}  />}</PatientConsumer>
    );
  }
}

export default PatientProfile;

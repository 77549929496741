import * as JWT from 'jwt-decode';
import React from 'react';
import Holidays from '../utils/colombia-holidays/index';
import moment from 'moment';
const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];
const DaysName = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado'
];

export const alphaNumericSort = (a, b) => {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;

  const aA = a.name.replace(reA, "");
  const bA = b.name.replace(reA, "");
  if (aA === bA) {
      const aN = parseInt(a.name.replace(reN, ""), 10);
      const bN = parseInt(b.name.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
      return aA > bA ? 1 : -1;
  }
}

export const getUrlHost = () => {
  const host = document.location.hostname;

  let url = `https://${host}`;
  // if (host === 'localhost') {
  //   url = `http://${host}:3000`;
  // }

  return url;
}


export const roleHavePermission = (user, resource) => {
  if (!JSON.parse(user._role.permission).patients.exclude) return true
  return !(JSON.parse(user._role.permission).patients.exclude.indexOf(resource) !== -1)
}

export const getUserFromToken = () => {
  let userInfo;
  let userId;
  if (localStorage.getItem('token')) {
    userInfo = JWT(localStorage.getItem('token'));
    userId = userInfo.user;
    return { userId, userInfo };
  }
};

export const passwordPopoverProps = (popoverIsOpen, requirements, close) => {
  return {
    isOpen: popoverIsOpen,
    preferPlace: 'above',
    place: 'above',
    onOuterAction: () => close(),
    body: [
      <div className="popover-container" key={1}>
        <p className="helper-msg">
          {' '}
          Tu contraseña debe cumplir con los siguientes requerimientos:{' '}
        </p>

        {Object.keys(requirements).map(req => {
          return (
            <div className="form-inline" key={requirements[req].id}>
              <i
                className={`material-icons + ${
                  requirements[req].value ? 'success-msg' : 'error-msg'
                  }`}
              >
                {requirements[req].value ? 'check' : 'close'}
              </i>
              <span>{requirements[req].message} </span>
            </div>
          );
        })}
      </div>
    ]
  };
};
export const goLogin = history => {
  if (!localStorage.getItem('token')) {
    history.replace('/');
    return false;
  }
};
export const inputPastMax = () => {
  var dtToday = new Date();

  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate();
  var year = dtToday.getFullYear();
  if (month < 10) month = '0' + month.toString();
  if (day < 10) day = '0' + day.toString();

  var maxDate = year + '-' + month + '-' + day;
  return maxDate;
};
export const formatDateAppointment = date => {
  date = new Date(parseInt(date));

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const year = date.getFullYear();
  return `${DaysName[date.getDay()]} ${day} de ${month}  de ${year} / ${hour}:${minutes}`;
};
export const formatDate = date => {
  date = new Date(date);

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${month} ${day} de ${year} / ${hour}:${minutes} ${year}`;
};
export const formatBirthDay = date => {
  const nowYear = new Date().getFullYear();
  if (date.length > 10) {
    date = new Date(date);
    // const month = monthNames[date.getMonth()];
    const m = date.getMonth();
    const month = m < 10 ? `0${m}` : m;
    const d = date.getDate();
    const day = d < 10 ? `0${d}` : d;
    const year = date.getFullYear();
    return `${month}-${day}-${year} (${nowYear - year} años)`;
  } else {
    const d = date.split('-');
    return `${d[1]}-${d[2]}-${d[0]} (${nowYear - Number(d[0])} años)`;
  }
};
export const firstNameFirstLastName = (name, lastName) => {
  name =
    name
      .split(' ')[0]
      .charAt(0)
      .toUpperCase() + name.split(' ')[0].slice(1);
  lastName =
    lastName
      .split(' ')[0]
      .charAt(0)
      .toUpperCase() + lastName.split(' ')[0].slice(1);

  const initialLetter = ((name.split('')[0]).charAt(0).toLowerCase()) + ((lastName.split('')[0]).charAt(0).toLowerCase());
  return { name, lastName, initialLetter };
};
export const isHoliday = date => {
  let year = date.format('YYYY');
  let holidays = Holidays.getColombiaHolidaysByYear(Number(year));
  let holiday = holidays.find(day => {
    return day.holiday === date.format('YYYY-MM-DD');
  });
  return holiday ? holiday.celebration : false;
};

export const toTwelveHourFormat = completeHour => {
  let formatedHour;
  const hourArray = completeHour.split(':');
  let hour = parseInt(hourArray[0], 10);
  const minutes = hourArray[1];
  if (hour > 12) {
    formatedHour = `${(hour - 12).toString()}:${minutes} PM`;
  } else if (hour < 12) {
    formatedHour = `${hour.toString()}:${minutes} AM`;
  } else {
    formatedHour = `${hour.toString()}:${minutes} PM`;
  }
  return formatedHour;
};

// Función que devuelve los días festivos contenidos en un rango de tiempo del año actual, que coincidan con ciertos días de la semana
// pasando el día inicial y final del rango y los días de la semana que se van a evaluar como argumentos.
export const areThereHolidays = (initialDay, finalDay, days) => {

  let endYear = finalDay.year();

  let holidays = Holidays.getColombiaHolidaysByYear(endYear)
    .map(holiday => {
      return moment(holiday.holiday);
    })
    .filter(holiday => {
      return isTheDayContained(holiday, initialDay, finalDay);
    });
  // .filter(holiday => {
  //   return days[holiday.day()];
  // });
  return holidays;
};

// Función que verifica si un día está contenido en un rango de tiempo, pasando el día inicial y final del rango como argumentos.
const isTheDayContained = (day, initial, final) => {
  return day
    .clone()
    .startOf('day')
    .diff(initial.clone().startOf('day')) >= 0 &&
    day
      .clone()
      .startOf('day')
      .diff(final.clone().startOf('day')) <= 0
    ? true
    : false;
};

// Función que recibe los atributos de una serie y devuelve la esta serie separada omitiendo los festivos contentidos en ella.
export const skipHolidays = (initial, final, interval, repeatedDays) => {
  const embededHolidays = areThereHolidays(initial, final, repeatedDays);
  const serieId = `serie-${Math.floor(Math.random() * 1000000) + 1}`;
  let finalSeries = [];
  if (embededHolidays.length > 0) {
    let initialDay = initial.clone();
    let finalDay = embededHolidays[0].clone().subtract(1, 'day');
    embededHolidays.forEach((holiday, index) => {

      if (!isTheDayContained(holiday, initial, initial)) {
        finalSeries.push({
          serieId,
          initialDate: initialDay,
          finalDate: finalDay,
          interval,
          repeatedDays
        });
      }

      initialDay = holiday.clone().add(1, 'day');
      if (index === embededHolidays.length - 1) { finalDay = final; }
      else { finalDay = embededHolidays[index + 1].clone().subtract(1, 'day'); }
    });

    if (!isTheDayContained([...embededHolidays].pop(), final, final)) {
      finalSeries.push({
        serieId,
        initialDate: initialDay,
        finalDate: finalDay,
        interval,
        repeatedDays
      });
    }
    return finalSeries;
  }
  else {
    finalSeries.push({
      serieId,
      initialDate: initial,
      finalDate: final,
      interval,
      repeatedDays
    });
    return finalSeries;
  }
};

// Función que verifica si la serie que está siendo editada contiene festivos o no.
export const serieSkipsHolidays = (id, series) => {
  var BreakException = {
    message: 'An error ocurred validating holidays in edited serie'
  };
  try {
    series
      .filter(serie => {
        return serie.serieId === id;
      })
      .forEach(serie => {
        if (
          areThereHolidays(
            serie.initialDate,
            serie.finalDate,
            serie.repeatedDays
          ).length > 0
        ) {
          throw BreakException;
        }
      });
    return true;
  } catch (e) {
    if (e === BreakException) {
      return false;
    } else {
      throw e;
    }
  }
};

import React, { createContext, Component } from 'react';
import { withApollo } from 'react-apollo';
import { GET_PHYSICIANS_LOGIN_HISTORY } from '../graphql/types/physicianLoginHistory.js/queries';
import _ from 'lodash';

export const context = createContext({});
const { Consumer, Provider } = context;

export const PhysicianLConsumer = Consumer;

class PhysicianLoginHistoryProvider extends Component {
    state = {
        sort: {
            field: 'total',
            direction: -1
        },
        pagination: {
            page: 1,
            perPage: 10
        },
        loginHistoryData: [],
        loginHistoryTotal: 0
    }

    getLoginHistory = async () => {
        try {
          const { sort, pagination } = this.state;
          
            let response = await this.props.client.query({
                query: GET_PHYSICIANS_LOGIN_HISTORY,
                variables: { 
                    pagination,
                    sort
                }
            });
    
          response = response.data.getPhysiciansLoginHistory;
    
          this.setState({ 
            loginHistoryData: response.data,
            loginHistoryTotal: response.total
          });
    
        } catch (error) {
            
        }
    }

    handlePageChange = page => {
        this.setState({ 
            pagination: {
                ...this.state.pagination,
                page
            }
        });
    }
    
    handleRowsChange = (perPage, page) => {
        this.setState({
            pagination: {
                perPage,
                page: 1
            }
        })
    }

    handleSort = ({ sortField }, direction) => {
        this.setState({
          sort: {
            field: sortField,
            direction: direction === 'asc' ? 1 : -1
          }
        });
    }

    componentDidUpdate(nextProps, prevState) {
        const { sort: prevSort, pagination: prevPagination } = prevState;
        const { sort: nextSort, pagination: nextPagination } = this.state;
    
        if (
          !_.isEqual(prevSort, nextSort) ||
          !_.isEqual(prevPagination, nextPagination)
        ) {
          this.getLoginHistory();
        }
    }

    render () {
        return ( 
            <Provider 
                value = {{
                    ...this.state,
                    getLoginHistory: this.getLoginHistory,
                    handlePageChange: this.handlePageChange,
                    handleRowsChange: this.handleRowsChange,
                    handleSort: this.handleSort
                }}
            >
                { this.props.children }
            </Provider>
        );
    }
}

export default withApollo(PhysicianLoginHistoryProvider);
import gql from 'graphql-tag';


export const GET_PHYSICIAN = gql`
	query getPhysician($fullname: String!, $eq: Boolean) {
		getPhysician(fullname: $fullname, eq: $eq) {
			success
			errors {
				path
				message
			}
			data {
				_id
				fullname
				dni
				medicalRecord
				specialties
				confirm
			}
		}
	}
`;

export const GET_PHYSICIAN_IDENTIFICATION = gql`
	query getByIdentification($identification: String!) {
		getByIdentification(identification: $identification) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				lastname
				fullname
        gender
				specialty
				institution
				address
				office
				phone
        city
				mobile
				email
				active
				medicalrecord
				status
				identificacion
				address1
				eps_particular
				eps_EPS
				eps_Poliza
				shipping_address1
				office1
				shipping_address2
				office2

			}

		}
	}
`;


export const GET_PHYSICIANS = gql`
	query getPhysicians(
		$_id: String
		$fullname: String, 
		$medicalRecord: String,
		$dni: String,
		$email: String,
		$perPage: Int, 
		$page: Int,
	) {
		getPhysicians(
			_id: $_id
			fullname: $fullname, 
			medicalRecord: $medicalRecord,
			dni: $dni,
			email: $email,
			perPage: $perPage, 
			page: $page, 
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				city
				confirm
				dni
				email
				fullname
				gender
				institutions
				insurance
				medicalRecord
				mobile
				phone
				shipping {
					address
					institution
					office
				}
				specialties,
				observations		
			}
			cnt
		}
	}
`;

export const GET_PHYSICIAN_EQ = gql`
	query getPhysician($fullname: String!, $eq: Boolean) {
		getPhysician(fullname: $fullname, eq: $eq) {
			success
			errors {
				path
				message
			}
			data {
				_id
				fullname
				name
				lastname
				specialty
				status
				active
				medicalrecord
				email
				phone
				mobile
				address
				identificacion
				office
				institution

			}

		}
	}
`;
export const GET_PHYSICIANBYID = gql`
query getPhysicianById($_id: ID!) {
	getPhysicianById(_id: $_id ) {
		success
		errors {
			path
			message
		}
		data {
      _id
      active
      address
      address1
      city
      email
      eps_EPS
      eps_particular
      eps_Poliza
      fullname
      gender
      identificacion
      institution
      lastname
      medicalrecord
      mobile
      name
      office
      office1
      office2
      phone
      shipping_address1
      shipping_address2
      specialty
      status

			history{
				success
				errors {
					path
					message
				}
				data{
					name
					cnt
				}
			}
		}
	}
}
`;

export const GET_PHYSICIANS_LIST = gql`
	query getPhysiciansList(
		$page: Int, 
		$perPage: Int,
		$sort: SortPhysicians
		$filters: FiltersPhysicians
	) {
		getPhysiciansList(
			page: $page
			perPage: $perPage
			sort: $sort
			filters: $filters
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				confirm
				email
				fullname
				medicalRecord
				mobile
				phone
				specialties
				resultsPlatformInvitation
			}
			total
		}
	}
`;

export const SEND_RESULTS_PLATFORM_INVITATION = gql`
	query sendResultsPlatformInvitation(
		$id: ID!
	) {
		sendResultsPlatformInvitation(
			id: $id
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

import gql from 'graphql-tag';

export const GET_PROGRAM_TYPES = gql`
	query getProgramTypes($page: Int, $perPage: Int) {
		getProgramTypes(page: $page, perPage: $perPage) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				status
				date_created
			}
		}
	}
`;

export const minutesList = [
  { _id: 0, name: '00' },
  { _id: 5, name: '05' },
  { _id: 10, name: '10' },
  { _id: 15, name: '15' },
  { _id: 20, name: '20' },
  { _id: 25, name: '25' },
  { _id: 30, name: '30' },
  { _id: 35, name: '35' },
  { _id: 40, name: '40' },
  { _id: 45, name: '45' },
  { _id: 50, name: '50' },
  { _id: 55, name: '55' }
];
export const hoursList = [
  { _id: 0, name: '0' },
  { _id: 1, name: '1' },
  { _id: 2, name: '2' },
  { _id: 3, name: '3' },
  { _id: 4, name: '4' },
  { _id: 5, name: '5' },
  { _id: 6, name: '6' }
];

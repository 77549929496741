const salaCheckboxes = [
  {
    name: 'Holter7dias',
    key: 'Holter7dias',
  },
  {
      name: 'Consultorio 3',
      key: 'consultorio3',
  },
  {
      name: 'Sala (N) EKG',
      key: 'SalaNEKG',
  },
  {
      name: 'Monitoreo Simultánea',
      key: 'monitoreoSimultanea',
  },
  {
      name: 'Rehabilitacion 1',
      key: 'rehabilitacion1',
  },
  {
      name: 'Simultánea de Ecos',
      key: 'simultaneaDeEcos',
  },
  {
      name: 'Rehabilitacion 2',
      key: 'rehabilitacion2',
  },
  {
      name: 'Mapa',
      key: 'mapa',
  },
  {
      name: 'Rehabilitacion 3',
      key: 'rehabilitacion3',
  },
  {
      name: 'Prueba de Esfuerzo',
      key: 'pruebaDeEsfuerzo',
  },
  {
      name: 'Consultorio 1',
      key: 'consultorio1',
  },
  {
      name: 'Ecocardiología',
      key: 'ecocardiologia',
    index:11
  },
  {
      name: 'Consultorio 2',
      key: 'consultorio2',
  },
  {
    name: 'Tilt Test',
    key: 'tiltTest',
    index:13
  },
];

export default salaCheckboxes;
/**
 * ScheduleTable
 */
/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';

import './style.css';
moment.locale('es');

const ScheduleTable = ({ user, onScroll, appointments }) => {
  const scrollableRef = useRef(null);
  const appointmentsLength = useRef(0);

  useEffect(() => {
      const currentAppointmentsLength = appointments.length;
      const previousAppointmentsLength = appointmentsLength.current;

      if (previousAppointmentsLength > 0 && currentAppointmentsLength < previousAppointmentsLength) {
        scrollableRef.current.scrollTop = 0;
      }

      appointmentsLength.current = currentAppointmentsLength;
  }, [appointments]);

  return (
    <div 
      className="table-container schedule-table"
      onScroll={onScroll}
      ref={scrollableRef}
    >
      {user !== false ? (
        appointments && appointments.length > 0 ? (
          <table className="list users-list">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Sala</th>
                <th>Prestación</th>
                <th>Paciente</th>
                <th>Identificación</th>
                <th>Estado cita</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((item, index) => {
                const { sede, state, space, doctor, service, patient } = item;

                const titleDate = item.time
                  ? moment(parseInt(item.time)).format(
                    'D/MM/YYYY [\n] hh:mm A'
                  )
                  : '';

                let dni = '';
                let fullName = '';
                let stateName = '';
                let sedeName = '';
                let spaceName = '';
                let doctorName = '';
                let serviceName = '';
                let doctorLastName = '';
                let _id = '';

                if (patient) {
                  dni = patient.dni;
                  fullName = `${patient.name} ${patient.lastName}`;
                  _id = patient._id;
                }

                let stateDate = '';
                if (state && state.state){
                  stateName = state.state;
                  stateDate = moment(state.date_created).format('D/MM/YYYY [-] hh:mm A');
                }
                if (sede && sede.name) sedeName = sede.name;
                if (space && space.name) spaceName = space.name;
                if (doctor && doctor.name) doctorName = doctor.name;
                if (doctor && doctor.lastName) doctorLastName = doctor.lastName;
                if (service && service.name) serviceName = service.name;

                return (
                  <tr key={index} className="list-item clickable ripple-title">
                    <td>{titleDate}</td>
                    <td>{spaceName}</td>
                    <td>{serviceName}</td>
                    <td>{fullName}</td>
                    <td>
                      <Link to={`/patients/${_id}`}>
                        {dni}
                      </Link>
                    </td>
                    <td>{stateName}<br/>{stateDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-center">No se encontraron pacientes</p>
        )
      ) : null}
    </div>
  );
};
ScheduleTable.propTypes = {
  onScroll: PropTypes.func,
  appointments: PropTypes.array
};
ScheduleTable.defaultProps = {
  onScroll: () => {}
};
export default ScheduleTable;

import gql from 'graphql-tag';

export const SAVE_BILLING_APPOINMENT = gql`
	mutation saveBillingAppointment(
		$appointmentId: ID!
		$data: BillingAppointment!
    ) {
		saveBillingAppointment(
			appointmentId: $appointmentId
			data: $data
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const SAVE_CUPS = gql`
	mutation saveCups(
		$service: ID
       	$eps: ID
        $code: String

	) {
		saveCups(
			service: $service
			eps: $eps
			code: $code
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const UPDATE_CUPS = gql`
	mutation updateCups(
		$_id: ID!
		$service: ID
       	$eps: ID
        $code: String

	) {
		updateCups(
			_id: $_id
			service: $service
			eps: $eps
			code: $code
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const DELETE_CUPS = gql`
	mutation deleteCups(
		$_id: ID!

	) {
		deleteCups(
			_id: $_id
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;
import React, { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// my components
import SelectInput from '../../../components/general/selectInput';
import TextInput from '../../../components/general/textInput';
import CheckboxInput from '../../../components/general/checkboxInput';

import { GET_RESOURCE_TYPES, GET_RESOURCE_SEDE } from '../../../graphql/types/resourcesModule/queries';
import { specialScheduleOptions } from '../../../utils/resources/resourcesUtils';

import './style.css';

class ResourceForm extends Component {
  state = {
    types: [],
    sedes: [],
    currentType: {},
    type_resource: ''
  };

  componentDidMount = async () => {
    const {
      data: {
        getResourceType: { data: types }
      }
    } = await this.props.client.query({ query: GET_RESOURCE_TYPES });
    const sedeType = types.find(type => {
      return type.name === 'Sede';
    });
    const _resourceType = sedeType._id;
    const {
      data: {
        getResourceSede: { data: sedes }
      }
    } = await this.props.client.query({
      query: GET_RESOURCE_SEDE,
      variables: { _resourceType }
    });
    const currentType = this.props.currentType ? this.props.currentType : {};

    this.setState({
      types,
      sedes,
      currentType
    });
  };

  updateField = (name, { value, aux, radioName }) => {
    if (name === 'type_resource') {
      const currentType = value !== ''? this.state.types.filter(type => type._id === value): [{}];
      this.props.updateField(name, { value, aux: currentType[0] });
      this.setState({ currentType: currentType[0] });
    }
    else if (radioName === 'sede' || radioName === 'specialSchedule') {
      this.props.updateField(radioName, { value: aux });
    }
    else if (name === 'quantity'){
      this.props.updateField(name, { value: parseInt(value, 10) });
    }
    else {
      this.props.updateField(name, { value });
    }
  };

  render () {
    const { currentType } = this.state;
    const {
      name,
      clear,
      quantity,
      resourceErrors,
      specialSchedule,
      timeOut
    } = this.props;
    const disabledType = /(\/edit)/.test(this.props.location.pathname);
    return (
      <div className="container-resources-form flex j-c">
        <div
          className={`${resourceErrors['type'] ? 'wrong-input' : ''}`}
          onFocus={clear}
        >
          <SelectInput
            name="type_resource"
            label="Tipo de recurso"
            value={this.state.currentType._id}
            options={this.state.types}
            disabled={disabledType}
            updateField={this.updateField}
          />
        </div>
        <div
          className={`${resourceErrors['name'] ? 'wrong-input' : ''}`}
          onFocus={clear}
        >
          <TextInput
            type="text"
            label="Nombre"
            updateField={this.updateField}
            value={name}
            name="name"
          />
        </div>
        {
          currentType.name && currentType.name !== 'Sede' &&
            <div className={`${resourceErrors['sede'] ? 'wrong-input' : ''} sedeField`}>
              <label className="muted-text active">Sede</label>
              <div className="flex j-sa">
                {
                  this.state.sedes.map((sede, index) => {
                    return (
                      <div key={index} onFocus={clear}>
                        <CheckboxInput
                          checked={sede._id === this.props.sede}
                          type="radio"
                          updateField={this.updateField}
                          value={sede._id}
                          label={sede.name}
                          name="sede"
                          id={`sede-${index}`}
                        />
                      </div>
                    );
                  })
                }
              </div>
            </div>
        }
        {
          currentType.name === 'Equipo' &&
            <Fragment>
              <div
                onFocus={clear}
                className={`${resourceErrors['quantity'] ? 'wrong-input' : ''}`}
              >
                <TextInput
                  label="Cantidad"
                  updateField={this.updateField}
                  value={quantity}
                  type="number"
                  name="quantity"
                />
              </div>
            </Fragment>
        }
        {
          currentType.name === 'Espacio de trabajo' &&
            <Fragment>
              <div className={`${resourceErrors['specialSchedule'] ? 'wrong-input' : ''}`}>
                <label className="muted-text active">¿Horario especial?</label>
                <div className="flex j-sa specialSchedule">
                  {
                    specialScheduleOptions.map((option, index) => {
                      return (
                        <div key={index} onFocus={clear}>
                          <CheckboxInput
                            id={`special-${index}`}
                            type="radio"
                            name="specialSchedule"
                            label={option.label}
                            value={option.value}
                            checked={option.value === specialSchedule}
                            updateField={this.updateField}
                          />
                        </div>
                      );
                    })
                  }
                </div>
              </div>
              <div>
                <SelectInput
                  name="active"
                  label="Estado"
                  value={this.props.active === null? 'false': this.props.active.toString()}
                  labelActive={true}
                  updateField={this.props.updateField}
                  without_blank={true}
                  options={[
                    { _id: true, name: 'activo' },
                    { _id: false, name: 'inactivo' }
                  ]}
                />
              </div>
            </Fragment>
        }

        {
          currentType.name === 'Equipo' &&

          <div 
            onFocus={clear} 
            className={`${resourceErrors['specialSchedule'] ? 'wrong-input' : ''}`} >

            <SelectInput
              name="timeOut"
              label="Tiempo de servicio (días)"
              value={timeOut}
              options={[
                { _id: 1, name: '1' },
                { _id: 2, name: '2' },
                { _id: 3, name: '3' },
                { _id: 4, name: '4' },
                { _id: 5, name: '5' },
                { _id: 6, name: '6' },
                { _id: 7, name: '7' }
              ]}
              updateField={this.updateField}
            />

          </div>
        }

        
      </div>
    );
  }
}

export default withApollo(withRouter(ResourceForm));

import React, { Component } from 'react';
import TextInput from '../../../components/general/textInput';
import { graphql } from 'react-apollo';
import { PASSWORD_RECOVERY_MUTATION } from '../../../graphql/types/usersModule/mutations';
import MESSAGES from './messages.json';

class PasswordRecovery extends Component {
  state = {
    email: '',
    message: '',
    error: false,
    success: false
  };

  updateField = (name, { value }) => this.setState({ [name]: value });

  validateEmail = ({ email }) => {
    let message = '';
    var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(email).toLowerCase());
    message =
      !valid && email === '' ? MESSAGES['noEmail'] : MESSAGES['invalidEmail'];
    return { valid, message };
  };

  confirm = async e => {
    e.preventDefault();
    const { email } = this.state;
    const { valid, message } = this.validateEmail(this.state);
    if (valid) {
      const result = await this.props.recoverPassword({
        variables: {
          email
        }
      });
      const success = result.data.recoverPassword.success;
      const error = !success;
      const message = success ? MESSAGES['success'] : MESSAGES['notInDb'];
      this.setState({ success, error, message });
    } else {
      this.setState({ error: true, message });
    }
  };

  render () {
    return (
      <div className="container flex j-c a-c-c a-i-c">
        <div className="recovery-container">
          <img src={require('../../../assets/logo.png')} alt="Logo Sicor" />

          <p className="text-center">
            {' '}
            Ingresa a continuación tu correo electrónico institucional para validar tu identidad y recuperar tu contraseña de forma segura.
          </p>

          <form className="text-center flex f-c a-i-c" onSubmit={this.confirm}>
            <div className="form-group">
              <TextInput
                value={this.state.email}
                type="email"
                name="email"
                label="email"
                updateField={this.updateField}
              />
            </div>

            <div className="text-center mt-2">
              <button className="button button-lg bg-primary" type="submit" onClick={this.confirm}>
                Validar correo
              </button>
            </div>
          </form>
          {this.state.error ? (
            <p className="text-center error-msg">{this.state.message}</p>
          ) : null}
          {this.state.success ? (
            <p className="text-center success-msg">{this.state.message}</p>
          ) : null}
        </div>
      </div>
    );
  }
}

export default graphql(PASSWORD_RECOVERY_MUTATION, { name: 'recoverPassword' })(PasswordRecovery);

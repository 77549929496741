import gql from 'graphql-tag';

export const GET_PHYSICIANS_LOGIN_HISTORY = gql`
	query getPhysiciansLoginHistory(
		$pagination: paginationPhysicianLoginHistory
		$sort: sortPhysicianLoginHistory
	) {
		getPhysiciansLoginHistory(
			pagination: $pagination
			sort: $sort
		) {			
			data {
				_id
				physician
                date_created
                total
			}
            success
            total
		}
	}
`;
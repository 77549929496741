import React, { Component } from 'react';
import ServicesList from '../../../components/servicesModule/servicesList';
import { Link } from 'react-router-dom';

class Resources extends Component {
  render () {
    return (
      <div>
        <ServicesList>
          <div className="text-center pt-1 pb-1 flex a-i-c j-c">
            <Link to="/services/new">
              <button
                className="button ripple save-user button-sm bg-icon"
                type="submit"
                style={{ width: '200px' }}
              >
                <span className="icon-resources mr-02" />
                Nueva prestación
              </button>
            </Link>
          </div>
        </ServicesList>
      </div>
    );
  }
}

export default Resources;

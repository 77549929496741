/**
 * PatientProvider
 */
/* eslint-disable */
import SelectSearch from 'react-select';
import {
  BOOK_APPOINTMENT,
  CREATE_STATE_APPOINTMENTS,
  DELETE_BOOK_APPOINTMENT,
  DELIVERY_APPOINTMENT,
  ROLLBACK_STATE_APPOINTMENT,
  SAVE_BOOK_APPOINTMENT,
  UPDATE_BOOK_APPOINTMENT,
  UPDATE_EPS_APPOINTMENT
} from '../graphql/types/appointment/mutations';
import {
  CREATE_PATIENT,
  CREATE_PATIENTR,
  UPDATE_PATIENT
} from '../graphql/types/patientsModule/mutations';
import { SUBSCRIPTION_PATIENT_UPDATE } from '../graphql/types/patientsModule/subscriptions';
import { CREATE_PHYSICIAN, CREATE_REFERRING_PHYSICIAN } from '../graphql/types/physician/mutations';
import {
  GET_APPOINTMENTS,
  GET_STATE_APPOINTMENTS,
  GET_ALL_LEE_REALIZA_STATE_APPOINTMENTS,
  getMultipleAppointmentsBySpaces
} from '../graphql/types/appointment/queries';
import {
  GET_APPOINTMENTS_SCHEDULE,
  GET_AUTHORIZATION,
  GET_PATIENT,
  CAN_UPDATE_PATIENT,
  GET_WILDCARD_PATIENTS_DNIS
} from '../graphql/types/patientsModule/queries';
import { GET_PHYSICIAN, GET_PHYSICIANBYID } from '../graphql/types/physician/queries';
import {
  GET_RESOURCE_APPOINTMENT,
  GET_RESOURCE_SEDE,
  GET_RESOURCE_TYPES
} from '../graphql/types/resourcesModule/queries';
import React, { Component, createContext } from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import { getUserFromToken, inputPastMax } from '../utils/functions';

import {
  FIND_IMAGE
} from '../graphql/types/image/queries';
import FormTypeHead from '../components/general/formTypeHead';
// graphql models
import { GET_EPS } from '../graphql/types/eps/queries';
import { GET_PROGRAM_TYPES } from '../graphql/types/programTypes/queries';
import { GET_SERVICES_NAMES } from '../graphql/types/servicesModule/queries';
import { GET_USER_DOCTORS } from '../graphql/types/usersModule/queries';
import PropTypes from 'prop-types';
import SelectInput from '../components/general/selectInput';
import StateAppointment from '../utils/appointment/stateAppointment';
import TextInput from '../components/general/textInput';
// components
import Validate from './validate';
import _ from 'lodash';
import { days } from '../utils/resources/resourcesUtils';
import gql from 'graphql-tag';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { fetchWithTokenResults } from '../utils/fetch';
import { timestampToDate } from '../utils/helpers';
import { SAVE_BILLING_APPOINMENT } from '../graphql/types/billing/mutations';

moment.locale('es');

export const context = createContext({
  currentServices: [],
  currentAppointments: [],
  currentStateAppointments: [],
  currentPatient: {
    contactPreference: 'mobile',
    show: false,
    classM: 'animated tada'
  },
  serviceInputValue: '',
  selectedServices: [],
  sedes: [],
  spaceType: '',
  selectedSedeId: '',
  calendarDate: moment(),
  appointments: {}
});

var timeO

const { Consumer, Provider } = context;

class PatientProvider extends Component {

  selfMountComponent = true;
  currentReScheduleAppointment = null;
  patientUpdateSubscription = null;
  state = {
    isLoader: false,
    currentAppointments: [],
    currentStateAppointments: [],
    currentPatient: {
      contactPreference: 'mobile'
    },
    calendarData: {},
    calendarScheduleModal: false,
    patientAppointment: null,
    showNewPatientAvailability: false,
    spaceType: '',
    sedes: [],
    selectedDay: new Date(new Date().getTime()).setHours(0, 0, 0, 0),
    dateExtra: new Date(new Date().getTime()).setHours(0, 0, 0, 0),
    serviceInputValue: '',
    selectedSedeId: '',
    entityService: '',
    calendarDate: moment(),
    recievedAppointments: false,
    servicesAppointments: {},
    showModalPhoto: false,
    photo: false,
    errorMessage: false,
    successMessage: false,
    confirmationModal: false,
    showAppointmentSchedule: false,
    showModalSchedule: false,
    currentServices: [],
    selectedServices: [], //  prestaciones que escogemos en el typehead
    patientErrors: {},
    appointments: {},
    selectedAppointmentsService: {},
    appointmentsService: {},
    dataAppointmentsService: {},
    appointmentsInfo: {},
    appointmentsInfoObservation: {},
    blockResource: [],
    errSchedule: {},
    errSaveAppointment: {},
    selectedCalendarService: {},
    canUpdatePatient: true,
    showPatientResultForm: false,
    patientIsWildcard: false,
    showBillingForm: false,
    billingData: {
      tipoDocumentoIdentificacion: '',
      NumDocumentoIdentificacion: '',
      codPrestador: '050011111101',
      nombre1: '',
      nombre2: '',
      apellido1: '',
      apellido2: '',
      tipoUsuario: '',
      fechaNacimiento: '',
      codSexo: '',
      codPaisResidencia: '170',
      codMunicipioResidencia: '',
      codDepartamentoResidencia: '',
      codBarrioResidencia: '',
      codZonaTerritorialResidencia: '02',
      incapacidad: 'NO',
      codPaisOrigen: '',
      codDepartamentoOrigen: '',
      codMunicipioOrigen: '',
      Email: '',
      Celular: '',
      Direccion: '',
      Etnia: '',
      Profesion: '',
      NivelSalarial: '',
      TipoEntidad: '',
      EntidadFacturacion: '',
      NitEps: '',
      CodigoProfesional: '900304869',
      CentroCostosEjecuta: '',
      CentroCostosSolicita: '',
      modalidadGrupoServicioTecSal: '01',
      EstadoCivil: '',
      NivelEscolaridad: '',
      numAutorizacion: '',
      FechaAutorizacion: ''
    },
    userTypeSelect: [],
    identificationTypeSelect: [],
    genderSelect: [],
    territorialZone: [],
    incapacitySelect: [],
    etniaSelect: [],
    entityTypeSelect: [],
    atentionModalitySelect: []
  };

  componentWillUnmount() {
    this.selfMountComponent = false;
  }

  componentDidMount = async () => {
    this.selfMountComponent = true;
    const locationState = this.props.location.state;

    if (locationState && locationState.dni) {
      debugger;
      const {
        dni,
        name,
        lastName,
        type_dni,
        phone,
        mobile } = locationState;
      this.setState({
        currentPatient: { ...this.state.currentPatient, dni, name, lastName, type_dni, phone, mobile }
      });
    }

    if (!localStorage.getItem('token')) {
      this.props.history.replace('/');
      return false;
    }
    const {
      data: {
        getResourceType: { data: types }
      }
    } = await this.props.client.query({ query: GET_RESOURCE_TYPES });
    const sedeType = types.find(type => {
      return type.name === 'Sede';
    });
    const spaceType = types.find(type => {
      return type.name === 'Espacio de trabajo';
    })._id;
    const _resourceType = sedeType._id;
    const {
      data: {
        getResourceSede: { data: sedes }
      }
    } = await this.props.client.query({
      query: GET_RESOURCE_SEDE,
      variables: { _resourceType }
    });
    const {
      data: {
        allEps: { data: eps }
      }
    } = await this.props.client.query({
      query: GET_EPS
    });

    // si el componente esta montado
    if (this.selfMountComponent) {
      this.setState({
        patientAppointment: null,
        spaceType,
        sedes,
        eps
      });
      let _id = this.props.location.pathname.replace(/(\/patients\/)/, '');
      _id = _id.replace(/(\/edit)/, '');
      this.getPatient(_id);
    }
  };
  querySubscription = async () => {
    // console.log("querySubscription");
    const self = this;
    const { selectedSedeId } = self.state;
    const { blockResource } = self.state;
    var { userId: user } = getUserFromToken();
    this.onSubscription = this.props.client
      .subscribe({
        query: gql`
          subscription patientBookAppointment($sede: ID, $user: ID) {
            patientBookAppointment(sede: $sede, user: $user) {
              sede
              service
              doctor
              space
              time
              day
              user
              type
            }
          }
        `,
        variables: { sede: selectedSedeId, user }
      })
      .subscribe({
        async next(data) {
          // console.log("subscribe: ", data);
          const citaSubs = data.data.patientBookAppointment;
          switch (citaSubs.type) {
            case 'save':
              if (!self.state.showModalSchedule) {
                await self.getAvailability('subs');
              } else {
                const index = _.findIndex(blockResource, {
                  service: citaSubs.service,
                  user: citaSubs.user,
                  time: citaSubs.time,
                  sede: citaSubs.sede
                });
                if (index < 0) {
                  blockResource.push(citaSubs);
                } else {
                  blockResource[index] = citaSubs;
                }
              }
              break;
            case 'delete':
              _.remove(blockResource, {
                service: citaSubs.service,
                user: citaSubs.user,
                time: citaSubs.time,
                sede: citaSubs.sede
              });
              if (!self.state.showModalSchedule) {
                await self.getAvailability('subs');
              }
              break;
          }

          self.setState({ blockResource });
          // Notify your application with the new arrived data
        }
      });
  };

  handleCanUpdatePatient = async patientId => {
    const response = await this.props.client.query({
      query: CAN_UPDATE_PATIENT,
      variables: { patientId }
    });

    this.handlePatientUpdateSubscription(patientId, 'add');

    const canUpdatePatient = response.data.canUpdatePatient.canUpdate;

    this.setState({ canUpdatePatient });
  }

  handlePatientUpdateSubscription = async (patientId, action) => {
    this.patientUpdateSubscription = this.props.client
      .subscribe({
        query: SUBSCRIPTION_PATIENT_UPDATE,
        variables: { id: patientId, action }
      })
      .subscribe({
        next: (data) => {
          console.log(data)
          if (action === 'drop') {
            this.handlePatientUpdateUnsubscription();
          }
        },
        error(err) {
          console.log(err)
        } 
      });
  }

  handlePatientUpdateUnsubscription = () => {
    this.patientUpdateSubscription.unsubscribe();
  }

  /**
   * saveAppointementReasign
   * @param {appointment} object Object contain data appointment
   * @param {isNewPatient} boolean True for create patient new
   * @param {newPatient} object Contein data patient new
   * @param {observations} string Contein observation for state in appointment new
   */
  createPatientR = async (variables) => {
    let { currentPatient } = this.state;
    const createPatientR = await this.props.createPatientR({
      variables: variables
    });
    if (createPatientR.data.createPatientR.success) {
      currentPatient.show = true;

      this.setState({
        currentPatient: { ...this.state.currentPatient, show: true, classM: 'animated tada' }
      });

      try {
        clearTimeout(timeO);
      } catch (err) {

      }

      timeO = setTimeout(() => {
        this.setState({
          currentPatient: { ...this.state.currentPatient, classM: 'animated fadeOut' }
        });
      }, 5300);
    } 
  }

  saveBillingAppoinment = async ({id, data}) => {
    const result = await this.props.saveBillingAppoinment({
      variables: {
        appointmentId: id,
        data
      }
    });
    
    return result
  }
  
  saveAppointementReasign = async (
    appointment,
    isNewPatient,
    newPatient,
    observations
  ) => {
    let isErrorNewPatient = false;
    const defaultErrorMessage = 'Ocurrio un error al crear el usuario!';

    // create new patient
    if (isNewPatient) {
      const responseNewPatient = await this.props.createPatient({
        variables: newPatient
      });
      try {
        const { success, errors, data } = responseNewPatient.data.createPatient;
        if (success) {
          const idPatient = data._id;
          newPatient._id = idPatient;
        } else {
          let msj = '';
          isErrorNewPatient = true;
          errors.map(itemError => {
            msj += `\n${itemError.message}`;
          });
          alert(msj !== '' ? msj : defaultErrorMessage);
        }
      } catch (e) {
        isErrorNewPatient = true;
        alert(defaultErrorMessage);
        return;
      }
    }

    if (isErrorNewPatient === true) return;
    var { userId: user } = getUserFromToken();

    // create state appointment
    await this.props.createStateAppointments({
      variables: {
        state: 'reasignada',
        _idAppointments: appointment._id,
        observations
      }
    });

    // create appointment
    const response = await this.props.bookAppointment({
      variables: {
        ...appointment,
        user,
        temp: false,
        space: appointment.space ? appointment.space._id : null,
        entity: appointment.entity ? appointment.entity._id : null,
        doctor: appointment.doctor ? appointment.doctor._id : null,
        sede: appointment.sede ? appointment.sede._id : null,
        service: appointment.service ? appointment.service._id : null,
        patient: newPatient._id
      }
    });
    const idAppointment = response.data.bookAppointment.data;

    await this.props.createStateAppointments({
      variables: {
        _idAppointments: idAppointment,
        state: StateAppointment[0].name,
        observations
      }
    });

    this.props.history.replace(`/patients/${newPatient._id}`);
    // window.location.href=`./${newPatient._id}`;
  };

  setAppointmentInfoObservation = (appointment, value) => {
    const { service } = appointment;
    const { appointmentsInfoObservation } = this.state;
    appointmentsInfoObservation[service] = value;
    this.setState({ appointmentsInfoObservation });
  };


  updateEpsAppointment = async (obj) => {
    let response = await this.props.updateEps({
      variables: {
        _id: obj._id,
        patient: obj.patient,
        entity: obj.entity
      }
    });

    response = response.data.updateBookAppointment;
    return response;
  }

  /*
   * Guardar y actualiza cita
   * Guardamos los datos de una cita, medico y sala
   */
  handleappointmentsInfo = async (appointment, name, value, obj) => {
    const {
      appointmentsInfo,
      selectedSedeId,
      entityService,
      patientAppointment,
      appointmentsService,
      dataAppointmentsService
    } = this.state;
    let { currentPatient } = this.state;
    let errSchedule = {};
    if (document.location.pathname.indexOf('availability') > -1) {
      if (!patientAppointment) {
        errSchedule = { path: 'paciente', msg: 'No seleccionó Paciente' };
      }
      currentPatient = patientAppointment;
    }
    if (errSchedule.path) {
      this.setState({ errSchedule });
      return false;
    }
    const errSaveAppointment = {};
    var { userId: user } = getUserFromToken();
    const service = appointment.service;
    let nameAUX = name;
    if (nameAUX === 'doctor') {
      nameAUX = 'doctors';
    }
    if (
      appointmentsInfo[service] &&
      appointmentsInfo[service][`${name}Obj`] &&
      _.indexOf(
        appointmentsService[service][nameAUX],
        appointmentsInfo[service][`${name}Obj`]._id
      ) < 0
    ) {
      if (
        _.indexOf(
          appointmentsService[service][nameAUX],
          appointmentsInfo[service][`${name}Obj`]._id
        )
      ) {
        appointmentsService[service][nameAUX].push(
          appointmentsInfo[service][`${name}Obj`]._id
        );
      }
      if (
        !_.find(dataAppointmentsService[name + 's'], {
          _id: appointmentsInfo[service][`${name}Obj`]._id
        })
      ) {
        // dataAppointmentsService[name + 's'].push(
        // 	appointmentsInfo[service][`${name}Obj`]
        // );
        dataAppointmentsService[name + 's'] = [
          ...dataAppointmentsService[name + 's'],
          appointmentsInfo[service][`${name}Obj`]
        ];
      }
    }

    appointmentsInfo[service] = {
      ...appointmentsInfo[service],
      ...appointment,
      [name]: value,
      [`${name}Obj`]: obj,
      entity: entityService
    };
    if (appointmentsInfo[service].doctor == '') {
      delete appointmentsInfo[service].doctor;
    }
    if (appointmentsInfo[service].space == '') {
      delete appointmentsInfo[service].space;
    }
    let response;

    if (appointmentsInfo[service]._id) {
      response = await this.props.updateBookAppointment({
        variables: {
          ...appointmentsInfo[service],
          service,
          sede: selectedSedeId,
          patient: currentPatient._id,
          user
        }
      });
      response = response.data.updateBookAppointment;
    } else {
      try {
        if (
          appointmentsInfo[service].doctor ||
          appointmentsInfo[service].space
        ) {
          response = await this.props.bookAppointment({
            variables: {
              ...appointmentsInfo[service],
              sede: selectedSedeId,
              service,
              patient: currentPatient._id,
              user,
              temp: true
            }
          });
          response = response.data.bookAppointment;
        }
      } catch (error) {
        console.log("error :", error); // eslint-disable-line
      }
      if (response && response.success) {
        const idAppointment = response.data;

        appointmentsInfo[service]._id = idAppointment;
      }
    }
    if (response && !response.success) {
      errSaveAppointment[service] = response.errors;
      appointmentsInfo[service].space = null;
      appointmentsInfo[service].doctor = null;
    }
    this.setState({
      errSchedule,
      errSaveAppointment,
      appointmentsInfo,
      appointmentsService,
      dataAppointmentsService
    });
  };
  selectedTypeHeadPatient = async _id => {
    const { appointmentsInfo } = this.state;

    if (_id) {
      const response = await this.props.client.query({
        query: GET_PATIENT,
        variables: { _id }
      });
      if (response.data && response.data.getPatient.success) {
        this.setState({ patientAppointment: response.data.getPatient.data });
      }
      // si cambia el paciente actualizamos
      var { userId: user } = getUserFromToken();
      Object.keys(appointmentsInfo).forEach(key => {
        this.props.updateBookAppointment({
          variables: {
            ...appointmentsInfo[key],
            patient: _id,
            user
          }
        });
      });
      this.setState({ appointmentsInfo });
    }
  };
  saveBookAppointment = async () => {
    let {
      eps,
      sedes,
      entityService,
      selectedSedeId,
      selectedServices,
      appointmentsInfo,
      appointmentsInfoObservation,
      currentAppointments,
      dataAppointmentsService,
      patientAppointment
    } = this.state;
    const _id = [];
    let errSchedule = {};
    const arrayNewAppointment = [];
    Object.keys(appointmentsInfo).forEach(key => {
      if (appointmentsInfo[key]._id) {
        arrayNewAppointment.push({
          idAppointment: appointmentsInfo[key]._id,
          idService: key
        });
        _id.push(appointmentsInfo[key]._id);
      }

      const service = _.find(selectedServices, { _id: key });
      service.name = service.label;

      if (!appointmentsInfo[key].space) {
        errSchedule = { path: key, msg: 'No seleccionó Sala' };
      }

      const sede = _.find(sedes, { _id: selectedSedeId });
      const entity = _.find(eps, { _id: entityService });

      currentAppointments = [
        { ...appointmentsInfo[key], service, sede, entity },
        ...currentAppointments
      ];
    });

    if (!appointmentsInfo || Object.keys(appointmentsInfo).length === 0) {
      errSchedule = { path: 'cita', msg: 'No seleccionó Sala' };
    }
    if (_id.length !== Object.keys(appointmentsInfo).length) {
      errSchedule = { path: 'cita', msg: 'No seleccionó Sala' };
    }
    if (errSchedule.path) {
      this.setState({ errSchedule });
      return false;
    }
    const response = await this.props.saveBookAppointment({
      variables: { _id: JSON.stringify(_id) }
    });

    if (response.data.saveBookAppointment.success) {
      // insert default state Appointment
      arrayNewAppointment.forEach(async ({ idAppointment, idService }) => {
        await this.props.createStateAppointments({
          variables: {
            _idAppointments: idAppointment,
            state: StateAppointment[0].name,
            observations: appointmentsInfoObservation[idService] || ''
          }
        });
      });
      let _idPatient;
      if (patientAppointment && patientAppointment._id) {
        _idPatient = patientAppointment._id;
      }
      await this.initState();
      await this.setState({
        errSchedule: {},
        currentAppointments
      });
      if (
        document.location.pathname.indexOf('availability') > -1 &&
        _idPatient
      ) {
        this.props.history.push(`/patients/${_idPatient}`);
      }
      // si guardamos la cita nos unsubscribe
      this.onSubscription.unsubscribe();
    }

    if (this.currentReScheduleAppointment) {
      const variables = {
        state: 'reprogramada',
        observations: '',
        _idAppointments: this.currentReScheduleAppointment._id
      };

      const saveState = await this.props.createStateAppointments({ variables });

      // update state card for state 'reprogramada'
      let indexState = '';
      currentAppointments.map((appointment, index) => {
        if (appointment._id === this.currentReScheduleAppointment._id)
          indexState = index;
      });
      if (currentAppointments[indexState].state) {
        currentAppointments[indexState].state.state = 'reprogramada';
      } else {
        currentAppointments[indexState].state = { state: 'reprogramada' };
      }
      this.setState({ currentAppointments });
    }
  };
  rescheduleAppointment = async appointment => {
    this.currentReScheduleAppointment = appointment;

    const entityService = appointment.entity._id;
    const selectedSedeId = appointment.sede._id;
    const { name } = appointment.sede;

    const date = parseInt(appointment.day);
    await this.selectService([appointment.service]);
    await this.onCalendarChange(date, appointment.service);

    await this.setState(
      {
        // appointmentsInfo,
        selectedSedeId,
        selectedDay: date,
        sede: name,
        entityService,
        showAppointmentSchedule: true
      },
      async () => {
        await this.getAvailability('reschedule', appointment);
        // await this.handleShowModalSchedule(true);
      }
    );
  };
  initCurrentPatient = () => {
    this.setState({
      currentPatient: {
        contactPreference: 'mobile'
      }
    });
  };
  initState = async () => {
    this.setState({
      selectedDay: new Date(new Date().getTime()).setHours(0, 0, 0, 0),
      serviceInputValue: '',
      selectedSedeId: '',
      calendarDate: moment(),
      showNewPatientAvailability: false,
      showModalPhoto: false,
      photo: false,
      sede: '',
      currentAppointments: [],
      patientAppointment: null,
      errorMessage: false,
      entityService: '',
      successMessage: false,
      confirmationModal: false,
      showAppointmentSchedule: false,
      showModalSchedule: false,
      currentServices: [],
      selectedServices: [],
      patientErrors: {},
      appointments: {},
      selectedAppointmentsService: {},
      appointmentsService: {},
      dataAppointmentsService: {},
      appointmentsInfo: {},
      appointmentsInfoObservation: {},
      recievedAppointments: false,
      servicesAppointments: {},
      errSaveAppointment: {}
    });
  };
  modalPhoto = async open => {
    this.setState({ showModalPhoto: open });
  };
  getScreenshot = async screen => {
    this.setState({ photo: { photo: screen, camera: true } });
  };
  getAuthorization = async document => {
    this.setState({ isLoader: true });

    const apiResponse = await this.props.client.query({
      query: GET_AUTHORIZATION,
      variables: { document }
    });

    let authorized = apiResponse.data.getAuthorization.data;
    
    if (!_.isEmpty(authorized)) {
      authorized = [...authorized[0]?.authorized];

      authorized = authorized.map(a => {
        const doc = new DOMParser().parseFromString(a.names, 'text/html');

        return {
          ...a,
          names: doc.body.innerText.trim()
        }
      });
    } else {
      authorized = [];
    }

    return authorized;
  }
  
  findImageB = async name => {
    this.setState({ isLoader: true });
    const response = await this.props.client.query({
      query: FIND_IMAGE,
      variables: { name }
    });
    return response
  }
  getPatientInfo = async _id => {
    // console.log(_id)
    const response = await this.props.client.query({
      query: GET_PATIENT,
      variables: { _id }
    });
    if (response.data && response.data.getPatient.success) {
      const currentPatient = response.data.getPatient.data;
      let { photo } = currentPatient;
      let image = ''
      if (photo != 'null') {
        try {
          try {

            photo = photo.replace(/..\//i, '');
          } catch (err) {
            console.log("error")
          }
          if (photo != '' && photo != null) {
            // console.log(photo)
            image = await this.findImageB(photo);
            image = image.data.findImage.data[0].base64 ? image.data.findImage.data[0].base64 : '';
          }

        } catch (err) {
          console.log(err)
        }
      }

      this.setState({ currentPatient, image });
    }
  }
  getPatient = async _id => {
    if (_id === '/patients/new' || _id === '/availability') return; // this send page new
    if (!_id) _id = this.props.location.pathname.replace(/(\/patients\/)/, '');

    this.setState({ isLoader: true });
    const response = await this.props.client.query({
      query: GET_PATIENT,
      variables: { _id }
    });

    if (response.data && response.data.getPatient.success) {
      const currentPatient = response.data.getPatient.data;

      const document = currentPatient.dni;
      let { photo } = currentPatient;

      let authorized = await this.getAuthorization(document);
      let image = ''
      try {
        photo = photo.replace(/..\//i, '');
        image = await this.findImageB(photo);
        image = image.data.findImage.data[0].base64 ? image.data.findImage.data[0].base64 : '';

      } catch (err) {
        console.log(err)
      }
      try {
        authorized = authorized.data.getAuthorization.data;
      } catch (err) {
        console.log(err);
      }
      const appointment = await this.props.client.query({
        query: GET_APPOINTMENTS,
        variables: { patient: _id, perPage: 3, page: 1, order: 'desc' }
      });

      let currentAppointments = [];
      if (appointment.data.getAppointment.success) {
        currentAppointments = appointment.data.getAppointment.data;
      }
      
      const newObject = Object.assign({}, currentPatient);
      if (currentPatient && currentPatient.eps) {
        newObject.epsPatient = currentPatient.eps;
        newObject.eps =
          typeof currentPatient.eps === 'object'
            ? currentPatient.eps._id
            : currentPatient.eps;
      }
      if (currentPatient && currentPatient.programType) {
        newObject.programTypePatient = currentPatient.programType;
        newObject.programType =
          typeof currentPatient.programType === 'object'
            ? currentPatient.programType._id
            : currentPatient.programType;
      }
      this.setState({
        currentAppointments,
        currentPatient: { ...this.state.currentPatient, ...newObject, authorized, image },
        photo: currentPatient
          ? { photo: `${currentPatient.photo}`, camera: false }
          : false
      }, async () => {
        const { currentPatient } = this.state;

        this.wildcardPatientValidation(currentPatient.dni);
      });
    }
    this.setState({ isLoader: false });
  };
  /**
   * getAppointments
   * @param {patient} string id patient
   * @param {page} int number page pagination
   * @param {perPage} int number appointment in page
   * @param {service} string id type appointment presentation
   */
  getAppointments = async (
    patient,
    page = 1,
    perPage = 3,
    service,
    lastState,
    time,
    order = 'desc'
  ) => {
    const variables = { patient, perPage, page, order, lastState };
    let { currentAppointments } = this.state;

    if (service) variables.service = service;
    if (time && time != '') variables.time = time;
    
    this.setState({ isLoader: true });
    
    const response = await this.props.client.query({
      query: GET_APPOINTMENTS,
      variables
    });

    const responseObj = response.data.getAppointment;

    if (responseObj.success) {
      currentAppointments =
        page > 1
          ? currentAppointments.concat(responseObj.data)
          : responseObj.data;

      this.setState({ currentAppointments, appointmentsService: {} });
    }

    this.setState({ isLoader: false });

    return responseObj;
  };
  /**
   * saveStateAppointments
   * @param {appointment} object selected appointment
   * @param {state} string new state for save
   * @param {observations} string observations to state
   * @param {isGetState} boolean isQuery get getStateAppointments
   * @param {_idPhysician} string
   * @param {indexAppointmentUpdate} int integer index currentAppointment update
   */
  saveStateAppointments = async (
    appointment,
    state,
    observations,
    isGetState,
    _idPhysician = '',
    indexAppointmentUpdate = undefined,
    leeValue = undefined,
    realizaValue = undefined
  ) => {

    const { currentAppointments } = this.state;
    const variables = {
      state,
      observations,
      _idAppointments: appointment._id
    };
    if (_idPhysician !== '') {
      variables._idPhysician = _idPhysician;
    }

    if (leeValue && leeValue != '') {
      variables.leeValue = leeValue;
    }

    if (realizaValue && realizaValue != '') {
      variables.realizaValue = realizaValue;
    }

    this.setState({ isLoader: true });
    await this.props.createStateAppointments({ variables });
    if (isGetState) {
      let respStateAppo_ = await this.getStateAppointments(appointment);
    }
    // update card appointment
    if (typeof indexAppointmentUpdate === 'number') {
      const { state: provisionalState } = currentAppointments[
        indexAppointmentUpdate
      ];
      currentAppointments[indexAppointmentUpdate].state = {
        ...provisionalState,
        ...variables
      };

      this.setState({ currentAppointments });
    }
    this.setState({ isLoader: false });
  };
  /**
   * getStateAppointments
   * @param {appointment} object appointment selected details
   */
  getStateAppointments = async appointment => {
    this.setState({ appointmentsService: {}, isLoader: true });

    let response = await this.props.client.query({
      query: GET_STATE_APPOINTMENTS,
      variables: { _idAppointments: appointment._id }
    });

    if (response.data && response.data.getStateAppointments.success) {
      let currentStateAppointments = response.data.getStateAppointments.data;
      const appointmentsService = appointment;

      currentStateAppointments.map(async (stateAppointment, i) => {
        if (stateAppointment._idPhysician) {

          this.setState({
            currentStateAppointments
          })
        }
      });

      let tam = currentStateAppointments.length;
      let ultimateState = null;
      if (tam !== 0) {
        let indexEnd = +tam - 1;
        ultimateState = currentStateAppointments[indexEnd];
      }

      await this.setState({
        currentStateAppointments,
        appointmentsService
      });

      if (ultimateState !== null) {
        /// Actualizo el estado de la "Cita" Modificada
        let citas = this.state.currentAppointments;
        let listCurrentAppointments = citas.map((item) => {
          if (item._id === ultimateState._idAppointments) {
            item = { ...item, state: ultimateState };
          }

          return item;
        });

        this.setState({
          currentAppointments: listCurrentAppointments
        });
      }

    }
    this.setState({ isLoader: false });
  };
  /**
   * getAllStatesLeeRealiza
  */
  getAllStatesLeeRealiza = async () => {
    //console.log(_id);
    const response = await this.props.client.query({
      query: GET_ALL_LEE_REALIZA_STATE_APPOINTMENTS
    });
    if (response.data && response.data.getPhysician.success) {
      return response.data.getPhysician.data;
    } else {
      return [];
    }
  };

  /**
   * getPhysicianById
   * @param {name} string text input name referring physician in appointmentStatate
  */
  getPhysicianById = async _id => {
    //console.log(_id);
    const response = await this.props.client.query({
      query: GET_PHYSICIANBYID,
      variables: { _id }
    });
    if (response.data && response.data.getPhysician.success) {
      return response.data.getPhysician.data;
    } else {
      return [];
    }
  };


  /**
   * getPhysician
   * @param {name} string text input name referring physician in appointmentStatate
   */
  getPhysician = async (fullname, eq = false) => {
    const response = await this.props.client.query({
      query: GET_PHYSICIAN,
      variables: { fullname, eq }
    });
    if (response.data && response.data.getPhysician.success) {
      return response.data.getPhysician.data;
    } else {
      return [];
    }
  };
  savePhysician = async (obj) => {
    const { name, lastname, fullname, specialty, address, phone, email, status } = obj;
    const response = await this.props.createPhysician({
      variables: { name, lastname, fullname, specialty, address, phone, email, status }
    });
    if (response.data && response.data.createPhysician.success) {
      return response.data.createPhysician.data;
    } else {
      return 'error';
    }
  }
  /**
   * saveReferringPhysician
   * @param {name} string text input name referring physician in appointmentStatate
   */
  saveReferringPhysician = async (appointment, fullname) => {
    const response = await this.props.createReferringPhysician({
      variables: { _idAppointment: appointment._id, fullname }
    });
    if (response.data && response.data.createReferringPhysician.success) {
      return response.data.createReferringPhysician.data;
    } else {
      return 'error';
    }
  };
  /**
   * getProgramTypes
   * @param {page} int number page
   * @param {perPage} int cant data for page
   */
  getProgramTypes = async (page = 1, perPage = 30) => {
    this.setState({ isLoader: true });
    const response = await this.props.client.query({
      query: GET_PROGRAM_TYPES,
      variables: { page, perPage }
    });
    if (response.data && response.data.getProgramTypes.success) {
      const allProgramTypes = response.data.getProgramTypes.data;
      this.setState({ isLoader: false });
      return allProgramTypes;
    }
    this.setState({ isLoader: false });
    return [];
  };
  /**
   * loadFieldsScanner
   * @param {object} object data load scanner
   */
  loadFieldsScanner = ({
    dni = '',
    name1 = '',
    name2 = '',
    name3 = '',
    gender = '',
    typeNit = '',
    birthDay = '',
    lastName1 = '',
    lastName2 = '',
    lastName3 = ''
  }) => {
    if (typeNit !== 'Cedula') return;
    const { currentPatient } = this.state;

    const name = `${name1} ${name2} ${name3}`.trim();
    const lastName = `${lastName1} ${lastName2} ${lastName3}`.trim();
    const type_dni = 'CC';

    birthDay = `${birthDay.slice(0, 4)}-${birthDay.slice(
      4,
      6
    )}-${birthDay.slice(6, 8)}`;

    this.setState({
      currentPatient: {
        ...currentPatient,
        dni,
        name,
        gender,
        type_dni,
        birthDay,
        lastName
      }
    });
  };

  /**
   * resetCurrentDataAppoinment
   */
  resetCurrentDataAppoinment = () => {
    this.setState({ appointmentsService: {} });
  };
  validate = async (variables, page) => {
    const { errors, patientErrors, errorMessage } = await Validate(
      variables,
      page
    );
    this.setState({ patientErrors, errorMessage, successMessage: false });
    return errors.length > 0 ? true : false;
  };
  savePatient = async () => {
    let { currentPatient } = this.state;
    const { photo } = this.state;
    const page = this.props.history.location.pathname;

    if (photo && typeof photo === 'object') {
      currentPatient = { ...currentPatient, ...photo };
    }
    if (photo && typeof photo === 'string') {
      currentPatient.photo = photo;
    }
    const variables = Object.assign({}, currentPatient);

    if (await this.validate(variables, page)) {
      return false;
    }
    let response;
    if (currentPatient._id) {
      response = await this.props.updatePatient({
        variables
      });
      response = response.data.updatePatient;
    } else {
      response = await this.props.createPatient({
        variables
      });
      response = response.data.createPatient;
    }
    localStorage.removeItem('canExitEdit');
    localStorage.removeItem('clickSidebar');

    if (response.success) {
      this.setState({
        successMessage: `¡Paciente ${currentPatient._id ? 'guardado' : 'creado'
          } exitosamente!`,
        errorMessage: ''
      });

      // si guardamos un paciente desde availability ( agendar desde disponibilidad )
      // obtenemos us id y cargamos su info

      if (document.location.pathname.indexOf('availability') > -1) {
        // this.selectedTypeHeadPatient(response.data._id);
        this.setState({
          patientAppointment: { ...variables, _id: response.data._id }
        });
      }
    } else {
      this.setState({
        successMessage: false,
        errorMessage: response.errors[0].message
      });
    }

    if (response.success) {
      if (currentPatient._id) {
        setTimeout(() => {
          this.setState({
            successMessage: '',
            disableSubmit: false
          });
          if (document.location.pathname.indexOf('availability') === -1) {
            this.props.history.push(`/patients/${currentPatient._id}`);
          }
        }, 2000);
      } else {
        setTimeout(() => {
          this.setState({ disableSubmit: false });
          // si nos encontramos en ver diponibilidad de citas y necesitamos
          // crear un paciente. esta validacion es para que no salte a patients al
          // guardar el nuevo paciente
          if (document.location.pathname.indexOf('availability') > -1) {
            this.setState({
              showNewPatientAvailability: false,
              currentPatient: {
                contactPreference: 'mobile'
              }
            });
          } else {
            this.props.history.push('/patients');
          }
        }, 2000);
      }
    }
  };
  handleShowNewPatientAvailability = showNewPatientAvailability => {
    this.setState({ showNewPatientAvailability });
  };
  clearErrors = () => {
    this.setState({
      patientErrors: {},
      errorMessage: false
    });
  };
  updateField = (name, { value, aux, radioName }) => {

    const { currentPatient, currentAppointments } = this.state;
    // localStorage.setItem('canExitEdit', true);
    this.clearErrors();
    if (name === 'entityService') {
      try {
        const arg = {
          _id: this.currentReScheduleAppointment._id,
          patient: currentPatient._id,
          entity: value
        };
        this.updateEpsAppointment(arg);
        if (currentAppointments.length) {
          currentAppointments.forEach((appointment) => {
            if (appointment._id === this.currentReScheduleAppointment._id) {
              var entidad = document.getElementById('entityService');
              var name = entidad.options[entidad.selectedIndex].innerHTML;
              appointment.entity.nombre = name;
            }

          });
        }
      } catch (err) {
        console.log(err);
      }


      this.setState({ entityService: value });
    } else if (radioName) {
      this.setState({ [radioName]: aux });
    } else {
      this.setState({
        currentPatient: { ...currentPatient, [name]: value }
      });
    }
  };
  renderText = ({
    name,
    input,
    label,
    pattern,
    optional,
    disabled = false,
    className = '',
    labelActive,
    disablePaste
  }) => {
    // const disabled = this.props.disabledFields
    // 	? this.props.disabledFields[name]
    // 	: false;
    const { patientErrors, currentPatient } = this.state;

    const max = input.type === 'date' ? inputPastMax() : null;
    const classError = patientErrors[name] ? 'wrong-input' : '';
    // onFocus={this.clearErrors} 1994-02-21

    return (
      <div className={`cell-form ${classError} ${className}`}>
        <TextInput
          // iconAfter={disabled ? 'contrasena' : false}
          max={max}
          disabled={disabled}
          optional={optional}
          labelActive={labelActive}
          value={
            currentPatient && currentPatient[name] ? currentPatient[name] : ''
          }
          type={input.type}
          name={name}
          label={label}
          pattern={pattern}
          updateField={this.updateField}
          disablePaste={disablePaste && disablePaste}
        />
      </div>
    );
  };
  renderTextarea = (
    { labelActive, name, label, without_blank, className = '' },
    options
  ) => {
    const { patientErrors, currentPatient } = this.state;
    const classError = patientErrors[name] ? 'wrong-input' : '';

    return (
      <div key={1} className={`gridTextarea ${classError} ${className}`}>
        <label htmlFor={name} className="active">
          {label}
        </label>
        <textarea
          without_blank={without_blank}
          name={name}
          label={label}
          placeholder={labelActive}
          onChange={e => {
            this.updateField(name, { value: e.target.value });
          }}
          options={options}
          defaultValue={
            currentPatient && currentPatient[name]
              ? currentPatient[name].toString()
              : ''
          }
        />
      </div>
    );
  };

  renderSelect = (params, options) => {
    const {
      labelActive,
      name,
      label,
      without_blank,
      className = '',
      disabled,
      classLabel = ''
    } = params;
    //if (disabled) console.log("params select", params); // eslint-disable-line

    let value = '';
    const { patientErrors, currentPatient, entityService } = this.state;
    const classError = patientErrors[name] ? 'wrong-input' : '';

    // onFocus={this.clearErrors}
    if (currentPatient && currentPatient[name]) {
      if (typeof currentPatient[name] === 'object') {
        value = currentPatient[name]._id.toString();
      } else {
        value = currentPatient[name].toString();
      }
    }
    if (name === 'entityService') value = entityService;
    return (
      <div className={`cell-form ${classError} ${className}`}>
        <SelectInput
          // iconAfter={disabled ? 'contrasena' : false}
          name={name}
          value={value}
          label={label}
          options={options}
          disabled={disabled}
          classLabel={classLabel}
          updateField={this.updateField}
          without_blank={without_blank}
        />
      </div>
    );
  };

  renderSelectSearch = (field) => {
    const { name, label, input: { options } } = field;
    const value = options.find(opt => opt.value === this.state.currentPatient[name])

    const { patientErrors } = this.state;
    const classError = patientErrors[name] ? 'wrong-input' : '';
    return (
      <div className={`cell-form ${classError}`} style={{zIndex: 3}}>
        <SelectSearch
          options={options}
          value={value}
          onChange={option => this.updateField(name, { value: option.value })}
          placeholder={label}
        />
      </div>
    )
  }

  /**
   * renderTypeHead
   * @param {name, label, multiple} object
   * 		{name} string name field
   * 		{label} string label field
   * 		{multiple} boolean is multiple field
   * @param {options} array array options list
   * @param {onInputChange} function function type in input
   * @return {FormTypeHead}
   */

  renderTypeHead = (
    { name, label, multiple = false, className = '' },
    options,
    onInputChange = () => { }
  ) => {
    const {
      patientErrors,
      currentPatient,
      entityService,
      patientAppointment
    } = this.state;
     
    let value = [];

    if (currentPatient && currentPatient[name] && currentPatient[name] !== '') {
      value = [{ _id: currentPatient[name], name: currentPatient[name] }];
    }

    const classError = patientErrors[name] ? 'wrong-input' : '';

    if (name === 'patientTypeHead')
      value = patientAppointment ? [patientAppointment] : [];

    if (name === 'entityService') value = entityService;
    
    return (
      <div className={`cell-form ${classError} ${className}`}>
        <FormTypeHead
          labelKey="name"
          name={name}
          label={label}
          options={options}
          multiple={multiple}
          onChange={value => {
            this._onSelectedTypeHead(name, value);
          }}
          onInputChange={onInputChange}
          selectedOptions={value}
        />
      </div>
    );
  };

  /**
   * _onSelectedTypeHead
   * @param {name} string name parameter in currentPatient
   * @param {value} string new value for name parameter in currentPatient
   */
  _onSelectedTypeHead = (name, value) => {
    this.clearErrors();
    
    const { currentPatient } = this.state;

    if (value.length === 0) {
      this.setState({ currentPatient: { ...currentPatient, [name]: '' } });
      return;
    }
    
    this.setState({
      currentPatient: {
        ...currentPatient,
        [name]: value[value.length - 1]._id
      }
    });
  };

  /**
   * renderRadio
   * @param {name, label, input} object
   * 		{name} string name field
   * 		{label} string label field
   * 		{options} array options list
   * @return {ComponentRadio}
   */
  renderRadio = ({ name, label, input: { options }, className = '' }) => {
    const { patientErrors, currentPatient } = this.state;

    let valueParameter = '';
    if (currentPatient && currentPatient[name] && currentPatient[name] !== '') {
      valueParameter = currentPatient[name];
    }
    const classError = patientErrors[name] ? 'wrong-input' : '';
    return (
      <div className={`cell-form ${classError} radio ${className}`}>
        <div className="form-group">
          <div className="form-inline input-field">
            {label && (
              <label htmlFor={name} className="active">
                {label}
              </label>
            )}
            <div className="container-radio">
              {options.map((item, index) => {
                const checked = item._id === valueParameter ? true : false;
                return (
                  <div key={`radio-${index}`}>
                    {name && <label htmlFor={name}>{item.name}</label>}
                    <input
                      id={item._id}
                      type="radio"
                      name={name}
                      value={item._id}
                      checked={checked}
                      onChange={() => {
                        this.updateField(name, { value: item._id });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleShowAppointmentSchedule = () => {
    this.currentReScheduleAppointment = null;
    this.setState({
      selectedDay: new Date(new Date().getTime()).setHours(0, 0, 0, 0),
      entityService: '',
      selectedSedeId: '',
      currentServices: [],
      selectedServices: [],
      serviceInputValue: '',
      successAppointments: false,
      showAppointmentSchedule: true
    });
  };
  handleselectedAppointmentsService = async obj => {

    const service = obj.service._id ? obj.service._id : obj.service;
    const { selectedAppointmentsService, appointmentsService } = this.state;

    if (selectedAppointmentsService[service] === obj.time) {

      delete selectedAppointmentsService[service];
      delete appointmentsService[service];

      await this.setState({
        selectedAppointmentsService,
        appointmentsService
      });
    } else {
      selectedAppointmentsService[service] = obj.time;
      appointmentsService[service] = obj;

      await this.setState({
        selectedAppointmentsService,
        appointmentsService
      });
    }

  };
  handleCalendarScheduleModal = async (value, serviceId) => {
    if (!value) {
      this.setState({ calendarScheduleModal: value, calendarData: {} });
    }
    if (!serviceId) return;

    const { selectedDay, appointmentsService } = this.state;
    const availableSpaces = await this.getAvailableSpaces(serviceId);

    //console.log(this.state);
    const currentService = appointmentsService[serviceId];

    const appointments = (await this.props.client.query({
      query: getMultipleAppointmentsBySpaces({ spaces: availableSpaces.map(space => space._id), day: selectedDay, lastState: 'agendada' }),
      variables: {}
    })).data;

    const events = [];
    const resourceMap = availableSpaces.map(space => { return { resourceId: space._id, resourceTitle: space.name }; });

    availableSpaces.forEach((space, index) => {
      const event = {
        id: serviceId + index,
        resourceId: space._id,
        selectedHour: true,
        title: 'Hora seleccionada',
        start: new Date(parseInt(currentService.time)),
        end: new Date(parseInt(currentService.time) + currentService.duration * 60000)
      };

      events.push(event);

    });

    for (const key in appointments) {
      if (appointments.hasOwnProperty(key)) {
        const spaceAppointment = appointments[key];

        spaceAppointment.data.forEach((appointment, index) => {

          const event = {
            id: index,
            resourceId: appointment.space._id,
            title: appointment.service.name,
            start: new Date(parseInt(appointment.time)),
            end: new Date(parseInt(appointment.time) + appointment.duration * 60000)
          };

          events.push(event);

        });

      }
    }

    const calendarData = {
      events,
      resourceMap,
      defaultDate: new Date(selectedDay)
    };

    this.setState({ calendarScheduleModal: value, calendarData });
  }

  getAvailableSpaces = async (serviceId) => {
    if (!serviceId) return;
    let {
      appointmentsService,
      selectedAppointmentsService,
      servicesAppointments } = this.state;

    let spaces = [];
    const newStateAppointmentsService = {};

    const day = moment(selectedAppointmentsService[serviceId])
      .startOf('day')
      .valueOf();
    let recursos = _.find(servicesAppointments[serviceId][day]['morning'], {
      time: appointmentsService[serviceId].time
    });
    if (recursos) {
      newStateAppointmentsService[serviceId] = recursos;
    }
    recursos = _.find(servicesAppointments[serviceId][day]['afternoon'], {
      time: appointmentsService[serviceId].time
    });
    if (recursos) {
      newStateAppointmentsService[serviceId] = recursos;
    }

    appointmentsService = newStateAppointmentsService;

    spaces = [...spaces, ...appointmentsService[serviceId].space];
    spaces = _.uniq(spaces);

    const spacesData = (await this.props.client.query({
      query: GET_RESOURCE_APPOINTMENT,
      variables: { spaces: JSON.stringify(spaces) }
    })).data.getResourceAppointment.data;

    return spacesData;
  }

  /*
   * Obtenemos los doctores y salas donde se puede dar la prestacion
   */
  handleShowModalSchedule = async showModalSchedule => {
    let {
      dataAppointmentsService,
      appointmentsInfo,
      servicesAppointments,
      blockResource,
      selectedAppointmentsService,
      appointmentsService,
      currentPatient,
      selectedSedeId,
      patientAppointment
    } = this.state;
    let cancel = null;
    var { userId: user } = getUserFromToken();
    let doctors = [],
      spaces = [];
    if (Object.keys(appointmentsService).length === 0) {
      showModalSchedule = false;
    }
    if (showModalSchedule) {
      const newStateAppointmentsService = {};
      Object.keys(appointmentsService).forEach(key => {
        const day = moment(selectedAppointmentsService[key])
          .startOf('day')
          .valueOf();
        let recursos = _.find(servicesAppointments[key][day]['morning'], {
          time: appointmentsService[key].time
        });
        if (recursos) {
          newStateAppointmentsService[key] = recursos;
        }
        recursos = _.find(servicesAppointments[key][day]['afternoon'], {
          time: appointmentsService[key].time
        });
        if (recursos) {
          newStateAppointmentsService[key] = recursos;
        }
      });
      appointmentsService = newStateAppointmentsService;
      // buscamos los doctores y salas de los servicios
      Object.keys(appointmentsService).forEach(key => {
        if (appointmentsService[key].doctors)
          doctors = [...doctors, ...appointmentsService[key].doctors];
        spaces = [...spaces, ...appointmentsService[key].space];
      });
      doctors = _.uniq(doctors);
      spaces = _.uniq(spaces);
      const users = await this.props.client.query({
        query: GET_USER_DOCTORS,
        variables: { doctors: JSON.stringify(doctors) }
      });

      const spaces_Data = await this.props.client.query({
        query: GET_RESOURCE_APPOINTMENT,
        variables: { spaces: JSON.stringify(spaces) }
      });

      dataAppointmentsService['spaces'] =
        spaces_Data.data.getResourceAppointment.data;
      dataAppointmentsService['doctors'] = users.data.getUserDoctors.data;
    } else {
      cancel = true;
      // si se camcela entramos a limbiar las citas en db
      Object.keys(appointmentsInfo).forEach(async key => {
        // if (appointmentsInfo[key]._id) _id.push(appointmentsInfo[key]._id);
        if (appointmentsInfo[key]._id) {
          if (document.location.pathname.indexOf('availability') > -1) {
            currentPatient = patientAppointment;
          }
          await this.props.deleteBookAppointment({
            variables: {
              ...appointmentsInfo[key],
              patient: currentPatient._id,
              sede: selectedSedeId,
              user
            }
          });
        }
      });
      dataAppointmentsService = {};
      appointmentsInfo = {};
      blockResource = [];
      selectedAppointmentsService = {};
      appointmentsService = {};
    }
    // si estamos dentro un paciente y reagendamos entramos a la modal
    // si estamos en el menu de disponibilidad y agendamos noas abre
    // otra vista
    if (document.location.pathname.indexOf('availability') > -1) {
      showModalSchedule = false;
      if (cancel === true) {
        this.props.history.push('/availability');
      } else {
        this.props.history.push('/availability/schedule');
      }
    }

    this.setState({
      selectedAppointmentsService,
      appointmentsService,
      showModalSchedule,
      dataAppointmentsService,
      appointmentsInfo,
      blockResource
    });

  };

  /**
   * onCalendarChange
   */
  onCalendarChange = (date, service) => {
    const selectedDay = new Date(moment(date).toDate()).getTime();
    const currentWeek = this.getWeekDays(moment(date));
    const stateService = {
      ...this.state.appointments[service._id],
      calendarDate: moment(date),
      week: currentWeek
    };

    const appointments = {
      ...this.state.appointments,
      calendarDate: moment(date),
      [service._id]: stateService
    };
    this.setState(
      {
        appointments,
        date,
        selectedDay,
        selectedCalendarService: stateService
      },
      () => this.getAvailability()
    );
  };

  selectService = selectedServices => {

    selectedServices.map(item => {
      if (item['value']) {
        item['_id'] = item['value'];
        // delete item['value'];
      }
    });

    const appointments = {};

    selectedServices.forEach(service => {
      appointments[service._id] = this.state.appointments[service._id]
        ? { ...this.state.appointments[service._id] }
        : { calendarDate: moment(), week: this.getWeekDays(moment()) };
    });

    this.setState({
      selectedServices,
      currentServices: [],
      appointments,
      ...this.cleanStateAppoinment()
    });
  };

  selectSede = (sede, { aux: selectedSedeId }) => {
    this.setState({
      selectedSedeId,
      sede,
      ...this.cleanStateAppoinment()
    });
  };

  /*
   * Limpiar los estdos del horario de las prestaciones
   */
  cleanStateAppoinment = () => {
    return {
      errSaveAppointment: {},
      selectedAppointmentsService: {},
      appointmentsService: {},
      dataAppointmentsService: {},
      appointmentsInfo: {},
      recievedAppointments: false,
      servicesAppointments: {},
      successAppointments: false
    };
  };

  /**
   * loadServices type appointment prestations
   * @param {name} string name service filter
   * @param {perPage} int number services return for filter
   */
  loadServices = async (name, perPage = 10) => {
    this.setState({ currentServices: [] });
    const currentServices = await this.props.client.query({
      query: GET_SERVICES_NAMES,
      variables: {
        name,
        perPage
      }
    });
    this.setState({
      currentServices: currentServices.data.getServices.data,
      successAppointments: false
    });
  };

  updateMultipleInput = serviceInputValue => {
    // console.log('serviceInputValue :', serviceInputValue);
    if (serviceInputValue.length >= 3) {
      this.setState({ serviceInputValue }, () => {
        this.loadServices(serviceInputValue);
      });
    } else {
      this.setState({
        serviceInputValue,
        currentServices: [],
        successAppointments: false
      });
    }
  };

  getWeekDays = selectedDay => {
    const currentWeek = [];
    const day = selectedDay.isoWeekday(1);
    for (var i = 0; i < 7; i++) {
      const stringDay = days[day.day()];
      currentWeek.push({
        momentDay: day.clone(),
        title: stringDay + day.format(' D')
      });
      day.add(1, 'day');
    }
    return currentWeek;
  };

  /*
   * Buscamos la disponibilidad de las prestaciones
   */
  getAvailability = async (type, rescheduleAppointment) => {
    // await this.setState({
    // 	...this.cleanStateAppoinment()
    // });
    let { selectedServices: services } = this.state;
    const {
      date,
      spaceType: _resourceTypeWorkSpace,
      selectedSedeId: _sede,
      entityService
    } = this.state;

    if (services === '' || _sede === '' || entityService === '') {
      return false;
    }
    services = JSON.stringify([...services].map(service => service._id));

    const week = this.getWeekDays(date ? moment(date) : moment());
    let start = [...week]
      .shift()
      .momentDay.startOf('day')
      .toDate();
    // start el dia en que se esta buscando la cita, no buscar dias atras
    if (new Date(start).getTime() < new Date().getTime()) {
      start = moment(new Date().getTime())
        .startOf('day')
        .toDate();
    }
    const end = [...week]
      .pop()
      .momentDay.startOf('day')
      .toDate();
    const recievedAppointments = await this.props.client.query({
      query: GET_APPOINTMENTS_SCHEDULE,
      variables: {
        services,
        _sede,
        start,
        end,
        _resourceTypeWorkSpace
      }
    });
    if (recievedAppointments) {
      await this.getAppointmentHours(
        JSON.parse(services),
        JSON.parse(recievedAppointments.data.getAppointmentSchedule.data),
        type,
        rescheduleAppointment
      );

      if (type === 'subs') {
        // console.log('type :', type);
        // await this.handleShowModalSchedule(true);
      } else {
        this.querySubscription();
      }
      // nos subscribimos para obtener actualizaciones de las prestaciones que buscamos
    }
  };
  getAppointmentHours = async (
    services,
    recievedAppointments,
    type,
    rescheduleAppointment
  ) => {
    const { selectedDay } = this.state;
    let morning = [],
      afternoon = [];
    const { servicesAppointments } = this.state;

    for (let i = 0; i < services.length; i++) {
      // TODO:debuggin data get Hours

      const service = services[i];

      for (const spaceID in recievedAppointments[service]) {
        if (recievedAppointments[service].hasOwnProperty(spaceID)) {
          const space = recievedAppointments[service][spaceID];
          const selectedDayD = selectedDay;
          const selectedDayN = Number(
            moment(selectedDay, 'x')
              .endOf('day')
              .format('x')
          );
          const dayArr = [selectedDayD, selectedDayN];
          // console.log(dayArr);
          // if (!space[selectedDayD] && !space[selectedDayN]) {
          //   continue;
          // }
          for (var u in dayArr) {

            if (space[dayArr[u]] !== undefined) {

              for (let h = 0; h < space[dayArr[u]].length; h++) {

                const {
                  nurse,
                  doctors,
                  initialDay: initialDay_space,
                  finalDay: finalDay_space
                } = space[dayArr[u]][h];

                if (nurse) {
                  const { morning: m, afternoon: a } = await this.interval(
                    initialDay_space,
                    finalDay_space,
                    Number(space[dayArr[u]][h].duration),
                    spaceID,
                    null,
                    morning,
                    afternoon,
                    service,
                    dayArr[u]
                  );
                  morning = m;
                  afternoon = a;
                } else if (doctors) {
                  // console.log(doctors)
                  for (let d = 0; d < doctors.length; d++) {
                    const {
                      initialDay: initialDay_doctor,
                      finalDay: finalDay_doctor,
                      duration,
                      id_doctor
                    } = doctors[d];
                    let initialDay, finalDay;
                    if (
                      initialDay_space <= initialDay_doctor &&
                      finalDay_doctor <= finalDay_space
                    ) {
                      if (
                        initialDay_space == initialDay_doctor &&
                        finalDay_doctor < finalDay_space
                      ) {
                        initialDay = initialDay_doctor;
                        finalDay = finalDay_doctor;
                      }
                      if (initialDay_space < initialDay_doctor) {
                        initialDay = initialDay_doctor;
                        if (finalDay_doctor <= finalDay_space) {
                          finalDay = finalDay_doctor;
                        }
                      }
                      if (
                        initialDay_space == initialDay_doctor &&
                        finalDay_space == finalDay_doctor
                      ) {
                        initialDay = initialDay_space;
                        finalDay = finalDay_space;
                      }
                    }
                    if (
                      initialDay_space > initialDay_doctor &&
                      finalDay_doctor > finalDay_space
                    ) {
                      initialDay = initialDay_space;
                      finalDay = finalDay_space;
                    }
                    if (
                      initialDay_space <= initialDay_doctor &&
                      finalDay_doctor > finalDay_space &&
                      initialDay_doctor < finalDay_space
                    ) {
                      initialDay = initialDay_doctor;
                      finalDay = finalDay_space;
                    }
                    if (
                      initialDay_space > initialDay_doctor &&
                      finalDay_space > initialDay_space &&
                      finalDay_doctor <= finalDay_space
                    ) {
                      initialDay = initialDay_space;
                      finalDay = finalDay_doctor;
                    }
                    if (
                      new Date(initialDay) === 'Invalid Date' ||
                      new Date(finalDay) === 'Invalid Date'
                    ) {
                      continue;
                    }
                    const { morning: m, afternoon: a } = await this.interval(
                      initialDay,
                      finalDay,
                      Number(duration),
                      spaceID,
                      id_doctor,
                      morning,
                      afternoon,
                      service,
                      selectedDay
                    );
                    morning = m;
                    afternoon = a;
                  }
                }
              }
            }
          }
        }
      }
      morning = _.orderBy(morning, ['time']);
      afternoon = _.orderBy(afternoon, ['time']);

      if (!servicesAppointments[service]) servicesAppointments[service] = {};
      servicesAppointments[service][selectedDay] = { morning, afternoon };
      morning = [];
      afternoon = [];
    }
    if (type === 'reschedule') {
      const time = parseInt(rescheduleAppointment.time);
      // Estos nos abria el modal en el horairio que esta la cita que se va a
    }
    this.setState({
      successAppointments: true,
      servicesAppointments,
      recievedAppointments
    });
  };
  interval = async (
    initialDay,
    finalDay,
    duration,
    spaceID,
    id_doctor,
    morning,
    afternoon,
    service,
    selectedDay
  ) => {
    let diff = finalDay - initialDay;
    diff = diff / (1000 * 60) + duration;

    let hour = initialDay;
    for (let i = duration; i <= diff; i += duration) {
      // console.log('diff :', duration);

      if (
        new Date(hour).getTime() >= finalDay ||
        new Date(
          moment(hour)
            .add(duration, 'minute')
            .toDate()
        ).getTime() > finalDay
      ) {
        continue;
      }

      const time = new Date(hour).getTime();
      const h = new Date(hour).getHours();
      let m = new Date(hour).getMinutes();

      if (m < 10) m = `0${m}`;
      if (h < 12) {
        const index = _.findIndex(morning, { time });
        // console.log(index, morning.length);
        if (index > -1) {
          if (_.indexOf(morning[index].space, spaceID) === -1)
            morning[index].space = [...morning[index].space, spaceID];
          if (
            morning[index].doctors &&
            _.indexOf(morning[index].doctors, id_doctor) === -1
          )
            morning[index].doctors = [...morning[index].doctors, id_doctor];
        } else {
          morning.push({
            space: [spaceID],
            doctors: id_doctor ? [id_doctor] : null,
            service,
            day: selectedDay,
            time,
            hour: `${h}:${m}`,
            duration
          });
        }
      } else {
        const index = _.findIndex(afternoon, { time });
        if (index > -1) {
          if (_.indexOf(afternoon[index].space, spaceID) === -1)
            afternoon[index].space = [...afternoon[index].space, spaceID];
          if (
            afternoon[index].doctors &&
            _.indexOf(afternoon[index].doctors, id_doctor) === -1
          )
            afternoon[index].doctors = [...afternoon[index].doctors, id_doctor];
        } else {
          afternoon.push({
            space: [spaceID],
            doctors: id_doctor ? [id_doctor] : null,
            service,
            day: selectedDay,
            time,
            hour: `${h > 12 ? h - 12 : h}:${m}`,
            duration
          });
        }
      }

      hour = moment(hour)
        .add(duration, 'minute')
        .toDate();
    }

    // borrar las horas menores a la hora actual
    if (
      new Date(initialDay).setHours(0, 0, 0, 0) ===
      new Date(new Date().getTime()).setHours(0, 0, 0, 0)
    ) {
      const currentHour = moment().format('x');
      morning = morning.filter(schedule => schedule.time > currentHour);
      afternoon = afternoon.filter(schedule => schedule.time > currentHour);
    }


    return { morning, afternoon };
  };
  toggleConfirmationModal = () => {
    const confirmationModal = !this.state.confirmationModal;
    this.setState({ confirmationModal });
    if (!confirmationModal) {
      localStorage.removeItem('clickSidebar');
      // localStorage.removeItem('canExitEdit');
    }
    if (!localStorage.getItem('canExitEdit')) this.cancelRedirect();
  };

  cancelRedirect = () => {
    localStorage.removeItem('canExitEdit');
    localStorage.removeItem('clickSidebar');
    this.setState({ confirmationModal: false });
    this.exitService();
  };

  exitService = () => {
    this.props.history.push(
      `/patients/${this.state.currentPatient._id ? this.state.currentPatient._id : ''
      }`
    );
  };
  changeSelectedDay = async selectedDay => {
    this.setState({ selectedDay }, () => {
      const { selectedServices, recievedAppointments } = this.state;
      const services = [...selectedServices].map(service => service._id);

      this.getAppointmentHours(services, recievedAppointments);
    });
  };
  changeSelectedDateExtra = async dateExtra => {
    this.setState({ dateExtra });
  };

  handleRollbackStateAppointment =  async () => {
    this.setState({ isLoader: true });
    
    const { appointmentsService: appointment } = this.state;

    await this.props.rollbackStateAppointment({
      variables: {
        _idAppointments: appointment._id
      }
    })

    await this.getPatient();

    await this.getStateAppointments(appointment);
  }

  handleVisibilityResultForm = () => {
    this.setState({ showPatientResultForm: !this.state.showPatientResultForm })
  }

  sendResult = async ({
    file, 
    selectedReferringPhysician, 
    idSelectedReferringPhysician,
    readerPhysician,
    makerPhysician
  }) => {
    const { 
      appointmentsService: appointment,
      currentPatient: { type_dni, dni, email, mobile, name, lastName }
    } = this.state;

    const {  
      _id: appointmentId,
      day,
      serviceFamily : { resultsServiceId }
    } = appointment;        

    await this.createPatientR({ dni, email, mobile, name, lastName });

    await fetchWithTokenResults({
      endpoint: `wp-json/mapa/v1/resultados`,
      method: "POST",
      data: {
        usuario_documento_tipo: type_dni,
        usuario_documento: dni,
        usuario_nombre: `${name} ${lastName}`,
        ...(selectedReferringPhysician ? {  medico_nombre: selectedReferringPhysician } : {}),
        ...(idSelectedReferringPhysician ? { medico_id: idSelectedReferringPhysician } : {}),
        tipo_examen_id: resultsServiceId,
        fecha_examen: timestampToDate(day),
        archivo: new Blob([file], { type: 'application/pdf' })
      },
      content: "multipart/form-data"
    });    

    await this.props.deliveryAppointment({
      variables: {
        _id: appointmentId,
        ...(readerPhysician ? { readerPhysician } : {}),
        ...(makerPhysician ? { makerPhysician } : {})
      }
    })

    await this.getStateAppointments(appointment);

    this.handleVisibilityResultForm();
  }

  wildcardPatientValidation = async (patientId) => {
    let response = false;

    const apiResponse = await this.props.client.query({
      query: GET_WILDCARD_PATIENTS_DNIS
    });

    const { data, success } = apiResponse.data.getWildcardPatientsDnis;
    
    if (success) {
      response = data.indexOf(patientId) >= 0;
    }

    this.setState({ patientIsWildcard: response });
  }

  handleVisibilityBillingForm = () => {
    this.setState(prevState => ({
      showBillingForm: !prevState.showBillingForm
    }));
  }

  handleSetBillingData = (field, value) => {
    this.setState(prevState => ({
      billingData: {
        ...prevState.billingData,
        [field]: value
      }
    }));
  }

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          ...this.props,
          loadServices: this.loadServices,
          changeSelectedDay: this.changeSelectedDay,
          changeSelectedDateExtra: this.changeSelectedDateExtra,
          handleShowAppointmentSchedule: this.handleShowAppointmentSchedule,
          updateMultipleInput: this.updateMultipleInput,
          selectService: this.selectService,
          selectSede: this.selectSede,
          onCalendarChange: this.onCalendarChange,
          getAvailability: this.getAvailability,

          renderText: this.renderText,
          renderRadio: this.renderRadio,
          renderSelect: this.renderSelect,
          renderTextarea: this.renderTextarea,
          renderTypeHead: this.renderTypeHead,
          renderSelectSearch: this.renderSelectSearch,

          handleCalendarScheduleModal: this.handleCalendarScheduleModal,
          savePatient: this.savePatient,
          getPatient: this.getPatient,
          getPatientInfo: this.getPatientInfo,
          getStateAppointments: this.getStateAppointments,
          getAllStatesLeeRealiza: this.getAllStatesLeeRealiza,
          modalPhoto: this.modalPhoto,
          getScreenshot: this.getScreenshot,
          cancelRedirect: this.cancelRedirect,
          toggleConfirmationModal: this.toggleConfirmationModal,
          handleselectedAppointmentsService: this.handleselectedAppointmentsService,
          handleShowModalSchedule: this.handleShowModalSchedule,
          handleappointmentsInfo: this.handleappointmentsInfo,
          setAppointmentInfoObservation: this.setAppointmentInfoObservation,
          saveStateAppointments: this.saveStateAppointments,
          saveBookAppointment: this.saveBookAppointment,
          getAppointments: this.getAppointments,
          createPatientR: this.createPatientR,
          getPhysician: this.getPhysician,
          getPhysicianById: this.getPhysicianById,
          saveReferringPhysician: this.saveReferringPhysician,
          savePhysician: this.savePhysician,
          getProgramTypes: this.getProgramTypes,
          loadFieldsScanner: this.loadFieldsScanner,
          rescheduleAppointment: this.rescheduleAppointment,
          selectedTypeHeadPatient: this.selectedTypeHeadPatient,
          handleShowNewPatientAvailability: this.handleShowNewPatientAvailability,
          initCurrentPatient: this.initCurrentPatient,
          saveAppointementReasign: this.saveAppointementReasign,
          handleRollbackStateAppointment: this.handleRollbackStateAppointment,
          handlePatientUpdateSubscription: this.handlePatientUpdateSubscription,
          handlePatientUpdateUnsubscription: this.handlePatientUpdateUnsubscription,
          handleCanUpdatePatient: this.handleCanUpdatePatient,
          handleVisibilityResultForm: this.handleVisibilityResultForm,
          sendResult: this.sendResult,
          handleVisibilityBillingForm: this.handleVisibilityBillingForm,
          handleSetBillingData: this.handleSetBillingData,
          saveBillingAppoinment: this.saveBillingAppoinment
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

PatientProvider.propTypes = {
  client: PropTypes.object, // query graphql
  history: PropTypes.object, // change view react-router
  location: PropTypes.object, // location react-router
  children: PropTypes.element,
  updatePatient: PropTypes.func,
  createPatient: PropTypes.func,
  bookAppointment: PropTypes.func,
  saveBookAppointment: PropTypes.func,
  updateBookAppointment: PropTypes.func,
  updateEps: PropTypes.func,
  deleteBookAppointment: PropTypes.func,
  createStateAppointments: PropTypes.func,
  createReferringPhysician: PropTypes.func,
  createPhysician: PropTypes.func,
  saveBillingAppoinment: PropTypes.func,
};

export default compose(
  graphql(CREATE_PATIENT, { name: 'createPatient' }),
  graphql(CREATE_PATIENTR, { name: 'createPatientR' }),
  graphql(BOOK_APPOINTMENT, { name: 'bookAppointment' }),
  graphql(UPDATE_BOOK_APPOINTMENT, { name: 'updateBookAppointment' }),
  graphql(UPDATE_EPS_APPOINTMENT, { name: 'updateEps' }),
  graphql(DELETE_BOOK_APPOINTMENT, { name: 'deleteBookAppointment' }),
  graphql(SAVE_BOOK_APPOINTMENT, { name: 'saveBookAppointment' }),
  graphql(UPDATE_PATIENT, { name: 'updatePatient' }),
  graphql(CREATE_STATE_APPOINTMENTS, { name: 'createStateAppointments' }),
  graphql(CREATE_REFERRING_PHYSICIAN, { name: 'createReferringPhysician' }),
  graphql(CREATE_PHYSICIAN, { name: 'createPhysician' }),
  graphql(ROLLBACK_STATE_APPOINTMENT, { name: 'rollbackStateAppointment' }),
  graphql(DELIVERY_APPOINTMENT, { name: 'deliveryAppointment' }),
  graphql(SAVE_BILLING_APPOINMENT, { name: 'saveBillingAppoinment' }),
)(withApollo(withRouter(PatientProvider)));

export const PatientConsumer = Consumer;

import React, { Component } from 'react';
import { AmpaConsumer } from '../../../providers/ampaProvider';
import Ampa from './index';

class AmpaEditable extends Component {
  
  render () {
    return <AmpaConsumer>{props => <Ampa {...props} />}</AmpaConsumer>;
  }
  
}

export default AmpaEditable;

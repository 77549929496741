import React, { Component } from 'react';
import './style.css';
import '../../../icomoon/style.css';

export default class File extends Component {
  constructor (props){
    super(props);
    const { labelActive } = props;
    this.state = {
      focus: false,
      active: labelActive? true: false
    };
  }

  toggleActive = () => {
    this.setState({ active: true, focus: true });
  };

  isEmpty = e => {
    if (e.target.value === ''){
      this.setState({ active: false, focus: false });
    }
  };
  render () {
    const { uploadFile, name, value, label, optional } = this.props;
    return (
      <div className="form-group">
        <div className="form-inline input-field">
          <input
            type="file"
            name="file"
            id="file"
            className="inputfile inputfile-6"
            onChange={(
              {
                target: {
                  validity,
                  files: [file]
                }
              } // validity.valid && mutate({ variables: { files } })
            ) => validity.valid && uploadFile(file)}
          />
          <label htmlFor="file" id="custom-file">
            <span>{value && value}</span>
            <label
              htmlFor="file"
              id="btnUpload"
              className="button ripple save-user button-sm bg-icon"
            >
              Examinar
            </label>{' '}
          </label>
          <label htmlFor={name} className={value !== undefined ? 'active' : ''}>
            {label} {optional && <span className="muted-text">(Opcional)</span>}
          </label>
        </div>
      </div>
    );
  }
}

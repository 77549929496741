/**
 * PhysicianProvider
 */
/* eslint-disable */
import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo, compose } from 'react-apollo';
import { GET_SERVICE, GET_SERVICES_NAMES } from '../graphql/types/servicesModule/queries';
import { GET_PHYSICIANBYID, GET_PHYSICIANS_LIST, SEND_RESULTS_PLATFORM_INVITATION } from '../graphql/types/physician/queries';
import { UPDATE_PHYSICIAN, CREATE_PHYSICIAN, DELETE_PHYSICIAN } from '../graphql/types/physician/mutations';
import { GET_RESOURCE, GET_RESOURCE_TYPES } from '../graphql/types/resourcesModule/queries';
import { CREATE_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from '../graphql/types/servicesModule/mutations';
import { GET_USER_SERVICE } from '../graphql/types/usersModule/queries';
import _ from 'lodash';
import download from 'downloadjs';
import { getSessionTokens } from '../utils/helpers';

export const context = createContext({ currentService: false });
const { Consumer, Provider } = context;
export const PhysicianConsumer = Consumer;

class PhysicianProvider extends Component {
  selfMountComponent = true;

  state = {
    currentService: undefined,
    errorMessage: '',
    successMessage: '',
    error: false,
    success: false,
    currentServices: [],
    serviceErrors: {},
    name: '',
    lastname: '',
    active: '',
    email: '',
    phone: '',
    mobile: '',
    address: '',
    description: '',
    documents: '',
    recommendations: '',
    disableSubmit: false,
    rooms: [],
    _resourceTypeEquip: false,
    _resourceTypeRooms: false,
    equipos: [],
    nurse: true,
    presentdoctor: null,
    presentdoctorsSelected: [],
    quantity: null,
    timeOut: null,

    data: [],
    total: 0,
    sort: {
      direction: 1,
      field: 'fullname'
    },
    perPage: 10,
    page: 1,
    filters: {
      dateFrom: '',
      dateTo: '',
      specialty: {
        label: 'Seleccione',
        value: ''
      },
      search: '',
      confirm: ''
    }
  };

  componentWillUnmount () {
    this.selfMountComponent = false;
  }

  componentDidMount = async () => {
    this.selfMountComponent = true;
    if (!localStorage.getItem('token')) {
      this.props.history.replace('/');
      return false;
    }
  };

  componentDidUpdate(nextProps, prevState) {
    const { sort, perPage, page, filters } = prevState;
    const { sort: newSort, perPage: newPerPage, page: newPage, filters: newFilters } = this.state;

    if (
      sort !== newSort ||
      perPage !== newPerPage ||
      page !== newPage ||
      !_.isEqual(filters, newFilters)
    ) {
      this.getPhysicians();
    }
  }

  getCurrentService = async _id => {
    let {
      name,
      lastname,
      status,
      specialty,
      identificacion,
      active,
      history_2,
      address,
      office,
      mobile,
      _resourceTypeRooms,
      _resourceTypeEquip,
      institution,
      email,
      phone,
      medicalrecord
    } = this.state;

    // console.log(_id);
    const result = await this.props.client.query({
      query: GET_PHYSICIANBYID,
      variables: {
        _id
      }
    });
    const physician = result.data ?
      result.data.getPhysicianById.data :
      result.loading ?
        undefined :
        false;
    let currentService = {};
    const hours = 0,
      minutes = 0;
    if (physician) {
      // console.log({ physician });
      name = physician[0].name;
      lastname = physician[0].lastname;
      specialty = physician[0].specialty;
      active = physician[0].active;
      status = physician[0].status;
      email = physician[0].email;
      phone = physician[0].phone;
      mobile = physician[0].mobile;
      address = physician[0].address;
      identificacion = physician[0].identificacion;
      office = physician[0].office;
      institution = physician[0].institution;
      medicalrecord = physician[0].medicalrecord;
      history_2 = physician[0].history;
      currentService = {
        ...physician,
        _id
      };
    } else {
      currentService = false;
    }

    this.setState({
      ...this.state,
      ...currentService,
      name,
      lastname,
      specialty,
      status,
      active,
      email,
      phone,
      mobile,
      address,
      identificacion,
      office,
      institution,
      history_2,
      medicalrecord
    });
  };

  updateField = (name, { value, aux, radioName }) => {
    localStorage.setItem('canExitEdit', true);
    this.clearErrors();
    if (radioName) {
      this.setState({
        [radioName]: aux
      });
    } else {
      console.log(name, value);
      this.setState({
        [name]: value
      }, () => {

      });
    }
  };

  updatePresentDoctorSelected = async (value) => {
    await this.setState({ presentdoctorsSelected: value });
  }

  clearErrors = () => {
    this.setState({
      error: false,
      errorMessage: '',
      serviceErrors: {},
      disableSubmit: false
    });
  };

  validate = ({ name, lastname, status, identificacion, specialty, office, medicalrecord, institution, address, phone, mobile, email }) => {
    const errors = [];
    const serviceErrors = {};
    let errorMessage = '';
    if (!name) {
      errors.push('name');
      serviceErrors['name'] = true;
      errorMessage = 'EL nombre del médico remitente debe existir';
    }
    if (!lastname) {
      errors.push('lastname');
      serviceErrors['lastname'] = true;
      errorMessage = 'El apellido del médico remitente debe existir';
    }
    if (!status) {
      errors.push('status');
      serviceErrors['status'] = true;
      errorMessage = 'El Estado del médico debe existir';
    }

    if (!identificacion) {
      errors.push('identificacion');
      serviceErrors['identificacion'] = true;
      errorMessage = 'La identificación del médico debe existir';
    }
    
    if (!specialty) {
      errors.push('specialty');
      serviceErrors['specialty'] = true;
      errorMessage = 'La especialidad del médico debe existir';
    }

    if (!office) {
      errors.push('office');
      serviceErrors['office'] = true;
      errorMessage = 'La oficina del médico debe existir';
    }

    if (!medicalrecord) {
      errors.push('medicalrecord');
      serviceErrors['medicalrecord'] = true;
      errorMessage = 'El registro médico debe existir';
    }

    if (!institution) {
      errors.push('institution');
      serviceErrors['institution'] = true;
      errorMessage = 'La institución del médico debe existir';
    }

    if (!address) {
      errors.push('address');
      serviceErrors['address'] = true;
      errorMessage = 'La dirección de trabajo del médico debe existir';
    }

    if (!phone) {
      errors.push('phone');
      serviceErrors['phone'] = true;
      errorMessage = 'El teléfono del médico debe existir';
    }

    if (!mobile) {
      errors.push('mobile');
      serviceErrors['mobile'] = true;
      errorMessage = 'El móvil del médico debe existir';
    }

    if (!email) {
      errors.push('email');
      serviceErrors['email'] = true;
      errorMessage = 'El correo electrónico del médico debe existir';
    }

    this.setState({ serviceErrors, errorMessage });
    return errors.length > 0 ? false : true;
  };

  saveService = async () => {

    const {
      _id,
      name,
      lastname,
      medicalrecord_sicor,
      email,
      phone,
      mobile,
      address,
      specialty,
      identificacion,
      medicalrecord,
      office,
      institution,
      status
    } = this.state;

    if (this.validate(this.state)) {

      const variables = {
        _id,
        name,
        lastname,
        specialty,
        status,
        medicalrecord_sicor,
        email,
        phone,
        mobile,
        address,
        identificacion,
        medicalrecord,
        office,
        institution
      };
      this.setState({ disableSubmit: true });
      let result;
      if (_id) {

        result = await this.props.updatePhysician({ variables });
        result = result.data.updatePhysician;
      } else {
        result = await this.props.createPhysician({
          variables
        });
        result = result.data.createPhysician;
      }
      localStorage.removeItem('canExitEdit');
      localStorage.removeItem('clickSidebar');
      if (result.success) {
        this.setState({
          success: true,
          error: false,
          successMessage: `!Medico remitente ${_id ? 'guardado' : 'creado'} exitosamente!`,
          errorMessage: ''
        });
      } else {
        this.setState({
          success: false,
          error: true,
          errorMessage: result.errors[0].message
        });
      }

      if (result.success && _id) {
        setTimeout(() => {
          this.setState({
            successMessage: '',
            success: false,
            disableSubmit: false
          });
          this.props.history.push(`/medicosremitentes/${_id}`);
        }, 2000);
      } else {
        setTimeout(() => {
          this.setState({ disableSubmit: false });
          this.props.history.push('/medicosremitentes');
        }, 2000);
      }
    } else {
      this.setState({
        success: false,
        error: true,
        successMessage: '',
        disableSubmit: false
      });
    }
  };

  getPhysicians = async () => {
    try {
      const { sort, perPage, page } = this.state;
      
      let response = await this.props.client.query({
        query: GET_PHYSICIANS_LIST,
        variables: { 
          page, 
          perPage,
          sort,
          filters: this.getFilters()
        }
      });

      response = response.data.getPhysiciansList;

      this.setState({ 
        data: response.data,
        total: response.total
      });

    } catch (error) {
      console.error('Error:', error);
    }
  }

  handlePageChange = page => {
    this.setState({ page });
  }

  handleRowsChange = (perPage, page) => {
    this.setState({ perPage });
  }

  handleSort = ({ sortField }, direction) => {
    this.setState({
      sort: {
        field: sortField,
        direction: direction === 'asc' ? 1 : -1
      }
    });
  }

  setFilter = (field, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: value
      }
    });
  }

  getFilters = () => {
    const { filters } = this.state;
    const { specialty } = filters;

    return {
      ...filters,
      ...(specialty ? { specialty: specialty.value } : {})
    }
  }

  deletePhysician = async physicianID => {

    const response = await this.props.deletePhysician({
      variables: {
        _id: physicianID
      }
    });

    return response.data.deletePhysician.success;
  }

  export = async () => {
    const url = `${ process.env.REACT_APP_URL_AGENDAMIENTO }`;
    const params = new URLSearchParams(this.getFilters());

    const response = await fetch(`${url}/physicians/export?${params}`);

    const blob = await response.blob();
    download(blob, 'report.xlsx');
  }

  exportStickers = async () => {
    const url = `${ process.env.REACT_APP_URL_AGENDAMIENTO }`;
    const params = new URLSearchParams(this.getFilters());

    const response = await fetch(`${url}/physicians/export-stickers?${params}`, {
      headers: {
          'x-token': getSessionTokens().token
      }
    });

    const blob = await response.blob();
    download(blob, 'stickers.pdf');
  }

  sendResultsPlatformInvitation = async id => {
    try {
      await this.props.client.query({
        query: SEND_RESULTS_PLATFORM_INVITATION,
        variables: { 
          id
        }
      });

    } catch (error) {
      
    }
  }

  render () {
    return ( <Provider value = {
      {
        ...this.state,
        saveService: this.saveService,
        deleteService: this.deleteService,
        getCurrentService: this.getCurrentService,
        updateField: this.updateField,
        loadServices: this.loadServices,
        clearErrors: this.clearErrors,
        updatePresentDoctorSelected: this.updatePresentDoctorSelected,
        validate: this.validate,
        getPhysicians: this.getPhysicians,
        handlePageChange: this.handlePageChange,
        handleRowsChange: this.handleRowsChange,
        handleSort: this.handleSort,
        setFilter: this.setFilter,
        deletePhysician: this.deletePhysician,
        export: this.export,
        exportStickers: this.exportStickers,
        sendResultsPlatformInvitation: this.sendResultsPlatformInvitation
      }
    }
    > { this.props.children } </Provider>
    );
  }
}

PhysicianProvider.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.element,
  createService: PropTypes.func,
  updatePhysician: PropTypes.func
};

export default compose(
  graphql(CREATE_PHYSICIAN, { name: 'createPhysician' }),
  graphql(UPDATE_PHYSICIAN, { name: 'updatePhysician' }),
  graphql(DELETE_PHYSICIAN, { name: 'deletePhysician' }),
  graphql(DELETE_SERVICE, { name: 'deleteService' })
)(withRouter(withApollo(PhysicianProvider)));
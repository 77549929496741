import React, { useContext, useEffect } from 'react';
import { context } from '../../../providers/billingProvider';
import CupsTableList from './cupsTableList';
import Modal from '../../../components/general/modal';
import CupsForm from './cupsForm';
import CupsFilter from './cupsFIlter';
import Alert from '../../../components/general/alert';

const CupsList = () => {
    const { 
        cups, 
        getCups, 
        hideCupsForm, 
        showCupsForm,
        getServices,
        getSecurers,
        deleteCups,
        hideDeleteVerification,
        resetCupsForm
    } = useContext(context);

    const { pagination, filters, showForm, deleteVerification } = cups;

    const handleDelete = async () => {
        await deleteCups();
        await getCups();
        hideDeleteVerification();
    }

    const handleCancelDelete = () => {
        hideDeleteVerification();
    }

    const handleShowCupsForm = () => {
        resetCupsForm();
        showCupsForm();
    }

    useEffect(() => {
        getCups();
        getServices();
        getSecurers();
    }, []);

    useEffect(() => {
        getCups();
    }, [pagination, filters]);

    return (
        <div className="app-main__inner">
            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div className="page-title-icon">
                            <i className="icon-medicosremitentes"></i>
                        </div>
                        <div> Codigos CUPS </div>
                    </div>

                    <div className="page-title-actions">
                        <button 
                            type="button" 
                            className="btn-shadow mr-3 btn btn-info" 
                            onClick={() => handleShowCupsForm()}
                        >
                            Crear Cups 
                            <i className="nav-link-icon lnr-inbox"></i>
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="cards no-shadow bg-transparent no-border rm-borders mb-3">
                        <div className="cards">
                            <div className="col-md-12 col-lg-12">
                                <CupsFilter />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-12 col-lg-12">
                        <div className="row">
                            <div className="col">
                                <div className="main-card mb-3 cardz">
                                    <CupsTableList />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal 
                    isOpenModal={showForm} 
                    title="Cups" 
                    onClose={() => hideCupsForm()}
                >
                    <CupsForm />
                </Modal>

                <Alert
                    show={deleteVerification}
                    title='Estas seguro?'
                    message='Es irreversible'
                    confirmButtonText='Eliminar'
                    confirmButtonAction={() => handleDelete()}
                    cancelButtonText='Cancelar'
                    cancelButtonAction={() => handleCancelDelete()}
                />
            </div>
        </div>
    )
}

export default CupsList;
/**
 * Separator
 */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Separator = ({ children, ...props }) => (
  <div {...props} className={`separator ${props.className}`}>
    <div className="parent-separator">
      <div className="separator-left">
        <div className="top"></div>
        <div className="bottom"></div>
      </div>
      <div className="separator-center">{children}</div>
      <div className="separator-right">
        <div className="top"></div>
        <div className="bottom"></div>
      </div>
    </div>
  </div>
);
Separator.propTypes = {
  children: PropTypes.any
};
Separator.defaultProps = {
  children: ''
};
export default Separator;

import React, { Component, createContext } from "react";
import { compose, graphql, withApollo } from "react-apollo";
import { GET_SPECIALTY } from "../graphql/types/specialty/queries";
import {
    CREATE_SPECIALTY, DELETE_SPECIALTY, UPDATE_SPECIALITY
  } from '../graphql/types/specialty/mutations';
import _ from 'lodash';

const context = createContext();
const { Consumer, Provider } = context;

class SpecialtyProvider extends Component {
    constructor () {
        super();
        
        this.state = {
            specialties: [],
            totalSpecialties: 0,
            pagination: {
                page: 1,
                perPage: 10
            },
            filters: {
                name: ''
            },
            sort: {
                direction: 1,
                field: 'name'
            },
            form: {
                name: '',
            },
            showModal: false,
            editionMode: false,
            deleteVerification: false,
            deleteConfirmation: false,
            specialtyIdToDelete: null
        }
    }

    getSpecialties = async () => {    
        const { filters, pagination, sort } = this.state;

        try {
            let apiResponse = await this.props.client.query({
                query: GET_SPECIALTY,
                variables: {
                    filters,
                    pagination,
                    sort
                }
            });
          
            apiResponse = apiResponse.data.getSpecialty;

            const { data: specialties, total: totalSpecialties } = apiResponse;

            this.setState({ 
                specialties,
                totalSpecialties
            });
    
        } catch (error) {}        
    }

    createSpecialty = async () => {
        const response = await this.props.createSpecialty({
            variables: this.getFormData()
        });

        return response;
    }

    updateSpecialty = async () => {
        const response = await this.props.updateSpecialty({
            variables: this.getFormData()
        });

        return response;
    }

    deleteSpecialty = async () => {
        const response = await this.props.deleteSpecialty({
            variables: {
                _id: this.state.specialtyIdToDelete
            }
        });

        this.setState({
            deleteVerification: false,
            physicianIdToDelete: null,       
            deleteConfirmation: true
        })

        return response;
    }

    handleDeleteVerification = async specialtyId => {
        this.setState({
          deleteVerification: true,
          specialtyIdToDelete: specialtyId
        });
    }
  
    handleCancelDelete = () => {
        this.setState({
            deleteVerification: false,
            physicianIdToDelete: null
        });
    }

    handleAcceptDelete = () => {
        this.setState({
            deleteConfirmation: false
        });
    }

    setPaginationData = (field, value) => {
        this.setState({ 
            pagination: {
                ...this.state.pagination,
                [field]: value
            }
        });
    }

    handlePageChange = page => {
        this.setPaginationData('page', page);
    }
    
    handleRowsChange = (perPage, page) => {
        this.setPaginationData('perPage', perPage);
    }

    setFilter = (field, value) => {
        this.setState({
          filters: {
            ...this.state.filters,
            [field]: value
          }
        });
    }

    handleSort = ({ sortField }, direction) => {
        this.setState({
          sort: {
            field: sortField,
            direction: direction === 'asc' ? 1 : -1
          }
        });
    }

    setField = (field, value) => {
        this.setState({
          form: {
            ...this.state.form,
            [field]: value
          }
        });
    }

    openModal = () => {
        this.setState({ showModal: true });
    }
    
    closeModal = () => {
        this.setState({ 
            editionMode: false,
            showModal: false
        });

        this.cleanFormData();
    }

    setFormData = data => {
        this.setState({
            form: {
                ...this.state.form,
                ...data
            }
        });
    }

    getFormData = () => {
        return this.state.form;
    }

    cleanFormData = () => {
        this.setFormData({
            name: '',
        });
    }

    setEditionMode = value => {
        this.setState({ editionMode: value })
    }

    componentDidUpdate(prevProps, prevState) {
        const { sort, pagination, filters } = prevState;
        const { sort: newSort, pagination: newPagination, filters: newFilters } = this.state;
    
        if (
            !_.isEqual(filters, newFilters) ||
            !_.isEqual(pagination, newPagination) ||
            !_.isEqual(sort, newSort)
        ) {
          this.getSpecialties();
        }
    }

    render = () => (
       <Provider
            value= {{
                ...this.state,
                getSpecialties: this.getSpecialties,
                createSpecialty: this.createSpecialty,
                updateSpecialty: this.updateSpecialty,
                deleteSpecialty: this.deleteSpecialty,
                handleDeleteVerification: this.handleDeleteVerification,
                handleCancelDelete: this.handleCancelDelete,
                handleAcceptDelete: this.handleAcceptDelete,
                handlePageChange: this.handlePageChange,
                handleRowsChange: this.handleRowsChange,
                setFilter: this.setFilter,
                handleSort: this.handleSort,
                setField: this.setField,
                setFormData: this.setFormData,
                openModal: this.openModal,
                closeModal: this.closeModal,
                setEditionMode: this.setEditionMode,
                cleanFormData: this.cleanFormData
            }}
       >
        { this.props.children }
       </Provider> 
    )
    
}

export {
    context,
    Consumer
};

export default compose(
    graphql(CREATE_SPECIALTY, { name: 'createSpecialty' }),
    graphql(UPDATE_SPECIALITY, { name: 'updateSpecialty' }),
    graphql(DELETE_SPECIALTY, { name: 'deleteSpecialty' })
)(withApollo(SpecialtyProvider));
import React from 'react';

const Alert = ({ 
    title,
    message, 
    confirmButtonText, 
    confirmButtonAction,
    cancelButtonText,
    cancelButtonAction,
    show
}) => {
    return (
        <div className='modal' tabIndex='-1' role='dialog' style={{ display: show ? 'block' : 'none' }}>
            <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                <div className="modal-header">
                    <h5 className="modal-title"> { title } </h5>
                </div>
                <div className='modal-bodyr' style={{ padding: '20px 30px' }}>
                    { message }
                </div>
                <div className='modal-footer'>
                    { confirmButtonText && confirmButtonAction && (
                        <button 
                            type='button' 
                            className='btn btn-primary' 
                            onClick={() => confirmButtonAction()} 
                            data-dismiss='modal'
                        >
                            { confirmButtonText }
                        </button>
                    ) }

                    { cancelButtonText && cancelButtonAction && (
                        <button 
                            type='button' 
                            className='btn btn-secondary' 
                            onClick={() => cancelButtonAction()} 
                            data-dismiss='modal'
                        >
                            { cancelButtonText }
                        </button>
                    ) }
                </div>
                </div>
            </div>
        </div>
    );
}

export default Alert;
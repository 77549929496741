import gql from 'graphql-tag';

export const BOOK_APPOINTMENT = gql`
	mutation bookAppointment(
		$day: String
		$doctor: ID
		$patient: ID!
		$hour: String
		$service: ID!
		$space: ID
		$entity: ID!
		$time: String
		$temp: Boolean
		$duration: Int
		$sede: ID!
		$user: ID!
	) {
		bookAppointment(
			day: $day
			doctor: $doctor
			hour: $hour
			entity: $entity
			service: $service
			space: $space
			time: $time
			temp: $temp
			duration: $duration
			patient: $patient
			sede: $sede
			user: $user
		) {
			data
			success
			errors {
				path
				message
			}
		}
	}
`;
export const UPDATE_EPS_APPOINTMENT = gql`
	mutation updateEps(
		$_id: ID!
		$patient: ID!
		$entity: ID!
	){
		updateEps(
			_id: $_id
			patient: $patient
			entity: $entity
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;
export const UPDATE_BOOK_APPOINTMENT = gql`
	mutation updateBookAppointment(
		$_id: ID!
		$patient: ID!
		$service: ID
		$entity: ID
		$day: String
		$doctor: ID
		$hour: String
		$space: ID
		$time: String
		$temp: Boolean
		$duration: Int
		$sede: ID
		$user: ID
	) {
		updateBookAppointment(
			_id: $_id
			day: $day
			doctor: $doctor
			hour: $hour
			service: $service
			space: $space
			entity: $entity
			time: $time
			temp: $temp
			duration: $duration
			patient: $patient
			sede: $sede
			user: $user
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;
export const DELETE_BOOK_APPOINTMENT = gql`
	mutation deleteBookAppointment(
		$_id: ID!
		$patient: ID!
		$service: ID!
		$entity: ID!
		$day: String
		$doctor: ID
		$hour: String
		$space: ID
		$time: String
		$temp: Boolean
		$duration: Int
		$sede: ID!
		$user: ID!
	) {
		deleteBookAppointment(
			_id: $_id
			day: $day
			doctor: $doctor
			hour: $hour
			service: $service
			space: $space
			entity: $entity
			time: $time
			temp: $temp
			duration: $duration
			patient: $patient
			sede: $sede
			user: $user
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;
export const SAVE_BOOK_APPOINTMENT = gql`
	mutation saveBookAppointment($_id: String!) {
		saveBookAppointment(_id: $_id) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const CREATE_STATE_APPOINTMENTS = gql`
	mutation createStateAppointments(
		$_idAppointments: ID!
		$_idPhysician: ID
    $leeValue: ID
    $realizaValue: ID
		$state: String
		$observations: String
	) {
		createStateAppointments(
			_idAppointments: $_idAppointments
			_idPhysician: $_idPhysician
      leeValue: $leeValue
      realizaValue: $realizaValue
			state: $state
			observations: $observations
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;
export const SUBSCRIPTION_BOOK_APPOINTMENT = gql`
	subscription patientBookAppointment($service: ID, $user: ID) {
		patientBookAppointment(sede: $sede, user: $user) {
			doctor
			space
		}
	}
`;

export const ROLLBACK_STATE_APPOINTMENT = gql`
	mutation rollBackStateAppointment($_idAppointments: ID!) {
		rollBackStateAppointment(_idAppointments: $_idAppointments) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const DELIVERY_APPOINTMENT = gql`
	mutation deliveryAppointment($_id: ID!, $readerPhysician: String, $makerPhysician: String) {
		deliveryAppointment(_id: $_id, readerPhysician: $readerPhysician, makerPhysician: $makerPhysician) {
			success
			errors {
				path
				message
			}
		}
	}
`;

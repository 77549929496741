import React from 'react';
import './style.css';
import Interval from '../interval';

const ScheduleWeek = ({ currentWeek, currentDay, addInterval, deleteSerie, edit, editInterval, clearErrors }) => {
  return (
    <div className="schedule-week-container">
      {currentWeek.map((day, index) => {
        return (
          <div className="schedule-day flex f-c" key={index}>
            <p
              className={`text-center ${
                currentDay === day.title ? 'icon text-bold' : ''
              }`}
            >
              {day.title}
            </p>
            {day.intervals.map((interval, index) => {
              return (
                <Interval
                  edit={edit}
                  key={index}
                  interval={interval}
                  resourceDay={day.momentDay}
                  deleteSerie={deleteSerie}
                  editInterval={editInterval}
                  clearErrors={clearErrors}
                />
              );
            })}
            <Interval resourceDay={day.momentDay} clearErrors={clearErrors} addInterval={addInterval} edit={edit} />
          </div>
        );
      })}
    </div>
  );
};

export default ScheduleWeek;

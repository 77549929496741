/* eslint-disable */
import './style.css';

/**
 * PatientsTable
 */
import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import moment from 'moment';

class PatientsTable extends Component {
  days_c(patient) {
    return Number(moment().diff(moment(patient.Ampas.dateInit), 'days')) >= 6 ? 6 : Number(moment().diff(moment(patient.Ampas.dateInit), 'days'))
  }
  status_c(patient) {
    const d = Number(moment().diff(moment(patient.Ampas.dateInit), 'days')) >= 6 ? 6 : Number(moment().diff(moment(patient.Ampas.dateInit), 'days')) + 1
    return d >= 6 ? <span className='end'>Terminado</span> : <span className='progress'>En Proceso</span>
  }
  render() {
    const { patients, toProfile, scrolled, finished } = this.props;


    return (
      <div className="table-container" onScroll={scrolled}>
        {finished ? (
          <Fragment>
            {patients && patients.length > 0 ? (
              <table className="list users-list">
                <thead>
                  <tr>
                    <th>Nombres</th>
                    <th>Identificación</th>
                    <th>Teléfono</th>
                    <th>Estado</th>
                    <th>Fecha inicio</th>
                    <th>Fecha finalización</th>
                    <th>Días</th>
                  </tr>
                </thead>
                <tbody>
                  {patients.map((patient, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => toProfile(patient.Ampas.patient, patient.Ampas._id)}
                        className="list-item clickable ripple-title"
                      >
                        <td>{patient.fullName}</td>
                        <td>{patient.dni}</td>
                        <td>{patient.phone}</td>
                        <td>{this.status_c(patient)}</td>
                        <td>
                          {`${moment(patient.Ampas.dateInit).format("DD/MM/YYYY")}`}
                        </td>
                        <td>
                          {`${moment(patient.Ampas.dateInit).add(5, "days").format("DD/MM/YYYY")}`}
                        </td>
                        <td>
                          {`${this.days_c(patient)}/6`}
                        </td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
                <p className="text-center">No se encontraron telemonitoreos</p>
              )}
          </Fragment>
        ) : (
            !this.props.assign && (
              <p className="text-center">Cargando telemonitoreos...</p>
            )
          )}
      </div>
    );
  }
}

PatientsTable.propTypes = {
  patients: PropType.array,
  toProfile: PropType.func,
  scrolled: PropType.func,
  finished: PropType.bool
};

export default PatientsTable;

/* eslint-disable */
import 'moment/locale/es';

/**
 * AppointmentsAssigned
 */
import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import { roleHavePermission } from '../../../utils/functions';
import Modal from '../../general/modal';
import FormTypeHead from '../../general/formTypeHead';
import assets from '../../../assets';
import { TILTEST_PHYSICIAN, ESFUERZO_PHYSICIAN, ECO_PHYSICIAN, EKG_PHYSICIAN, STRESS_PHYSICIAN } from '../../../utils/constants';
import Select from 'react-select';

moment.locale('es');
  class AppointmentsAssigned extends Component {

    constructor(props) {
      super(props);
      this.state = {
        listTreatingPhysician: [],
        valueTreatingPhysician: '',
        selectedTreatingPhysician: '',
        idSelectedTreatingPhysician: '',
        modalState: false,
        loadModal: true
      }
      this._sendAutomatizacionState = this._sendAutomatizacionState.bind(this)
      this._closeModal = this._closeModal.bind(this)
    }

    _closeModal = () => {
      this.setState({modalState:false});
      this.setState({loadModal: false});
      localStorage.setItem('flagCloseModal', true)
    }

    _sendAutomatizacionState = (e, index, item) => {

      e.preventDefault();

      if(this.refs["input-" + index].value == 'consultorio'){ //Add wait time
        localStorage.setItem("esperar_consultorio", true);
      }

      if(localStorage.getItem("esperar_consultorio") == 'true' && this.refs["input-" + index].value == 'atencion'){
        const flagValidate = window.confirm("¿Está seguro de cambiar el estado a Atención Terminada?");
        if(flagValidate){
          localStorage.removeItem('esperar_consultorio');
          this.props.saveStateAppointments(
            item,
            this.refs["input-" + index].value,
            this.refs["textarea-" + index].value,
            true,
            null,
            undefined,
            item.leeValue,
            item.realizaValue
          );
          this.refs["textarea-" + index].value = ''
          return
        }else{
          return
        }
      }else{
        this.props.saveStateAppointments(
          item,
          this.refs["input-" + index].value,
          this.refs["textarea-" + index].value,
          true,
          null,
          undefined,
          item.leeValue,
          item.realizaValue
        );
        this.refs["textarea-" + index].value = ''
        return
      }
    }



  render () {
    const thiss = this
    const {
      onVisibleReasign,
      currentAppointments,
      getStateAppointments,
      onVisibleModalCancel,
      rescheduleAppointment,
      idSelectedAppointment,
      handleShowAppointmentSchedule,
      user
    } = this.props;
    let updateStates = [];

    let assetIconAdd = assets.plusBlue;
    let classReferring = 'green';

    const nullState = ['anulada', 'cancelada', 'reprogramada', 'reasignada'];
    const aviableStateToUdate = ['agendada', 'confirmada', 'presente', 'recepcion', 'sala', 'consultorio', 'atencion'];

    function datesAreOnSameDay (first, second) {

      first = new Date(first)
      second = new Date(second)

      if(first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()){
        return true
      }else{
        return false
      }
    }

    function validateIfTomorrow (first, second) {

      first = new Date(first)
      second = new Date(second)

      second = new Date(second.setDate(second.getDate() + 1))

      if(first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()){
        return true
      }else{
        return false
      }
    }

    function validateIfWeekend (first, second) {

      first = new Date(first)
      second = new Date(second)

      const _second = new Date()

      _second.setDate(second.getDate() + 3)

      if(first.getFullYear() === _second.getFullYear() &&
        first.getMonth() === _second.getMonth() &&
        first.getDate() === _second.getDate() &&
        (first.getDay() == 1 && second.getDay() == 5)){
        return true
      }else{
        return false
      }
    }

    function showToUpdate (data) {

      let nextState = null
      let nextStateText = ''
      let htmlContent = []
      let index = null
      let item = null
      data.map((item, index) => {
        nextState = null
        nextStateText = ''
        index = index
        item = {...item}
        if(item.state && item.state.state){

          validateIfTomorrow(Number(item.time), Date.now())

          switch (item.state.state) {
            case 'agendada':
              if(user._role.name == 'Personal administrativo' &&
                (validateIfWeekend(Number(item.time), Date.now()) ||
                datesAreOnSameDay(Number(item.time), Date.now()) ||
                validateIfTomorrow(Number(item.time), Date.now()))){

                nextState = 'confirmada'
                nextStateText = (<span>Estado siguiente: <span className="red-state inline">confirmada</span></span>)

              }
              break;
            case 'confirmada':
              if(user._role.name == 'Personal administrativo' &&
                datesAreOnSameDay(Number(item.time), Date.now())){

                nextState = 'presente'
                nextStateText = (<span>Estado siguiente: <span className="red-state inline">presente</span></span>)

                }
              break;
            case 'presente':
              if(user._role.name == 'Personal administrativo' &&
                datesAreOnSameDay(Number(item.time), Date.now())){

                nextState = 'recepcion'
                nextStateText = (<span>Estado siguiente: <span className="red-state inline">En recepción</span></span>)

                }
              break;
            /*case 'recepcion':
              nextState = 'sala'
              nextStateText = (<span>Estado siguiente: <span class="red-state inline">En sala de espera</span></span>)
              break;*/
            case 'sala':
              if((user._role.name == 'Médico' || user._role.name == 'Enfermera') &&
                datesAreOnSameDay(Number(item.time), Date.now())){

                nextState = 'consultorio'
                nextStateText = (<span>Estado siguiente: <span className="red-state inline">En consultorio</span></span>)

                }
              break;
            case 'consultorio':
              if((user._role.name == 'Médico' || user._role.name == 'Enfermera') &&
                datesAreOnSameDay(Number(item.time), Date.now())){

                nextState = 'atencion'
                nextStateText = (<span>Estado siguiente: <span className="red-state inline">En atención</span></span>)

                }
              break;
            /*case 'atencion':
              nextState = 'entregado'
              nextStateText = (<span>Estado siguiente: <span class="red-state inline">Entregado</span></span>)
              break;*/

            default:
              break;
          }

        }


        /* Load Selects */

          const inputReferringPhysician =
          <div>
            <div className="referring-physician-input">
              <FormTypeHead
                id="typehead_physician"
                name="fullname"
                label="Médico remitente"
                labelKey="fullname"
                aditional="specialty"
                renderAtional={true}
                /*options={thiss.state.listReferringPhysician}
                multiple={!1}
                clearButton={thiss.clearButton}
                onChange={thiss._onChangeTypeHead}
                onInputChange={thiss._onInputChangeTypeHead}
                selectedOptions={thiss.state.selectedReferringPhysician !== '' ?
                  [{
                    fullname: thiss.state.selectedReferringPhysician, id_
                      : thiss.state.idSelectedReferringPhysician
                  }] : []
                }*/
              />
            </div>
            <div className="referring-physician-botton" onClick={thiss.state.idSelectedReferringPhysician === '' ? this._onSaveTypeHead : () => { }}>
              <div className="icon">
                <img src={assetIconAdd} alt="icon-add" />
              </div>
              <div className={`text-icon ${classReferring}`}></div>
            </div>
          </div>;

const inputTreatingPhysician =
  <div>
    <div className="referring-physician-input">
      <FormTypeHead
        id="typehead_physician2"
        name="fullname"
        label="Médico tratante"
        labelKey="fullname"
        aditional="specialty"
        renderAtional={true}
        /*options={listReferringPhysician}
        multiple={!1}
        clearButton={this.clearButton}
        onChange={this._onChangeTypeHead}
        onInputChange={this._onInputChangeTypeHead}
        selectedOptions={selectedReferringPhysician !== '' ?
          [{
            fullname: selectedReferringPhysician, id_
              : idSelectedReferringPhysician
          }] : []
        }*/
      />
    </div>
    <div className="referring-physician-botton" onClick={thiss.state.idSelectedReferringPhysician === '' ? this._onSaveTypeHead : () => { }}>
              <div className="icon">
                <img src={assetIconAdd} alt="icon-add" />
              </div>
              <div className={`text-icon ${classReferring}`}></div>
    </div>
  </div>;

        if(item.state && item.state.leeValue){
          item.leeValue = item.state.leeValue._id
        }

        if(item.state && item.state.realizaValue){
          item.realizaValue = item.state.realizaValue._id
        }

        let extraLeeRealizaValues = ''

        if(item.service.name == 'Tilt Test'){
          extraLeeRealizaValues = <div className="extra-physician">
          <span>Médico que realiza</span>
          <Select
          defaultValue = {
            TILTEST_PHYSICIAN.realiza.filter(option =>
              option.value == item.realizaValue)
          }
          onChange={e=>{
            item.realizaValue = e.value
          }}
          options={TILTEST_PHYSICIAN.realiza}
          />
          <span>Médico que lee</span>
          <Select
          defaultValue = {
            TILTEST_PHYSICIAN.lee.filter(option =>
              option.value == item.leeValue)
          }
          onChange={e=>{
            item.leeValue = e.value
          }}
          options={TILTEST_PHYSICIAN.lee}
          />
        </div>;
        }

        if(item.service.name.toLowerCase().indexOf('Prueba de esfuerzo') >= 0
        || item.service.name.toLowerCase().indexOf('pdee') >= 0){
          extraLeeRealizaValues = <div className="extra-physician">
          <span>Médico que realiza</span>
          <Select
          defaultValue = {
            ESFUERZO_PHYSICIAN.realiza.filter(option =>
              option.value == item.realizaValue)
          }
          onChange={e=>{
            item.realizaValue = e.value
          }}
          options={ESFUERZO_PHYSICIAN.realiza}
          />
          <span>Médico que lee</span>
          <Select
          defaultValue = {
            ESFUERZO_PHYSICIAN.lee.filter(option =>
              option.value == item.leeValue)
          }
          onChange={e=>{
            item.leeValue = e.value
          }}
          options={ESFUERZO_PHYSICIAN.lee}
          />
        </div>;
        }

        if(item.service.name.toLowerCase().indexOf('eco doppler') >= 0
        || item.service.name.toLowerCase().indexOf('eco tt') >= 0 ){
          extraLeeRealizaValues = <div className="extra-physician">
          <span>Médico que realiza</span>
          <Select
          defaultValue = {
            ECO_PHYSICIAN.realiza.filter(option =>
              option.value == item.realizaValue)
          }
          onChange={e=>{
            item.realizaValue = e.value
          }}
          options={ECO_PHYSICIAN.realiza}
          />
          <span>Médico que lee</span>
          <Select
          defaultValue = {
            ECO_PHYSICIAN.lee.filter(option =>
              option.value == item.leeValue)
          }
          onChange={e=>{
            item.leeValue = e.value
          }}
          options={ECO_PHYSICIAN.lee}
          />
        </div>;
        }

        if(item.service.name.toLowerCase() === 'ekg'){
          extraLeeRealizaValues = <div className="extra-physician">
          <span>Médico que realiza</span>
          <Select
          defaultValue = {
            EKG_PHYSICIAN.realiza.filter(option =>
              option.value == item.realizaValue)
          }
          onChange={e=>{
            item.realizaValue = e.value
          }}
          options={EKG_PHYSICIAN.realiza}
          />
          <span>Médico que lee</span>
          <Select
          defaultValue = {
            EKG_PHYSICIAN.lee.filter(option =>
              option.value == item.leeValue)
          }
          onChange={e=>{
            item.leeValue = e.value
          }}
          options={EKG_PHYSICIAN.lee}
          />
        </div>;
        }

        if(item.service.name.toLowerCase().indexOf('stress') >= 0){
          extraLeeRealizaValues = <div className="extra-physician">
          <span>Médico que realiza</span>
          <Select
          defaultValue = {
            STRESS_PHYSICIAN.realiza.filter(option =>
              option.value == item.realizaValue)
          }
          onChange={e=>{
            item.realizaValue = e.value
          }}
          options={STRESS_PHYSICIAN.realiza}
          />
          <span>Médico que lee</span>
          <Select
          defaultValue = {
            STRESS_PHYSICIAN.lee.filter(option =>
              option.value == item.leeValue)
          }
          onChange={e=>{
            item.leeValue = e.value
          }}
          options={STRESS_PHYSICIAN.lee}
          />
        </div>;
        }

        /* End Load Selects */

        if(nextState){
          if(thiss.state.modalState == false){
            thiss.setState({modalState:true});
          }
          htmlContent.push(
            <div className="container-actualizar">
              <div className="flex-actualizar1">
                <div className="cardAppointmentsAssigned full init save flex j-c a-i-c updateCard">
                  <span className="title">{item.service.name}</span>
                  <span>{moment(parseInt(Number(item.time))).format('dddd D [de] MMMM [/] hh:mm A YYYY')}</span>
                  <span>{item.sede.name}</span>
                  <span>{item.entity.nombre}</span>
                  <span>Estado actual:
                    <span className="state inline">{item.state.state}</span>
                  </span>
                  {nextStateText}
                  <button type="button" title="Actualizar estado" className="actualizar-estado-btn" onClick={(e) => thiss._sendAutomatizacionState(e, index, item)} >Actualizar estado </button>
                </div>
              </div>
              <div className="flex-actualizar2">
                <form>
                  <input
                    ref={'input-' + index}
                    type={'hidden'}
                    value={nextState}
                  />
                  {extraLeeRealizaValues}
                  <textarea
                  ref={'textarea-' + index}
                  placeholder="Observaciones"
                  />
                  <div className="contentBtnAppointment">

                  </div>
                </form>
              </div>
            </div>
          )
        }
      });

      return htmlContent

    }

    return (
      <Fragment>
        { user._role && roleHavePermission(user, "agendarCita") &&
          <div
            className="cardAppointmentsAssigned ripple-title init flex j-c a-i-c"
            onClick={() => handleShowAppointmentSchedule()}
          >
            <i className="icon-reloj large " />
            <span className="">Agendar cita</span>
          </div>
        }
        {
          currentAppointments && currentAppointments.map((appointment, index) => {
            if (index > 2) return;
            const classSelected = appointment._id === idSelectedAppointment ? 'selected' : '';
            const state = appointment.state && appointment.state.state? appointment.state.state: 'Agendada';
            const entity = appointment.entity && appointment.entity.nombre? appointment.entity.nombre: '\u00A0';
            const titleDate = appointment.time? moment(parseInt(appointment.time)).format('dddd D [de] MMMM [/] hh:mm A YYYY'): '';
            const extraAppointment = appointment.extra === true ? 'extra-color' : '';
            const extraAppointmentTitle = appointment.extra === true ? 'Cita extra' : '';

            if(aviableStateToUdate.includes(state.toLowerCase())){
              updateStates.push(appointment)
            }

            return (
              <div
                key={index}
                className={`cardAppointmentsAssigned init save flex j-c a-i-c ${classSelected} ${extraAppointment}`}
              >
                <span className="title">
                  {appointment.service && appointment.service.name} - {extraAppointmentTitle}
                </span>
                <span>{titleDate}</span>
                <span>{appointment.sede && appointment.sede.name}</span>
                <span>{appointment.doctor && `${appointment.doctor.name} ${appointment.doctor.lastName}`}</span>
                <span>{entity}</span>
                <span className={nullState.indexOf(state) > -1? 'red-state': 'state'} >
                  {state}
                </span>
                <div className="contentBtnAppointment">
                  <span
                    title="Ver"
                    className="icon-view large ripple-gray"
                    onClick={() => getStateAppointments(appointment, index)}
                  />
                  {
                    nullState.indexOf(state) === -1 && state !== 'terminada' &&
                      <Fragment>
                        { user._role && roleHavePermission(user, "editarCita") && 
                          <span
                            title="Editar"
                            className="icon-edit large ripple-gray"
                            onClick={() => setTimeout(() => rescheduleAppointment(appointment, index), 300)}
                          /> 
                        }
                      </Fragment>
                  }
                </div>
              </div>
            );
          })
        }
        {
          //updateStates.length > 0 && this.state.modalState == false && !localStorage.getItem('flagCloseModal') ? this.setState({modalState: true}) : false
        }
        {this.state.loadModal &&
          <Modal isOpenModal={this.state.modalState} onClose={()=> {this._closeModal()} } title="Actualizar estado" >
            {showToUpdate(updateStates).length != 0 ? showToUpdate(updateStates) : (this.state.modalState == true ? this._closeModal() : false)}
          </Modal>}
      </Fragment>
    );
  };
}



AppointmentsAssigned.propTypes = {
  onVisibleReasign: PropTypes.func,
  currentAppointments: PropTypes.array,
  onVisibleModalCancel: PropTypes.func,
  getStateAppointments: PropTypes.func,
  rescheduleAppointment: PropTypes.func,
  idSelectedAppointment: PropTypes.string,
  handleShowAppointmentSchedule: PropTypes.func
};
AppointmentsAssigned.defaulProps = {
  onVisibleModalCancel: () => {},
  idSelectedAppointment: ''
};
export default AppointmentsAssigned;

import gql from 'graphql-tag';

export const GET_RESOURCE_TYPES = gql`
	query getResourceType {
		getResourceType {
			success
			errors {
				path
				message
			}
			data {
				name
				_id
				calendar
			}
		}
	}
`;

export const GET_RESOURCE_SEDE = gql`
	query getResourceSede($_resourceType: ID!) {
		getResourceSede(_resourceType: $_resourceType) {
			success
			errors {
				path
				message
			}
			data {
				name
				_id
				calendar
			}
		}
	}
`;
export const GET_RESOURCE_APPOINTMENT = gql`
	query getResourceAppointment($spaces: String!) {
		getResourceAppointment(spaces: $spaces) {
			data {
				_id
				name
			}
			success
			errors {
				message
				path
			}
		}
	}
`;

export const GET_RESOURCE = gql`
	query getResource(
		$_id: ID
		$_resourceType: ID
		$_sede: ID
		$page: Int
		$name: String
		$services: String
	) {
		getResource(
			_resourceType: $_resourceType
			_sede: $_sede
			page: $page
			name: $name
			_id: $_id
			services: $services
		) {
			data {
				_id
				name
				quantity
				services
				series
				specialSchedule
        active
        timeOut
				_sede {
					_id
					name
				}
				_resourceType {
					_id
					name
				}
			}
			success
			errors {
				message
				path
			}
		}
	}
`;

import React, { useContext } from "react";
import { context } from "../../../providers/billingProvider";

const CupsFilter = () => {
    const { cups, setCupsFilter } = useContext(context);
    const { filters } = cups;

    return (
        <div className="row">
            <div className="mb-2 mb-sm-0 col-sm-12">
                Busqueda
                <input
                    type="text"
                    className="form-control"
                    value={filters.search}
                    onChange={e => setCupsFilter('search', e.target.value)}
                />
            </div>
        </div>
    )
}

export default CupsFilter;
import gql from 'graphql-tag';

export const GET_CITIES = gql`
    query getCities {
        getCities {
            data {
                _id
                name
            }
            errors {
                message
                path
            }
            success
        }
    }
`;
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import TextInput from '../../../components/general/textInput';
import Popover from 'react-popover';
import MESSAGES from '../../../containers/general/resetPassword/messages.json';
import { passwordPopoverProps } from '../../../utils/functions';
import { CHANGE_PASSWORD_PROFILE } from '../../../graphql/types/usersModule/mutations';
import './style.css';

class PasswordModal extends Component {
  state = {
    message: MESSAGES['error'],
    success: false,
    invalid: false,
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    passwordRequirements: {
      length: {
        id: 1,
        value: false,
        message: 'Contener al menos 6 caracteres de longitud'
      },
      upper: { id: 2, value: false, message: 'Contener una letra mayúscula' },
      lower: { id: 3, value: false, message: 'Contener una letra minúscula' },
      number: { id: 4, value: false, message: 'Contener un número' }
    }
  };

  showPopover = () => {
    this.setState({ popoverIsOpen: true }, this.removeError);
  };

  closePopover = () => {
    this.setState({ popoverIsOpen: false }, this.removeError);
  };

  updateField = (name, { value }) => {
    let error = false;
    const passwordRequirements = this.scanPassword({
      ...this.state,
      [name]: value
    });
    if (this.state.showMismatch) {
      error = !this.matchPasswords({ ...this.state, [name]: value });
    }
    this.setState({ [name]: value, error, passwordRequirements });
  };

  hideInvalid = () => {
    this.setState({ invalid: false });
  }
  showError = () => {
    this.setState({ error: true, invalid: false });
  };
  removeError = () => {
    this.setState({ error: false });
  };
  toggleMatchErrors = () => {
    this.setState({ showMismatch: !this.state.showMismatch });
  };
  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  matchPasswords = ({ newPassword, newPasswordConfirmation }) => {
    const match = newPassword === newPasswordConfirmation;
    if (!match) {
      const message = 'Las contraseñas no coinciden';
      this.setState({ message });
    }
    return match;
  };

  scanPassword = ({ newPassword }) => {
    const status = {
      length: newPassword.length >= 6,
      upper: /[A-Z]+/.test(newPassword),
      lower: /[a-z]+/.test(newPassword),
      number: /[0-9]+/.test(newPassword)
    };
    const newPasswordState = this.state.passwordRequirements;
    Object.keys(status).forEach(req => {
      newPasswordState[req].value = status[req];
    });
    return newPasswordState;
  };
  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  validate = () => {
    const invalidReq = Object.keys(this.state.passwordRequirements).filter(
      req => {
        return this.state.passwordRequirements[req].value === false;
      }
    );
    const restrictions = invalidReq.length === 0;
    const match = this.matchPasswords(this.state);
    if (!restrictions) {
      let indication = this.state.passwordRequirements[invalidReq[0]].message;
      indication = indication[0].toLowerCase() + indication.slice(1);
      const message = `La contraseña debe ${indication}`;
      this.setState({ message });
    }
    return restrictions & match;
  };

  changePassword = async (e) => {
    e.preventDefault();
    const { _id } = this.props;
    const { currentPassword: password, newPassword } = this.state;
    e.preventDefault();
    if (this.validate()) {
      const result = await this.props.changePassword({
        variables: {
          _id,
          password,
          newPassword
        }
      });
      const success = result.data.changePasswordProfile.success;
      const invalid = !success;
      const message = success ? MESSAGES['success'] : result.data.changePasswordProfile.errors[0].message;
      this.setState({ invalid, success, message });
    } else {
      this.showError();
    }
  };
  render () {
    const { open, onCancel } = this.props;
    const { passwordRequirements: requirements, popoverIsOpen } = this.state;
    const popoverProps = passwordPopoverProps(
      popoverIsOpen,
      requirements,
      this.closePopover
    );

    return (
      <div
        onClick={onCancel}
        className={`modal-container passwordModal ${!open ? 'hide' : ''}`}
      >
        <div
          className="modal-body text-center"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="text-center bg-icon password-modal-title ripple-title">
            Cambiar contraseña
          </div>
          <form className="text-center" onSubmit={this.changePassword}>
            <div
              className={`current-password ${this.state.invalid ? 'wrong-input' : null}`}
              onFocus={this.hideInvalid}
            >
              <TextInput
                value={this.state.currentPassword}
                type="password"
                name="currentPassword"
                label="Contraseña actual"
                updateField={this.updateField}
                disablePaste={true}
              />
            </div>

            <div className=" password-group">
              <Popover {...popoverProps}>
                <div className="form-inline" onFocus={this.showPopover}>
                  <TextInput
                    value={this.state.newPassword}
                    type={this.state.showPassword ? 'text' : 'password'}
                    name="newPassword"
                    label="Nueva contraseña"
                    updateField={this.updateField}
                  />
                  {this.state.popoverIsOpen && (
                    <i
                      className={`clickable small material-icons ${
                        this.state.showPassword
                          ? 'shown-password'
                          : 'hidden-password'
                      }`}
                      onClick={this.toggleShowPassword}
                    >
                      remove_red_eye
                    </i>
                  )}
                </div>
              </Popover>
            </div>

            <div
              className={` ${this.state.error ? 'wrong-input' : null}`}
              onFocus={this.toggleMatchErrors}
              onBlur={this.toggleMatchErrors}
            >
              <TextInput
                value={this.state.newPasswordConfirmation}
                type="password"
                name="newPasswordConfirmation"
                label="Confirmar nueva contraseña"
                updateField={this.updateField}
                disablePaste={true}
              />
            </div>

            <div className="text-center mt-2">
              <button
                className="button button-sm bg-icon btn-save ripple"
                type="submit"
              >
                Guardar
              </button>
            </div>
          </form>
          { (this.state.error || this.state.invalid) && <p className="text-center error-msg">{this.state.message}</p> }
          {
            this.state.success &&
            <div className="mt-2">
              <p className="text-center success-msg">{this.state.message}</p>
              <p className="text-center clickable button-msg go-back" onClick={onCancel}>Regresar</p>
            </div>
          }
        </div>
      </div>
    );
  }
}
export default graphql(CHANGE_PASSWORD_PROFILE, { name: 'changePassword' })(
  PasswordModal
);

/* eslint-disable */
import './index.css';

import * as request from 'superagent';

import { CREATE_AMPA, CREATE_MEDICINES } from '../../../graphql/types/ampa/mutations';
// *PatientInfoAmpa
import React, { Component, Fragment } from 'react';
import { compose, graphql, withApollo } from 'react-apollo';

import {
  ALL_USERS
} from '../../../graphql/types/usersModule/queries';
// import FormTypeHeadPh from '../../../components/general/formTypeHeadPh/index';
import AsyncSelect from 'react-select/async'
import CheckboxInput from '../../../components/general/checkboxInput';
import FormTextArea from '../../../components/general/formTextArea';
import FormTypeHead2 from '../../../components/general/formTypeHead2';
import { GET_AMPAS_TAKE } from '../../../graphql/types/ampa/queries';
import { GET_MEDICAMENTOS } from '../../../graphql/types/medicamentos/querires';
import { GET_PHYSICIANS } from '../../../graphql/types/physician/queries';
import OptionsTwo from '../../../components/general/optionsTwo';
import Pdf from 'react-to-pdf';
import PropTypes from 'prop-types';
import RadioInput from '../../../components/general/radioInput';
import SelectInput from '../../../components/general/selectInput';
import TextInput from '../../../components/general/textInput';
import ViewAmpa from '../viewAmpa/viewAmpa';
import _ from 'lodash';
import debounce from "debounce-promise";
import moment from 'moment';
// import PatientInfo from '../../../components/patients/patientsInfo/patientsInfo'
import { withRouter } from 'react-router-dom';
import { GENDERS } from '../../../utils/constants';

const options = {
  orientation: 'landscape',
};

var timeout;
class PatientInfoAmpa extends Component {
  filterTimeOut = null;
  state = {
    selectedRemitentes: [],
    selectedEnfermeras: [],
    dateInit: '',
    image: '',
    size: undefined,
    weight: undefined,
    patient: undefined,
    physicalActivity: 1,
    nameEquipment: '',
    actividadfisica: 'Inactivo o poca actividad distinta a las actividades cotidianas',
    embarazada: 'No',
    smoke: 'No',
    liquor: 'Si',
    listPh: [],
    listEnfermeras: [],
    selectedEnfermera: '',
    idRemitente: '',
    selectedRemitente: '',
    nameMedicine: '',
    idMedicine: '',
    dosis_plena: '',
    idNurseManager: '',
    liquorFrequency: '',
    nursingNotes: '',
    doctorNotes: '',
    listMedicamentos: [],
    medicines: [],
    licorfuerte: false,
    vino: false,
    cerveza: false,
    _id: '',
    takes: [],
    token: ''
  }
  async componentWillMount() {
    const patient = this.props.location.pathname.split('/')[2];
    let _id = patient;
    this.setState({ _id });
    const patient_data = await this.props.getPatientInfo(patient);
    this.setState({ image: this.props.image });
    // this.getUsers()


    const strNewAmpa = this.props.location.pathname;
    const newAmpa = (/new/.test(strNewAmpa));
    if (newAmpa === false) {

      let _id = this.props.location.pathname.split('/')[3];
      this.getAmpasTake(patient, _id);
    }
  }
  irAmpa = () => {
    const dataBase = window.location.origin == 'http://localhost:3001';
    var token = this.state.token;
    var otherWindow = window.open();
    otherWindow.opener = null;
    if (dataBase) {
      otherWindow.location = 'http://localhost:8080/core.html?' + token;
    } else {
      otherWindow.location = `https://${ process.env.REACT_APP_URL_AMPA }/core.html?` + token;
    }
  }
  bmi = () => {
    let { size, weight } = this.state;
    size = size ? size / 100 : 0;
    return (weight / (size * size)).toFixed(2);
  }
  updateTextArea = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }
  updateField = (name, { value }) => {
    console.log(name, value);
    this.setState({ [name]: value }, () => {
      if (name === 'weight' || name === 'size') {
        const bmi = this.bmi();
        this.updateField('bmi', { value: bmi });
      }
    });
  }
  updateRadio = (name, { value }) => {
    if (timeout || timeout !== undefined) clearTimeout(timeout);
    timeout = setTimeout(() => {
      console.log('aplied', [name]);
      this.setState({ [name]: value });
    }, 300);

  }
  updateClick = (number) => {
    const options = [
      'Inactivo o poca actividad distinta a las actividades cotidianas',
      'Participación en actividades, 2 a 5 veces por semana, que requieren pequeños esfuerzos que resultan en leves incrementos en la respiración y el ritmo cardiaco por al menos diez minutos cada vez.',
      'Aeróbicos como caminar con energía, trotar o correr, montar bicicleta, nadar o practicar un deporte vigoroso por: 20 a 60 minutos por semana.',
      'Aeróbicos como caminar con energía, trotar o correr, montar bicicleta, nadar o practicar un deporte vigoroso por: 1 a 3 horas por semana.',
      'Aeróbicos como caminar con energía, trotar o correr, montar bicicleta, nadar o practicar un deporte vigoroso por: más de 3 horas por semana.'
    ];
    const actividadfisica = options[number];
    this.setState({ physicalActivity: number + 1, actividadfisica });
  }
  classActividad = (current, number) => {
    if (current == number) {
      return `selected${number} selectbox`;
    } else {
      return 'selectbox';
    }

  }
  changePh = async (options) => {
    if (options.length === 0) return;
    //debugger
    this.setState({
      selectedRemitente: options[0].fullname,
      idRemitente: options[0]._id
    });
  }
  _onChangeTypeHead = async (options) => {
    if (options.length === 0) return;
    this.setState({
      selectedEnfermera: options[0].fullName,
      idNurseManager: options[0]._id,
      phone: options[0].mobile
    });
  }
  clearButton_2 = () => {
    this.setState({ selectedRemitente: '', idRemitente: '' });
  }
  clearButton = () => {
    this.setState({ selectedEnfermera: '', idNurseManager: '' });
  }
  _onInputChangeTypeHead = async (value) => {
    if (value === '') {
      this.setState({
        selectedEnfermera: '',
        idNurseManager: ''
      });
      return;
    }
    const allUsers = await this.getUsers(value);
    if (allUsers) {
      const listEnfermeras = allUsers.data.allUsers.data;
      this.setState({
        listEnfermeras,
        valueEnfermera: value,
        selectedEnfermera: '',
        idNurseManager: ''
      });
    }
  };
  phOnChange = selectedRemitentes => {
    this.setState({
      selectedRemitentes: selectedRemitentes || []
    })
  }
  enOnChange = selectedEnfermeras => {
    this.setState({
      selectedEnfermeras: selectedEnfermeras || []
    })
  }
  loadOptions = async (inputText, callback) => {
    if (inputText.length >= 3) {
      const phList = await this.getPhysitians(inputText);
      if (
        phList && 
        typeof phList.data !== 'undefined' && 
        typeof phList.data.getPhysicians !== 'undefined' && 
        typeof phList.data.getPhysicians.data !== 'undefined' &&
        phList.data.getPhysicians.data.length > 0
      ) {
        var listPh = phList.data.getPhysicians.data;
        callback(listPh.map(i => ({
            label: typeof i.confirm !== null && i.confirm ? `${i.fullname} (confirmado)` : i.fullname,
            value: i._id
        })))
      }
    } 
  };
  loadEnfermeras = async (inputText, callback) => {

    if (inputText.length >= 3) {

      //console.log("entra ------ a pedir a base de datos")
      //console.log({ inputText })
      const list = await this.getUsers(inputText);
      if (list) {
        const listEn = list.data.allUsers.data;
        callback(listEn.map(i => ({
          label: i.fullName,
          value: i._id
        })))
      }

    }
  };

  getPhysitians = async (value) => {
    let filters = {}
    filters.fullname = value
    filters.page = 1
    filters.status = 'confirmado'
    filters.perPage = 10
    const phs = await this.props.client.query({
      query: GET_PHYSICIANS,
      variables: filters
    });
    return phs;
  }
  getUsers = async (value) => {
    const users = await this.props.client.query({
      query: ALL_USERS,
      variables: { _role: '5b646cafd08dfcfeca467753', name: value, active: true }
    });
    return users;
  }
  __clearButton = () => {
    this.setState({
      nameMedicine: '',
      idMedicine: ''
    });
  }
  __onChangeTypeHead = async (options) => {
    if (options.length === 0) return;
    this.setState({
      nameMedicine: options[0].nombre,
      idMedicine: options[0].id,
      dosis_plena: options[0].dosis_plena,
      unidades: options[0].unidades
    });
  }
  __onInputChangeTypeHead_ = async (value) => {
    if (value === '') {
      this.setState({
        nameMedicine: '',
        idMedicine: ''
      });
      return;
    }
    const getMedicamentos = await this.props.client.query({
      query: GET_MEDICAMENTOS,
      variables: { name: value }
    });
    if (getMedicamentos) {
      const listMedicamentos = getMedicamentos.data.allMedicamentos.data;
      if (listMedicamentos) {
        this.setState({
          listMedicamentos,
          valueMedicamento: value,
          nameMedicine: '',
          idMedicine: '',
          dosis_plena: ''
        });
      }
    }
  };
  addMedicmentos = () => {
    const {
      nameMedicine,
      idMedicine,
      quantity,
      dose,
      unidades,
      dosis_plena
    } = this.state;
    const { medicines } = this.state;
    const full = (quantity * dose) == dosis_plena;
    const duration = `1 cada ${24 / quantity} horas`;
    const doseUnits = `${dose} ${unidades}`;
    medicines.push({
      nameMedicine,
      idMedicine,
      quantity,
      dose,
      full,
      duration,
      doseUnits
    });
    this.setState({
      medicines,
      nameMedicine: '',
      idMedicine: '',
      quantity: '',
      dose: '',
      unidades: '',
      dosis_plena: ''
    });


  }
  deleteMedicamentos = (id) => {
    let { medicines } = this.state;
    medicines = medicines.filter((medicamento, _id) => {
      if (id !== _id) {
        return medicamento;
      }
    });
    console.log({ medicines });
    this.setState({ medicines });
  }
  getAmpasTake = (patient, _id) => {
    const filter = { patient, _id };
    this.props.client
      .query({
        query: GET_AMPAS_TAKE,
        variables: filter
      })
      .then(response => {
        try {
          if (!_.isEmpty(response.data) && !_.isEmpty(response.data.getAmpasTake.data)) {

            const takes = response.data.getAmpasTake.data.Take;
            const { dateInit, token, dateInitTimestamp, medicines, medico, enfermera, size,
              weight,
              bmi,
              physicalActivity,
              pregnant,
              smoke,
              liquor,
              liquorFrequency,
              hardLiquor,
              wine,
              beer,
              nursingNotes,
              doctorNotes,
              nameEquipment,
              equipmentReference } = response.data.getAmpasTake.data;

            this.setState({
              takes, dateInit, token, dateInitTimestamp, medicines, medico, enfermera, size,
              weight,
              bmi,
              physicalActivity,
              pregnant,
              smoke,
              liquor,
              liquorFrequency,
              hardLiquor,
              wine,
              beer,
              nursingNotes,
              doctorNotes,
              nameEquipment,
              equipmentReference
            });
          }
        } catch (err) {

        }
      });
  }
  redirectAmpa = () => {

    this.props.history.replace('/ampa');
  }
  createAmpa = () => {
    const _id = this.props.location.pathname.split('/')[2];
    const { state } = this;
    let { dateInit } = state;
    dateInit = dateInit + 'T05:00:00.000Z';
    const {
      size,
      weight,
      bmi,
      status,
      nursingNotes,
      nameEquipment,
      equipmentReference,
      physicalActivity,
      smoke,
      liquor,
      liquorFrequency,
      Semanal,
      Mensual,
      Cotidiano,
      phone,
      medicines,
      selectedRemitentes,
      selectedEnfermeras,
      licorfuerte,
      cerveza, vino

    } = state;

    const idRemitente = selectedRemitentes.value;
    const selectedRemitente = selectedRemitentes.label;
    const idNurseManager = selectedEnfermeras.value;
    const selectedEnfermera = selectedEnfermeras.label;
    const patient = _id;
    const liquorFrequency_ = Semanal ? 'Semanal' : Mensual ? 'Mensual' : Cotidiano ? 'Cotidiano' : '';
    
    const wine = vino
    const beer = cerveza
    const hardLiquor = licorfuerte
    if (selectedRemitente != '' && dateInit != '' && idNurseManager != '' && bmi != '') {
      this.props.createAmpa({
        variables: {
          dateInit,
          size,
          selectedRemitente,
          idRemitente,
          weight,
          bmi,
          status,
          patient,
          idNurseManager,
          nursingNotes,
          nameEquipment,
          equipmentReference,
          physicalActivity,
          smoke,
          liquor,
          liquorFrequency: liquorFrequency_,
          phone,
          wine,
          beer,
          hardLiquor
        }
      }).then((result) => {
        try {
          if (!_.isEmpty(result) && result != undefined) {

            const idAmpa = result.data.createAmpa.data._id;

            medicines.map((d) => {
              const { nameMedicine,
                idMedicine,
                quantity,
                dose,
                full,
                duration,
                doseUnits } = d;
              this.props.createMedicines({
                variables: {
                  idAmpa,
                  nameMedicine,
                  idMedicine,
                  quantity,
                  dose,
                  full,
                  duration,
                  doseUnits,
                }
              });
            });
          }
          alert('ampa creado')
          this.props.history.push('/ampa/');
        } catch (err) {
          console.log({ err })
        }
      })      
    }


  }
  toPdf = () => {
    const { id, _id } = this.props.match.params;

    window.open(`/ampa/report/${id}/${_id}`, '_blank');
  }
  ErrorclassName = (element) => {
    if (this.state[element] === undefined || this.state[element] === '') {
      return 'error';
    }
  }
  changeNotes=({name,value})=>{
    this.setState({[name]:value})
  }
  render() {
    // const debounce = _.debounce
    const strNewAmpa = this.props.location.pathname;
    // const newAmpa = true;
    const newAmpa = (/new/.test(strNewAmpa));

    const {
      name, lastName, birthDay, type_dni, dni, phone, mobile, email, gender, eps
    } = this.props.currentPatient;

    const {
      Cotidiano,
      Semanal,
      Mensual,
      dateInit,
      image,
      status,
      weight,
      size,
      bmi,
      actividadfisica,
      physicalActivity,
      embarazada,
      smoke,
      liquor,
      vino,
      cerveza,
      licorfuerte,
      liquorFrequency,
      nursingNotes,
      nameEquipment,
      doctorNotes,
      equipmentReference,
      selectedEnfermeras,
      selectedRemitentes,
      nameMedicine,
      idMedicine,
      medicines,
      listMedicamentos,
      quantity,
      dose,
      dosis_plena,
      warning
    } = this.state;
    const titleDate = moment(birthDay);
    return (
      <div id="PatientInfoAmpa" className="gridInfo" >
        <div className="head">
          <div className="personalInfo">
            <div className="profileImage" style={{
              backgroundImage: `url(data:image/gif;base64,${image})`
            }}
            >
            </div>
            <div className="directData borderRightGray">
              <div>
                {name + ' ' + lastName}
              </div>
              <div>
                {type_dni}: {dni}
              </div>
              <div className="no-print">
                {birthDay ? `${titleDate.format('D [de] MMMM [de] YYYY')} (${moment().diff(titleDate, 'years')})` : ''}
              </div>
            </div>
          </div>
          <div className="otherInfo borderRightGray no-print">
            <div className="no-print">
              {mobile} / {phone}
            </div>
            <div className="no-print">{email}</div>
            <div className="no-print">{gender && GENDERS[gender]}&nbsp;</div>
          </div>
          <div className="otherInfo no-print">
            <div className="no-print">
              EPS {eps && eps.nombre}
            </div>
          </div>
        </div>
        {newAmpa ? (
          <div className="bodyForm">
            <div className="head">
              Nuevo Telemonitoreo
            </div>
            <div className="container-3">
              <div className="one">
                <div>
                  <TextInput
                    type="date"
                    label="Fecha inicio"
                    updateField={this.updateField}
                    value={dateInit}
                    name="dateInit"
                    classNameValue={this.ErrorclassName('dateInit')}
                  />
                </div>
                <div className="threeInputs">
                  <TextInput
                    type="number"
                    label="Talla"
                    updateField={this.updateField}
                    value={size}
                    name="size"
                    classNameValue={this.ErrorclassName('size')}
                  />
                  <TextInput
                    type="number"
                    label="Peso"
                    updateField={this.updateField}
                    value={weight}
                    name="weight"
                    classNameValue={this.ErrorclassName('weight')}
                  />
                  <TextInput
                    type="number"
                    label="Imc"
                    disabled="true"
                    value={bmi}
                    name="bmi"
                  />
                </div>
                <div className="selectInput">
                  <SelectInput
                    type="text"
                    label="Indicación"
                    updateField={this.updateField}
                    value={status}
                    name="status"
                    options={[
                      { _id: '1', name: 'Sospecha de hipertensión' },
                      { _id: '2', name: 'Cifras altas de presión arterial en la consulta' },
                      { _id: '3', name: 'Hipertension en tratamiento - control de cifras.' },
                      { _id: '4', name: 'Cefalea y otros síntomas' },
                      { _id: '5', name: 'Efecto de cambios de tratamiento.' },
                      { _id: '6', name: 'Suspensión del tratamiento - evaluar control de la presión arterial.' },
                      { _id: '6', name: 'Daño de órgano blanco - evaluación de cifras' },
                      { _id: '8', name: 'Hipotensión arterial' },
                      { _id: '9', name: 'Evaluación de presión arterial' }
                    ]}
                  />
                </div>

              </div>
              <div className="two">
                <div className="title">Actividad física(Frecuencia)</div>
                <div className="optionsClick" >
                  <div>
                    <div onClick={() => { this.updateClick(0); }} className={this.classActividad(physicalActivity, 1)}>1</div>
                  </div>
                  <div>
                    <div onClick={() => { this.updateClick(1); }} className={this.classActividad(physicalActivity, 2)}>2</div>
                  </div>
                  <div>
                    <div onClick={() => { this.updateClick(2); }} className={this.classActividad(physicalActivity, 3)}>3</div>
                  </div>
                  <div>
                    <div onClick={() => { this.updateClick(3); }} className={this.classActividad(physicalActivity, 4)}>4</div>
                  </div>
                  <div>
                    <div onClick={() => { this.updateClick(4); }} className={this.classActividad(physicalActivity, 5)}>5</div>
                  </div>
                </div>
                <div className="texto">
                  {actividadfisica}
                </div>
              </div>
              <div className="three">
                {gender && (gender === 'M' ? '' : (
                  <OptionsTwo
                    name="embarazada"
                    label="Embarazada"
                    value={embarazada}
                    updateField={this.updateField}
                    options={['Si', 'No']}
                  >
                  </OptionsTwo>
                ))}

                <OptionsTwo
                  name="smoke"
                  label="Fuma"
                  value={smoke}
                  updateField={this.updateField}
                  options={['Si', 'No']}
                >
                </OptionsTwo>
                <OptionsTwo
                  name="liquor"
                  label="Consume licor"
                  value={liquor}
                  updateField={this.updateField}
                  options={['Si', 'No']}
                >
                </OptionsTwo>
                {liquor === 'Si' ? (
                  <div className="licoresOptions">
                    <div className="sectionCheckBox">
                      <CheckboxInput
                        // checked={
                        //   true &&
                        //     selectedServices.find(selService => {
                        //       return selService === service._id;
                        //     }) ? true : false
                        // }
                        className="service-option ripple-gray"

                        id={`id${Math.random()}`}
                        value={vino}
                        label="vino"
                        name="vino"
                        updateField={this.updateField}
                      />
                      <CheckboxInput
                        className="service-option ripple-gray"
                        id={`id${Math.random()}`}
                        value={cerveza}
                        label="cerveza"
                        name="cerveza"
                        updateField={this.updateField}
                      />
                      <CheckboxInput
                        className="service-option ripple-gray"
                        id={`id${Math.random()}`}
                        value={licorfuerte}
                        label="Licor fuerte"
                        name="licorfuerte"
                        updateField={this.updateField}
                      />
                    </div>
                    <div>
                      <RadioInput
                        checked={
                          Cotidiano ? Cotidiano : false
                        }
                        type="radio"
                        className="service-option ripple-gray"
                        id={`id${Math.random()}`}
                        // value={liquorFrequency}
                        label="Cotidiano"
                        name="liquorFrequency"
                        updateField={this.updateRadio}
                      />
                      <RadioInput
                        checked={
                          Semanal ? Semanal : false
                        }
                        type="radio"
                        className="service-option ripple-gray"
                        id={`id${Math.random()}`}
                        // value={liquorFrequency}
                        label="Semanal"
                        name="liquorFrequency"
                        updateField={this.updateRadio}
                      />
                      <RadioInput
                        checked={
                          Mensual ? Mensual : false
                        }
                        type="radio"
                        className="service-option ripple-gray"
                        id={`id${Math.random()}`}
                        // value={liquorFrequency}
                        label="Mensual"
                        name="liquorFrequency"
                        updateField={this.updateRadio}
                      />
                    </div>
                  </div>
                ) : ''}


              </div>
            </div>
            <div className="container-3">
              <div className="containerNotas">
                <FormTextArea
                  label='Notas de enfermeria'
                  name="nursingNotes"
                  value={nursingNotes}
                  cols={38}
                  rows={6}
                  onChange={this.updateTextArea}
                  classTextArea='notas'
                ></FormTextArea>
              </div>

              <div className='enfermeraAmpa'>
                <div>
                  <label>Medico Remitente</label>
                  <AsyncSelect
                    value={selectedRemitentes}
                    onChange={this.phOnChange}
                    placeholder={"Escribe para buscar el médico remitente"}
                    loadOptions={debounce(this.loadOptions, 350)}
                  />
                </div>
                <div className={this.ErrorclassName('selectedEnfermera')}>
                  <label>Enfermera</label>
                  <AsyncSelect
                    value={selectedEnfermeras}
                    onChange={this.enOnChange}
                    placeholder={"Escribe para buscar la enfermera"}
                    loadOptions={debounce(this.loadEnfermeras, 340)}
                  />

                </div>

              </div>
              <div className="containerEquipo">
                <TextInput
                  type="text"
                  label="Equipo Utilizado"
                  updateField={this.updateField}
                  value={nameEquipment}
                  name="nameEquipment"
                />
                <TextInput
                  type="text"
                  label="Referencia"
                  updateField={this.updateField}
                  value={equipmentReference}
                  name="equipmentReference"
                />
              </div>
            </div>
            <div className="medicamentosPanel">
              <div className="container-5">
                <div className="container-tool">
                  <FormTypeHead2
                    id="find_medicamentos"
                    name="medicamentos"
                    label="Medicamentos"
                    labelKey="nombre"
                    renderAtional={false}
                    options={listMedicamentos}
                    multiple={!1}
                    clearButton={this.__clearButton}
                    onChange={this.__onChangeTypeHead}
                    onInputChange={this.__onInputChangeTypeHead_}
                    selectedOptions={nameMedicine !== '' ?
                      [{
                        nombre: nameMedicine, id: idMedicine, dosis_plena
                      }] : []
                    }
                  />
                </div>
                <div className="container-tool">
                  <TextInput
                    type="number"
                    label="Dosis"
                    updateField={this.updateField}
                    value={dose}
                    name="dose"
                  />
                </div>
                <div className="container-tool">
                  <TextInput
                    type="number"
                    label="Cantidad"
                    updateField={this.updateField}
                    value={quantity}
                    name="quantity"
                  />
                </div>
                <div className="container-tool">
                  Dosis plena {dosis_plena}
                </div>
                <div className="container-tool">
                  <div className="agregar-button" onClick={this.addMedicmentos}>Agregar</div>
                </div>
                {medicines && medicines.map((medicamento, id) => {
                  return (<Fragment>
                    <div className={`row ${id % 2 == 0 ? 'rowback' : ''}`} key={`${id * Math.random()}rand`}>{medicamento.nameMedicine}</div>
                    <div className={`row ${id % 2 == 0 ? 'rowback' : ''}`} key={`${id * Math.random()}rand`}>{medicamento.doseUnits}</div>
                    <div className={`row ${id % 2 == 0 ? 'rowback' : ''}`} key={`${id * Math.random()}rand`}>{medicamento.duration}</div>
                    <div className={`row ${id % 2 == 0 ? 'rowback' : ''}`} key={`${id * Math.random()}rand`}>{medicamento.full ? 'Si' : 'No'}</div>
                    <div className={`row ${id % 2 == 0 ? 'rowback' : ''}`} key={`${id * Math.random()}rand`} onClick={() => this.deleteMedicamentos(id)} id={`${id}delete`}><div className='delete'>x</div> </div>
                  </Fragment>);
                })}

              </div>
            </div>
            <div className="container-create">
              <div className="crear-ampa-button" onClick={this.createAmpa}>Crear Ampa</div>
            </div>
          </div>
        ) : !_.isEmpty(this.state.takes) ? (
          <Fragment>
            <ViewAmpa
              _id={this.state._id}
              takes={this.state.takes}
              token={this.state.token}
              medicines={this.state.medicines}
              enfermera={this.state.enfermera}
              medico={this.state.medico}
              dateInit={this.state.dateInit}
              dateInitTimestamp={this.state.dateInitTimestamp}
              size={this.state.size}
              weight={this.state.weight}
              bmi={this.state.bmi}
              status={this.state.status}
              physicalActivity={this.state.physicalActivity}
              pregnant={this.state.pregnant}
              smoke={this.state.smoke}
              liquor={this.state.liquor}
              liquorFrequency={this.state.liquorFrequency}
              hardLiquor={this.state.hardLiquor}
              wine={this.state.wine}
              beer={this.state.beer}
              nursingNotes={this.state.nursingNotes}
              doctorNotes={this.state.doctorNotes}
              nameEquipment={this.state.nameEquipment}
              equipmentReference={this.state.equipmentReference}
              patient = {this.props.currentPatient}
              changeNotes={this.changeNotes}
            />
          </Fragment>

        ) : (
              <div className="provicional">
                {this.state.token ? (<button onClick={this.irAmpa}>Abrir el telemonitoreo</button>) : 'no hay token'}

                <button onClick={() => { this.redirectAmpa() }}>
                  Regresar al listado del telemonitoreo
                </button>
              </div>

            )
        }


      </div>
    );
  }
}
PatientInfoAmpa.propTypes = {
  getPatient: PropTypes.func,
  createAmpa: PropTypes.func,
  createMedicines: PropTypes.func,
  getAmpasTake: PropTypes.func
};
export default compose(
  graphql(CREATE_AMPA, { name: 'createAmpa' }),
  graphql(CREATE_MEDICINES, { name: 'createMedicines' })
)(withApollo(withRouter(PatientInfoAmpa)));

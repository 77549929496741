import { useEffect, useState } from "react";
import moment from 'moment';
import _ from 'lodash';
import client from '../utils/apollo';
import { GET_APPOINTMENTS_COST_CENTER, GET_CITIES_OPTIONS, GET_COUNTRIES_OPTIONS, GET_CUPS_CODE, GET_RELATED_SISPRO_OPTIONS, GET_SISPRO_OPTIONS, GET_STATES_OPTIONS, GET_COST_CENTERS, GET_PROFESSIONS } from "../graphql/types/billing/querys";
import { ATENTION_MODALITY_GROUP, EDUCATION_LEVEL_GROUP, ENTITY_GROUP, ETNIA_GROUP, GENDER_GROUP, IDENTITY_TYPE_GROUP, INCAPACITY_GROUP, MARITAL_STATUS_GROUP, TERRITORIAL_ZONE_GROUP, USER_TYPE_GROUP, getBillingDniType, getBillingGender, getEducationLevel, getMaritalStatus, getSeparatedNames, getUserType } from "../utils/helpers";

export const useBillingForm = ({ 
    updateField, 
    codStateResidence = null, 
    codeStateOrigin = null, 
    codOriginCountry = null,
    appointment,
    eps
}) => {

    const appointmentId = appointment?._id;

    const [identificationTypeSelect, setIdentificationTypeSelect] = useState([])
    const [userTypeSelect, setUserTypeSelect] = useState([])
    const [genderSelect, setGenderSelect] = useState([])
    const [territorialZone, setTerritorialZone] = useState([])
    const [incapacitySelect, setIncapacitySelect] = useState([])
    const [etniaSelect, setEtniaSelect] = useState([])
    const [entitySelect, setEntitySelect] = useState([])
    const [entityTypeSelect, setEntityTypeSelect] = useState([])
    const [atentionModalitySelect, setAtentionModalitySelect] = useState([])
    const [stateSelect, setStateSelect] = useState([])
    const [cities, setCities] = useState([])
    const [citiesOrigin, setCitiesOrigin] = useState([])
    const [costCenters, setCostCenters] = useState([])
    const [allowFillOriginCity, setAllowFillOriginCity] = useState(true);
    const [maritalStatusSelect, setMaritalStatusSelect] = useState([]);
    const [educationLevelSelect, setEducationLevelSelect] = useState([]);

    const fillDataGeneral = ({ currentPatient, appointment }) => {

        const { name, lastName } = currentPatient;

        const [nombre1, nombre2] = getSeparatedNames(name);
        const [apellido1, apellido2] = getSeparatedNames(lastName);

        if (!_.isEmpty(currentPatient?.type_dni)) {
            const selectedDniType = getBillingDniType(currentPatient.type_dni);

            updateField("tipoDocumentoIdentificacion", {
                name: selectedDniType,
                value: selectedDniType
            });
        }

        if (!_.isEmpty(currentPatient?.dni)) {
            updateField("NumDocumentoIdentificacion", currentPatient?.dni);
        }

        if (!_.isEmpty(nombre1)) {
            updateField("nombre1", nombre1);
        }

        if (!_.isEmpty(nombre2)) {
            updateField("nombre2", nombre2);
        }

        if (!_.isEmpty(apellido1)) {
            updateField("apellido1", apellido1);
        }

        if (!_.isEmpty(apellido2)) {
            updateField("apellido2", apellido2);
        }

        if (!_.isEmpty(currentPatient?.birthDay)) {
            updateField("fechaNacimiento", currentPatient?.birthDay);
        }
        
        if (!_.isEmpty(currentPatient?.gender)) {
            updateField("codSexo", getBillingGender(currentPatient?.gender));
        }

        if (!_.isEmpty(currentPatient?.email)) {
            updateField("Email", currentPatient?.email)
        }
        
        if (!_.isEmpty(currentPatient?.mobile)) {
            updateField("Celular", currentPatient?.mobile.trim())
        }
        
        updateField(
            "fechaInicioAtencion",
            moment(parseInt(appointment.time)).format('YYYY-MM-DD')
        );

        if (!_.isEmpty(appointment?.entity?._id)) {
            const selectedEps = eps.find(eps => eps._id === appointment?.entity?._id);        
            updateField("EntidadFacturacion", selectedEps.nit);
            
            handleGetRelatedEntityType(selectedEps.nit);
        }

        if (!_.isEmpty(currentPatient?.address)) {
            updateField("Direccion", currentPatient.address);
        }

        if (!_.isEmpty(currentPatient?.civil_status)) {
            updateField("EstadoCivil", getMaritalStatus(currentPatient?.civil_status));
        }

        if (!_.isEmpty(currentPatient?.education_level)) {
            updateField("NivelEscolaridad", getEducationLevel(currentPatient?.education_level));
        }

        if (!_.isEmpty(currentPatient?.regimeType)) {
            updateField("tipoUsuario", getUserType(currentPatient?.regimeType));
        }
    }

    const loadOptionsCountry = async (inputText) => {
        try {
            const { data } = await client.query({
                query: GET_COUNTRIES_OPTIONS,
                variables: {
                    filters: {
                        ...(inputText ? { name: inputText } : {})
                    },
                }
            });

            if (data.getCountries.data.length > 0) {
                return data.getCountries.data.map(item => ({
                    value: item.code,
                    label: item.name
                }))
            }
            return []
        } catch (error) {
            return []
        }
    }

    const loadOptionsProfessions = async (profession) => {
        let response;

        try {            
            const { data: { getProfessions } } = await client.query({
                query: GET_PROFESSIONS,
                variables: {
                    ...(profession ? { name: profession } : {})
                }
            });

            response = getProfessions.data.map(item => ({
                label: item.name,
                value: item.code
            }));
        
        } catch (error) {
            response = [];
        }

        return response;
    }

    const loadOptionsCities = async (inputText) => {
        if (inputText) {
            return cities.filter(city => city.label.toLowerCase().includes(inputText))
        }
        return cities
    }

    const loadOptionsCitiesOrigin = async (inputText) => {
        if (inputText) {
            return citiesOrigin.filter(city => city.label.toLowerCase().includes(inputText))
        }
        return citiesOrigin
    }

    const getCupsCode = async () => {
        try {

            if( appointmentId ){
                const { data: { getCupsCode } } = await client.query({
                    query: GET_CUPS_CODE,
                    variables: {
                        appointmentId
                    }
                });

                if (getCupsCode?.code) {
                    updateField("codConsulta", getCupsCode?.code)
                }
            }

        } catch (error) {}
    }

    const getApointmentCostCenter = async () => {
        try {

            if( appointmentId ){
                const { data: { getAppointmentCostCenter } } = await client.query({
                    query: GET_APPOINTMENTS_COST_CENTER,
                    variables: {
                        appointmentId
                    }
                });
                
                if (getAppointmentCostCenter?.data?.code) {
                    const costCenter = getAppointmentCostCenter?.data?.code;

                    updateField("CentroCostosEjecuta", costCenter);
                    updateField("CentroCostosSolicita", costCenter);
                }
            }

        } catch (error) {}
    }

    const getAllSelects = async () => {
        try {
            const { data: { getSisproOptions } } = await client.query({
                query: GET_SISPRO_OPTIONS,
                variables: {}
            });

            setIdentificationTypeSelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === IDENTITY_TYPE_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    _id: item.code,
                                    value: item.code
                                }))
            )
            setUserTypeSelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === USER_TYPE_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    value: item.code
                                }))
            )
            setGenderSelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === GENDER_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    _id: item.code
                                }))
            )
            setTerritorialZone(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === TERRITORIAL_ZONE_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    value: item.code
                                }))
            )
            setIncapacitySelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === INCAPACITY_GROUP.toLowerCase().trim())
                                .map(item => ({
                                        name: item.name,
                                        value: item.code
                                    }))
            )
            setEtniaSelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === ETNIA_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    value: item.code,
                                    _id: item.code
                                }))
            )
            setEntitySelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === ENTITY_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    value: item.code
                                }))
            )            
            setAtentionModalitySelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === ATENTION_MODALITY_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    value: item.code
                                }))
            )

            setMaritalStatusSelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === MARITAL_STATUS_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    value: item.code
                                }))
            )

            setEducationLevelSelect(
                getSisproOptions?.data
                                ?.filter(item => item.group.toLowerCase().trim() === EDUCATION_LEVEL_GROUP.toLowerCase().trim())
                                .map(item => ({
                                    name: item.name,
                                    value: item.code
                                }))
            )            

        } catch (error) {
        }
    }

    const getStates = async () => {

        try {
            const { data: { getStates } } = await client.query({
                query: GET_STATES_OPTIONS,
                variables: {}
            });

            setStateSelect(getStates?.data?.map(item => ({
                name: item.name,
                value: item.code,
                id: item._id
            })))
        } catch (error) {
            console.log(error)
        }
    }

    const getCitiesResidence = async () => {
        let filters = {};

        try {
            if (codStateResidence) {
                filters['state'] =  stateSelect?.find(state => state.value === codStateResidence).id
            }

            const { data: { getCities } } = await client.query({
                query: GET_CITIES_OPTIONS,
                variables: {
                    filters
                }
            });

            setCities(getCities.data.map(item => ({
                label: item.name,
                value: item.code
            })));

        } catch (error) {}
    }

    const getCitiesOrigin = async () => {

        try {
            if (codeStateOrigin) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                const idState = stateSelect?.find(state => state.value === codeStateOrigin).id
                const { data: { getCities } } = await client.query({
                    query: GET_CITIES_OPTIONS,
                    variables: {
                        filters: {
                            state: idState
                        },
                    }
                });

                return setCitiesOrigin(getCities.data.map(item => ({
                    label: item.name,
                    value: item.code
                })))
            }
            return setCitiesOrigin([])
        } catch (error) {
            console.log(error)
        }
    }

    const getCostCenters = async () => {
        try {
            const response = await client.query({
              query: GET_COST_CENTERS
            });

            const costCenters = response.data.getCostCenters.data.map(item => ({
                name: item.name,
                value: item.code
            }))
            
            setCostCenters(costCenters);            
        } catch (error) {}
    }

    const handleGetRelatedEntityType = async (code) => {
        try {
            const { data } = await client.query({
                query: GET_RELATED_SISPRO_OPTIONS,
                variables: {
                    code,
                    group: ENTITY_GROUP
                }
            });

            const entityTypesOptions = data.getRelatedSisproOptions.data.related.map(item => ({
                name: item.name,
                value: item.code
            }));

            setEntityTypeSelect(entityTypesOptions);

            if (entityTypesOptions.length === 1) {
                updateField('TipoEntidad', entityTypesOptions[0].value);
            }
        } catch (error) {}
    }

    const handleAllowFillOriginCity = () => {
        if (!_.isEmpty(codOriginCountry) && codOriginCountry != '170') {
            setAllowFillOriginCity(false);
            updateField('codDepartamentoOrigen', codOriginCountry);
            updateField('codMunicipioOrigen', codOriginCountry);
        } else {
            setAllowFillOriginCity(true);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getCitiesResidence();
        getCitiesOrigin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codStateResidence, codeStateOrigin]);

    useEffect(() => {
        handleAllowFillOriginCity();
    }, [codOriginCountry]);

    return {
        fillDataGeneral,
        identificationTypeSelect,
        userTypeSelect,
        genderSelect,
        territorialZone,
        incapacitySelect,
        etniaSelect,
        entitySelect,
        entityTypeSelect,
        atentionModalitySelect,
        stateSelect,
        maritalStatusSelect,
        educationLevelSelect,
        cities,
        citiesOrigin,
        costCenters,
        allowFillOriginCity,
        loadOptionsCountry,
        loadOptionsCities,
        loadOptionsCitiesOrigin,
        loadOptionsProfessions,
        handleGetRelatedEntityType,
        getAllSelects,
        getStates,
        getCupsCode,
        getCostCenters,
        getApointmentCostCenter
    }
}

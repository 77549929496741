import React, { Component } from 'react';
import TextInput from '../../general/textInput';
import SelectInput from '../../general/selectInput';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { USER_HISTORY } from '../../../graphql/types/usersModule/queries';
import ActionsTable from '../actionsTable';
import {
  actionsList,
  dateFilters
} from '../../../utils/users/actions/actionsUtils';

class ActionHistory extends Component {
  filterTimeOut = null;
  constructor (props) {
    super(props);
    const initialState = {
      _user: props._user,
      filters: ['fullName', 'action', 'days'],
      actions: [],
      filtered: false,
      filter: {},
      page: 1,
      actionLoad: true,
      filtersCollapsed: true,
      headers: ['Acción', 'Fecha', 'Descripción']
    };
    initialState.filters.map(filter => {
      return (initialState[filter] = '');
    });
    this.state = initialState;
  }

  updateActions = async (time=0) => {
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);

    const filter = this.buildFilters(this.state);
    const { _user } = this.state;
    const variables = { _user, ...filter };

    this.filterTimeOut = setTimeout( async () => {
      const actions = await this.props.client.query({
        query: USER_HISTORY,
        variables
      });
      if (this.state.filter !== filter) {
        this.setState({
          actions: actions.data.allHistoryUser.data,
          filtered: true,
          filter
        });
      }
    }, time);
  };

  buildFilters = state => {
    let filters = {};
    state.filters.map(filter => {
      if (state[filter] !== '') {
        if (filter === 'fullName' && state[filter].length < 4) {
          return null;
        } else {
          return (filters[filter] = state[filter]);
        }
      } else {
        return null;
      }
    });
    filters['page'] = this.state.page;
    return filters;
  };

  updateField = (name, { value }) => {
    value = name === 'days' ? parseInt(value, 10) : value;
    this.setState({ [name]: value, page: 1 }, () => this.updateActions(700));
  };

  scrolled = async o => {
    if (
      o.target.offsetHeight + o.target.scrollTop >=
      o.target.scrollHeight - 30
    ) {
      let { actions } = this.state;
      let { actionLoad } = this.state;
      if (actionLoad) {
        this.loadActions(actions);
      }
    }
  };

  loadActions = async actions => {
    const { _user } = this.props;
    const filter = this.buildFilters(this.state);
    const variables = { _user, ...filter };
    const response = await this.props.client.query({
      query: USER_HISTORY,
      variables
    });
    let data =
      response.data.allHistoryUser.data.length > 0
        ? response.data.allHistoryUser.data
        : [];
    actions = actions.concat(data);

    let { actionLoad } = this.state;
    if (data.length < 30) {
      actionLoad = false;
    }
    this.setState({ actions, page: this.state.page + 1, actionLoad });
    this.setState({ actions });
  };

  toggleFilters = () => {
    this.setState({ filtersCollapsed: !this.state.filtersCollapsed });
  };

  componentDidMount () {
    let { actions } = this.state;
    this.loadActions(actions);
  }

  render () {
    const { filtersCollapsed } = this.state;
    return (
      <div
        className={`contentListUser contentListUserHistory ${
          filtersCollapsed ? 'filters-collapsed' : ''
        }`}
      >
        <div className="flex j-c a-i-c search-container">
          <div className="search-field">
            <TextInput
              value={this.state.fullName}
              type="text"
              name="fullName"
              label={window.innerWidth < 780 ? 'Buscar' : 'Ingresa nombre'}
              updateField={this.updateField}
            />
          </div>
          <div className="users-filters-toggle text-right ripple-gray">
            <span
              className="large material-icons clickable"
              onClick={this.toggleFilters}
            >
              toc
            </span>
          </div>
        </div>
        <div className="user-filters-container flex j-c a-i-c f-w">
          <div className="filter-field">
            <SelectInput
              value={this.state.action}
              name="action"
              label="tipo de acción"
              options={actionsList}
              updateField={this.updateField}
            />
          </div>
          <div className="filter-field">
            <SelectInput
              value={this.state.days}
              name="days"
              label="Fecha"
              options={dateFilters}
              updateField={this.updateField}
            />
          </div>
        </div>
        <ActionsTable
          scrolled={this.scrolled}
          header={this.state.headers}
          body={this.state.actions}
        />
      </div>
    );
  }
}

export default withApollo(withRouter(ActionHistory));

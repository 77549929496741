import React, { Component } from 'react';
import './style.css';
import SelectInput from '../../general/selectInput';
import FormInput from "../../general/formInput";
import { withApollo } from "react-apollo";
import RoomSchedule from '../roomSchedule'

import { GET_RESOURCE, GET_RESOURCE_TYPES } from "../../../graphql/types/scheduleModule/queries";

class RoomScheduleFilters extends Component {

    constructor(props) {
        super(props)
        this.state = { showSchedule: false, selectedDate: '', selectedWorkSpaceId: null, workSpaces: [], isError: false, isLoading: true }
    }

    componentDidMount = async () => {
        try {
            this.setState({ workSpaces: [] })
            const responseResoureTypes = await this.props.client.query({ query: GET_RESOURCE_TYPES });
            const workSpaceResourceId = responseResoureTypes.data.getResourceType.data.find(resource => resource.code === 'espacioTrabajo')._id
            const responseWorkSpaces = await this.props.client.query({ query: GET_RESOURCE, variables: { _resourceType: workSpaceResourceId } });
            const workSpaces = responseWorkSpaces.data.getResource.data.sort((a, b) => a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1)
            this.setState({ workSpaces: workSpaces, isLoading: false })
        } catch (e) {
            this.setState({ isError: true, isLoading: false })
        }
    }



    updateWorkSpace = (name, { value }) => {
        this.setState({ selectedWorkSpaceId: value, showSchedule: false })
    }

    updateDate = (e) => {
        const selectedDate = e.target.value;
        this.setState({ selectedDate, showSchedule: false })
    }

    showSchedule = () => {
        const { selectedDate, selectedWorkSpaceId } = this.state;
        if (!selectedDate || !selectedWorkSpaceId) return;
        this.setState({ showSchedule: true })
    }

    render() {
        const { workSpaces, selectedDate, selectedWorkSpaceId, isError, isLoading, showSchedule } = this.state;
        if (isError) return <p>Error</p>
        if (isLoading) return <p>Cargando...</p>
        return (
            <div style={{ maxHeight: '100%', overflowY: 'auto' }}>

                <div className="room-schedule-filter-container">
                    <div className="filter-text">Selecciona una sala para ver su ocupación</div>

                    <div className="filters-container">

                        <div className="filter-field" style={{ borderRight: 'none', marginRight: 10 }}>
                            <SelectInput
                                label="Espacio de trabajo"
                                name="selectedWorkSpaceId"
                                value={selectedWorkSpaceId}
                                options={workSpaces}
                                updateField={this.updateWorkSpace}
                            />
                        </div>

                        <div className="container-input-schedule-list" style={{ paddingBottom: 12, marginRight: 15 }}>
                            <FormInput
                                type="date"
                                name="selectedDate"
                                label="Fecha"
                                style={{ maxWidth: 200 }}
                                value={selectedDate}
                                onChange={this.updateDate}
                            />
                        </div>

                        <button className="button button-sm bg-icon ripple" onClick={this.showSchedule}>
                            Mostrar
                    </button>

                    </div>

                </div>
                <div>
                    {showSchedule && <RoomSchedule workSpaceId={selectedWorkSpaceId} date={selectedDate}></RoomSchedule>}
                </div>
            </div>
        )
    }


}

export default withApollo(RoomScheduleFilters);

/* eslint-disable */
import React, { Fragment, Component } from 'react';
import DataTable from 'react-data-table-component';
import _, { debounce } from 'lodash';
import { context } from '../../../providers/institutionProvider';
import Alert from '../../../components/general/alert';

class InstitutionsList extends Component {

  static contextType = context;

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: 'Nombre',
          selector: row => row.name,
          sortable: true,
          sortField: 'name',
          width: '250px'
        },
        {
          name: 'Ciudad',
          selector: row => row.city,
          sortable: false,
          width: '250px'
        },
        {
          name: 'Direccion',
          selector: row => row.address,
          sortable: false,
          width: '250px'
        },
        {
          key: "action",
          text: "Action",
          className: "action",
          width: 100,
          align: "left",
          sortable: false,
          cell: row => (
            <>
              <button
                className="btn btn-sm"
                onClick={() => this.handleInstitutionEdition(row)}
              >
                <span className="clickable icon-edit"></span>
              </button>

              <button
                className="btn btn-sm"
                onClick={() => this.context.handleDeleteVerification(row._id)}
              >
                <span className="clickable icon-delete"></span>
              </button>
            </>
          )
        }
      ],
    };
  }

  componentDidMount() {
    this.context.getInstitutions();
  }

  debouncedSearch = debounce(search => {
    this.context.setFilter('search', search)
  }, 300);

  handleSearch = e => {
    this.debouncedSearch(e.target.value);
  }

  handleInstitutionEdition = data => {
    this.context.setFormData(data);
    this.context.openModal();
    this.context.setEditionMode(true);
  }

  handleDelete = async () => {
    await this.context.deleteInstitution();
    
    await this.context.getInstitutions();
  }

  render() {
    const { columns } = this.state;

    const { 
      institutions,
      totalInstitutions,
      handlePageChange,
      handleRowsChange,
      pagination: { perPage },
      handleSort,
      deleteVerification,
      deleteConfirmation,
      handleCancelDelete,
      handleAcceptDelete
    } = this.context;

    const myNewTheme = {
      rows: {
        fontSize: '50px',
      }
    };

    return (
      <Fragment>
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <input 
                name="filter" 
                id="filter" 
                type="text" 
                className="form-control" 
                placeholder='Buscar' 
                onChange={this.handleSearch}
              />
            </div>
          </div>

          <div className="row mh700 mb-10">
            <DataTable              
              columns={columns}
              customTheme={myNewTheme}
              data={institutions}              
              pagination
              paginationPerPage={perPage}
              paginationServer
              paginationTotalRows={totalInstitutions}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsChange}  
              onSort={handleSort}
              sortServer                                        
              rowStyle={{ alignItems: "stretch" }}
              style={{ display: "flex", alignItems: "center" }}
              width={9 * 150}
            />
          </div>
        </div>

        <Alert
          show={deleteVerification}
          title='Estas seguro?'
          message='Es irreversible'
          confirmButtonText='Eliminar'
          confirmButtonAction={() => this.handleDelete()}
          cancelButtonText='Cancelar'
          cancelButtonAction={() => handleCancelDelete()}
        />
        
        <Alert
          show={deleteConfirmation}
          title='Accion exitosa'
          message='Institucion eliminada satisfactoriamente'
          confirmButtonText='Aceptar'
          confirmButtonAction={() => handleAcceptDelete()}
        />
      </Fragment>
    );
  }

}
export default InstitutionsList;

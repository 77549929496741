
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { STATUS_LOGIN } from '../../../graphql/types/usersModule/mutations';

const Logout = ({ status_login }) => {
  // this.props
  status_login({ variables: { login: false } })
    .then(response => {})
    .catch(error => {
      // handle error
    });
  localStorage.clear();
  // this.props.history.push('login');
  // return <Redirect to="/login" />;
  window.location = "/login";
  // return null;
};

export default compose(graphql(STATUS_LOGIN, { name: 'status_login' }))(
  withApollo(withRouter(Logout))
);

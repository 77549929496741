/**
 * Patient
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';

// my components
import PatientForm from '../../../components/patients/patientForm';
import ConfirmationModal from '../../../components/general/confirmationModal';
import BodyContainer from '../../../components/general/bodyContainer';

import LibScanner from '../../../utils/libScanner';
import { withRouter } from 'react-router-dom';
const SCANNER = new LibScanner();

class Patient extends Component {
  state = {
    programTypes: [],
    stateScanner: 'default', // {default, search, loaded, error}
    isExecuteScanner: false
  }
  componentDidMount = () => {
    this._alert();
    // this.props.confirmationModal.subscribe(this._alert);
    this.props.getProgramTypes().then(programTypes => {
      this.setState({ programTypes });
    });

    // this.props.handleCanUpdatePatient(this.props.match.params.id);

    window.addEventListener('beforeunload', this._onPageExit)
  };
  componentWillUnmount = () => {
    this._onPageExit();

    window.removeEventListener('beforeunload', this._onPageExit)
  }
  _alert = async () => {
    const { confirmationModal, toggleConfirmationModal } = this.props;
    if (
      localStorage.getItem('canExitEdit') &&
      localStorage.getItem('clickSidebar') &&
      !confirmationModal
    ) {
      await toggleConfirmationModal();
    }
  };
  _onLoadScanner = async () => {
    this.setState({ stateScanner: 'search' });
    const { loadFieldsScanner } = this.props;
    const loadData = await SCANNER._onLoadData();
    const { code, data } = loadData;
    if (code !== 1){
      this.setState({ stateScanner: 'error' });
      return;
    }

    loadFieldsScanner(data);
    this.setState({ stateScanner: 'loaded', isExecuteScanner: true });
  }

  _onPageExit = () => {
    this.props.handlePatientUpdateSubscription(this.props.match.params.id, 'drop');
  }

  render () {
    const { programTypes, stateScanner, isExecuteScanner } = this.state;
    const {
      eps,
      photo,
      isLoader,
      location,
      renderText,
      modalPhoto,
      renderRadio,
      savePatient,
      errorMessage,
      renderSelect,
      renderSelectSearch,
      getScreenshot,
      successMessage,
      showModalPhoto,
      cancelRedirect,
      renderTextarea,
      renderTypeHead,
      currentPatient,
      confirmationModal,
      toggleConfirmationModal
    } = this.props;
    if (!confirmationModal) {
      this._alert();
    }
    return (
      <BodyContainer isLoader={isLoader}>
        {
          confirmationModal &&
            <ConfirmationModal
              onCancel={toggleConfirmationModal}
              onConfirm={cancelRedirect}
            />
        }
        <Accordion accordion={false}>
          <AccordionItem uuid="uniqueItem-create-patient" expanded={true}>
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>{`${currentPatient && currentPatient._id? 'Editar': 'Crear'} paciente`}</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody className="noPadding">
              <PatientForm
                eps={eps}
                photo={photo}
                location={location}
                modalPhoto={modalPhoto}
                savePatient={savePatient}
                stateScanner={stateScanner}
                errorMessage={errorMessage}
                programTypes={programTypes}
                getScreenshot={getScreenshot}

                renderText={renderText}
                renderRadio={renderRadio}
                renderSelect={renderSelect}
                renderTypeHead={renderTypeHead}
                renderTextarea={renderTextarea}
                renderSelectSearch={renderSelectSearch}

                showModalPhoto={showModalPhoto}
                successMessage={successMessage}
                currentPatient={currentPatient}
                isExecuteScanner={isExecuteScanner}
                toggleConfirmationModal={toggleConfirmationModal}

                onLoadScanner={this._onLoadScanner}

                canUpdatePatient={this.props.canUpdatePatient}
              />
            </AccordionItemBody>
          </AccordionItem>
        </Accordion>
      </BodyContainer>
    );
  }
}

Patient.propTypes = {
  eps: PropTypes.array,
  photo: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isLoader: PropTypes.bool,
  location: PropTypes.object,
  renderText: PropTypes.func,
  modalPhoto: PropTypes.func,
  savePatient: PropTypes.func,
  renderRadio: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderSelect: PropTypes.func,
  getScreenshot: PropTypes.func,
  renderTypeHead: PropTypes.func,
  successMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showModalPhoto: PropTypes.bool,
  cancelRedirect: PropTypes.func,
  renderTextarea: PropTypes.func,
  currentPatient: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getProgramTypes: PropTypes.func,
  confirmationModal: PropTypes.bool,
  toggleConfirmationModal: PropTypes.func,

  loadFieldsScanner: PropTypes.func,
};

export default withRouter(Patient);

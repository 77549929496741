import React from 'react';

const RadioInput = ({
  id,
  label,
  name,
  value,
  updateField,
  type,
  checked,
  disabled,
  labelRight,
  className
}) => {
  return (
    <div className={`check-container flex a-i-c j-sb ${className}`}>
      <div className="flex a-i-c">
        {labelRight && (
          <label htmlFor={id} className={disabled ? 'muted-text' : ''}>
            {label}
          </label>
        )}
        <input
          // className={`${value}`}
          disabled={disabled}
          type={type || 'radio'}
          checked={checked}
          name={name}
          value={value}
          onChange={e => {
            updateField(label, {
              value: e.target.checked,
              aux: value,
              radioName: name
            });
          }}
          id={id}
        />
        {!labelRight && (
          <label htmlFor={id} className={disabled ? 'muted-text' : ''}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export default RadioInput;

import React, { Component } from 'react';
import './style.css';
import moment from 'moment';
import RepeatedDaysSection from '../repeatedDaysSection';
import TextInputTime from '../../../components/general/textInputTime';
import { toTwelveHourFormat } from '../../../utils/functions';

class RepetitionModal extends Component {
  state = {
    date: this.props.resourceDay['_d'],
    currentDay: this.props.resourceDay,
    initialDate: '',
    finalDate: '',
    showDatesAlert: false,
    repeatedDays: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false
    }
  };

  componentDidMount = () => {
    this.setState({
      repeatedDays: { ...this.state.repeatedDays, [this.props.resourceDay.day()]: true }
    });
    this.onChange(this.props.resourceDay);
  };

  onChange = date => {
    const currentDay = moment(date);
    this.setState({ currentDay });
  };

  updateField = (name, { value, aux: day }) => {
    if (day === null){ this.setState({ [name]: value }); }
    else if (day === 7){
      this.setState({
        repeatedDays: {
          ...this.state.repeatedDays,
          1: value,
          2: value,
          3: value,
          4: value,
          5: value,
          6: value
        }
      });
    }
    else {
      this.setState({
        repeatedDays: { ...this.state.repeatedDays, [day]: value }
      });
    }
  };

  validateDates = (initial, final) => {
    const { repeatedDays, currentDay } = this.state;
    if (initial && final) {
      const initialArray = initial.split(':');
      const finalArray = final.split(':');
      const initialHour = initialArray[0];
      const initialMinute = initialArray[1];
      const finalHour = finalArray[0];
      const finalMinute = finalArray[1];
      const initialTime = moment()
        .hour(initialHour)
        .minutes(initialMinute)
        .seconds('00');
      const finalTime = moment()
        .hour(finalHour)
        .minutes(finalMinute)
        .seconds('00');

      if (initialTime.diff(finalTime) <= 0){
        this.props.onConfirm(
          toTwelveHourFormat(initial),
          toTwelveHourFormat(final),
          repeatedDays,
          currentDay
        );
      }
      else {
        this.setState({ showDatesAlert: true });
      }
    } else {
      this.setState({ showDatesAlert: true });
    }
  };

  removeAlert = () => {
    this.setState({ showDatesAlert: false });
  };

  render () {
    const { onCancel, resourceDay } = this.props;
    const { showDatesAlert, currentDay, date, repeatedDays } = this.state;
    return (
      <div className={'modal-container'}>
        <div className="modal-body-repetition flex f-c a-i-c j-sb text-center">
          <div className="text-center bg-icon password-modal-title ripple-title">
            Repetir horario laboral
          </div>
          <p className="modal-section">
            Elige el horario laboral de este recurso
          </p>
          <span className="header text-bold">Hora</span>
          <div
            className="hours-container flex j-sa a-i-c"
            onFocus={this.removeAlert}
          >
            <div className="flex j-c a-i-c f-w">
              <span className="mr-03">Desde</span>
              <TextInputTime
                type="time"
                updateField={this.updateField}
                value={this.state.initialDate}
                name="initialDate"
              />
            </div>
            <div className="flex j-c a-i-c f-w">
              <span className="mr-03">Hasta</span>
              <TextInputTime
                type="time"
                updateField={this.updateField}
                value={this.state.finalDate}
                name="finalDate"
              />
            </div>
          </div>
          {showDatesAlert && (
            <p className="error-msg">
              La hora inicial debe ser anterior a la hora de finalización
            </p>
          )}
          <p className="modal-section">
            Para repetir este horario laboral completa las siguientes opciones.
          </p>
          <RepeatedDaysSection
            repeatedDays={repeatedDays}
            resourceDay={resourceDay}
            currentDay={currentDay}
            onChange={this.onChange}
            updateField={this.updateField}
            date={date}
          />
          <div className="modal-actions mb-1">
            <button className="button button-sm bg-muted" onClick={onCancel}>
              Cancelar
            </button>
            <button
              className="button button-sm bg-icon ripple"
              onClick={() => {
                this.validateDates(
                  this.state.initialDate,
                  this.state.finalDate
                );
              }}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RepetitionModal;

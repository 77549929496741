/**
 * AppointmentsHistory
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import './style.css';

// my components
// import FormCalendar from '../../general/formCalendar';
import FormInput from '../../general/formInput';
import FormTypeHead from '../../general/formTypeHead';
import FormInputSelect from '../../general/formInputSelect';
import AppointmentsHistoryGrid from '../../general/appointmentHistoryGrid';
import stateAppointment from '../../../utils/appointment/stateAppointment';
import _ from 'lodash';
import { context } from '../.././../providers/patientProvider';
import { appointmentStates } from '../../../utils/constants';

moment.locale('es');

class AppointmentsHistory extends React.Component {
  pageHistory = 1;
  isFetchData = false;
  isLastPageHistory = false;

  static contextType = context;

  state = {
    filterDateHistory: '',
    filterStateHistory: '',
    filterPrestationId: '',
    filterPrestationName: '',
    indexFilterStateHistory: ''
  }
  _convertTimestamp = (date) => {
    const arrayDate=date.split('-');
    const newDate=arrayDate[1]+'/'+arrayDate[2]+'/'+arrayDate[0];
    return new Date(newDate).getTime(); // will alert 1330210800000
  }
  _onScroll = async o => {
    let { filterStateHistory, filterPrestationId, filterDateHistory } = this.state;
    const { getAppointments } = this.props;

    filterDateHistory = this._convertTimestamp(filterDateHistory);
    
    if (o.target.offsetHeight + o.target.scrollTop >= o.target.scrollHeight - 30) {
      if (!this.isLastPageHistory && !this.isFetchData) {
        this.pageHistory += 1;
        this.isFetchData = true;

        const response = await getAppointments(
          this.pageHistory, 
          filterPrestationId, 
          filterStateHistory, 
          filterDateHistory
        );

        if (response.data && (response.data).length === 0){
          this.isLastPageHistory = true;
        }

        this.isFetchData = false;
      }
    }
  };

  // onchange filter date
  _onFilterDate = async (e) => {
    this.setState({ filterDateHistory: e.target.value });
  }

  _onFilterState = ({ item, index }) => {
    this.setState({ filterStateHistory: item ? item.name : null });
    this.setState({ indexFilterStateHistory: index });
  }

  // onchange select type prestation (typehead)
  _onFilterPrestation = async (options) => {   
    // empty field
    if (options.length === 0){
      this.setState({
        filterPrestationId: '',
        filterPrestationName: ''
      });
     
    } else {
      this.setState({
        filterPrestationId: options[0]._id,
        filterPrestationName: options[0].name
      });  
    }
  }

  _onFilter = async () => {
    this.pageHistory = 1;

    const { filterStateHistory, filterPrestationId, filterDateHistory } = this.state;
    const { getAppointments } = this.props;

    if (!this.isFetchData) {
      this.isFetchData = true;

      const response = await getAppointments(
        this.pageHistory, 
        filterPrestationId, 
        filterStateHistory, 
        this._convertTimestamp(filterDateHistory)
      );
      
      if (response.data && (response.data).length === 0){
        this.isLastPageHistory = true;
      }

      this.isFetchData = false;
    }
  }
  
  // onkeyup input filter type prestation (typehead)
  _onInputFilterPrestation = async (value) => {
    const { loadServices } = this.props;
    const listReferringPhysician = await loadServices(value);

    this.setState({
      listReferringPhysician,
      valueReferringPhysician: value,
      filterPrestationName: '',
      filterPrestationId: ''
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { 
      filterStateHistory: oldState, 
      filterPrestationId: oldService, 
      filterDateHistory: oldDate 
    } = prevState;

    const { 
      filterStateHistory: newState, 
      filterPrestationId: newService, 
      filterDateHistory: newDate 
    } = this.state;
    
    if(
      !_.isEqual(oldState, newState) ||
      !_.isEqual(oldService, newService) ||
      !_.isEqual(oldDate, newDate)
    )
      this._onFilter();
  }

  componentDidMount() {
    if (this.context.patientIsWildcard) {
      this.setState({
        indexFilterStateHistory: '0',
        filterStateHistory: appointmentStates.scheduled
      })
    }
  }

  render (){
    const style = { maxWidth: 200 };
    const { indexFilterStateHistory, filterPrestationName, filterPrestationId, filterDateHistory } = this.state;
    const {
      currentServices,
      appointmentsService,
      currentAppointments,
      getStateAppointments,
      onVisibleModalCancel,
      rescheduleAppointment,
      idSelectedAppointment
    } = this.props;
    return (
      <div className="appointment-history" onScroll={this._onScroll}>
        <div className="head-appointment-history">
          <div>
            <FormTypeHead
              name="filter-prestacion-history"
              label="Prestación"
              style={style}
              options={currentServices}
              multiple={!1}
              onChange={this._onFilterPrestation}
              onInputChange={this._onInputFilterPrestation}
              selectedOptions={
                filterPrestationName !== ''? [{ name: filterPrestationName, id_: filterPrestationId }]: []
              }
            />
          </div>
          <div>
            <FormInputSelect
              name="filter-state-history"
              label="Estado"
              style={style}
              value={indexFilterStateHistory}
              options={stateAppointment}
              onChange={this._onFilterState}
              optionFieldId="name"
              optionFieldText="title"
            />
          </div>
          <div>
            <FormInput
              type="date"
              name="filter-date-history"
              label="Fecha"
              style={style}
              value={filterDateHistory}
              onChange={this._onFilterDate}
            />
          </div>
        </div>
        <AppointmentsHistoryGrid
          currentAppointments={currentAppointments}
          appointmentsService={appointmentsService}
          getStateAppointments={getStateAppointments}
          onVisibleModalCancel={onVisibleModalCancel}
          idSelectedAppointment={idSelectedAppointment}
          rescheduleAppointment={rescheduleAppointment}
        />
      </div>
    );
  }
}
AppointmentsHistory.propTypes = {
  currentServices: PropTypes.array,
  appointmentsService: PropTypes.object,
  currentAppointments: PropTypes.array,
  getStateAppointments: PropTypes.func,
  onVisibleModalCancel: PropTypes.func,
  rescheduleAppointment: PropTypes.func,
  idSelectedAppointment: PropTypes.string

};
export default AppointmentsHistory;

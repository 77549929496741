import moment from 'moment';
import _ from 'lodash';

export const elapsedTime = (timestamp) => {
  const initialDate = new Date(moment(Number(timestamp)).format("YYYY-MM-DD"));
  const diffDays = Number(moment().diff(moment(initialDate), 'days'));
  const days = diffDays >= 6 ? 6 : diffDays;
  return days;
}

export const initialDate = (dateInit) => {
  if (!dateInit) {
    return null;
  }
  return moment(dateInit).format('DD/MM/YYYY');
};

export const finalDate = (dateInit) => {
  if (!dateInit) {
    return null;
  }
  return moment(dateInit).add(5, 'days').format('DD/MM/YYYY');
};

export const convertDateHuman = (date) => {
  return moment(date).format("DD/MM/YYYY")
}

export const getAverage = numbers => {
  let response = 0;
  numbers = numbers.map(Number).filter(n => n > 0);

  if (!_.isEmpty(numbers))
    response = Math.floor(_.mean(numbers));

  return response;
}

export const parseTakes = takes => {
  takes = takes.map(
    t => ({
      ...t,
      created: moment(t.created, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY-M-D')
    })
  );
  takes = _.groupBy(takes, 'created');
  takes = Object.keys(takes).map(t => takes[t]);

  const parsedTakes = [];

  takes.forEach((take, index) => {
    let takesLength = take.length;

    if (takesLength < 4) {
      for (let i = takesLength; i <= 3; i++) {
        take[i] = {
          diastolic: 0,
          sistolic: 0,
          frequency: 0,
        };
      }
    }

    const diastolicDay = getAverage([take[0].diastolic, take[1].diastolic]);
    const sistolicDay = getAverage([take[0].sistolic, take[1].sistolic]);
    const frequencyDay = getAverage([take[0].frequency, take[1].frequency]);

    const diastolicNight = getAverage([take[2].diastolic, take[3].diastolic]);
    const sistolicNight = getAverage([take[2].sistolic, take[3].sistolic]);
    const frequencyNight = getAverage([take[2].frequency, take[3].frequency]);

    parsedTakes.push({
      timestamp: take[0].created,
      day: index + 1,
      diastolicDay,
      sistolicDay,
      frequencyDay,
      diastolicNight,
      sistolicNight,
      frequencyNight
    });
  });

  return parsedTakes;
}

export const avarageTaks = ({ avarage }) => {
  const mediaPromedy = {
    sistolic: 0,
    diastolic: 0,
    frecuency: 0
  };
  avarage.forEach((item) => {
    mediaPromedy.sistolic = mediaPromedy.sistolic + Number(item.sistolicDay) + Number(item.sistolicNight);
    mediaPromedy.diastolic = mediaPromedy.diastolic + Number(item.diastolicDay) + Number(item.diastolicNight);
    mediaPromedy.frecuency = mediaPromedy.frecuency + Number(item.frequencyDay) + Number(item.frequencyNight);
  });

  const avarageTaskTotal = {};
  for (const key in mediaPromedy) {
    avarageTaskTotal[key] = Math.floor(mediaPromedy[key] / 12);
  }
  return avarageTaskTotal;
}

export const capitalizeWords = (str) => {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
};

export const calculateAge = (age) => {
  return moment().diff(moment(age), 'years');
}

export const convertTagBase64 = () => {
  const originalTable = document.getElementById("chart");

  const originalSvg = originalTable.querySelector(".apexcharts-svg");
  const clonedSvg = originalSvg.cloneNode(true);
  clonedSvg.id = "clonedTable";
  clonedSvg.style.cssText = `
  width: 700px;
  height: 350px;
  `;
  const base64 = svgToBase64(clonedSvg);
  return base64;
};

export const extractNumbers = value => {
  return value.match(/\d/g).join("");
};

export const capitalize = text => {
  return text.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
};

export const getSessionTokens = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  return {
    token,
    refreshToken
  }
}

function svgToBase64(svgText) {
  const svgXml = new XMLSerializer().serializeToString(svgText);
  const base64 = Buffer.from(svgXml).toString('base64');
  return base64;
}

export const timestampToDate = timestamp => {
  return moment.unix(timestamp / 1000).format('DD/MM/YYYY');
}

export const IDENTITY_TYPE_GROUP = "tipo_identificacion"
export const USER_TYPE_GROUP = "tipo_usuario"
export const GENDER_GROUP = "sexo"
export const TERRITORIAL_ZONE_GROUP = "zona_territorial"
export const INCAPACITY_GROUP = "incapacidad"
export const ETNIA_GROUP = "etnia"
export const ENTITY_GROUP = "entidad"
export const ENTITY_TYPE_GROUP = "tipo_entidad"
export const ATENTION_MODALITY_GROUP = "modalidad_atencion"
export const MARITAL_STATUS_GROUP = "estado_civil";
export const EDUCATION_LEVEL_GROUP = 'nivel_escolaridad';

export const rulesOfXencoForm = {
  tipoDocumentoIdentificacion: true,
  NumDocumentoIdentificacion: true,
  codPrestador: true,
  nombre1: true,
  nombre2: false,
  apellido1: true,
  apellido2: false,
  tipoUsuario: true,
  fechaNacimiento: true,
  codSexo: true,
  codPaisResidencia: true,
  codDepartamentoResidencia: true,
  codMunicipioResidencia: true,
  codZonaTerritorialResidencia: true,
  incapacidad: true,
  codPaisOrigen: true,
  codDepartamentoOrigen: false,
  codMunicipioOrigen: true,
  Email: true,
  Celular: true,
  Direccion: true,
  profesion: false,
  TipoEntidad: true,
  EntidadFacturacion: true,
  nivelSalarial: false,
  causaMotivoAtencion: false,
  CentroCostosEjecuta: true,
  CentroCostosSolicita: true
}

export const validateFields = ( {data, rules} ) => {
  const fieldsNotFill = [];
    for (const key in rules) {
        if (rules[key] && (!data[key] || data[key].trim() === '')) {
            fieldsNotFill.push(key); // Agrega el campo faltante a la lista
        }
    }
    return fieldsNotFill;
}

export const NAME_OF_FIELDS_FORM_XENCO = {
  "tipoUsuario": "Tipo de usuario",
  "codPaisResidencia": "País residencia",
  "codDepartamentoResidencia": "Departamento residencia",
  "codMunicipioResidencia": "Municipio residencia",
  "codZonaTerritorialResidencia": "Zona territorial",
  "incapacidad": "Incapacidad",
  "codPaisOrigen": "País origen",
  "codMunicipioOrigen": "Municipio origen",
  "Direccion": "Dirección",
  "Etnia": "Etnia",
  "TipoEntidad": "Tipo de entidad",
  "EntidadFacturacion": "Entidad de facturación",
  "codPrestador": "Código de prestador",
  "tipoDocumentoIdentificacion": "Tipo de documento",
  "NumDocumentoIdentificacion": "Número de documento",
  "nombre1": "Primer nombre",
  "apellido1": "Primer apellido",
  "apellido2": "Segundo nombre",
  "Email": "Email",
  "Celular": "Celular",
  "codSexo": "Género",
  "fechaNacimiento": "Fecha de nacimiento",
  "codConsulta": 'Código de consulta',
  "xencoResponse": 'Respuesta de xenco'
}

export const validateEmail = (email) => {
  // Expresión regular para validar un email
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const getBillingDniType = dniType => {
  const dniTypes = [
    { sicor: 'NIT', billing: 'NI' },
    { sicor: 'CC', billing: 'CC' },
    { sicor: 'CN', billing: 'CN' },
    { sicor: 'TI', billing: 'TI' },
    { sicor: 'CE', billing: 'CE' },
    { sicor: 'PP', billing: 'PA' },
    { sicor: 'RC', billing: 'RC' }
  ];

  const response = dniTypes.find(dt => dt.sicor === dniType);

  return response.billing;
}

export const getBillingGender = gender => {
  const genders = [
    { sicor: 'M', billing: 'H' },
    { sicor: 'F', billing: 'M' },
    { sicor: 'O', billing: 'I' }
  ];

  const response = genders.find(g => g.sicor === gender);

  return response.billing;
}

export const getSeparatedNames = text => {
  const name = text.split(" ");

  const firstName = name[0];
  const secondName = name.slice(1).join(" ");

  return [firstName, secondName];
}

export const getMaritalStatus = maritalStatus => {
	const maritalStatuses = [
		{ sicor: 'soltero', billing: 'SO' },
		{ sicor: 'casado', billing: 'CA' },
		{ sicor: 'viudo', billing: 'VI' },
		{ sicor: 'separado', billing: 'SE' },
		{ sicor: 'union_libre', billing: 'UL' },
		{ sicor: 'soltero', billing: 'DE' }
	];

	const response = maritalStatuses.find(ms => ms.sicor === maritalStatus);
  
	return response.billing;
}

export const getEducationLevel = educationLevel => {
	const educationLevels = [
		{ sicor: 'primaria', billing: '02' },
		{ sicor: 'primaria', billing: '01' },
		{ sicor: 'secundaria', billing: '03' },
		{ sicor: 'secundaria', billing: '04' },
		{ sicor: 'secundaria', billing: '05' },
		{ sicor: 'secundaria', billing: '06' },
		{ sicor: 'tecnico', billing: '07' },
		{ sicor: 'tecnologo', billing: '08' },
		{ sicor: 'profesional', billing: '09' },
		{ sicor: 'postgrado', billing: '10' },
		{ sicor: 'postgrado', billing: '11' },
		{ sicor: 'postgrado', billing: '12' },
		{ sicor: 'postgrado', billing: '12' },
		{ sicor: 'primaria', billing: '13' }
	];

	const response = educationLevels.find(el => el.sicor === educationLevel);
  
	return response.billing;
}

export const getUserType = userType => {
	const userTypes = [
		{ sicor: 'contributivo', billing: '01' },
		{ sicor: 'contributivo', billing: '02' },
		{ sicor: 'subsidiado', billing: '04' },
		{ sicor: 'contributivo', billing: '12' }
	];

	const response = userTypes.find(ut => ut.sicor === userType);
  
	return response.billing;
}
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { PatientConsumer } from '../../../providers/patientProvider';
import PatientInfoAmpa from './ampa';
class Ampa extends Component {

  render () {
    return (
        <PatientConsumer>
            {props => <PatientInfoAmpa {...props} />}
        </PatientConsumer>
    );
  }
}
export default withApollo(withRouter(Ampa));
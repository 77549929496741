/**
 * AppointmentWrapper
 */
import React from 'react';
import AppointmentDisponibility from './index';

const AppointmentWrapper = props => {
  return <AppointmentDisponibility {...props} />;
};

export default AppointmentWrapper;

/* eslint-disable */
import React, { Component, Fragment } from 'react';
import Modal from '../../components/general/modal';
import PropTypes from 'prop-types';
import imageWarning from './iconwarning.png';
import './style.css';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    props = {
      openModalRooms: false,
      closeModal: () => { },
      putActionConfirm: () => { }
    };
  }

  render() {
    const { openModalRooms, closeModal, putActionConfirm } = this.props;
    return (
      <Fragment>
        {openModalRooms &&
          <Modal id="confirmModal" onClose={() => closeModal()} title="Confirmar Accion">
            <Fragment>
              <div className="modal-inner-content">
                <img className="image-warning" src={imageWarning}></img>
                <div className="modal-text">¿Estas seguro que deseas fusionar los médicos<br></br> remitentes seleccionados?</div>
                <div className="modal-buttons">
                  <button onClick={() => closeModal()} className="modal-button-cancel"> Cancelar</button>
                  <button onClick={() => putActionConfirm()} className="modal-button-confirm"> Confirmar</button>
                </div>
              </div>
            </Fragment>
          </Modal>
        }

      </Fragment>

    );
  }
}

ConfirmModal.propTypes = {
  openModalRooms: PropTypes.bool,
  closeModal: PropTypes.func,
  putActionConfirm: PropTypes.func
};
export default ConfirmModal;
/**
 * Profile
 */
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

// my components
import BodyContainer from '../../../components/general/bodyContainer';
import UserForm from '../../../components/usersModule/userForm';
import UserInfo from '../../../components/usersModule/userInfo';
import ActionHistory from '../../../components/usersModule/actionHistory';

import { GET_USER } from '../../../graphql/types/usersModule/queries';
import { getUserFromToken } from '../../../utils/functions';
import UserFormData from '../../../utils/users/userFormData.json';
import UserInfoData from '../../../utils/users/userInfoData.json';
import './style.css';

class Profile extends Component {
  state = { successMessage: '', isLoader: false };

  getCurrentUser = async () => {
    this.setState({ isLoader: true });
    const user = getUserFromToken();
    const _id = user.userId;
    const result = await this.props.client.query({
      query: GET_USER,
      variables: {
        _id
      }
    });

    const currentUser = result.data.getUser.data;
    this.setState({ currentUser, isLoader: false });
  };

  componentDidMount = async () => {
    this.getCurrentUser();
  };

  exitEdit = wait => {
    const newPath = this.props.location.pathname.replace(/(\/edit)/, '');
    if (wait){ setTimeout(() => this.props.history.push(newPath), 2500); }
    else { this.props.history.push(newPath); }

    this.getCurrentUser();
  };

  showSuccessAlert = (successMessage, wait) => {
    this.setState({ successMessage });
    this.exitEdit(wait);
    setTimeout(() => {
      this.setState({ successMessage: '' });
    }, 2000);
  };

  editUser = () => {
    this.props.history.push('/profile/edit');
    // this.setState({ edit: true });
  };

  render () {
    const { currentUser, successMessage, isLoader } = this.state;

    if (!currentUser) {
      return (
        <BodyContainer isLoader={true}>
          <div>Cargando...</div>
        </BodyContainer>
      );
    }

    const urlEdit = /(edit)/.test(this.props.location.pathname);

    const userFields = UserInfoData[this.state.currentUser._role.role];
    const disabledFields = {};
    userFields.map(field => {
      return (disabledFields[field.name] = field.locked);
    });
    return (
      <BodyContainer isLoader={isLoader}>
        <Accordion accordion={false}>
          <AccordionItem uuid="uniqueItem-edit-profile" expanded={true}>
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>Editar perfil</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody className="userMainFields">
              {
                (currentUser && urlEdit) &&
                  <UserForm
                    formData={UserFormData}
                    currentUser={currentUser}
                    disabledFields={disabledFields}
                    exitEdit={this.exitEdit}
                    showSuccessAlert={this.showSuccessAlert}
                  />
              }
              {
                (currentUser && !urlEdit) &&
                  <UserInfo
                    currentUser={currentUser}
                    editUser={this.editUser}
                    userFields={userFields}
                  />
              }
              {
                successMessage &&
                  <div className="success-msg">
                    <p className="text-center success-msg">
                      <span className="icon-check mr-02" />
                      {successMessage}
                    </p>
                  </div>
              }
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem uuid="uniqueItem-my-history">
            <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
              <span>Mi historial</span>
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody>
              <ActionHistory _user={currentUser._id} />
            </AccordionItemBody>
          </AccordionItem>
        </Accordion>
      </BodyContainer>
    );
  }
}
Profile.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};
export default withApollo(Profile);

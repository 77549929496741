/**
 * ResetPassword
 */
/* eslint-disable */
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import Popover from 'react-popover';

import { CHANGE_PASSWORD } from '../../../graphql/types/usersModule/mutations';
import { VERIFY_TOKEN_QUERY } from '../../../graphql/types/usersModule/queries';
import { passwordPopoverProps } from '../../../utils/functions';

// my components
import TextInput from '../../../components/general/textInput';

import './style.css';
import MESSAGES from './messages.json';

class ResetPassword extends Component {
  state = {
    message: MESSAGES['error'],
    success: false,
    newPassword: '',
    newPasswordConfirmation: '',
    passwordRequirements: {
      length: {
        id: 1,
        value: false,
        message: 'Contener al menos 6 caracteres de longitud'
      },
      upper: { id: 2, value: false, message: 'Contener una letra mayúscula' },
      lower: { id: 3, value: false, message: 'Contener una letra minúscula' },
      number: { id: 4, value: false, message: 'Contener un número' }
    }
  };

  showError = () => {
    this.setState({ error: true });
  };
  removeError = () => {
    this.setState({ error: false });
  };
  toggleMatchErrors = () => {
    this.setState({ showMismatch: !this.state.showMismatch });
  };
  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  showPopover = () => {
    this.setState({ popoverIsOpen: true }, this.removeError);
  };
  closePopover = () => {
    this.setState({ popoverIsOpen: false }, this.removeError);
  };

  matchPasswords = ({ newPassword, newPasswordConfirmation }) => {
    const match = newPassword === newPasswordConfirmation;
    if (!match) {
      const message = 'Las contraseñas no coinciden';
      this.setState({ message });
    }
    return match;
  };

  scanPassword = ({ newPassword }) => {
    const status = {
      length: newPassword.length >= 6,
      upper: /[A-Z]+/.test(newPassword),
      lower: /[a-z]+/.test(newPassword),
      number: /[0-9]+/.test(newPassword)
    };
    const newPasswordState = this.state.passwordRequirements;
    Object.keys(status).forEach(req => {
      newPasswordState[req].value = status[req];
    });
    return newPasswordState;
  };

  updateField = (name, { value }) => {
    let error = false;
    const passwordRequirements = this.scanPassword({
      ...this.state,
      [name]: value
    });
    if (this.state.showMismatch) {
      error = !this.matchPasswords({ ...this.state, [name]: value });
    }
    this.setState({ [name]: value, error, passwordRequirements });
  };

  validate = () => {
    const invalidReq = Object.keys(this.state.passwordRequirements).filter(
      req => {
        return this.state.passwordRequirements[req].value === false;
      }
    );
    const restrictions = invalidReq.length === 0;
    const match = this.matchPasswords(this.state);
    if (!restrictions) {
      let indication = this.state.passwordRequirements[invalidReq[0]].message;
      indication = indication[0].toLowerCase() + indication.slice(1);
      const message = `La contraseña debe ${indication}`;
      this.setState({ message });
    }
    return restrictions & match;
  };

  toLogin = () => {
    this.props.history.push('/login');
  };

  confirm = async e => {
    e.preventDefault();
    if (this.validate()) {
      const token = this.props.match.params.resetToken;
      const { newPassword } = this.state;
      const result = await this.props.resetPassword({
        variables: {
          token,
          password: newPassword
        }
      });
      const success = result.data.changePassword.success;
      const error = !success;
      const message = success ? MESSAGES['success'] : MESSAGES['error'];
      this.setState({ error, success, message });
    } else {
      this.showError();
    }
  };

  render () {
    if (this.props.verifyToken && this.props.verifyToken.loading) {
      return <div>Loading</div>;
    }

    if (this.props.verifyToken && this.props.verifyToken.error) {
      return <div>Error</div>;
    }

    const success = this.props.verifyToken.verifyTokenMail.success;
    const { passwordRequirements: requirements, popoverIsOpen } = this.state;
    const popoverProps = passwordPopoverProps(popoverIsOpen, requirements, this.closePopover);
    const confirmationPath = (/(confirm-account)/).test(this.props.location.pathname);

    return (
      <div className="container flex j-c a-c-c a-i-c">
        <div className="reset-password-container">
          <img src={require('../../../assets/logo.png')} alt="Logo Sicor" />
          <p className="text-center helper-msg font-18">
            {confirmationPath ? 'Establecer contraseña' : 'Recuperar contraseña'}
          </p>

          <form className="text-center" onSubmit={this.confirm}>
            <div className=" password-group">
              <Popover {...popoverProps}>
                <div className="form-inline" onFocus={this.showPopover}>
                  <TextInput
                    value={this.state.newPassword}
                    type={this.state.showPassword ? 'text' : 'password'}
                    name="newPassword"
                    label="Nueva contraseña"
                    updateField={this.updateField}
                  />
                  {this.state.popoverIsOpen && (
                    <i
                      className={`clickable small material-icons ${
                        this.state.showPassword
                          ? 'shown-password'
                          : 'hidden-password'
                      }`}
                      onClick={this.toggleShowPassword}
                    >
                      remove_red_eye
                    </i>
                  )}
                </div>
              </Popover>
            </div>

            <div
              className={` ${this.state.error ? 'wrong-input' : null}`}
              onFocus={this.toggleMatchErrors}
              onBlur={this.toggleMatchErrors}
            >
              <TextInput
                value={this.state.newPasswordConfirmation}
                type="password"
                name="newPasswordConfirmation"
                label="Confirmar nueva contraseña"
                updateField={this.updateField}
                disablePaste={true}
              />
            </div>

            <div className="text-center mt-2">
              <button className="button button-lg bg-primary" type="submit" onClick={this.confirm}>
                Guardar
              </button>
            </div>
          </form>
          {(this.state.error || !success) ? (
            <p className="text-center error-msg">{this.state.message}</p>
          ) : null}
          {this.state.success ? (
            <p className="text-center success-msg">{this.state.message}</p>
          ) : null}
          {(!success || this.state.success) && (
            <div className="text-center" id="redirection">
              <a href="" onClick={this.toLogin}>
                {' '}
                Ir a login{' '}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(CHANGE_PASSWORD, { name: 'resetPassword' }),
  graphql(VERIFY_TOKEN_QUERY, {
    name: 'verifyToken',
    options: ownProps => {
      const token = ownProps.match.params.resetToken;
      return {
        variables: { token }
      };
    }
  })
)(ResetPassword);

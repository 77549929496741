import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
	mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			success
			token
			refreshToken
			errors {
				path
				message
			}
		}
	}
`;

export const CHANGE_PASSWORD = gql`
	mutation changePassword($token: String!, $password: String!) {
		changePassword(token: $token, password: $password) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const PASSWORD_RECOVERY_MUTATION = gql`
	mutation recoverPassword($email: String!) {
		recoverPassword(email: $email) {
			success
			errors {
				path
				message
			}
		}
	}
`;
export const CREATE_USER = gql`
	mutation createUser(
		$name: String
		$address: String
		$username: String
		$lastName: String
		$birthDay: String
		$dni: String
		$phone: String
		$mobile: String
		$email: String
		$active: Boolean
		$_role: ID
		$medical_record: String
		$_position: ID
		$signature: String
		$services: String
		$series: String
		$canApproveMaps: Boolean
		$canPublishMaps: Boolean
	) {
		createUser(
			birthDay: $birthDay
			username: $username
			address: $address
			name: $name
			lastName: $lastName
			dni: $dni
			phone: $phone
			mobile: $mobile
			email: $email
			active: $active
			_role: $_role
			medical_record: $medical_record
			_position: $_position
			signature: $signature
			services: $services
			series: $series
			canApproveMaps: $canApproveMaps
			canPublishMaps: $canPublishMaps
		) {
			success
			data {
				_id
			}
			errors {
				path
				message
			}
		}
	}
`;
export const UPDATE_USER = gql`
	mutation updateUser(
		$_id: ID
		$name: String
		$username: String
		$lastName: String
		$address: String
		$birthDay: String
		$dni: String
		$phone: String
		$mobile: String
		$email: String
		$active: Boolean
		$_role: ID
		$medical_record: String
		$_position: ID
		$signature: String
		$services: String
		$series: String
		$canApproveMaps: Boolean
		$canPublishMaps: Boolean
	) {
		updateUser(
			_id: $_id
			birthDay: $birthDay
			username: $username
			address: $address
			name: $name
			lastName: $lastName
			dni: $dni
			phone: $phone
			mobile: $mobile
			email: $email
			active: $active
			_role: $_role
			medical_record: $medical_record
			_position: $_position
			signature: $signature
			services: $services
			series: $series
			canApproveMaps: $canApproveMaps
			canPublishMaps: $canPublishMaps
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const SINGLE_UPLOAD = gql`
	mutation singleUpload($file: Upload!) {
		singleUpload(file: $file) {
			id
			path
			filename
			mimetype
			encoding
		}
	}
`;

export const TOGGLE_SIDEBAR = gql`
	mutation toggleSidebar($collapsed: Boolean!) {
		toggleSidebar(collapsed: $collapsed) @client {
			collapsed
		}
	}
`;

export const STATUS_LOGIN = gql`
	mutation setCurrentLogin($login: Boolean!) {
		setCurrentLogin(login: $login) @client {
			status
		}
	}
`;

export const CHANGE_PASSWORD_PROFILE = gql`
	mutation changePasswordProfile(
		$_id: ID!
		$password: String!
		$newPassword: String!
	) {
		changePasswordProfile(
			_id: $_id
			password: $password
			newPassword: $newPassword
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const SHOW_ALERT = gql`
	mutation showAlert($status: Boolean!) {
		showAlert(status: $status) @client {
			status
		}
	}
`;

/**
 * ModalSchedule
 */
import React, { Fragment } from 'react';
import _ from 'lodash';

import { PatientConsumer } from '../../../providers/patientProvider';

// components
import Card from './card';
import DataAppointment from './dataAppointment';

const ModalSchedule = () => {
  return (
    <PatientConsumer>
      {({
        appointmentsService,
        handleShowModalSchedule,
        dataAppointmentsService,
        handleappointmentsInfo,
        setAppointmentInfoObservation,
        appointmentsInfo,
        appointmentsInfoObservation,
        saveBookAppointment,
        sede,
        entityService,
        selectedServices,
        eps,
        blockResource,
        errSchedule,
        errSaveAppointment
      }) => {
        const entity = _.find(eps, { _id: entityService });
        const noSave = Object.keys(errSaveAppointment);
        return (
          <Fragment>
            <div className="modal-container" />

            <div className="modalSchedule modal-body flex f-c text-center">
              <div className="modal-header ripple-title">
                <p>Información de la cita</p>
              </div>
              <div className="modal-msg">
                {
                  Object.keys(appointmentsService).map((key, index) => {
                    let doctor = false;
                    let observation = '';

                    if (appointmentsInfoObservation[key]) observation = appointmentsInfoObservation[key];
                    if (appointmentsInfo[key] && appointmentsInfo[key].doctorObj) doctor = appointmentsInfo[key].doctorObj;
                    if (appointmentsService[key] && !appointmentsService[key].service._id) {
                      appointmentsService[key].service = _.find(
                        selectedServices,
                        {
                          _id: appointmentsService[key].service
                        }
                      );
                    }
                    return (
                      <div
                        key={`ms-${index}`}
                        className="HousrService flex j-r a-i-c"
                      >
                        <Card
                          doctor={doctor}
                          sede={sede}
                          entity={entity}
                          service={appointmentsService[key]}
                          errSaveAppointment={errSaveAppointment[key]}
                        />
                        <DataAppointment
                          observation={observation}
                          blockResource={blockResource}
                          appointmentsInfo={appointmentsInfo[key]}
                          handleappointmentsInfo={handleappointmentsInfo}
                          setAppointmentInfoObservation={setAppointmentInfoObservation}
                          service={appointmentsService[key]}
                          doctors={dataAppointmentsService.doctors}
                          spaces={dataAppointmentsService.spaces}
                        />
                      </div>
                    );
                  })
                }
              </div>
              <p className="text-center error-msg" style={{ marginBottom: 0 }}>
                {errSchedule.path &&
                  appointmentsService[errSchedule.path] &&
                  appointmentsService[errSchedule.path].service &&
                  `${appointmentsService[errSchedule.path].service.name}: `}

                { errSchedule.msg && `${errSchedule.msg}` }
              </p>
              <div className="modal-actions mt-1 ">
                <button
                  className="button button-sm bg-muted"
                  onClick={() => handleShowModalSchedule(false)}
                >
                  Cancelar
                </button>
                <button
                  className="button button-sm bg-icon ripple"
                  onClick={() => { if (noSave.length === 0) saveBookAppointment(); }}
                >
                  Agendar
                </button>
              </div>
            </div>
          </Fragment>
        );
      }}
    </PatientConsumer>
  );
};

export default ModalSchedule;

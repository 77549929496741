/**
 * ServiceForm
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectInput from '../../../components/general/selectInput';
import TextInput from '../../../components/general/textInput';
import { minutesList, hoursList } from '../../../utils/services/servicesUtils';
import { GET_SERVICESFAMILY } from '../../../graphql/types/servicesFamilyModule/queries';

import './style.css';

class ServiceForm extends Component {
  state = {
    focusedName: false,
    servicesFamilies: [],
  };

  componentDidMount () {
    this.loadServicesFamilies();
  }

  async loadServicesFamilies (){

    this.props.values.client
        .query({
          query: GET_SERVICESFAMILY,
          variables: {}
        })
        .then(response => {
          this.setState({ servicesFamilies: response.data.getServicesFamily.data });
        })
        .catch(()=> {
          console.log('error')
      });
  }

  toggleFocus = () => {
    this.setState({ focusedName: !this.state.focusedName });
  };

  render () {
    let { values: { hours, minutes, name, servicefamily} } = this.props;

    const {
      clear,
      values: { currentServices, serviceErrors },
      updateField,
      currentService
    } = this.props;

    if (!name && currentService.name) name = currentService.name;
    if (!hours && currentService.duration) hours = Math.floor(currentService.duration / 60);
    if (!minutes && currentService.duration) minutes = currentService.duration % 60;
    if (!servicefamily && currentService.servicefamily) servicefamily = currentService.servicefamily;
    
    const { focusedName } = this.state;
    
    return (
      <div className="container-resources-form flex j-c">
        <div
          className={`p-relative ${serviceErrors['name'] ? 'wrong-input' : ''}`}
          onFocus={() => {
            this.toggleFocus();
            clear();
          }}
          onBlur={this.toggleFocus}
        >
          <TextInput
            type="text"
            label="Nombre"
            updateField={updateField}
            value={name}
            name="name"
          />
          {
            currentServices.length > 0 && focusedName && (
              <div className="existing-services">
                {
                  currentServices.map((service, index) => {
                    return <div key={index}>{service.name}</div>;
                  })
                }
              </div>
            )
          }
        </div>
        <div className={`duration flex j-fs a-i-c ${serviceErrors['duration'] ? 'wrong-input' : ''}`} >
          <div className="hours flex j-fs a-i-c" onFocus={clear}>
            <SelectInput
              name="hours"
              label="Duración"
              value={hours}
              options={hoursList}
              labelActive={true}
              updateField={updateField}
              without_blank={true}
            />
            <span> Horas </span>
          </div>
          <div className="minutes flex j-fs a-i-c" onFocus={clear}>
            <SelectInput
              name="minutes"
              value={minutes}
              options={minutesList}
              updateField={updateField}
              without_blank={true}
            />
            <span> Minutos </span>
          </div>
        </div>
        <div className={`duration flex j-fs a-i-c`} >
          <div className={`services-families ${serviceErrors['servicefamily'] ? 'wrong-input' : ''}`}>
            <SelectInput
              name="servicefamily"
              label="Familia de prestaciones"
              value={servicefamily}
              options={this.state.servicesFamilies}
              labelActive={true}
              updateField={updateField}
              without_blank={true}
            />
          </div>
        </div>

      </div>
    );
  }
}
ServiceForm.propTypes = {
  clear: PropTypes.func,
  values: PropTypes.object,
  timeOut: PropTypes.number,
  quantity: PropTypes.number,
  updateField: PropTypes.func,
  currentService: PropTypes.object
};
export default ServiceForm;

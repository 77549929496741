import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { GET_RESOURCE } from '../graphql/types/resourcesModule/queries';
import { GET_SERVICES } from '../graphql/types/servicesModule/queries';
import { UPDATE_SERVICE } from '../graphql/types/servicesModule/mutations';

import {
  days,
  splitSerie,
  validateOverlap
} from '../utils/resources/resourcesUtils';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo, compose } from 'react-apollo';
import {
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  DELETE_RESOURCE
} from '../graphql/types/resourcesModule/mutations';
import { skipHolidays } from '../utils/functions';

const { Consumer, Provider } = createContext({
  currentResource: undefined,
  date: moment()['_d'],
  currentDay: moment(),
  momentDay: null,
  interval: null,
  repeatedDays: null,
  currentWeek: [],
  series: [],
  name: '',
  sede: '',
  active: true,
  quantity: 0,
  specialSchedule: false,
  selectedServices: [],
  currentType: {},
  errorMessage: '',
  successMessage: '',
  error: false,
  success: false,
  resourceErrors: {},
  allServices: [],
  completeSelectedServices: [],
  currentService: false,
  disableSubmit: false,
  SkipHolidays: undefined,
  max: 50,
  timeOut: null
});

class ResourceProvider extends Component {
  state = {
    isLoader: false,
    currentResource: undefined,
    date: moment()['_d'],
    currentDay: moment(),
    momentDay: null,
    interval: null,
    repeatedDays: null,
    currentWeek: [],
    series: [],
    name: '',
    sede: '',
    active: true,
    quantity: 0,
    specialSchedule: false,
    selectedServices: [],
    currentType: {},
    errorMessage: '',
    successMessage: '',
    error: false,
    success: false,
    resourceErrors: {},
    allServices: [],
    completeSelectedServices: [],
    currentService: false,
    disableSubmit: false,
    SkipHolidays: undefined,
    max: 50,
    timeOut: null
  };

  componentDidMount = () => {
    if (/(\/new)/.test(this.props.location.pathname)) this.getCurrentWeek(this.state.currentDay);
  };

  resetResource = () => {
    this.setState({
      currentResource: undefined,
      date: moment()['_d'],
      currentDay: moment(),
      momentDay: null,
      interval: null,
      repeatedDays: null,
      currentWeek: [],
      series: [],
      name: '',
      sede: '',
      quantity: 0,
      specialSchedule: false,
      selectedServices: [],
      currentType: {},
      errorMessage: '',
      successMessage: '',
      error: false,
      success: false,
      resourceErrors: {}
    });
  };

  getCurrentResource = async _id => {
    this.setState({ isLoader: true });
    const result = await this.props.client.query({
      query: GET_RESOURCE,
      variables: {
        _id
      }
    });

    const currentResource = result.data
      ? result.data.getResource.data
      : result.loading
        ? undefined
        : false;
    let series;
    if (currentResource) {
      if (currentResource[0].series) {
        if (currentResource[0].specialSchedule) {
          series = {};
          const currentSeries = JSON.parse(currentResource[0].series);
          Object.keys(currentSeries).map(id => {
            series[id] = currentSeries[id];
            return (series[id] = series[id].map(serie => {
              return {
                ...serie,
                initialDate: moment(serie.initialDate).local(),
                finalDate: moment(serie.finalDate).local()
              };
            }));
          });
        } else {
          series = JSON.parse(currentResource[0].series);
          series = series.map(serie => {
            return {
              ...serie,
              initialDate: moment(serie.initialDate),
              finalDate: moment(serie.finalDate)
            };
          });
        }
      } else {
        series = [];
      }
      const name = currentResource[0].name;
      const active = currentResource[0].active;
      const sede = currentResource[0]._sede ? currentResource[0]._sede._id : '';
      const quantity = currentResource[0].quantity || 0;
      const specialSchedule = currentResource[0].specialSchedule;
      const currentType = currentResource[0]._resourceType;
      const timeOut = currentResource[0].timeOut;
      const selectedServices = currentResource[0].services? JSON.parse(currentResource[0].services): [];

      this.setState(
        {
          name,
          sede,
          series,
          active,
          quantity,
          isLoader: false,
          currentType,
          specialSchedule,
          timeOut,
          currentResource: currentResource[0],
          selectedServices
        },
        () => {
          this.parseServices();
        }
      );
    } else {
      this.setState({
        isLoader: false,
        currentResource
      });
    }
  };

  deleteResource = async (_id) => {
    const response = await this.props.deleteResource({ variables: { _id } });

    if (response && response.data && response.data.deleteResource && response.data.deleteResource.success) return { state: true };
    return { state: false };
  }

  parseServices = async () => {
    if (
      this.state.currentResource._resourceType.name === 'Espacio de trabajo' ||
      this.state.currentResource._resourceType.name === 'Equipo'
    ) {
      const {
        data: {
          getServices: { data: allServices }
        }
      } = await this.props.client.query({
        query: GET_SERVICES,
        variables: { perPage: 500 }
      });

      let services = this.state.currentResource.services || '[]';
      services = JSON.parse(services);

      const completeSelectedServices = [];      
      
      services.forEach(serviceId => {
        const service = allServices.find(service => service._id === serviceId);

        if (service)
          completeSelectedServices.push(service);
      });

      const currentService = this.state.specialSchedule
        ? completeSelectedServices[0]
        : false;
        // console.log("parseServices :", completeSelectedServices);
      this.setState(
        {
          currentService,
          completeSelectedServices,
          currentResource: {
            ...this.state.currentResource
          }
        },
        () => {
          this.getCurrentWeek(this.state.currentDay, this.state.currentService);
        }
      );
    } else {
      this.getCurrentWeek(this.state.currentDay, this.state.currentService);
    }
  };

  getCurrentWeek = (currentDay, currentService) => {
    const currentWeek = [];
    let day = moment(currentDay).isoWeekday(1);
    /*
    refactor from jhon
    let series = this.state.series;
    series = series
      ? this.state.specialSchedule
        ? series[currentService._id]
        : series
      : [];
    */
    let series = [];
    const { series: stateSeries, specialSchedule } = this.state;
    if (stateSeries){
      series = specialSchedule? stateSeries[currentService._id]: stateSeries;
    }

    for (var i = 0; i < 7; i++) {
      const stringDay = days[day.day()];
      currentWeek.push({
        momentDay: day.clone(),
        title: stringDay + day.format(' D'),
        intervals: series
          .filter(serie => {
            return (
              serie.initialDate.startOf('day').diff(day.clone()) <= 0 &&
              serie.finalDate.endOf('day').diff(day.clone()) >= 0 &&
              serie.repeatedDays[day.clone().day()]
            );
          })
          .map(serie => {
            return { interval: serie.interval, serieId: serie.serieId };
          })
      });
      day.add(1, 'day');
    }
    this.setState({ currentWeek });
  };

  addSerie = (initial, interval, repeatedDays, final, previousSeries, skip) => {
    var { selectedServices, currentService, specialSchedule, series } = this.state;
    let updatedSerie = specialSchedule? [...series[currentService._id]]: [...series];
    const seriesToAdd = skipHolidays(initial, final, interval, repeatedDays);

    var BreakException = {
      message: 'An error ocurred validating Schedule intervals overlapping'
    };

    if (skip) {
      try {
        seriesToAdd.forEach(serie => {
          if (validateOverlap(series, selectedServices, serie)) {
            updatedSerie = updatedSerie.concat(serie);
            series = this.state.specialSchedule? {
              ...this.state.series, [this.state.currentService._id]: updatedSerie
            }: updatedSerie;

            this.setState({ series }, () => {
              this.getCurrentWeek(initial, this.state.currentService);
            });
          } else {
            throw BreakException;
          }
        });
      } catch (e) {
        if (e === BreakException) {
          this.setState(
            {
              series: previousSeries ? previousSeries : this.state.series,
              errorMessage: 'Los horarios no se deben sobreponer',
              error: true
            },
            () => {
              this.getCurrentWeek(initial, this.state.currentService);
            }
          );
        } else {
          throw e;
        }
      }
    } else {
      const serie = {
        serieId: `serie-${Math.floor(Math.random() * 1000000) + 1}`,
        initialDate: initial,
        repeatedDays,
        interval,
        finalDate: final
      };
      if (validateOverlap(series, selectedServices, serie)) {
        updatedSerie = updatedSerie.concat(serie);
        series = this.state.specialSchedule? {
          ...this.state.series,
          [this.state.currentService._id]: updatedSerie
        }: updatedSerie;

        this.setState(
          {
            series
          },
          () => {
            this.getCurrentWeek(initial, this.state.currentService);
          }
        );
      } else {
        this.setState(
          {
            series: previousSeries ? previousSeries : this.state.series,
            errorMessage: 'Los horarios no se deben sobreponer',
            error: true
          },
          () => {
            this.getCurrentWeek(initial, this.state.currentService);
          }
        );
      }
    }
  };

  deleteSerie = (
    id,
    { deleteOption, initialDate, finalDate, callback, interval, repeatedDays }
  ) => {
    const previousSeries = { ...this.state.series };
    let series;
    var oldSeries = this.state.specialSchedule
      ? this.state.series[this.state.currentService._id]
      : this.state.series;
    if (deleteOption === 1) {
      series = splitSerie(id, oldSeries, initialDate, initialDate);
    } else if (deleteOption === 2) {
      finalDate = oldSeries
        .filter(serie => {
          return serie.serieId === id;
        })
        .map(serie => {
          return serie.finalDate;
        })
        .reduce((acum, val) => {
          return acum.diff(val) > 0 ? acum : val;
        });
      series = splitSerie(id, oldSeries, initialDate, finalDate);
    } else {
      series = splitSerie(id, oldSeries, initialDate, finalDate);
    }
    series = this.state.specialSchedule? {
      ...this.state.series, [this.state.currentService._id]: series
    }: series;

    this.setState({ series }, () => {
      if (callback){
        callback( initialDate, interval, repeatedDays, finalDate, previousSeries);
      }
      else {
        this.getCurrentWeek(initialDate, this.state.currentService);
      }
    });
  };

  editInterval = (
    id,
    initialHour,
    finalHour,
    initialDate,
    finalDate,
    deleteOption
  ) => {
    const currentInterval = `${initialHour}-${finalHour}`;
    let currentSerie = this.state.specialSchedule
      ? this.state.series[this.state.currentService._id]
      : this.state.series;
    currentSerie = currentSerie.filter(serie => {
      return serie.serieId === id;
    })[0];
    const repeatedDays = currentSerie.repeatedDays;
    this.deleteSerie(id, {
      deleteOption,
      initialDate,
      finalDate,
      callback: this.addSerie,
      interval: currentInterval,
      repeatedDays
    });
  };

  onChange = async date => {
    const currentDay = moment(date);
    this.setState({ currentDay });
    await this.getCurrentWeek(currentDay, this.state.currentService);
  };

  updateField = (name, { value, aux }) => {
    let {
      completeSelectedServices,
      selectedServices,
      series,
      currentService
    } = this.state;
    localStorage.setItem('canExitEdit', true);
    value = name === 'quantity' && !value ? '' : value;
    value = name === 'active' ? value === 'true' : value;
    const currentType = aux ? aux : this.state.currentType;
    if (name === 'specialSchedule') {
      if (value === true) {
        if (selectedServices.length > this.state.max) {
          completeSelectedServices = [];
          selectedServices = [];
          series = [];
          currentService = false;
        } else {
          series = {};
          selectedServices.forEach(service => {
            return (series[service] = []);
          });
          currentService = completeSelectedServices.filter(serv => {
            return serv._id === selectedServices[0];
          })[0];
          this.getCurrentWeek(this.state.currentDay, currentService);
        }
      } else {
        series = [];
      }
    }
    // console.log("updateField :", completeSelectedServices);
    this.setState({
      [name]: value,
      currentType,
      completeSelectedServices,
      selectedServices,
      series,
      currentService
    });
  };

  selectService = (_, { value, aux: _id }) => {
    localStorage.setItem('canExitEdit', true);
    let selectedServices = this.state.selectedServices;
    let series = this.state.series;
    if (value === true) {
      selectedServices = selectedServices.concat(_id);
      series = this.state.specialSchedule ? { ...series, [_id]: [] } : [];
    } else {
      selectedServices = this.state.selectedServices.filter(curService => {
        return curService !== _id;
      });
      if (this.state.specialSchedule) delete series[_id];
    }

    this.setState({ selectedServices, series }, () => {
      this.updateServices();
    });
  };

  updateServices = () => {
    const selectedServices = this.state.selectedServices;
    const allServices = this.state.allServices;
    const completeSelectedServices = [];

    selectedServices.forEach(serviceId => {
      const service = allServices.find(service => service._id === serviceId);

      if (service)
        completeSelectedServices.push(service);
    });
    
    const isActive = completeSelectedServices.find(s => {
      return this.state.currentService
        ? s._id === this.state.currentService._id
        : false;
    });

    const currentService =
      completeSelectedServices.length === 0
        ? false
        : isActive || completeSelectedServices[0];

    // console.log("updateServices :", completeSelectedServices);
    this.setState({ completeSelectedServices, currentService });
  };

  clearErrors = () => {
    this.setState({
      resourceErrors: {},
      errorMessage: '',
      error: false,
      disableSubmit: false
    });
  };

  handleSelectedService = (service, push) => {
    localStorage.setItem('canExitEdit', true);
    var series = this.state.series;
    var selectedServices = this.state.selectedServices || [];
    if (push) {
      selectedServices.push(service);
      series[service._id] = [];
    } else {
      _.remove(selectedServices, { _id: service._id });
      delete series[service._id];
    }
    const isActive = selectedServices.find(s => {
      return s._id === this.state.currentService._id;
    });

    const currentService =
      this.state.selectedServices.length === 0
        ? false
        : isActive || this.state.selectedServices[0];

    const userAttributes = { ...this.state.userAttributes, series };
    this.setState({ selectedServices, series, currentService, userAttributes });
    this.getCurrentWeek(this.state.currentDay, currentService);
  };

  handleChangeTab = currentService => {
    this.setState({ currentService });
    this.getCurrentWeek(this.state.currentDay, currentService);
  };

  validate = ({
    currentType,
    name,
    sede: _sede,
    quantity,
    selectedServices,
    specialSchedule,
    series
  }) => {
    const errors = [];
    const resourceErrors = {};
    let errorMessage = '';

    if (!currentType.name) {
      errors.push('type');
      resourceErrors['type'] = true;
      errorMessage = 'EL tipo de recurso debe existir';
    }
    if (!name) {
      errors.push('name');
      resourceErrors['name'] = true;
      errorMessage = 'EL nombre del recurso debe existir';
    }
    if (
      currentType.name === 'Equipo' ||
      currentType.name === 'Espacio de trabajo'
    ) {
      if (!_sede) {
        errors.push('sede');
        resourceErrors['sede'] = true;
        errorMessage = 'Se debe elegir una sede';
      }
      if (currentType.name === 'Equipo') {
        if (quantity <= 0) {
          errors.push('quantity');
          resourceErrors['quantity'] = true;
          errorMessage = 'Debe ingresar una cantidad mayor a cero';
        }
        if (selectedServices.length === 0) {
          errors.push('services');
          resourceErrors['services'] = true;
          errorMessage =
            'El equipo debe tener prestaciones asociadas';
        }
      } else if (currentType.name === 'Espacio de trabajo') {
        if (selectedServices.length === 0) {
          errors.push('services');
          resourceErrors['services'] = true;
          errorMessage =
            'El espacio de trabajo debe tener prestaciones asociadas';
        }
        if (specialSchedule === true && series.length === 0) {
          errors.push('series');
          resourceErrors['series'] = true;
          errorMessage = 'Debes escoger un horario para el equipo de trabajo';
        }
      }
    } else if (currentType.name === 'Sede') {
      if (series.length === 0) {
        errors.push('series');
        resourceErrors['series'] = true;
        errorMessage = 'Debes escoger un horario para la sede';
      }
    }
    this.setState({ resourceErrors, errorMessage });
    return errors.length > 0 ? false : true;
  };

  saveResource = async () => {
    const {
      currentType,
      name,
      sede: _sede,
      quantity: quantityBefore,
      selectedServices,
      series: completeSeries,
      specialSchedule,
      active,
      timeOut
    } = this.state;

    if (this.validate(this.state)) {
      let series;
      if (!specialSchedule) {
        // console.log("no special schedulle")
        series = completeSeries.map(serie => {
          return {
            ...serie,
            initialDate: serie.initialDate.startOf("day").toDate(),
            finalDate: serie.finalDate.endOf("day").toDate()
          };
        });
      } else {
        // console.log("special schedulle")
        series = { ...completeSeries };
        Object.keys(completeSeries).forEach(serviceId => {
          series[serviceId] = series[serviceId].map(serie => {
            return {
              ...serie,
              initialDate: serie.initialDate.startOf("day").toDate(),
              finalDate: serie.finalDate.endOf("day").toDate()
            };
          });
        });
      }
      // console.log(series)
      //debugger
      this.setState({ disableSubmit: true });
      const quantity = quantityBefore === '' ? 0 : quantityBefore;

      const variables = {
        _resourceType: currentType._id,
        name,
        _sede,
        quantity,
        specialSchedule,
        active,
        timeOut: timeOut*1,
        services: JSON.stringify(selectedServices),
        series: JSON.stringify(series)
      };
      if (_sede === '' || currentType.name === 'Sede') delete variables._sede;
      if (selectedServices.length === 0 || (currentType.name !== 'Espacio de trabajo' && currentType.name !== 'Equipo')) delete variables.services;
      if (currentType.name !== 'Espacio de trabajo' && currentType.name !== 'Sede') delete variables.series;
      if (currentType.name !== 'Espacio de trabajo') delete variables.specialSchedule;
      // console.log(variables);
      let result;
      let errorMessage;
      if (this.state.currentResource) {

        variables._id = this.state.currentResource._id;
        result = await this.props.updateResource({
          variables
        });
        result = result.data.updateResource;
        errorMessage = result.errors && result.errors[0].message;
      } else {
        result = await this.props.createResource({
          variables
        });
        result = result.data.createResource;
        errorMessage = result.errors && result.errors.messages[0];
      }

      localStorage.removeItem('canExitEdit');
      localStorage.removeItem('clickSidebar');

      if (result.success && currentType.name === 'Equipo') {
        JSON.parse(variables.services).forEach( async service => {
          const variables = {
            _id: service, 
            timeOut: timeOut*1
          }

          result = await this.props.updateServiceMutation({
            variables
          });

        });
      }

      if (result.success){
        this.setState({
          success: true,
          error: false,
          successMessage: '¡Recurso guardado exitosamente!',
          errorMessage: ''
        });
      }
      else {
        this.setState({
          success: false,
          error: true,
          errorMessage
        });
      }

      if (result.success && variables._id){
        setTimeout(() => {
          this.setState({
            success: false,
            disableSubmit: false,
            successMessage: ''
          });
          this.props.history.push(`/resources/${variables._id}`);
        }, 2000);
      }
      else if (result.success) {
        setTimeout(() => {
          this.setState({ disableSubmit: false });
          this.props.history.push('/resources');
        }, 2000);
      }
      else {
        setTimeout(() => this.setState({ disableSubmit: false }), 2000);
      }

    } else {
      this.setState({
        error: true,
        success: false,
        disableSubmit: false,
        successMessage: ''
      });
    }
  };

  setServices = allServices => {
    this.setState({ allServices });
  };

  render () {
    return (
      <Provider
        value={{
          ...this.state,
          getCurrentResource: this.getCurrentResource,
          getCurrentWeek: this.getCurrentWeek,
          resetResource: this.resetResource,
          deleteResource: this.deleteResource,
          updateField: this.updateField,
          clearErrors: this.clearErrors,
          selectService: this.selectService,
          onChange: this.onChange,
          addSerie: this.addSerie,
          deleteSerie: this.deleteSerie,
          saveResource: this.saveResource,
          setServices: this.setServices,
          handleChangeTab: this.handleChangeTab,
          editInterval: this.editInterval
        }}
      >
        { this.props.children }
      </Provider>
    );
  }
}
ResourceProvider.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  deleteResource: PropTypes.func,
  createResource: PropTypes.func,
  updateResource: PropTypes.func
};
export default compose(
  graphql(CREATE_RESOURCE, { name: 'createResource' }),
  graphql(UPDATE_RESOURCE, { name: 'updateResource' }),
  graphql(DELETE_RESOURCE, { name: 'deleteResource' }),
  graphql(UPDATE_SERVICE,  { name: 'updateServiceMutation' }),

)(withRouter(withApollo(ResourceProvider)));
export const ResourceConsumer = Consumer;
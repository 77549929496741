import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { withClientState } from 'apollo-link-state';
import utilsResolver from '../graphql/resolver/utils';
import _ from 'lodash';

let api = `${process.env.REACT_APP_URL_AGENDAMIENTO}/graphql`;

const httpLink = new createUploadLink({
  uri: api
});

const cache = new InMemoryCache({ dataIdFromObject: (o) => o.id });

const middlewareLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  return {
    headers: {
      ...headers,
      'x-token': token ? token : '',
      'x-refresh-token': refreshToken ? refreshToken : '',
    },
  };
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();
    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (token) {
        localStorage.setItem('token', token);
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    }
    return response;
  });
});

const stateLink = withClientState({
  cache,
  ..._.merge(utilsResolver),
});

const httpLinkWithMiddleware = afterwareLink.concat(
  middlewareLink.concat(stateLink.concat(httpLink))
);

const link = httpLinkWithMiddleware;

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export default new ApolloClient({
  link,
  cache,
  defaultOptions,
});

/* eslint-disable */
import React, { Fragment, Component, createContext } from 'react';
import Select2 from 'react-select';
import _ from 'lodash';
import '../select2.min.css';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo, compose } from 'react-apollo';
import SimpleReactValidator from 'simple-react-validator';
import {
  CREATE_NEW_PHYSICIAN, UPDATE_PHYSICIAN
} from '../../../graphql/types/physician/mutations';
import {
  GET_SPECIALTY
} from '../../../graphql/types/specialty/queries';
import {
  GET_INSTITUTION
} from '../../../graphql/types/institution/queries';
import client from '../../../utils/apollo';
import ModalInstitution from '../../institution/modal';
import ModalSpeciality from '../../specialty2/modal';
import {
  GET_PHYSICIANS,
} from '../../../graphql/types/physician/queries';
import { GET_CITIES } from '../../../graphql/types/city/queries';
import Alert from '../../../components/general/alert';
import { capitalize, extractNumbers } from '../../../utils/helpers';
import { context } from '../../../providers/specialtyProvider';

class Doctor extends Component {
  otherInstitutionOption = {
    value: 'Otra',
    label: 'Otra'
  };

  constructor(props) {
    super(props);

    this.state = {
      form: {
        city: null,
        confirm: false,
        dni: null,
        email: null,
        fullname: null,
        gender: 'M',
        institutions: [],
        insurance: [],
        medicalRecord: null,
        mobile: null,
        phone: null,
        specialties: [],     
        observations: '',
        shipping: {
          address: null,
          institution: null,
          office: null
        },  
      },
      optionsCities: [],
      optionsSpecialties: [],
      optionsInstitutions: [],
      validatorErrors: {},
      modal: false,
      modalSpecial: false,
      specialityOld: '',
      institutionsOld: '',
      defaultAddress: {},
      dataDuplicate: {},
      modalDuplicate: false,
      modalSavedSuccess: false,
      modalUpdatedSuccess: false,
      modalUpdate: false,
      updateDoctor: false,
      successDoctorReload: this.props.successReload,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        default: 'El campo :attribute es obligatorio',
        email: 'El e-mail no es válido',
        min: 'El campo :attribute debe tener mínimo 5 caracteres',
        max: 'El campo :attribute debe tener máximo 120 caracteres',
        phone: 'Número de teléfono no válido'
      },
      element: message => <div className="alert alert-danger small p-2 mt-2">{message}</div>
    })
  }

  componentDidMount() {
    this.dataCities();

    Promise.all([this.dataSpecialty(), this.datainstitution()])
    .then(() => {
      if (this.props.identity) {
        this.getPhysician();
      }
    });
  }

  datainstitution = async () => {
    let institutions;

    try {
      const queryResponse = await client.query({
        query: GET_INSTITUTION,
        variables: {
          pagination: {
            perPage: 1000
          }
        }
      });

      institutions = queryResponse.data.getInstitution.data;

      if (typeof this.props.quantityInstitutions === "function") {
        this.props.quantityInstitutions(institutions.length)
      }      

      if (!_.isEmpty(queryResponse)) {
        institutions = institutions.map(data => ({
          value: data._id,
          label: data.name,
          address: data.address
        }));
        
      }

    } catch (error) {
      institutions = [];
    }

    this.setState({ 'optionsInstitutions': institutions });
  }

  dataSpecialty = async () => {
    let specialties;

    try {
      const queryResponse = await client.query({
        query: GET_SPECIALTY,
        variables: {
          pagination: {
            perPage: 1000
          }
        }
      });

      specialties = queryResponse.data.getSpecialty.data.map((data) => ({
        value: data._id,
        label: data.name
      }));

    } catch (error) {
      specialties = [];
    }
    
    this.setState({ 'optionsSpecialties': specialties });
  }

  dataCities = async () => {
    let cities;

    try {
      const queryResponse = await client.query({
        query: GET_CITIES,
        variables: {}
      });

      cities = queryResponse.data.getCities.data;

      cities = cities.map(c => ({
        label: c.name,
        value: c._id
      }));

    } catch(error) {
      cities = [];
    }

    this.setState({ 'optionsCities': cities });
  }

  closeModal = () => {
    this.setState({ modal: !this.state.modal });
  }

  closeModalSpecialty = () => {
    this.setState({ modalSpecial: !this.state.modalSpecial });
  }

  validateForm = (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {

      if (this.state.updateDoctor) {
        this.updatePhysician();
      } else {
        this.savePhysician();
      }

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  getFormData = () => {
    const { form } =  this.state;

    const { 
      city, 
      email,
      fullname,
      institutions, 
      specialties, 
      shipping
    } = form;

    const { institution: shippingInstitution, address } = shipping;

    return {
      ...form,
      fullname: capitalize(fullname),
      ...(city ? { city: city.value } : {}),
      ...(email ? { email: email.toLowerCase() } : {}),      
      ...(institutions ? { institutions: institutions.map(sp => sp.value) } : {}),
      ...(specialties ? { specialties: specialties.map(sp => sp.value) } : {}),
      shipping: {
        ...shipping,
        ...(address ? { address: capitalize(address) } : {}),
        ...(shippingInstitution ? { institution: shippingInstitution.value } : {}),
      }
    }
  }

  savePhysician = async () => {
    const variables = this.getFormData();

    await this.props.createPhysician({
      variables
    }).then((res) => {
      if (res.data.createPhysician.success) {
        this.setState({ 'modalSavedSuccess': true });
      }
    }).catch((err) => {
      console.log('Error: ' + err);
    });    
  }

  updatePhysician = async () => {
    const variables = this.getFormData();
    
    await this.props.updatePhysician({
      variables: {
        ...variables,
        _id: this.state.form._id
      }
    }).then((res) => {
      if (res.data.updatePhysician.success) {
        this.setState({ 'modalUpdatedSuccess': true });
      }
    });
  }

  setActive = () => {
    this.setState({
      'form': {
        ...this.state.form,
        'confirm': this.state.form.confirm ? false : true,
      }
    });
  }

  setDataEpsPoliza = value => {
    let { insurance } = this.state.form;

    if (insurance.includes(value)) {
      insurance = insurance.filter(i => i !== value);
    } else {
      insurance = [...insurance, value]
    }

    this.setData(insurance, 'insurance');
  }

  setData = (value, field) => {
    this.setState({
      'form': {
        ...this.state.form,
        [field]: value
      }
    });
  }

  findPhysician = async (field, value) => {    
    let response = null;

    try {
      let apiResponse = await client.query({
        query: GET_PHYSICIANS,
        variables: {
          [field]: value,
          perPage: 1
        }
      });

      apiResponse = apiResponse.data.getPhysicians.data;

      if (apiResponse.length > 0) {
        const duplicatedPhysician = apiResponse[0];

        response = duplicatedPhysician;
      }

    } catch (error) {
      console.log('Error buscando la identificación:', error);
    }

    return response;
  }

  validatePhysicianDuplication = async (field, value) => {
    if (value) {
      const physician = await this.findPhysician(field, value);

      if (physician) {
        this.setState({ 'dataDuplicate':  physician});
        this.setState({ 'modalDuplicate': true });
      }
    }
  }

  getPhysician = async () => {
    const physician = await this.findPhysician('_id', this.props.identity);

    this.fillForm(physician);
  }

  fillForm = data => {
    this.setState({ updateDoctor: true });
    
    this.setState({ modalDuplicate: false });

    let { 
      form, 
      optionsSpecialties, 
      optionsInstitutions, 
      optionsCities 
    } = this.state;
    
    let { city, institutions, specialties, shipping } = data;
    let {institution : shippingInstitution} = shipping;    
    
    city = optionsCities.find(c => c.value === city);
    institutions = institutions.map(i => optionsInstitutions.find(oi => oi.value === i))
    specialties = specialties.map(s => optionsSpecialties.find(os => os.value === s))

    if (shippingInstitution)
      shippingInstitution = optionsInstitutions.find(i => i.value === shippingInstitution) || this.otherInstitutionOption
    
    data = _.omit(data, '__typename',);
    shipping = _.omit(shipping, '__typename',);

    this.setState({
      form: {
        ...form,
        ...data,
        ...(city ? { city } : {}),
        institutions,
        specialties,
        shipping: {
          ...shipping,
          ...(shippingInstitution ? { institution: shippingInstitution } : {})
        }
      }
    });
  }

  setShippingData = (value, field) => {
    const { form } = this.state;
    const { shipping } = form;

    this.setState({
      form: {
        ...form,
        shipping: {
          ...shipping,
          [field]: value
        }
      }
    })
  }

  handleSuccessAction = () => {
    this.props.onSuccess(this.getFormData());
  }

  handleSpecialtyRegister = async () => {
    await this.dataSpecialty();

    let specialty = this.state.optionsSpecialties;
    specialty = specialty[specialty.length - 1];

    this.setData([...this.state.form.specialties, specialty], 'specialties')
  }

  handleInstitutionRegister = async () => {
    await this.datainstitution();

    let institution = this.state.optionsInstitutions;
    institution = institution[institution.length - 1];

    this.setData([...this.state.form.institutions, institution], 'institutions');
  }

  showSpecialties = () => {
    let text = this.state.form.specialties.map(sp => sp.label).join(', ');
    text = text.substring(0, text.length);

    return text;
  }

  render() {
    const {
      form: {
        city,
        confirm,
        dni,
        email,
        fullname,
        gender,
        insurance,
        institutions,
        medicalRecord,
        mobile,
        phone,
        specialties,
        observations,
        shipping : {
          institution: shippingInstitution,
          address,
          office,
        }
      },
      dataDuplicate,
      optionsCities,
      optionsInstitutions,
      optionsSpecialties,
      modalSavedSuccess,
      modalDuplicate,
      modalUpdatedSuccess
    } = this.state;

    const shippingAddresses = [...institutions, this.otherInstitutionOption];

    return (
      <Fragment>
        <div className='row'>
          <div className="col-md-12 col-lg-12">
            <div className='main-card mb-3 cardz p-4'>
              <form>
                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <h5>Información</h5>
                  </div>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <div class="form-check form-switch">
                        <input 
                          class="form-check-input" 
                          type="checkbox" 
                          role="switch" 
                          id="flexSwitchCheckDefault" 
                          checked={confirm} 
                          onChange={this.setActive}
                        />
                        <label class="form-check-label" for="flexSwitchCheckDefault">
                          { confirm ? 'Confirmado' : 'Propuesto' }
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='identity'>Identificación</label>
                      <input 
                        type='text' 
                        className='form-control' 
                        onBlur={(e) => this.validatePhysicianDuplication('dni', e.target.value)} 
                        id='identity' 
                        name='identity' 
                        value={dni} 
                        onChange={(e) => this.setData(e.target.value, 'dni')} 
                      />
                      {this.validator.message('Identificación', dni, 'min:1|max:20')}
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='medical_identity'>Registro Médico</label>
                      <input 
                        type='text' 
                        className='form-control' 
                        onBlur={(e) => this.validatePhysicianDuplication('medicalRecord', e.target.value )} 
                        id='medical_identity' 
                        name='medical_identity' 
                        value={medicalRecord} 
                        onChange={(e) => this.setData(e.target.value, 'medicalRecord')} 
                      />
                      {this.validator.message('Registro Médico', medicalRecord, 'min:1|max:30')}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-12'>
                    <div className='form-group'>
                      <label htmlFor='names'>Nombre <strong className="text-danger">*</strong></label>
                      <input
                        type='text'
                        className='form-control'
                        id='names'
                        name='names'
                        value={fullname}
                        onChange={(e) => this.setData(e.target.value, 'fullname')}
                      />
                      {this.validator.message('Nombre', fullname, 'required|min:1|max:100')}
                    </div>
                  </div>                  
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='e_mail'>E-mail</label>
                      <input 
                        type='email' 
                        className='form-control' 
                        onBlur={(e) => this.validatePhysicianDuplication('email', e.target.value )} 
                        id='e_mail' 
                        name='e_mail' 
                        value={email} 
                        onChange={(e) => this.setData(e.target.value, 'email')} 
                      />
                      {this.validator.message('E-mail', email, 'min:5|max:50|email')}
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='gender'>Género</label>
                      <select 
                        className='form-control' 
                        id='gender' 
                        name='gender' 
                        onChange={(e) => this.setData(e.target.value, 'gender')} 
                        value={gender}
                      >
                        <option value='M'>Hombre</option>
                        <option value='F'>Mujer</option>
                      </select>
                      {this.validator.message('Género`', gender, 'required')}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='mobile'>Móvil</label>
                      <input            
                        className='form-control' 
                        id='mobile' 
                        name='mobile' 
                        onChange={(e) => this.setData(e.target.value, 'mobile')} 
                        type='text'           
                        value={mobile} 
                      />
                      {this.validator.message('Móvil', mobile, 'min:5|max:120')}
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='phone'>Teléfono</label>
                      <input
                        className='form-control' 
                        id='phone' 
                        name='phone' 
                        onChange={(e) => this.setData(e.target.value, 'phone')} 
                        type='text'
                        value={phone} 
                      />
                      {this.validator.message('Teléfono', phone, 'min:5|max:120')}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='city'>Ciudad</label>
                      <Select2
                        name="city"
                        isSearchable={true}
                        isMulti={false}
                        options={optionsCities}
                        value={city}
                        onChange={(e) => this.setData(e, 'city')}
                      />
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group d-flex flex-wrap'>
                      <label className='w-100' htmlFor=''>EPS / Póliza</label>
                      <br />
                      <div className='form-check mr-3 pt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={insurance.includes('particular')}
                          onChange={(e) => this.setDataEpsPoliza('particular')}
                        />
                        <label className='form-check-label'>Particular</label>
                      </div>

                      <div className='form-check mr-3 pt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={insurance.includes('eps')}
                          onChange={(e) => this.setDataEpsPoliza('eps')}
                        />
                        <label className='form-check-label'>EPS</label>
                      </div>

                      <div className='form-check mr-3 pt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={insurance.includes('póliza')}
                          onChange={(e) => this.setDataEpsPoliza('póliza')}
                        />
                        <label className='form-check-label'>Póliza</label>
                      </div>

                      <div className='form-check mr-3 pt-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={insurance.includes('mp')}
                          onChange={(e) => this.setDataEpsPoliza('mp')}
                        />
                        <label className='form-check-label'>MP</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row d-flex align-items-end'>
                  <div className='col-md-12 col-lg-9'>
                    <div className='form-group'>
                      <label htmlFor='specialty'>Especialidad</label>

                      <span style={{display: 'block'}} className="text-danger">{ !_.isEmpty(this.state.specialityOld) &&  this.state.specialityOld }</span>

                      <Select2
                        name="specialty"
                        isMulti={true}
                        isSearchable={true}
                        options={optionsSpecialties}
                        value={specialties}
                        onChange={(e) => this.setData(e, 'specialties')}
                      />
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-3'>
                    <div className='form-group'>
                      <button 
                        onClick={(e) => this.setState({ modalSpecial: true })} 
                        className='btn btn-success p-2 btn-sm btn-block'
                        type='button'
                      >
                        Nueva Especialidad
                      </button>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-12'>
                    <h5>Dirección</h5>
                  </div>
                </div>

                <div className='row d-flex align-items-end'>
                  <div className='col-md-12 col-lg-9'>
                    <div className='form-group'>
                      <label htmlFor='institution'>Institución</label>

                      <span style={{display: 'block'}} className="text-danger">
                        { !_.isEmpty(this.state.institutionsOld) &&  this.state.institutionsOld }
                      </span>

                      <Select2
                        name="institutions"
                        isMulti={true}
                        isSearchable={true}
                        options={optionsInstitutions}
                        value={institutions}
                        onChange={(e) => this.setData(e, 'institutions')}
                      >
                      </Select2>
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-3'>
                    <div className='form-group'>
                      <button 
                        onClick={(e) => this.setState({ modal: true })} 
                        className='btn btn-success p-2 btn-sm btn-block'
                        type='button'
                      >
                        Nueva Institución
                      </button>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='default_institution'>Escoge la dirección para envíos</label>
                      <Select2
                        name="default_institution"
                        isMulti={false}
                        isSearchable={true}
                        options={shippingAddresses}
                        value={shippingInstitution}
                        onChange={e => this.setShippingData(e, 'institution')}
                      >
                      </Select2>
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='clinic'>Complemento</label>
                      <input 
                        type='text' 
                        className='form-control' 
                        id='clinic' 
                        name='clinic' 
                        value={office} 
                        onChange={(e) => this.setShippingData(e.target.value, 'office')} 
                      />
                      {this.validator.message('Consultorio', office, 'min:1|max:120')}
                    </div>
                  </div>
                </div>

                {shippingInstitution && shippingInstitution.value == this.otherInstitutionOption.value &&
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='extra_address'>Otra dirección de envío</label>
                        <input 
                          type='text' 
                          className='form-control' 
                          id='extra_address' 
                          name='extra_address' 
                          value={address} 
                          onChange={e => this.setShippingData(e.target.value, 'address')}
                        />
                        {this.validator.message('Otra dirección de envío', address, 'min:5|max:120')}
                      </div>
                    </div>                    
                  </div>
                }

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="observations"> Observaciones </label>
                      <textarea 
                        className='form-control'
                        id="observations" 
                        onChange={(e) => this.setData(e.target.value, 'observations')}
                        rows="5"
                        value={observations}
                      >
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12 col-lg-6'>
                    <div className='card' style={{ width: '100%', height: '100%' }}>
                      <div className='card-body'>
                        <p className='card-text mb-1'>
                          Doctor{gender === 'F' && 'a'}
                        </p>
                        {fullname && (
                          <p className='card-text mb-1'>
                            {capitalize(fullname)}
                          </p>
                        )}
                        <p className='card-text mb-1'>
                          { this.showSpecialties() }
                        </p>                         
                        {!_.isEmpty(shippingInstitution) && 
                        shippingInstitution.value !== this.otherInstitutionOption.value &&
                          <>
                            <p className='card-text mb-1'>
                              { shippingInstitution.label }
                            </p> 
                            <p className='card-text mb-1'>
                              { shippingInstitution.address }
                            </p>          
                          </>                  
                        }
                        {!_.isEmpty(shippingInstitution) && 
                        shippingInstitution.value === this.otherInstitutionOption.value &&
                        !_.isEmpty(address) && 
                          <p className='card-text mb-1'>
                            { capitalize(address) }
                          </p>
                        }
                        {!_.isEmpty(office) &&
                          <p className='card-text mb-1'>
                            { office }
                          </p>
                        }
                        {!_.isEmpty(city) &&
                          <p className='card-text mb-1'>
                            { city.label }
                          </p>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='col-md-12 col-lg-6'>
                    <button type='submit' className='btn btn-primary' onClick={(e) => this.validateForm(e)}>Guardar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div >
        
        <Alert
          show={modalSavedSuccess}
          title='Registro exitoso'
          message='Se ha completado exitosamente el registro.'
          confirmButtonText='Cerrar'
          confirmButtonAction={this.handleSuccessAction}
        />

        <Alert
          show={modalDuplicate}
          title='Advertencia'
          message={`La identificación ${dni} ya se encuentra registrada`}
          confirmButtonText='Cargar Datos formulario'
          confirmButtonAction={() => this.fillForm(dataDuplicate)}
          cancelButtonText='Cancelar'
          cancelButtonAction={() => this.setState({ 'modalDuplicate': false, 'form': { ...this.state.form, identity: '' } })}
        />

        <Alert
          show={modalUpdatedSuccess}
          title='Actualización exitosa'
          message='Se ha actualizado exitosamente el médico.'
          confirmButtonText='Cerrar'
          confirmButtonAction={this.handleSuccessAction}
        />       

        <Fragment>
          <div className='modal' tabIndex='-1' role='dialog' style={{ display: this.state.modalUpdate ? 'block' : 'none' }}>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-bodyr'>
                  <p>Advertencia</p>
                  Desea actualizar la informacion para {typeof this.state.form.identity !== 'undefined' ? this.state.form.identity : ''}
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-primary' onClick={() => this.updateRecord()} data-dismiss='modal'>Actualizar</button>
                  <button type='button' className='btn btn-secondary' onClick={() => this.setState({ 'modalUpdate': false })} data-dismiss='modal'>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>

        <ModalInstitution
          modal={this.state.modal}
          closeModal={() => this.closeModal()}
          onSuccess={this.handleInstitutionRegister}
        >
        </ModalInstitution>

        <ModalSpeciality
          show={this.state.modalSpecial}
          closeModal={() => this.closeModalSpecialty()}
          onSuccess={this.handleSpecialtyRegister}
        />

      </Fragment >
    );
  }
}

export default compose(
  graphql(CREATE_NEW_PHYSICIAN, { name: 'createPhysician' }),
  graphql(UPDATE_PHYSICIAN, { name: 'updatePhysician' })
)(withRouter(withApollo(Doctor)));

import React, { Component } from 'react';
class Confirmed extends Component {
  constructor (props) {
    super(props);
    props = { data: {} };
  }

  render () {
    const { data, onChange } = this.props;
    const { fullname, specialty, mobile, email, medicalrecord, identificacion, _id } = data;
    return (
      <div className="row-ph"
        style={
          this.props.isChecked===_id?
            { outlineStyle:'solid', outlineColor:'#3d99ca', boxShadow:'2px 2px 5px #3d99ca' }
            :{ outlineStyle:'none' }}
      >
        <div className="files">
          <div className="border-gray-fk">
            <div className="label"> Nombre</div>
            <div className="value">{fullname} </div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Especialidad</div>
            <div className="value"> {specialty} </div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Rg. Med.</div>
            <div className="value">{medicalrecord}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Movil</div>
            <div className="value">{mobile?mobile:'--'}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> Identificación</div>
            <div className="value">{identificacion}</div>
          </div>
          <div className="border-gray-fk">
            <div className="label"> E-mail</div>
            <div className="value">{email}</div>
          </div>
        </div>
        <div>
          <div className="ch-ph">
            <input id={_id} name="confirmed" type="radio" onChange={element => {
              onChange(element.currentTarget);
            }}
            ></input>
          </div>
        </div>
      </div>

    );
  }

}
export default Confirmed;


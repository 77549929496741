/* eslint-disable */
import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
// import '../../dashboard/bootstrapv2.min.css';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { context } from '../../providers/dashboardProvider';

class PhysicianByReferralTable extends Component {

  static contextType = context;

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          name: 'Nombre',
          selector: row => row.fullname,
          width: '250px'
        },
        {
          name: 'Especialidad',
          selector: row => row.specialties.join(', '),
          width: '250px'
        },
        {
          name: 'Institucion',
          selector: row => row.institutions.join(', '),
          width: '250px'
        },
        {
          name: 'Remitidos',
          selector: row => row.referrals,
          width: '100px'
        }
      ]
    };
  }

  render() {
    const { 
      columns
    } = this.state;

    const {
      physicians,
      totalPhysicians,
      perPage,
      handlePageChange,
      handleRowsChange
    } = this.context;
    
    return (
        <div className="card-body">
          <div className="row mh700 mb-10">
            <DataTable
              columns={columns}
              data={physicians}
              pagination
              paginationPerPage={perPage}
              paginationServer
              paginationTotalRows={totalPhysicians}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsChange}
              width={9 * 150}
            />
          </div>
        </div>
    );
  }

}

export default withRouter(PhysicianByReferralTable);

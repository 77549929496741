/* eslint-disable */
import React, { Component } from 'react';
import { BarChart, Bar, Tooltip, Text, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { context } from "../../../providers/dashboardProvider";

class Top5Chart extends Component {

  static contextType = context;

  constructor (props) {
    super(props);
  }

  render () {
    const { topReferralPhysicians } = this.context;

    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h5 className='card-title'> Top 5 Médicos </h5>
          </div>
        </div>
        
        <br />

        <div className="row mh300">
          {topReferralPhysicians.length > 0 &&
            <ResponsiveContainer width={'100%'} height={250} >
              <BarChart data={topReferralPhysicians} barCategoryGap='30%'>                
                <XAxis 
                  dataKey="name"
                  hide                 
                />
                <YAxis type='number' hide />
                <Tooltip />
                <Text scaleToFit="true" />
                <Bar dataKey="referrals" fill="#16aaff" />
              </BarChart>
            </ResponsiveContainer>            
          }
        </div>
      </div>
    );
  }

}
export default Top5Chart;
import React, { Component } from 'react';
import moment from 'moment';
import DaySelection from '../../general/daysSelection';
import TextInputTime from '../../../components/general/textInputTime';

class RepetitionModal extends Component {
  state = {
    duration: 0,
    repeatedDays: {}
  };

  componentDidMount = () => {
    const duration = this.props.duration ? this.props.duration : 0;
    const repeatedDays = this.props.repeatedDays ? this.props.repeatedDays : {};
    this.setState({ duration, repeatedDays });
  };

  onChange = date => {
    let currentDay = moment(date);
    this.setState({ currentDay });
  };

  updateField = (name, { value, aux: day }) => {
    day === null
      ? this.setState({ [name]: value })
      : day === 7
        ? this.setState({
            repeatedDays: {
              ...this.state.repeatedDays,
              1: true,
              2: true,
              3: true,
              4: true,
              5: true,
              6: true,
            },
          })
        : this.setState({
            repeatedDays: { ...this.state.repeatedDays, [day]: value },
          });
  };

  removeAlert = () => {
    this.setState({ showDatesAlert: false });
  };

  render () {
    const { duration, repeatedDays } = this.state;
    const { onCancel, onSave } = this.props;
    return (
      <div className={'modal-container'} onClick={onCancel}>
        <div
          className="modal-body-repetition flex f-c a-i-c j-sb text-center"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="text-center bg-icon password-modal-title ripple-title">
            Duración especial
          </div>
          <p className="modal-section">
            Elige la duración especial para esta prestación
          </p>
          <div
            className="hours-container flex j-sa a-i-c"
            onFocus={this.removeAlert}
          >
            <div className="flex j-c a-i-c">
              <span className="mr-03 header text-bold">Duración</span>
              <TextInputTime
                type="number"
                updateField={this.updateField}
                value={this.state.duration}
                name="duration"
              />
              <span className="ml-03 muted-text">Minutos</span>
            </div>
          </div>

          <p className="modal-section">
            Para repetir este horario especial completa las siguientes opciones
          </p>

          <DaySelection updateField={this.updateField} repeatedDays={repeatedDays} />

          <div className="modal-actions mb-1">
            <button className="button button-sm bg-muted" onClick={onCancel}>
              Cancelar
            </button>
            <button
              className="button button-sm bg-icon ripple"
              onClick={() => {
                onSave(duration, repeatedDays);
                onCancel();
              }}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RepetitionModal;

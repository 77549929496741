const salaCheckboxes = [
    {
      name: 'Agenda',
      key: 'Agenda',
    },
    {
        name: 'En sala de espera',
        key: 'enSalaDeEspera',
    },
    {
        name: 'Confirmada',
        key: 'confirmada',
    },
    {
        name: 'En consultorio ',
        key: 'enConsultorio',
    },
    {
        name: 'Presente',
        key: 'Presente',
    },
    {
        name: 'Atención terminada',
        key: 'atencionTerminada',
    },
    {
        name: 'En recepcion',
        key: 'enRecepcion',
    },
    {
        name: 'Repetir',
        key: 'repetir',
    },
    {
        name: 'Entregado',
        key: 'entregado',
    }
  ];

  export default salaCheckboxes;

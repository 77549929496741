/**
 * AmpasList
 */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// my components
import TextInput from '../../general/textInput';
// import SelectInput from '../../general/selectInput';
import PatientsTable from '../patientsTable';
import { GET_PATIENTS, GET_PATIENT_CORE } from '../../../graphql/types/patientsModule/queries';
import { GET_AMPAS } from '../../../graphql/types/ampa/queries';
import PanelButton from '../../general/panelButton';
import assets from '../../../assets';

import BodyContainer from '../../general/bodyContainer';

import './style.css';

class AmpasList extends Component {
  filterTimeOut = null;
  constructor(props) {
    super(props);
    const initialState = {
      page: 1,
      filter: {},
      filters: ['name', '_role', 'active'],
      isLoader: false,
      patients: [],
      finished: false,
      filtered: false,
      isLastPage: false,
      patientsLoad: true,
      filtersCollapsed: true
    };
    initialState.filters.map(filter => {
      return (initialState[filter] = '');
    });
    this.state = initialState;
  }

  buildFilters = state => {
    const filters = {};
    state.filters.forEach(filter => {
      if (state[filter] !== '') {
        if (
          (filter === 'name' && state[filter].length >= 4) ||
          filter !== 'name'
        ) {
          filters[filter] = state[filter];
        }
      }
    });
    filters['page'] = this.state.page;
    return filters;
  };

  toProfile = (id, _id) => {
    if (this.props.assign) {
      this.props.selectedTypeHeadPatient(id);
      this.setState({ finished: false, name: '', patients: [] });
    } else {
      this.props.history.push(`/ampa/${id}/${_id}`);
    }
  };

  updateField = (name, { value, name: role }, callbackField = () => { }) => {
    if (value !== '') {
      value = name === 'active' ? value === 'true' : value;
      this.setState({ [name]: value, page: 1, finished: false }, () =>
        this.searchPatients()
      );
    }
    if (role) {
      const currentRole = role === 'medico' || role === 'enfermera' ? role : '';
      this.setState(
        { [name]: value, currentRole, page: 1, finished: false },
        () => this.searchPatients()
      );
    } else {
      this.setState({ [name]: value, page: 1, finished: false }, () =>
        this.searchPatients(700)
      );
    }
    callbackField();
  };

  searchPatients = time => {
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);
    let filter = this.buildFilters(this.state);
    if (this.props.assign) {
      filter.perPage = this.props.perPage;
    }
    this.filterTimeOut = setTimeout(async () => {
      this.setState({ isLoader: true });
      const response = await this.props.client.query({
        query: GET_AMPAS,
        variables: filter
      });

      this.setState({
        isLoader: false,
        finished: true,
        patients: response.data.getAmpas.data,
        page: this.state.page + 1
      });
    }, time);
  };

  scrolled = async o => {
    const { patients, patientsLoad, isLastPage, isLoader } = this.state;
    let xx = o.target.offsetHeight + o.target.scrollTop
    if (xx >= o.target.scrollHeight) {
      if (patientsLoad && !isLastPage && !isLoader) {
        this.setState({ isLoader: true });
        this.loadPatients(patients);
      }
    }
  };

  loadPatients = async (patients = [], time = 0) => {
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);
    const filter = this.buildFilters(this.state);

    this.filterTimeOut = setTimeout(async () => {
      const response = await this.props.client.query({
        query: GET_AMPAS,
        variables: filter
      });
      const dataResponse = response.data.getAmpas.data;

      // valiadate last page

      if (dataResponse.length === 0) {
        this.setState({
          patients,
          isLoader: false,
          isLastPage: true
        });
        return;
      }

      // before last page
      patients = patients.concat(dataResponse);
      this.setState({
        finished: true,
        patients,
        isLoader: false,
        page: this.state.page + 1
      });
    }, time);
  };
  searchPatientCORE = async (name, time = 0) => {
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);
    const filter = { identificacion_query: name }

    this.filterTimeOut = setTimeout(async () => {
      const response = await this.props.client.query({
        query: GET_PATIENT_CORE,
        variables: filter
      });
      try {
        const dataResponse = response.data.getPatientCore.data[0];

        if (dataResponse) {

          const {
            dni,
            name,
            lastName,
            type_dni,
            phone,
            mobile } = dataResponse;
          this.props.history.push({
            state: { dni, name, lastName, type_dni, phone, mobile },
            pathname: '/patients/new'
          });

        }
      } catch (err) {
        this.props.history.push({
          state: { dni: name },
          pathname: '/patients/new'
        });
      }

    })
    // valiadate last page
    //   if (dataResponse.length === 0) {
    //     this.setState({
    //       patients,
    //       isLoader: false,
    //       isLastPage: true
    //     });
    //     return;
    //   }

    //   // before last page
    //   patients = patients.concat(dataResponse);
    //   this.setState({
    //     finished: true,
    //     patients,
    //     isLoader: false,
    //     page: this.state.page + 1
    //   });
    // }, time);
  };
  toggleFilters = () => {
    this.setState({ filtersCollapsed: !this.state.filtersCollapsed });
  };

  componentDidMount() {
    this.setState({ isLoader: true });
    const { patients } = this.state;
    if (!this.props.assign) {
      this.loadPatients(patients);
    }
  }
  _createPatientAtDocument = () => {
    const { name } = this.state;
    if (document.location.pathname.indexOf('availability') > -1 || this.props.assign) {
      this.props.handleShowNewPatientAvailability(true);
    } else {
      this.searchPatientCORE(name);
      // this.props.history.push({
      //   state: { dni: name, lastName },
      //   pathname: '/patients/new'
      // });
    }
  };
  render() {
    const { children, callbackField } = this.props;

    const { patients, finished, filtersCollapsed, name, isLoader } = this.state;
    const numeric = parseInt(name) ? parseInt(name) : 0;
    return (
      <BodyContainer isLoader={isLoader}>
        <div
          className={`patients-list contentListUser ${
            filtersCollapsed ? 'filters-collapsed' : ''
            }`}
        >
          <div className="flex j-c a-i-c search-container">
            <div className="search-field-icon">
              <TextInput
                iconBefore="search"
                value={this.state.name}
                type="text"
                name="name"
                auxName="patient"
                label={
                  window.innerWidth < 780
                    ? 'Buscar'
                    : 'Identificación del paciente'
                }
                updateField={(name, params) => this.updateField(name, params, callbackField)}
              />
            </div>
            <div className="users-filters-toggle text-right ripple-gray">
              <span
                className="large material-icons clickable"
                onClick={this.toggleFilters}
              >
                toc
              </span>
            </div>
          </div>

          {finished && numeric > 999 && patients.length === 0 ? (
            <div className="table-container">
              <div className="patiens-list-container-button">
                <PanelButton
                  icon={assets.addUserWhite}
                  label="Crear paciente"
                  onClick={this._createPatientAtDocument}
                />
              </div>
            </div>
          ) : (
              <PatientsTable
                assign={this.props.assign}
                scrolled={this.scrolled}
                patients={patients}
                toProfile={this.toProfile}
                finished={finished}
              />
            )}
          {children}
        </div>
      </BodyContainer>
    );
  }
}

AmpasList.propTypes = {
  client: PropTypes.object, // action query graphql
  history: PropTypes.object
  // children: PropTypes.element
};
// AmpasList.defaultProps = {
// 	children: []
// };
export default withApollo(withRouter(AmpasList));

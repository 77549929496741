/**
 * ResourceInfo
 */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'react-calendar/dist/entry.nostyle';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';

// my components
import FormNotify from '../../../components/general/formNotify';
import BodyContainer from '../../../components/general/bodyContainer';
import ScheduleWeek from '../../../components/resourcesModule/scheduleWeek';
import { CREATE_RESOURCE } from '../../../graphql/types/resourcesModule/mutations';
import { days } from '../../../utils/resources/resourcesUtils';

import './style.css';

class ResourceInfo extends Component {
  state = {
    isLoader: true,
    isModalDelete: false
  };
  componentDidMount = async () => {
    await this.props.getCurrentResource(this.props.match.params.id);
    this.setState({ isLoader: false });
  };

  _editResource = _id => {
    this.props.history.push(`/resources/${_id}/edit`);
  };

  _toggleModalDelete = isModalDelete => {
    this.setState({ isModalDelete });
  };

  _deleteResource = async _id => {
    const { deleteResource } = this.props;
    const response = await deleteResource(_id);
    if (response.state) this.props.history.goBack();
  };

  onChange = async date => {
    const currentDay = moment(date);
    this.setState({ currentDay });
    await this.props.getCurrentWeek(currentDay, this.props.currentService);
  };

  exitEdit = (wait, cancel) => {
    let newPath = this.props.location.pathname;
    if (/(\/new)/.test(newPath)) {
      newPath = newPath.replace(/(\/new)/, '');

      if (wait) {
        setTimeout(() => {
          this.props.history.push(newPath);
        }, 2500);
      } else {
        this.props.history.push(newPath);
      }
    } else {
      this.setState({ edit: false, newUser: false });
      newPath = newPath.replace(/(\/edit)/, '');
      this.props.history.push(newPath);
    }
    if (!cancel) this.getCurrentUser();
  };

  showSuccessAlert = (successMessage, wait) => {
    this.setState({ successMessage });
    this.exitEdit(wait);
    setTimeout(() => {
      this.setState({ successMessage: '' });
    }, 2000);
  };

  renderServices = () => {
    const { completeSelectedServices } = this.props;
    if (!completeSelectedServices) {
      return null;
    }
    return completeSelectedServices.map((service, index) => {
      if (service) {
        return (
          <div key={index} className="resource-service">
            {service.name}
          </div>
        );
      }
    });
  };

  render () {
    const { isLoader, isModalDelete } = this.state;
    const {
      date,
      currentDay,
      currentWeek,
      currentService,
      specialSchedule,
      handleChangeTab,
      currentResource,
      selectedServices,
      completeSelectedServices
    } = this.props;

    let _resourceType = {};
    if (currentResource && currentResource._resourceType)
      _resourceType = currentResource._resourceType;
    return (
      <BodyContainer isLoader={isLoader}>
        {currentResource && (
          <Fragment>
            <Accordion accordion={false}>
              <AccordionItem uuid="uniqueItem-create-resource" expanded={true}>
                <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
                  <span>Crear Recurso</span>
                  <div className="accordion__arrow" role="presentation" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  {currentResource && (
                    <>
                      <div className="flex f-w f-c j-s">
                        <table border={1}>
                          <tr>
                            <td>Tipo:</td>
                            <td><b>{_resourceType.name}</b></td>
                            <td>Nombre:</td>
                            <td><b>{currentResource.name}</b></td>
                            {(_resourceType.name === 'Equipo' ||
                            _resourceType.name === 'Espacio de trabajo') && (
                              <>
                                <td>Sede:</td>
                                <td><b>{currentResource._sede.name}</b></td>
                              </>
                            )}
                            {_resourceType.name === 'Equipo' && (
                              <>
                                <td>Cantidad:</td>
                                <td><b>{currentResource.quantity}</b></td>
                              </>
                            )}
                          </tr>
                        </table>
                      </div>
                    </>
                  )}
                </AccordionItemBody>
              </AccordionItem>
              {
                (_resourceType.name === 'Espacio de trabajo' || _resourceType.name === 'Equipo') && selectedServices.length > 0 && (
                  <AccordionItem
                    uuid="uniqueItem-presentations"
                    expanded={true}
                  >
                    <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
                      <span>Prestaciones</span>
                      <div className="accordion__arrow" role="presentation" />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <div className="text-center font-14 mb-1">
                        <label>
                          Estas son las prestaciones que puedan realizarce en
                          este espacio de trabajo
                        </label>
                      </div>
                      <div className="text-center font-14 resource-services flex j-c f-w">
                        {this.renderServices()}
                      </div>
                    </AccordionItemBody>
                  </AccordionItem>
                )}
              {
                (_resourceType.name === 'Sede' || (_resourceType.name === 'Espacio de trabajo' && specialSchedule)) && (
                  <Fragment>
                    <div className="contentServices flex mt-1px">
                      {completeSelectedServices.map((service, i) => (
                        <div
                          key={i}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleChangeTab(service)}
                          className={`
                            cs_nav 
                            ${selectedServices.length === 1 && 'tabOne'} 
                            ${currentService._id === service._id && 'select'}
                          `}
                        >
                          <span>{service.name}</span>
                        </div>
                      ))}
                    </div>
                    <AccordionItem uuid="uniqueItem-calendar" expanded={true}>
                      <AccordionItemTitle
                        className="accordion_title_user_service accordion__title accordion__title--animated u-position-relative ripple-title"
                        onClick={() => {}}
                      >
                        <span>Calendario</span>
                      </AccordionItemTitle>
                      <AccordionItemBody>
                        <div className="flex f-w j-sb">
                          <Calendar
                            onChange={this.onChange}
                            value={date}
                          />
                          <ScheduleWeek
                            currentWeek={currentWeek}
                            currentDay={
                              days[currentDay.day()] + currentDay.format(' D')
                            }
                            ResourceDate={currentDay}
                          />
                        </div>
                      </AccordionItemBody>
                    </AccordionItem>
                  </Fragment>
                )
              }
            </Accordion>
          </Fragment>
        )}
        {currentResource === undefined && (
          <p className="text-center">Cargando recurso...</p>
        )}
        {currentResource === null && (
          <p className="text-center">El recurso no existe</p>
        )}
        {currentResource && (
          <div className="text-center flex j-c pt-1 pb-1">
            <button
              type="submit"
              className="button ripple save-user button-sm bg-icon"
              onClick={() => {
                this._editResource(currentResource._id);
              }}
            >
              Editar
            </button>
            <button
              type="submit"
              className="button ripple-btn-alert save-user button-sm btn-alert"
              onClick={() => this._toggleModalDelete(true)}
            >
              Eliminar
            </button>
          </div>
        )}
        {isModalDelete && (
          <FormNotify
            title="Aviso!"
            message=" Confirme si desea eliminar el recurso."
            buttons={[
              {
                text: 'Cancelar',
                btnClass: 'btn-gray',
                onClick: () => this._toggleModalDelete(false)
              },
              {
                text: 'Aceptar',
                btnClass: 'btn-alert',
                onClick: () => this._deleteResource(currentResource._id)
              }
            ]}
            onCloseNotify={() => this._toggleModalDelete(false)}
          />
        )}
      </BodyContainer>
    );
  }
}
ResourceInfo.propTypes = {
  date: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  currentDay: PropTypes.object,
  currentService: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  getCurrentWeek: PropTypes.func,
  currentResource: PropTypes.object,
  handleChangeTab: PropTypes.func,
  getCurrentResource: PropTypes.func,
  completeSelectedServices: PropTypes.array
};
export default graphql(CREATE_RESOURCE, { name: 'createResource' })(
  withApollo(withRouter(ResourceInfo))
);

import React from 'react';
import Select from 'react-select';

const FormMultipleSelect = ({ options, value, onChange, placeholder }) => {
    return (
        <Select
            options={options}
            value={value}
            onChange={onChange}
            isMulti
            placeholder={placeholder}
        />
    )
}

export default FormMultipleSelect;

import React, { Component } from "react";
import { PatientConsumer } from "../../../../providers/patientProvider";
import AmpaReport from "./ampaReport";

class AmpaRept extends Component {
    render() {

        return (
            <PatientConsumer>
                {props => <AmpaReport {...props} />}
            </PatientConsumer>
        );
    }
}

export default AmpaRept;

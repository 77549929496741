import React, { Component } from 'react';
import gql from 'graphql-tag';
import TextInput from '../../../components/general/textInput';
import './style.css';
import '../../../icomoon/style.css';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import { AUTH_TOKEN, AUTH_REFRESH_TOKEN } from '../../../utils/constants';
import { LOGIN_MUTATION } from '../../../graphql/types/usersModule/mutations';
import ERRORS from './errors.json';

class Login extends Component {
  state = {
    email: '',
    password: '',
    error: false,
    errorMessage: ''
  };

  updateField = (name, { value }) => {
    this.setState({ [name]: value });
  };

  removeError = () => {
    this.setState({ error: false });
  };

  confirm = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    const result = await this.props.login({
      variables: {
        email,
        password
      }
    });
    if (result.data.login.success) {
      const token = result.data.login.token;
      const refreshToken = result.data.login.refreshToken;
      this._saveUserData(token, refreshToken);
      this.removeError();
      let self = this;

      self.props
        .status_login({ variables: { login: true } })
        .then(response => {})
        .catch(error => {});

      this.props.history.push('patients');
    } else {
      const errorMessage = ERRORS[result.data.login.errors[0].message];
      this.setState({ error: true, errorMessage });
    }
  };

  _saveUserData = (token, refreshToken) => {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(AUTH_REFRESH_TOKEN, refreshToken);
  };

  render () {

    return (
      <div className="container flex j-c a-c-c a-i-c">
        <div className="login-container">
          <img src={require('../../../assets/logo.png')} alt="Logo Sicor" />

          <form
            className="text-center flex f-c a-i-c"
            onSubmit={this.confirm}
            onFocus={this.removeError}
          >
            <div
              className={`form-group ${this.state.error ? 'wrong-input' : ''}`}
            >
              <TextInput
                iconBefore="usuario"
                value={this.state.email}
                type="text"
                name="email"
                label="email"
                updateField={this.updateField}
              />
            </div>
            <div
              className={`form-group ${this.state.error ? 'wrong-input' : ''}`}
            >
              <TextInput
                iconBefore="contrasena"
                value={this.state.password}
                type="password"
                name="password"
                label="contraseña"
                updateField={this.updateField}
              />
            </div>

            <div className="recovery-link helper-text">
              <Link to="/forgot"> ¿Olvidaste tu contraseña? </Link>
            </div>

            <div className="text-center mt-2">
              <button
                className="button button-lg bg-primary"
                type="submit"
                onClick={this.confirm}
              >
                Ingresar
              </button>
            </div>
          </form>
          {this.state.error ? (
            <p className="text-center error-msg">{this.state.errorMessage}</p>
          ) : null}
        </div>
      </div>
    );
  }
}
const STATUS_LOGIN = gql`
  mutation setCurrentLogin($login: Boolean!) {
    setCurrentLogin(login: $login) @client {
      status
    }
  }
`;

export default compose(
  graphql(LOGIN_MUTATION, { name: 'login' }),
  graphql(STATUS_LOGIN, { name: 'status_login' })
)(Login);

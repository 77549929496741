/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Suggest from './suggest';
import TypeaheadMerge from '../../components/mergePh/TypeaheadMerge/index';
import Confirmed from './confimed';
import { GET_PHYSICIAN_EQ } from '../../graphql/types/physician/queries';
import { MERGE_PHYSICIAN } from '../../graphql/types/physician/mutations';
import ConfirmModal from './modal';
import _ from 'lodash';
import './style.css';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo, compose } from 'react-apollo';
class MergePh extends Component {
  state = { listReferringPhysician: [], phIds: [], phId: '', openModalRooms: false, loading: false, noData: true };
  onChangeConfirm = (elem) => {
    let { phId } = this.state;
    phId = elem.id;
    this.setState({ phId });
  }
  closeModal = () => {
    this.setState({ openModalRooms: false });
  }
  mergePhysician = async () => {
    const { phId } = this.state;
    if (phId !== '') {
      this.setState({ openModalRooms: true });
    }
    // return;
  }
  putActionConfirm = async () => {
    const { phIds, phId } = this.state;
    this.props.mergePhysician({
      variables: { phIds, phId }
    })
      .then(response => {
        if (response) {
          try {
            if (this.getDataTimeOut) clearTimeout(this.getDataTimeOut);
            this.getDataTimeOut = setTimeout(async () => {
              this.getPhysician();
              this.closeModal();
            }, 300);
          } catch (err) {
            this.closeModal();
          }
        }
      });
  }
  getPhysician = async () => {
    const { toFind } = this.state;
    if (toFind === '') {
      this.setState({
        listReferringPhysician: [], loading: false, noData: true
      });
    }
    const filter = { fullname: toFind, eq: true };
    this.props.client
      .query({
        query: GET_PHYSICIAN_EQ,
        variables: filter
      })
      .then(response => {
        
        try {
          if (response) {

            if (response.data.getPhysician.data) {
              const { data } = response.data.getPhysician;
              const listReferringPhysician = data;
              if (!_.isEmpty(data)) {
                console.log(data, "<<<<<< data >>>>>")
                this.setState({
                  listReferringPhysician, loading: false, noData: false
                });
              } else {
                this.setState({
                  listReferringPhysician: [], loading: false, noData: true
                });
              }
              // return data;
            }

          } else {
            alert("nada")
            this.setState({ noData: true });
          }
        } catch (err) {
          this.setState({ noData: true });
          console.log(err);
        }
      })


  }
  _onInputChangeTypeHead = async (value) => {
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);
    this.filterTimeOut = setTimeout(async () => {
      if (value === '') {
        this.setState({
          toFind: '', loading: false, noData: true, listReferringPhysician: []
        });
      } else {
        this.setState({ toFind: value, loading: true, noData: false, listReferringPhysician: [] });
      }
      await this.getPhysician();
    }, 300);

  };
  onChange = (elem) => {

    let { phIds } = this.state;
    if (elem.checked === true) {
      phIds.push(elem.id);
      this.setState({ phIds });
    } else {
      phIds = phIds.filter(function (value) {
        if (value !== elem.id) {
          return value;
        }
      });
      this.setState({ phIds });
    }
  }
  render() {
    const { listReferringPhysician, openModalRooms, loading, noData } = this.state;
    // console.log(listReferringPhysician);
    return (
      <Fragment>
        <ConfirmModal putActionConfirm={this.putActionConfirm} closeModal={this.closeModal} openModalRooms={openModalRooms} id="confirmModal"></ConfirmModal>
        <div id="mergeModule">
          <div id="topbar" className="users-title ripple-title">
            Fusionar médicos remitentes
          </div>
          <div className="help-text">
            Esta herramienta es para fusionar médicos que están repetidos, se busca por nombres, apellidos o ambos, los de la derecha son los médicos confirmados, los de la izquierda son médico sugeridos, puedes seleccionar uno solo confirmado y múltiples sugeridos, ya luego mueve los médicos con las prestaciones asignadas y los deshabilita del sistema
          </div>
          <div className="label-title">
            Buscar médico remitente
          </div>
          <div className="Search-Ph">
            <TypeaheadMerge
              onChange={this._onInputChangeTypeHead}
            >
            </TypeaheadMerge>
          </div>
          <div className="container-ph">
            <div className="suggest">
              <span>Médicos remitentes<span className="yellow"> por confirmar</span></span>
            </div>
            <div></div>
            <div className="confirmed">
              <span>Médicos remitentes<span className="green"> confirmados</span></span>
            </div>
          </div>
          <div className="container-ph">
            <div className="container-suggest-ph">
              {loading &&
                <div className="loading">
                  buscando
                </div>
              }

              {listReferringPhysician.map((elem, index) => {
                if (elem.status === 'suggested') {
                  return <Suggest key={`${index}sugc`} data={elem} onChange={this.onChange}></Suggest>;
                }
              })}
              {noData &&
                <div className=" animated heartBeat"> no hay resultados</div>
              }
            </div>

            <div className="glyph fs1" onClick={this.mergePhysician}>
              <div className="clearfix bshadow0 pbs">
                <span className="icon-btn big"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
              </div>
            </div>
            <div className="container-suggest-ph">
              {loading &&
                <div className="loading">
                  buscando
              </div>
              }
              {listReferringPhysician.map((elem, index) => {
                if (elem.status === 'confirmado') {
                  return <Confirmed onChange={this.onChangeConfirm} key={`${index}sugas`} data={elem} isChecked={this.state.phId} ></Confirmed>;
                }
              })}
              {noData &&
                <div className="empty merge"> no hay resultados</div>
              }
            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}
MergePh.propTypes = {
  client: PropTypes.object,
  mergePhysician: PropTypes.func
};
export default compose(graphql(MERGE_PHYSICIAN, { name: 'mergePhysician' }))(withRouter(withApollo(MergePh)));
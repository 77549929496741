/* eslint-disable */
import React, { Component } from 'react';
import { BarChart, Bar, Tooltip, Text, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { context } from "../../../providers/dashboardProvider";

class Top5ChartSpecialty extends Component {

  static contextType = context;

  constructor (props) {
    super(props);
  }


  render () {
    const { topReferralSpecialties } = this.context;

    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h5 className='card-title'> Top 5 Especialidades </h5>
          </div>
        </div>
        <br />
        <div className="row mh300">
          {topReferralSpecialties.length > 0 &&
            <ResponsiveContainer width={'100%'} height={250} >
              <BarChart data={topReferralSpecialties} barCategoryGap='30%' layout="vertical">                
                <XAxis type="number" hide />
                <YAxis 
                  dataKey="name"
                  type="category"
                  width={150}
                  padding={{ left: 20 }}
                  style={{
                    fontFamily: 'Arial'
                  }}
                />
                <Tooltip />
                <Text scaleToFit="true" />
                <Bar dataKey="referrals" fill="#16aaff" />
              </BarChart>
            </ResponsiveContainer>           
          }
        </div>
      </div>
    );
  }

}
export default Top5ChartSpecialty;
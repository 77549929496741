/**
 *  Schedule
 */
/* eslint-disable */
import React, { Component, Fragment} from 'react';
// import { Link } from 'react-router-dom';
//import ScheduleList from '../../../components/scheduleModule/scheduleList';
import Modal from '../../../components/general/modal';
import RoomScheduleFilters from '../../../components/scheduleModule/roomScheduleFilters';
import './style.css';
import FilterComponent from './filterComponent'

class Schedule extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openModalRooms: false,
      patients:[
        {
          appointmentTime: '08:20 am',
          state:'En sala',
          patientName:'Luisa Fernanda Valencia Velez',
          patientID:'CC 43206628',
          physician:'Luz Adriana Ocampo Aristizabal',
          sede:'Poblado',
          resources:'Monitoreo Simultánea',
          serviceType:'Mapa monitoreo'
        },
        {
          appointmentTime: '08:40 am',
          state:'En recepción',
          patientName:'Daniel Madrigal',
          patientID:'CC 43206628',
          physician:'Luz Adriana Ocampo Aristizabal',
          sede:'Poblado',
          resources:'Monitoreo Simultánea',
          serviceType:'Mapa monitoreo'
        },
        {
          appointmentTime: '09:00 am',
          state:'Confirmada',
          patientName:'Juan Esteban Gomez',
          patientID:'CC 43206628',
          physician:'Luz Adriana Ocampo Aristizabal',
          sede:'Poblado',
          resources:'Monitoreo Simultánea',
          serviceType:'Mapa monitoreo'
        },
        {
          appointmentTime: '09:30 am',
          state:'Confirmada',
          patientName:'Luisa Fernanda Valencia Velez',
          patientID:'CC 43206628',
          physician:'Luz Adriana Ocampo Aristizabal',
          sede:'Poblado',
          resources:'Monitoreo Simultánea',
          serviceType:'Mapa monitoreo'
        },
        {
          appointmentTime: '09:50 am',
          state:'Confirmada',
          patientName:'Daniel Madrigal',
          patientID:'CC 43206628',
          physician:'Luz Adriana Ocampo Aristizabal',
          sede:'Poblado',
          resources:'Monitoreo Simultánea',
          serviceType:'Mapa monitoreo'
        },
        {
          appointmentTime: '10:00 am',
          state:'Confirmada',
          patientName:'Luisa Fernanda Valencia Velez',
          patientID:'CC 43206628',
          physician:'Luz Adriana Ocampo Aristizabal',
          sede:'Poblado',
          resources:'Monitoreo Simultánea',
          serviceType:'Mapa monitoreo'
        },
      ]
    }
  }

  openRoomSchedule = () => {
    this.setState({ openModalRooms: true })
  }

  closeRoomSchedule = () => {
    this.setState({ openModalRooms: false })
  }

  checkStateColor = (state) => {
    let colorAsClassName = 'patient-state'
    if(state==='En sala'){
      colorAsClassName+=' sala'
    }
    else if(state==='En recepción'){
      colorAsClassName+=' recepcion'
    }
    else if(state==='Confirmada'){
      colorAsClassName+=' confirmada'
    }
    return colorAsClassName
  }

  render() {
    const { openModalRooms } = this.state
    return (
    <div className="schedule-container">
      <div className="schedule">
        {openModalRooms &&
          <Modal isOpenModal={openModalRooms} onClose={() => this.closeRoomSchedule()} title="Calendario de citas" >
            <RoomScheduleFilters></RoomScheduleFilters>
          </Modal>}
        <div className="users-title ripple-title" style={{justifyContent: "space-between",fontSize:'1.2rem'}}>
          Agenda
          <button className="button button-sm bg-icon rooms-schedule-button" onClick={() => this.openRoomSchedule()}>
              <span className="icon-schedule large ripple-gray icon-button" />
              Ocupación salas
          </button>
        </div>

        <div className="patients-count">
          <span>6 pacientes encontrados</span>
        </div>

          {this.state.patients.map(appointment=>

          (
            <div className="patient-table">
              <div className="row-header">
                <div className="row-header-element">
                  <span className="icon-availability icon-grey" />
                  <span>{appointment.appointmentTime}</span>
                </div>
                <span className={this.checkStateColor(appointment.state)}>{appointment.state}</span>
              </div>
              <div className="row-cells">
                <div className="patient-cell first-col">
                  <span>
                    <b>{appointment.patientName}</b>
                    <br/>
                    {appointment.patientID}
                  </span>
                </div>
                <div className="patient-cell">
                <span>
                    <b>{appointment.physician}</b>
                    <br/>
                    {appointment.sede}
                  </span>
                </div>
                <div className="patient-cell">
                <span>
                    <b>{appointment.resources}</b>
                    <br/>
                    {appointment.serviceType}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>

      <FilterComponent />

    </div>
    );
  }
}
export default Schedule;

import gql from 'graphql-tag';


export const GET_SPECIALTY = gql`
	query getSpecialty(
		$filters: FiltersSpecialty
		$pagination: PaginationSpecialty
		$sort: SortSpecialty
	) {
		getSpecialty(
			filters: $filters
			pagination: $pagination
			sort: $sort
		) {			
			data {
				_id
				name
			}
			errors {
				path
				message
			}
			success
			total
		}
	}
`;

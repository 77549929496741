/**
 * UsersTable
 */
import React, { Fragment } from 'react';
import './style.css';

const UsersTable = props => {
  const { users, toProfile, scrolled, finished } = props;
  return (
    <div className="table-container" onScroll={scrolled}>
      {finished ? (
        <Fragment>
          {users.length > 0 ? (
            <table className="list users-list">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Identificación</th>
                  <th>E-mail</th>
                  <th>Rol</th>
                  <th>Cargo</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user, index) => {
                    if (!user) {
                      return null;
                    }
                    const userPosition = user._position
                      ? user._position.name
                      : 'Sin cargo';
                    const userRole = user._role ? user._role.name : 'Sin rol';
                    const userActive = user.active ? 'Activo' : 'Inactivo';
                    return (
                      <tr
                        key={index}
                        onClick={() => toProfile(user._id)}
                        className="list-item clickable ripple-title"
                      >
                        <td>{user.fullName}</td>
                        <td>{user.dni}</td>
                        <td>{user.email}</td>
                        <td>{userRole}</td>
                        <td>{userPosition}</td>
                        <td>{userActive}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <p className="text-center">No se encontraron usuarios</p>
          )}
        </Fragment>
      ) : (
        <p className="text-center">Cargando usuarios...</p>
      )}
    </div>
  );
};

export default UsersTable;

import React, { Component } from "react";
import TreeFolderStructure from '../../../components/general/folderStructure'
import BodyContainer from '../../../components/general/bodyContainer';
import MakerAndReadPhysiciansReport from "../makerAndReadPhysicians";

class Report extends Component {

  constructor(props) {
    super(props)
    this.state = { tree: {}, loadData: true, error: false }
  }

  componentDidMount = async () => {
    //let url = getUrlHost()
    //debugger

    try {
      const realUrl = `${process.env.REACT_APP_URL_AGENDAMIENTO}/report/folder-structure/${localStorage.getItem('token')}/${localStorage.getItem('refreshToken')}`
      const response = await fetch(`${realUrl}`)
      const tree = (await response.json()).data
      this.setState({ loadData: false, tree })
    } catch (error) {
      console.log(error)
      this.setState({ loadData: false, error: true })
    }


  }

  render() {
    const { loadData, tree, error } = this.state;

    if (loadData) return <p>Cargando...</p>
    if (error) return <p>Error</p>

    return (
      <BodyContainer>
        <div className="users-title ripple-title">Reporte de médicos que leen y realizan</div>        
        <MakerAndReadPhysiciansReport />

        <div className="users-title ripple-title">Reportes periodicos</div>
        {tree ? (
          <TreeFolderStructure tree={tree}>

          </TreeFolderStructure>
        ) : 'no tiene permisos'}

      </BodyContainer>
    )
  }
}

export default Report;

import gql from 'graphql-tag';
export const CREATE_MEDICINES = gql`
mutation createMedicines(
  $nameMedicine: String
  $idMedicine: String
  $quantity: String
  $dose: String
  $full: String
  $duration: String
  $doseUnits:String
  $idAmpa: String
){
  createMedicines(
    nameMedicine: $nameMedicine
    idMedicine: $idMedicine
    quantity: $quantity
    dose: $dose
    full: $full
    duration: $duration
    doseUnits: $doseUnits
    idAmpa: $idAmpa
  ){
	  errors {
		path
		message
	  }
	  data {
		_id
	  }
	}
}
`
export const CREATE_AMPA = gql`

mutation createAmpa(
    $dateInit: String
    $selectedRemitente: String
    $idRemitente: String
    $dateEnd: String
    $status: String
    $size: String
    $weight: String
    $bmi: String
    $physicalActivity: String
    $pregnant: String
    $smoke: String
    $liquor: String
    $liquorFrequency: String
    $hardLiquor: Boolean
    $wine: Boolean
    $beer:Boolean
    $nursingNotes: String
    $nameEquipment: String
    $equipmentReference: String
    $idNurseManager: String
    $token: String
    $phone: String
    $patient: String
  ) {
	createAmpa(
        dateInit: $dateInit
        dateEnd: $dateEnd
        status: $status
        selectedRemitente: $selectedRemitente
        idRemitente: $idRemitente
        size: $size
        weight: $weight
        bmi: $bmi
        physicalActivity: $physicalActivity
        pregnant: $pregnant
        smoke: $smoke
        liquor: $liquor
        liquorFrequency: $liquorFrequency
        hardLiquor: $hardLiquor
        wine: $wine
        beer: $beer
        nursingNotes: $nursingNotes
        nameEquipment: $nameEquipment
        equipmentReference: $equipmentReference
        idNurseManager: $idNurseManager
        token: $token
        phone: $phone
        patient: $patient
        
    ) {
	  errors {
		path
		message
	  }
	  data {
		_id
	  }
	}

  }
`;

export const UPDATE_NURSENOTE = gql`
mutation updateNurseNote(
  $_id: ID!
  $nursingNotes: String
){
  updateNurseNote(
    _id: $_id
    nursingNotes: $nursingNotes
  ){
    errors {
      path
      message
    }
    data{
      _id
    }
  }
}
`;

export const UPDATE_DOCTORNOTE = gql`
mutation updateDoctorNote(
  $_id: ID!
  $doctorNotes: String
){
  updateDoctorNote(
    _id: $_id
    doctorNotes: $doctorNotes
  ){
    errors {
      path
      message
    }
    data{
      _id
    }
  }
}
`;
/**
 *  Schedule
 */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import ScheduleList from '../../../components/scheduleModule_old/scheduleList';
import './style.css';
class Schedule extends Component {

  constructor(props) {
    super(props);
    this.state = { results: 0 }
  }

  _numberResults = async results => {
    this.setState({results: results})
  }

  render() {
    const {
      results
    } = this.state;
    return (
      <div className="schedule">

        <div className="users-title ripple-title" style={{justifyContent: "space-between"}}>
          Agenda
          <div>{results} Registros</div>
        </div>

        <ScheduleList
            {...this.props}
            numberResults = {this._numberResults}
          ></ScheduleList>
      </div>
    );
  }
}
export default Schedule;

/**
 * ConfirmationModal
 */
import React from 'react';
import './style.css';
const ConfirmationModal = props => {
  const { onConfirm, onCancel, title, subtitle } = props;
  return (
    <div className={'modal-container'}>
      <div className="modal-body flex f-c a-i-c j-se text-center">
        <div className="modal-msg">
          { title 
              ? title 
              : <p>¿Estás seguro de que deseas cancelar?</p>
          }
          { subtitle 
              ? subtitle
              : <p className="muted-text">Los datos no quedarán guardados.</p> 
          }
        </div>
        <div className="modal-actions">
          <button className="button button-sm bg-muted" onClick={onCancel}>
            No
          </button>
          <button className="button button-sm bg-icon ripple" onClick={onConfirm}>
            Si
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmationModal;

/**
 * TextInput component
 */
import React, { Component } from 'react';

import './style.css';
import '../../../icomoon/style.css';

export default class TextInput extends Component {
  constructor (props) {
    super(props);
    const { labelActive } = props;
    this.state = { focus: false, active: labelActive ? true : false };
  }

  toggleActive = () => {
    this.setState({ active: true, focus: true });
  };

  isEmpty = e => {
    const { labelActive } = this.props;
    if (!labelActive && e.target.value === '') {
      this.setState({ active: false, focus: false });
    } else {
      this.setState({ focus: false });
    }
  };
  render () {
    let {
      max,
      type,
      name,
      label,
      value,
      auxName,
      pattern,
      optional,
      disabled,
      iconAfter,
      iconBefore,
      disablePaste,
      updateField,
      classNameValue
    } = this.props;

    if (name === 'birthDay' && value.length > 10) {
      const date = new Date(value);
      const month = Number(new Date(date).getMonth() + 1);
      value = `${new Date(date).getFullYear()}-${month < 10 ? '0' : ''}${month}-${new Date(date).getDate()}`;
    }
    return (
      <div className='form-group'>
        <div className='form-inline input-field'>
          {iconBefore && <span className={'before-icon icon-' + iconBefore} />}
          <input
            id={auxName ? `${auxName}-${name}` : name}
            max={max}
            name={name}
            type={type}
            value={value}
            onBlur={this.isEmpty}
            onFocus={this.toggleActive}
            onPaste={ disablePaste && ((e) => e.preventDefault()) }
            onChange={e => {
              if (!pattern || (pattern && e.target.value.match(pattern)!=null)) updateField(name, { value: e.target.value });
              else if (pattern && value.match(pattern)===null) updateField(name, { value: '' });
            }}
            disabled={disabled}
            className={`input-box ${this.state.active || this.props.value !== '' ? 'filled' : ''} ${classNameValue}`}
            autoComplete='off'
          />
          <label
            htmlFor={auxName ? `${auxName}-${name}` : name}
            className={`${iconBefore ? 'with-icon' : ''} ${
              this.state.active && this.state.focus ? 'active-label' : ''
            } ${this.state.active || this.props.value !== '' ? 'active' : ''}`}
          >
            {label} {optional && <span className='muted-text'>(Opcional)</span>}
          </label>
          {iconAfter && <span className={'after-icon icon-' + iconAfter} />}
        </div>
      </div>
    );
  }
}

import gql from 'graphql-tag';
export const CREATE_PATIENTR = gql`
mutation createPatientR(
	$name: String
	$lastName: String
	$dni: String
	$email: String
	$mobile: String
  ) {
	createPatientR(name: $name, lastName: $lastName, dni: $dni, email: $email, mobile: $mobile) {
	  errors {
		path
		message
	  }
	  data {
		userId
	  }
	  success
	}

  }
`;

export const CREATE_PATIENT = gql`
	mutation createPatient(
		$name: String
		$lastName: String
		$type_dni: String
		$dni: String
		$birthDay: String
		$email: String
		$phone: String
		$mobile: String
		$observations: String
		$address: String
		$city: String
		$education_level: String
		$gender: String
		$eps: String
		$active: Boolean
		$mother: String
		$father: String
		$attendant: String
		$profession: String
		$programType: ID
		$contactPreference: String
		$regimeType: String
    	$civil_status: String
    	$flag: String
		$photo: String
		$camera: Boolean
	) {
		createPatient(
			name: $name
			lastName: $lastName
			type_dni: $type_dni
			dni: $dni
			birthDay: $birthDay
			email: $email
			phone: $phone
			mobile: $mobile
			observations: $observations
			address: $address
			city: $city
			education_level: $education_level
			gender: $gender
			eps: $eps
			active: $active
			mother: $mother
			father: $father
			attendant: $attendant
			profession: $profession
			programType: $programType
			contactPreference: $contactPreference
			regimeType: $regimeType
      		civil_status: $civil_status
      		flag: $flag
			photo: $photo
			camera: $camera
		) {
			data {
				_id
			}
			success
			errors {
				path
				message
			}
		}
	}
`;

export const UPDATE_PATIENT = gql`
	mutation updatePatient(
		$_id: ID!
		$name: String
		$lastName: String
		$type_dni: String
		$dni: String
		$birthDay: String
		$email: String
		$phone: String
		$mobile: String
		$observations: String
		$address: String
		$city: String
		$education_level: String
		$gender: String
		$eps: String
		$active: Boolean
		$mother: String
		$father: String
		$attendant: String
		$profession: String
		$programType: ID
		$contactPreference: String
		$regimeType: String
    	$civil_status: String
    	$flag: String
		$photo: String
		$camera: Boolean
		$attendant_names: String
		$attending_phone: String
		$attendant_address: String
    $companion: String
    $companion_names: String
    $companion_phone: String
    ) {
      updatePatient(
			_id: $_id
			name: $name
			lastName: $lastName
			type_dni: $type_dni
			dni: $dni
			birthDay: $birthDay
			email: $email
			phone: $phone
			mobile: $mobile
			observations: $observations
			address: $address
			city: $city
			education_level: $education_level
			gender: $gender
			eps: $eps
			active: $active
			mother: $mother
			father: $father
			attendant: $attendant
			profession: $profession
			programType: $programType
			contactPreference: $contactPreference
			regimeType: $regimeType
      		civil_status: $civil_status
      		flag: $flag
			photo: $photo
			camera: $camera
			attendant_names: $attendant_names
			attending_phone: $attending_phone
      attendant_address: $attendant_address
      companion: $companion
      companion_names: $companion_names
      companion_phone: $companion_phone
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;
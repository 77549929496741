import './style.css';

import AppointmentWrapper from '../appointmentDisponibility/appointmentWrapper';
import Calendar from 'react-calendar/dist/entry.nostyle';
import PropTypes from 'prop-types';
/**
 * AppointmentSchedule
 */
import React from 'react';

const AppointmentSchedule = ({
  service,
  selectedDay,
  appointments,
  changeSelectedDay,
  servicesAppointments,
  selectedCalendarService,
  selectedAppointmentsService,
  handleselectedAppointmentsService,
  onCalendarChange
}) => {
  return (
    <div className="flex">
      <div className="contenCalendarAppoinment">
        <Calendar
          value={
            appointments.calendarDate
              ? appointments.calendarDate['_d']
              : appointments[service._id].calendarDate['_d']
          }
          onChange={e => onCalendarChange(e, service)}
          // formatShortWeekday={(value) =>{
          //   if(typeof value !== 'undefined'){
          //     return ['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]
          //   }
          // }
          // }
        />
        <div>
          <ul className="disponibilityIndicators">
            <li className="high">Disponibilidad alta</li>
            <li className="medium">Disponibilidad media</li>
            <li className="low">Disponibilidad baja</li>
            <li className="unavailable">No disponibilidad</li>
          </ul>
        </div>
      </div>
      <div>
        <AppointmentWrapper
          service={service}
          selectedDay={selectedDay}
          appointments={appointments}
          changeSelectedDay={changeSelectedDay}
          servicesAppointments={servicesAppointments}
          selectedCalendarService={selectedCalendarService}
          selectedAppointmentsService={selectedAppointmentsService}
          handleselectedAppointmentsService={handleselectedAppointmentsService}
        />
      </div>
    </div>
  );
};
AppointmentSchedule.propTypes = {
  onCalendarChange: PropTypes.func
};
export default AppointmentSchedule;

import gql from 'graphql-tag';

export const getMultipleAppointmentsBySpaces = ( { spaces, day, lastState } ) => {

  const query = spaces.reduce( (acc, space) => {
    const subquery = `
      _${space}: getAppointment(lastState: "${lastState}", space: "${space}", day: "${day}") {
        success
        errors {
          path
          message
        }
        data {
          _id
          time
          duration
          space {
            _id
            name
          }
          sede {
            _id
            name
          }
          service {
            name
          }
      }
    }
    `

    acc += "\n" + subquery
    return acc;
  }, '')


  return gql`{
    ${query}
  }`
}

export const GET_APPOINTMENTS = gql`
	query getAppointment(
		$lastState: String, 
		$day: String, 
		$patient: ID, 
		$service: ID, 
		$space: ID, 
		$perPage: Int, 
		$page: Int, 
		$time: String, 
		$order: String
	) {
		getAppointment(
			lastState: $lastState
			day: $day
			patient: $patient
			order: $order
			space: $space
			service: $service
			perPage: $perPage
			page: $page
			time: $time
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				time
				day
				hour
				extra
				duration
				billed
				doctor {
					_id
					name
					lastName
				}
				space {
					_id
					name
				}
				sede {
					_id
					name
				}
				service {
					_id
					description
					documents
					name
					nurse
					recommendations
				}
				serviceFamily {
					_id
					name
					resultsServiceId
				}
				state {
					_idAppointments
					state
					observations
					date_created
					leeValue {
						_id
						name
						lastName
						dni
					}
					realizaValue {
						_id
						name
						lastName
						dni
					}
				}
				entity {
					_id
					nombre
					status
					creadoPor
				}
				referringPhysician {
					_id
					fullname
				}
			}
		}
	}
`;

export const GET_STATE_APPOINTMENTS = gql`
	query getStateAppointments($_idAppointments: ID!) {
		getStateAppointments(_idAppointments: $_idAppointments) {
			success
			errors {
				path
				message
			}
			data {
				_idAppointments
				_idPhysician {
					_id
					fullname
				}
				state
				observations
				date_created
				_idUser {
					_id
					name
					lastName
				}
        leeValue {
					_id
					name
					lastName
          dni
				}
        realizaValue {
					_id
					name
					lastName
          dni
				}
			}
		}
	}
`;

export const GET_PHYSICIANS_BY_REFERRAL = gql`
	query getPhysiciansByReferral(
		$pagination: PaginationPhysicians
		$filters: FiltersPhysicians
	) {
		getPhysiciansByReferral(
			pagination: $pagination
			filters: $filters
		) {
			success
			errors {
				path
				message
			}
			data {
				_id
				fullname
				specialties
				institutions
				referrals
			}
			total
		}
	}
`;

export const GET_ALL_LEE_REALIZA_STATE_APPOINTMENTS = gql`
	query getAllStatesLeeRealiza($date1: String, $date2: String) {
		getAllStatesLeeRealiza(date1: $date1, date2: $date2) {
			success
			errors {
				path
				message
			}
			data {
				_idAppointments{
          _id
          entity {
            _id
            nombre
            status
            creadoPor
          }
          patient{
            _id
            dni
            name
            lastName
          }
          time
          service{
            _id
            name
          }
        }
				_idPhysician {
					_id
					fullname
				}
				state
				observations
				date_created
				_idUser {
					_id
					name
					lastName
          dni
				}
        leeValue {
					_id
					name
					lastName
          dni
				}
        realizaValue {
					_id
					name
					lastName
          dni
				}
			}
		}
	}
`;

export const GET_TOP_REFERRAL_PHYSICIANS = gql`
	query getTopReferralPhysicians($filters: FiltersPhysicians) {
		getTopReferralPhysicians(filters: $filters) {
			data {
				name
				referrals
			}
			errors {
				message
				path
			}
			success
		}
	}
`;

export const GET_TOP_REFERRAL_SPECIALTIES = gql`
	query getTopReferralSpecialties($filters: FiltersPhysicians) {
		getTopReferralSpecialties(filters: $filters) {
			data {
				name
				referrals
			}
			errors {
				message
				path
			}
			success
		}
	}
`;

export const GET_TOP_REFERRAL_INSURANCE = gql`
	query getTopReferralInsurance($filters: FiltersPhysicians) {
		getTopReferralInsurance(filters: $filters) {
			data {
				name
				referrals
			}
			errors {
				message
				path
			}
			success
		}
	}
`;

export const GET_TOP_REFERRAL_SERVICES = gql`
	query getTopReferralServices($filters: FiltersPhysicians) {
		getTopReferralServices(filters: $filters) {
			data {
				name
				referrals
			}
			errors {
				message
				path
			}
			success
		}
	}
`
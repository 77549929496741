import React, { Component } from 'react';
import './style.css';

class Unauthorized  extends Component { 
  render() {
    return (
      <div>
        <h1>Acceso no autorizado!</h1>
        <p>No tienes permiso para acceder a esta ruta.</p>
        <span className="back" onClick={() => this.props.history.push('patients')}>Volver a la pagina principal.</span>
      </div>
    )
  }
}

export default Unauthorized;
import React from 'react';
import TextAreaInput from '../../general/textAreaInput';

const ServiceInformation = ({ updateField, values, currentService }) => {
  let { description, documents, recommendations } = values;

  if (!description && currentService.description) description = currentService.description;
  if (!documents && currentService.documents) documents = currentService.documents;
  if (!recommendations && currentService.recommendations) recommendations = currentService.recommendations;

  return (
    <div className="container-resources-form w-100 flex j-c">
      <div className="delete-repetition description">
        <p className="icon text-bold">Descripción</p>
        <TextAreaInput
          rows={10}
          name="description"
          value={description}
          updateField={updateField}
        />
      </div>
      <div className="delete-repetition documents">
        <p className="icon text-bold">Lista de documentos para la cita</p>
        <TextAreaInput
          rows={10}
          name="documents"
          value={documents}
          updateField={updateField}
        />
      </div>
      <div className="delete-repetition recommendations">
        <p className="icon text-bold">Recomendaciones para el cliente</p>
        <TextAreaInput
          rows={10}
          name="recommendations"
          value={recommendations}
          updateField={updateField}
        />
      </div>
    </div>
  );
};

export default ServiceInformation;

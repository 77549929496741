/* eslint-disable */
import 'react-big-calendar/lib/css/react-big-calendar.css';

import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import React, { Fragment } from 'react';

import AppointmentSchedule from '../../../components/patients/appointmentSchedule';
import CheckboxInput from '../../../components/general/checkboxInput';
// import DateTimePicker from 'react-datetime-picker';
import Modal from '../../../components/general/modal';
import ModalSchedule from './modalSchedule';
import MultipleTextInput from '../../../components/general/multipleTextInput';
import moment from 'moment'

const localizer = momentLocalizer(moment)
// let valueDate =  new Date();

// const onchangeDate = (date) => {
//   valueDate = date
// }

const fieldEps = {
  name: 'entityService',
  label: 'Entidad',
  input: {
    name: 'select',
    options: []
  },
  labelActive: true
};

const customEvent = (event) => {

  if (event.selectedHour) {
    return {
      style: {
        background: '#21AF59',
      }
    }
  }

  return {
    style: {
      background: "#0B6183"
    }
  }

}

const customSlotPropGetter = () => {
  return {
    style: {
      background: 'white'
    }
  }
}

const PatientAppointment = ({
  serviceInputValue,
  currentServices,
  updateField,
  updateMultipleInput,
  selectService,
  selectedServices,
  sedes,
  selectSede,
  selectedSedeId,
  getAvailability,
  successAppointments,
  selectedAppointmentsService,
  appointments,
  selectedDay,
  changeSelectedDay,
  servicesAppointments,
  handleselectedAppointmentsService,
  onCalendarChange,
  handleShowModalSchedule,
  showModalSchedule,
  handleappointmentsInfo,
  appointmentsInfo,
  renderSelect,
  eps,
  selectedCalendarService,
  calendarScheduleModal,
  handleCalendarScheduleModal,
  calendarData
}) => {

  return (
    <Fragment>

      <Modal isOpenModal={calendarScheduleModal}
        title="Calendario de citas"
        onClose={() => handleCalendarScheduleModal(false)}>
        <Calendar
          culture={'en'}
          events={calendarData.events}
          toolbar={false}
          localizer={localizer}
          defaultView={Views.DAY}
          views={['day']}
          step={30}
          defaultDate={calendarData.defaultDate}
          resources={calendarData.resourceMap}
          slotPropGetter={customSlotPropGetter}
          eventPropGetter={customEvent}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
        />

      </Modal>

      <AccordionItem uuid="uniqueItem-schedule-appointment" expanded={true}>
        <AccordionItemTitle
          className="accordion__title accordion__title--animated u-position-relative ripple-title"
          onClick={() => { }}
        >
          <span>Agendar Cita</span>
        </AccordionItemTitle>
        <AccordionItemBody>
          <div>
            <div className="container-resources-form flex j-c">
              {renderSelect(fieldEps, eps)}

              <MultipleTextInput
                type="text"
                name="name"
                label="Prestaciones"
                maxValues={3}
                value={serviceInputValue}
                options={currentServices}
                updateField={updateField}
                labelActive={currentServices.length > 0 ? true : false}
                selectOption={selectService}
                updateSelected={updateMultipleInput}
                selectedOptions={selectedServices}
              />

              <div>
                <label className="icon active">Sede</label>
                <div className="flex j-sa">
                  {
                    sedes.map((sede, index) => {
                      if(index == 1){
                        return (
                          <div key={index}>
                            <CheckboxInput
                              labelRight={true}
                              checked={sede._id === selectedSedeId}
                              type="radio"
                              updateField={selectSede}
                              value={sede._id}
                              label={sede.name}
                              name="sede"
                              id={`sede-${index}`}
                            />
                          </div>
                        );
                      }
                    })
                  }
                </div>
              </div>
            </div>
            <div className="text-center mt-2">
              <button
                className="button button-sm bg-icon validate-appointments ripple"
                onClick={getAvailability}
              >
                Buscar disponibilidad
              </button>
            </div>
          </div>
        </AccordionItemBody>
      </AccordionItem>
      {
        successAppointments && selectedServices.map((service, index) => {
          return (
            <div>
              <AccordionItem
                key={service._id}
                uuid={`uniqueItem-hour-schedule-appointment-${index}`}
                expanded={true}
              >
                <AccordionItemTitle
                  className="accordion__title accordion__title--animated u-position-relative ripple-title"
                  onClick={() => { }}
                >
                  <span>Horario {service.label}</span>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <AppointmentSchedule
                    service={service}
                    selectedCalendarService={selectedCalendarService}
                    selectedAppointmentsService={selectedAppointmentsService}
                    handleselectedAppointmentsService={
                      handleselectedAppointmentsService
                    }
                    servicesAppointments={servicesAppointments}
                    changeSelectedDay={changeSelectedDay}
                    selectedDay={selectedDay}
                    appointments={appointments}
                    onCalendarChange={onCalendarChange}
                  />
                </AccordionItemBody>

                <div className="mt-2 mb-2">
                <button
                  style={{ marginBottom: 20, marginLeft: 20 }}
                  className={ selectedAppointmentsService[service._id] ? "button button-sm bg-icon validate-appointments ripple" : "button button-sm bg-icon validate-appointments bg-muted"}
                  onClick={ () =>  selectedAppointmentsService[service._id] && handleCalendarScheduleModal( true, service._id ) }
                >
                  Abrir calendario
                </button>
              </div>
              </AccordionItem>



            </div>
          );
        })
      }
      {
        successAppointments && selectedServices.length > 0 &&
        <div className="text-center mt-2 mb-2">

          <button
            className="button button-sm bg-icon validate-appointments ripple"
            onClick={() => handleShowModalSchedule(true)}
          >
            Agendar cita
            </button>
        </div>
      }
      {
        showModalSchedule && (
          <ModalSchedule
            appointmentsInfo={appointmentsInfo}
            handleappointmentsInfo={handleappointmentsInfo}
          />
        )
      }
    </Fragment>
  );
};

export default PatientAppointment;

/**
 * PanelButton
 */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const PanelButton = ({ id,
  icon,
  style,
  label,
  onClick,
  className
}) => {
  return (
    <button className={`panel-button ${className} bg-icon button ripple`} id={id} onClick={()=>setTimeout(onClick, 300)} style={style}>
      <div className="container-icon">
        <img src={icon} alt="Button" />
      </div>
      <div className="legend">{label}</div>
    </button>
  );
};

PanelButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};

PanelButton.defaultProps = {
  label: '',
  style: {},
  onClick: ()=>{},
  icon: 'https://fakeimg.pl/100x100/',
  className: ''
};

export default PanelButton;

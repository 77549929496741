/**
 * MultipleTextInput
 */
/* eslint-disable */
import React, { Component } from 'react';
import Select from 'react-select';
import { withApollo } from 'react-apollo';
import { GET_SERVICESFAMILY } from '../../../graphql/types/servicesFamilyModule/queries';
import { GET_SERVICES } from '../../../graphql/types/servicesModule/queries';

class MultipleTextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentServices: [],
      servicesFamily: [],
      groupServicesOptions: [],
      valueSelectPrestacion: []
    };
  }

  loadServicesFamily = async () => {
    const servicesFamily = await this.props.client.query({
      query: GET_SERVICESFAMILY
    })

    this.setState({ servicesFamily: servicesFamily.data.getServicesFamily.data });
  }

  loadServices = async () => {
    const currentServices = await this.props.client.query({
      query: GET_SERVICES
    });

    let groupServices = {};

    currentServices.data.getServices.data.map(item => {
      if (!groupServices.hasOwnProperty(item.servicefamily)) {
        groupServices[item.servicefamily] = []
      }

      groupServices[item.servicefamily].push({
        value: item._id,
        label: item.name
      })
    })

    this.setState({ currentServices: groupServices });
  };

  createGroup = (groupName, options, selectOption) => {
    return {
      label: (() => {
        return (
          <div
            onClick={() => {
              this.setState({ valueSelectPrestacion: options });
              selectOption(options)
            }}
          >
            {groupName}
          </div>
        );
      })(),
      options: options,
    };
  };

  makeGroupOptions = () => {
    let groupServicesLast = [];

    const { selectOption } = this.props;

    for (let x in this.state.currentServices) {
      this.state.servicesFamily.map(family => {
        if (family._id == x) {
          let newGroup = this.createGroup(family.name, this.state.currentServices[x], selectOption);

          groupServicesLast.push(
            newGroup
          )
        }
      })
    }

    this.setState({ groupServicesOptions: groupServicesLast });
  }

  componentDidMount = async () => {
    await this.loadServicesFamily();
    await this.loadServices();
    this.makeGroupOptions();
  }

  render() {
    const { divClass, selectOption } = this.props;

    const groupStyles = {
      fontSize: '16px',
      color: 'black',
      fontWeight: 'bold',
      cursor: 'pointer'
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
      </div>
    );

    const customStyles = {
      control: () => ({
        maxHeight: '38px',
        display: 'flex',
        border: '1px solid rgb(204, 204, 204)'
      })
    }

    return (
      <div className={`form-group ${divClass}`}>
        <Select
          placeholder="Buscar prestación..."
          options={this.state.groupServicesOptions}
          formatGroupLabel={formatGroupLabel}
          isMulti
          onChange={(option) => {
            return this.setState({ valueSelectPrestacion: option }, selectOption(option));
          }}
          value={this.state.valueSelectPrestacion}
          styles={customStyles}
        >
        </Select>
      </div>
    );
  }
}

export default withApollo(MultipleTextInput);

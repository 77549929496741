import gql from 'graphql-tag';

export const CREATE_SERVICE = gql`
	mutation createService(
		$name: String!
		$duration: String!
		$description: String
		$documents: String
		$recommendations: String
		$quantity: Int
		$timeOut: Int
    $servicefamily: String
	) {
		createService(
			name: $name
			duration: $duration
			description: $description
			documents: $documents
			recommendations: $recommendations
			quantity: $quantity
			timeOut: $timeOut
      servicefamily: $servicefamily
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;
export const UPDATE_SERVICE = gql`
	mutation updateService(
		$_id: ID!
		$name: String
		$duration: String
		$description: String
		$documents: String
		$recommendations: String
		$nurse: Boolean
    $presentdoctor: String
    $position: String
		$quantity: Int
		$timeOut: Int
    $servicefamily: String
	) {
		updateService(
			_id: $_id
			name: $name
			duration: $duration
			description: $description
			documents: $documents
			recommendations: $recommendations
			nurse: $nurse
      presentdoctor: $presentdoctor
      position: $position
			quantity: $quantity
			timeOut: $timeOut
      servicefamily: $servicefamily
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const DELETE_SERVICE = gql`
	mutation deleteService($_id: ID!) {
		deleteService(_id: $_id) {
			success
			errors {
				path
				message
			}
		}
	}
`;

import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import _ from 'lodash';
import { GET_SERVICES } from '../../../graphql/types/servicesModule/queries';
import CheckboxInput from '../../general/checkboxInput';
import './style.css';

class ServicesOptions extends Component {
  state = {
    allServices: [],
    selectedServices: []
  };
  componentDidMount = async () => {
    const result = await this.props.client.query({
      query: GET_SERVICES,
      variables: { perPage: 100 }
    });
    this.setState({ allServices: result.data.getServices.data });
    if (this.props.setServices) this.props.setServices(result.data.getServices.data);
  };

  render () {
    const { allServices } = this.state;
    const {
      selectService: updateField,
      clearErrors: clear,
      servicesSelected,
      selectedServices = [],
      onIconClick,
      max,
      specialSchedule
    } = this.props;
    return (
      <div className="services-options">
        <div
          className="text-left flex f-r f-w check-options mt-1"
          onFocus={clear}
        >
          {allServices.map((service, index) => {
            let duration;
            let repeatedDays;
            let disabled = false;
            let isSelected = selectedServices.filter(ser => {
              return ser === service._id;
            });

            if (servicesSelected) {
              const hasValues = servicesSelected.filter(ser => {
                return ser._id === service._id;
              });
              if (hasValues.length > 0) {
                duration = hasValues[0].duration;
                repeatedDays = hasValues[0].days;
              }
            }

            if (specialSchedule && max && selectedServices.length >= max) {
              disabled = isSelected.length === 0 ? true : false;
            }

            return (
              <CheckboxInput
                disabled={disabled}
                onIconClick={onIconClick}
                checked={
                  true &&
                  selectedServices.find(selService => {
                    return selService === service._id;
                  }) ? true : false
                }
                className="form-check service-option ripple-gray"
                key={index}
                id={`service-option-${index}`}
                value={service._id}
                label={service.name}
                name="Service"
                duration={duration}
                repeatedDays={repeatedDays}
                modalServices={
                  _.findIndex(servicesSelected, { _id: service._id }) > -1
                    ? true
                    : false
                }
                updateField={updateField}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withApollo(ServicesOptions);

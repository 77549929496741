/**
 * FormTypeHead
 */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typeahead, Menu, MenuItem, Token } from 'react-bootstrap-typeahead';
import './style.css';

class FormTypeHead extends Component {
  filterTimeOut = null;
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      active: (props.labelActive) ? true : false
    };
  }

  _toggleActive = () => {
    this.setState({ active: true, focus: true });
  };

  _isEmpty = e => {
    const { labelActive } = this.props;
    if (!labelActive && e.target.value === '') {
      this.setState({ active: false, focus: false });
    }
    else {
      this.setState({ focus: false });
    }
  };

  /**
   * onInputChange
   * @param {selected} string string in input
   * @param {selectedOptions} array selected options in input
   */
  _onInputChange = (selected, selectedOptions) => {
    const { onInputChange, maxSelectOptions, initLength } = this.props;

    if (selected.length < initLength && selected != '') return;
    if (this.filterTimeOut) clearTimeout(this.filterTimeOut);

    this.filterTimeOut = setTimeout(() => {
      if (selectedOptions.length < maxSelectOptions) {
        onInputChange(selected);
      }
    }, 300);
  }

  render() {
    let labelKey = this.props.labelKey || 'name';
    const { active, focus } = this.state;
    const {
      id,
      name,
      style,
      label,
      options,
      multiple,
      onChange,
      mutedText,
      emptyLabel,
      iconBefore,
      placeholder,
      clearButton,
      caseSensitive,
      selectedOptions,
      maxSelectOptions,
      renderAtional,
      aditional
    } = this.props;
    const labelClass1 = iconBefore ? 'with-icon' : '';
    const labelClass2 = active && focus ? 'label-active' : '';
    const labelClass3 = selectedOptions.length > 0 ? 'label-input-full' : 'empty';

    return (
      <div id={id} className="form-type-head" style={style}>
        {iconBefore !== '' ? <span className={'icon-' + iconBefore} /> : null}
        <label htmlFor={name} className={`${labelClass1} ${labelClass2} ${labelClass3}`}>
          {label}
          {mutedText && <span className="muted-text">{mutedText}</span>}
        </label>
        <Typeahead
          onBlur={this._isEmpty}
          onFocus={this._toggleActive}
          options={options}
          labelKey={labelKey}
          filterBy={['fullname', 'identificacion', 'medicalrecord']}
          // filterBy={() => true}
          multiple={multiple}
          disabled={selectedOptions.length >= maxSelectOptions ? true : false}
          selected={selectedOptions}
          onChange={selected => { onChange(selected); }}
          emptyLabel={emptyLabel}
          clearButton={clearButton}
          placeholder={placeholder}
          caseSensitive={caseSensitive}
          onInputChange={selected => { this._onInputChange(selected, selectedOptions); }}
          renderToken={(option, props, index) => {
            return (
              <Token
                key={index}
                onRemove={props.onRemove}
              >
                {option.name}
              </Token>
            );
          }}
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {
                selectedOptions.length < 3 &&
                [...results]
                  .sort((a, b) => a.fullname > b.fullname ? 1 : -1,)
                  .map((result, index) => {
                    return (
                      <MenuItem key={index} option={result} position={index}>
                        <div className="seudoTable">
                          {renderAtional ? (
                            <Fragment>
                              <strong className={result.status}>{result[labelKey]}</strong>
                            </Fragment>
                          ) : (
                            <strong>{result[labelKey]}</strong>
                          )}
                        </div>
                      </MenuItem>
                    );
                  })
              }
            </Menu>
          )}
        />
      </div>
    );
  }
}

FormTypeHead.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  options: PropTypes.array,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  mutedText: PropTypes.string,
  emptyLabel: PropTypes.bool,
  initLength: PropTypes.number,
  clearButton: PropTypes.bool,
  onInputChange: PropTypes.func,
  caseSensitive: PropTypes.bool,
  selectedOptions: PropTypes.array,
  maxSelectOptions: PropTypes.number
};

FormTypeHead.defaultProps = {
  style: {},
  options: [],
  multiple: true,
  onChange: () => { },
  emptyLabel: false,
  initLength: 3,
  clearButton: true,
  onInputChange: () => { },
  caseSensitive: false,
  selectedOptions: [],
  maxSelectOptions: 3
};

export default FormTypeHead;

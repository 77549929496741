import React, { useContext, useEffect, useState } from "react";
import _, { debounce, set } from "lodash";
import AsyncSelect from "react-select/async";
import { context } from "../../../providers/patientProvider";

const AsyncInput = ({
  functionToCall,
  ms = 700,
  placeholder = 'Buscar...',
  disabled = false,
  defaultValue = [],
  defaultOptions = [],
  name,
  value
}) => {

  const { handleSetBillingData: updateField } = useContext(context);

  const [fieldValue, setFieldValue] = useState(null);

  const debouncedSearch = debounce(async (inputValue, callback) => {
    const response = await functionToCall(inputValue);
    // Llamar al callback con las opciones del resultado
    callback(response);
  }, ms);

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      debouncedSearch(inputValue, callback);
    } else {
      callback([]);
    }
  };

  const handleSetOptionSearchInput = async () => {
    if (!_.isEmpty(value) && !_.isUndefined(value)) {
      const options = await functionToCall();      
      const selectedOption = options.find(option => option.value === value);
      setFieldValue(selectedOption);
    }
  }

  const handleSetValue = (e) => {
    updateField(name, e?.value ?? "");
    setFieldValue(e);
  }

  useEffect(() => {
    handleSetOptionSearchInput();
  }, []);

  return (
    <div>
      <AsyncSelect
        loadOptions={loadOptions}
        onChange={handleSetValue}
        placeholder={placeholder}
        isDisabled={disabled}
        defaultValue={defaultValue}
        defaultOptions={defaultOptions}
        value={fieldValue}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderTopStyle: 'none',
            borderLeftStyle: 'none',
            borderRightStyle: 'none',
          }),
        }}
      />
    </div>
  );
};

export default AsyncInput;

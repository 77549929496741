/**
 * FormModal
 */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const FormModal = ({
  children,
  isOpenModal,
  styleModalCenter
}) => (
  isOpenModal &&
    <div className="body-modal">
      <div className="body-center" style={styleModalCenter}>
        {children && children}
      </div>
    </div>
);
FormModal.propTypes = {
  children: PropTypes.element,
  isOpenModal: PropTypes.bool,
  styleModalCenter: PropTypes.object
};
FormModal.defaultProps = {
  isOpenModal: true,
  styleModalCenter: {}
};
export default FormModal;

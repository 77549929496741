import gql from 'graphql-tag';

export const CREATE_INSTITUTION = gql`
	mutation createInstitution(
		$name: String!
		$city: String!
		$address: String!

	) {
		createInstitution(
			name: $name
			city: $city
			address: $address
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const UPDATE_INSTITUTION = gql`
	mutation updateInstitution(
		$_id: ID!
		$name: String!
		$city: String!
		$address: String!
	) {
		updateInstitution(
			_id: $_id
			name: $name
			city: $city
			address: $address
		) {
			success
			errors {
				path
				message
			}
		}
	}
`;

export const DELETE_INSTITUTION = gql `
	mutation deleteInstitution(
		$_id: ID!
	) {
		deleteInstitution(
			_id: $_id
		) {
			errors {
				message
				path
			}
			success
		}
	}
`;

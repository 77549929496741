/* eslint-disable */
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes, { element } from 'prop-types';
class TypeaheadMerge extends React.Component {
    state = {
      listReferringPhysician: []
    }
    render () {
      const {
        onChange
      } = this.props;

      return (
        <input className="input-ph-find" onChange={ element => { onChange(element.currentTarget.value);} } />
      );
    }
}
TypeaheadMerge.propTypes = {
  onChange: PropTypes.func
};
export default TypeaheadMerge;
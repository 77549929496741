import React, { Component } from 'react';
import { ResourceConsumer } from '../../../providers/resourceProvider';
import ResourceInfo from './index';

class ResourceProfile extends Component {
  render () {
    return (
      <ResourceConsumer>{props => <ResourceInfo {...props} />}</ResourceConsumer>
    );
  }
}

export default ResourceProfile;

import 'moment/locale/es';
import './style.css';

/**
 * AppointmentHistoryGrid
 */
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

moment.locale('es');

const AppointmentHisotryGrid = ({
  currentAppointments,
  getStateAppointments,
  onVisibleModalCancel,
  rescheduleAppointment,
  idSelectedAppointment
}) => {
  const nullState = ['anulada', 'cancelada', 'reprogramada', 'reasignada'];
  
  return (
    <div className="container-history flex f-w">
      {
        currentAppointments && currentAppointments.map((appointment, index) => {
          const { _id } = appointment;
          const classSelected = _id === idSelectedAppointment? 'selected': '';
          const state = appointment.state && appointment.state.state? appointment.state.state: '';
          const entity = appointment.entity && appointment.entity.nombre? appointment.entity.nombre: '\u00A0';
          const titleDate = appointment.time? moment(parseInt(appointment.time)).format('dddd D [de] MMMM [/] hh:mm A YYYY'): '';
          return (
            <div key={index} className={`cardAppointmentsAssigned init save flex j-c a-i-c ${classSelected}`} >
              <span className="title">{appointment.service.name}</span>
              <span>{titleDate}</span>
              <span>{appointment.sede.name}</span>
              <span>{appointment.doctor && appointment.doctor.name}</span>
              <span>{entity}</span>
              <span className={ nullState.indexOf(state) > -1? 'red-state': 'state'}>{state}</span>
              <div className="contentBtnAppointment">
                <span
                  title="Ver"
                  className="icon-view large ripple-gray"
                  onClick={()=>setTimeout(()=>getStateAppointments(appointment, index), 300)}
                />
                {
                  nullState.indexOf(state) === -1 && state !== 'terminada' &&
                  <Fragment>                    
                    <span
                      title="Editar"
                      className="icon-edit large ripple-gray"
                      onClick={() => setTimeout(() => rescheduleAppointment(appointment, index), 300)}
                    />
                  </Fragment>
                }
              </div>
            </div>
          );
        })
      }
    </div>
  );
};
AppointmentHisotryGrid.propTypes = {
  onScroll: PropTypes.func,
  currentAppointments: PropTypes.array,
  onVisibleModalCancel: PropTypes.func,
  getStateAppointments: PropTypes.func,
  rescheduleAppointment: PropTypes.func,
  idSelectedAppointment: PropTypes.string
};
AppointmentHisotryGrid.defaulProps = {
  onVisibleModalCancel: () => {},
  idSelectedAppointment: ''
};
export default AppointmentHisotryGrid;

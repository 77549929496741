import React, { Component } from 'react';
import { PatientConsumer } from '../../../providers/patientProvider';
import Patient from './index';

class PatientNew extends Component {
  render () {
    return <PatientConsumer {...this.props}>{props => <Patient {...this.props} {...props} />}</PatientConsumer>;
  }
}

export default PatientNew;

/**
 * Service
 */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo, compose } from 'react-apollo';
import MultipleTextInput from '../../../components/general/multipleTextInput';
import PhysicianServices from './physicianServices';
import _ from 'lodash';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';

import ServiceForm from '../../../components/physicianModule/serviceForm';
import ServiceInformation from '../../../components/physicianModule/serviceInformation';
import SelectInput from '../../../components/general/selectInput';
import ConfirmationModal from '../../../components/general/confirmationModal';
import CheckboxInput from '../../../components/general/checkboxInput';
import { specialScheduleOptions } from '../../../utils/resources/resourcesUtils';

import './style.css';

class Physician extends Component {
  state = {
    confirmationModal: false
  };

  componentDidMount = async () => {
    if (!this.props.currentService && this.props.match.params.id) {
      // console.log('getCurrentService TODO: change to getmedico remitente');
      this.props.getCurrentService(this.props.match.params.id);
    }
  };

  toggleConfirmationModal = () => {
    const confirmationModal = !this.state.confirmationModal;
    this.setState({ confirmationModal });
    if (!confirmationModal) localStorage.removeItem('clickSidebar');
    if (!localStorage.getItem('canExitEdit')) this.cancelRedirect();
  };

  cancelRedirect = () => {
    localStorage.removeItem('canExitEdit');
    localStorage.removeItem('clickSidebar');
    this.exitService();
  };

  exitService = () => {
    this.props.history.push(
      '/medicosremitentes/'
    );
  };

  render() {
    if (
      localStorage.getItem('canExitEdit') &&
      localStorage.getItem('clickSidebar') &&
      !this.state.confirmationModal
    ) {
      this.toggleConfirmationModal();
    }

    const {
      name,
      lastname,
      error,
      success,
      timeOut,
      position,
      active,
      quantity,
      updateField,
      clearErrors,
      saveService,
      errorMessage,
      disableSubmit,
      successMessage,
      currentService,
      history_2
    } = this.props;
    // console.log(name, lastname, errorMessage);
    if (!_.isEmpty(history_2)) {
      // console.log("medico")
      // console.log(history_2)
    }
    const { confirmationModal } = this.state;
    return (<div > {
      confirmationModal &&
      <ConfirmationModal
        onCancel={this.toggleConfirmationModal}
        onConfirm={this.cancelRedirect}
      />
    } <Accordion accordion={false}>
        <AccordionItem uuid="uniqueItem-presentation"
          expanded={true}
        >
          <AccordionItemTitle className="accordion__title accordion__title--animated u-position-relative ripple-title">
            <span >  Médico remitente</span> <div className="accordion__arrow"
              role="presentation"
            />
          </AccordionItemTitle> <AccordionItemBody >
            <ServiceForm currentService={currentService ? currentService : {}}
              updateField={updateField}
              quantity={quantity}
              timeOut={timeOut}
              values={this.props}
              clear={clearErrors}
            /> </AccordionItemBody > </AccordionItem>

        <div className="user-form-actions flex f-c a-i-c j-c mb-1 mt-1"> {
          error && <p className="text-center error-msg" > {errorMessage} </p>} {
            success &&
            <p className="text-center success-msg">
              <span className="icon-check mr-02" /> {successMessage} </p>
          } <div className="text-center flex j-c">
            <button type="reset"
              className="button save-user button-sm bg-muted"
              onClick={success ? this.cancelRedirect : this.toggleConfirmationModal}
            >
              Cancelar </button> <button disabled={disableSubmit}
                className={`button ripple save-user button-sm ${disableSubmit ? 'bg-muted' : 'bg-icon'}`}
                type="submit"
                onClick={saveService}
              >
              Guardar </button> </div > </div>
        <PhysicianServices history_2={history_2}></PhysicianServices>
        <div></div>
      </Accordion > </div>
    );
  }
}
Physician.propTypes = {
  error: PropTypes.bool,
  match: PropTypes.object,
  timeOut: PropTypes.number,
  success: PropTypes.bool,
  quantity: PropTypes.number,
  position: PropTypes.array,
  clearErrors: PropTypes.func,
  saveService: PropTypes.func,
  errorMessage: PropTypes.string,
  disableSubmit: PropTypes.bool,
  successMessage: PropTypes.string,
  currentService: PropTypes.object,
  getCurrentService: PropTypes.func
};
export default compose()(withApollo(withRouter(Physician)));

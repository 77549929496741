/**
 * SelectInput
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './style.css';

class SelectInput extends Component {
  constructor (props) {
    super(props);
    this.state = {
      focus: false,
      disabled: props.disabled,
      active: props.labelActive,
    };
  }

  _toggleActive = () => {
    this.setState({ active: true, focus: true });
  };

  _isEmpty = e => {
    const { labelActive } = this.props;
    if (!labelActive && e.target.value === ''){
      this.setState({ active: false, focus: false });
    }
    else {
      this.setState({ focus: false });
    }
  };
  componentDidMount () {
    /*
    if (selectOneDefault) {
      if (options.length == 2 && !options[0]._id) {
        disabled = true;
        updateField(name, {
          value: options[1]._id,
          name: null,
        });
      }
    }
    */
  }

  render () {
    const {
      name,
      value,
      label,
      options,
      disabled: propDisabled,
      optional,
      iconAfter,
      iconBefore,
      classLabel,
      updateField,
      without_blank,
      className,
      classNameGeneral
    } = this.props;
    const { disabled: stateDisable } = this.state;
    let disabled = stateDisable;
    if (propDisabled && (name === 'type_dni' || name === 'gender')) disabled=true;
    return (
      <div>
        <div className={`form-group ${classNameGeneral}`}>
          <div className={`form-inline input-field select-field ${className}`}>
            { iconBefore && <span className={'icon-' + iconBefore} /> }
            <select
              disabled={disabled}
              ref={input => {
                this.nameInput = input;
              }}
              onFocus={this._toggleActive}
              onBlur={this._isEmpty}
              onChange={e => {
                var index = e.target.selectedIndex;
                var optionElement = e.target.childNodes[index];
                var option = optionElement.getAttribute('data-id');
                const obj = _.find(options, { _id: e.target.value });
                updateField(
                  name,
                  {
                    value: e.target.value,
                    name: option
                  },
                  obj
                );
              }}
              className="input-box"
              value={value || ''}
              name={name}
              id={name}
            >
              { !without_blank && <option value="" /> }

              {_.isEmpty(value) && (
                <option key={name} value={value}>Seleccione</option>
              )}

              {
                options && options.map((option, index) => {
                  let textName = '';
                  const textLastName = option.lastName ? option.lastName : '';

                  if (option.nombre) textName = option.nombre;
                  else if (option.name) textName = option.name;

                  let text = `${textName} ${textLastName}`;
                  if (option.fullName) text = option.fullName;
                  return (
                    <option key={index} value={option._id} data-id={option.role}>{text}</option>
                  );
                })
              }
            </select>
            <label
              htmlFor={name}
              className={`${classLabel} ${iconBefore ? 'with-icon' : ''} ${this.state.active && this.state.focus ? 'active-label' : ''} ${this.state.active || this.props.value ? 'active' : ''}`}
            >
              {label}{' '}
              {optional && <span className="muted-text">(Opcional)</span>}
            </label>
            { iconAfter && <span className={'after-icon-select icon-' + iconAfter} /> }
          </div>
        </div>
      </div>
    );
  }
}
SelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  iconAfter: PropTypes.string,
  classLabel: PropTypes.string,
  iconBefore: PropTypes.string,
  labelActive: PropTypes.bool,
  updateField: PropTypes.func,
  without_blank: PropTypes.bool,
  className: PropTypes.string,
  classNameGeneral: PropTypes.string,
};
SelectInput.defaultProps = {
  options: [],
  classLabel: '',
  labelActive: false,
  without_blank: false
};
export default SelectInput;

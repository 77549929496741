import React, { Component } from 'react';
// import socketIOClient from 'socket.io-client';
// var socket;
class Core2 extends Component {
  // constructor() {
  //   super();
  //   this.state = {
  //     endpoint: 'http://localhost:3000/',
  //     date: ''
  //   };
  //   socket = socketIOClient(this.state.endpoint);
  // }
  getData = date_ => {
    console.log(date_);
    // this.setState({ date: date_.date });
  };
  setData = () => {
    // socket.emit('user', {id:'geronimo'} );
  }
  componentDidMount = async () => {
    // socket.emit('initial_data');
    // socket.on('get_data', this.getData);
    var otherWindow = window.open();
    otherWindow.opener = null;
    otherWindow.location = `https://${ process.env.REACT_APP_URL_CORE }`;
    /*
    {date && (date)}
        <button onClick={this.setData}> action </button>
        */
  }
  render () {
    // const { date } = this.state;
    return (
      <div>
        Core2 <br />
      </div>
    );
  }
}
export default Core2;
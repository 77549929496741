import React from 'react';

const CheckboxInput = ({
  id,
  label,
  name,
  value,
  updateField,
  type,
  checked,
  modalServices,
  onIconClick,
  duration,
  repeatedDays,
  disabled,
  labelRight,
  className
}) => {
  return (
    <div className={`check-container flex a-i-c j-sb ${className}`}>
      <div className="flex a-i-c">
        {labelRight && (
          <label htmlFor={id} className={disabled ? 'muted-text' : ''}>
            {label}
          </label>
        )}
        <input
          className="form-check-input"
          disabled={disabled}
          type={type || 'checkbox'}
          checked={checked}
          name={name}
          value={value}
          onChange={e => {
            updateField(label, {
              value: e.target.checked,
              aux: value,
              radioName: name
            });
          }}
          id={id}
        />
        {!labelRight && (
          <label htmlFor={id} className={disabled ? 'muted-text' : ''}>
            {label}
          </label>
        )}
      </div>
      {modalServices && (
        <i
          className={`icon-reloj large ${
            duration || repeatedDays ? 'success-msg' : ''
          }`}
          onClick={() => {
            onIconClick(value, duration, repeatedDays);
          }}
        />
      )}
    </div>
  );
};

export default CheckboxInput;

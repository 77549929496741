import React, { useContext } from 'react';
import DataTable from 'react-data-table-component';
import { context } from "../../../providers/billingProvider";

const CupsTableList = () => {
    const { 
        cups, 
        handleCupsPageChange, 
        handleCupsRowsChange, 
        showCupsForm,
        setCupsFormData,
        showDeleteVerification,
    } = useContext(context);

    const { data, pagination, total } = cups;
    const { perPage } = pagination;

    const columns = [
        {
          name: 'Servicio',
          selector: row => row.service,
          width: '250px'
        },
        {
            name: 'Eps',
            selector: row => row.eps,
            width: '250px'
        },
        {
            name: 'Codigo',
            selector: row => row.code,
            width: '250px'
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: row => (
                <>
                  <button
                    className="btn btn-sm"
                    onClick={() => handleCupsEdition(row)}
                  >
                    <span className="clickable icon-edit"></span>
                  </button>
                  <button
                    className="btn btn-sm"
                    onClick={() => handleDeleteVerification(row._id)}
                  >
                    <span className="clickable icon-delete"></span>
                  </button>
                </>
            )
        }
    ];

    const handleCupsEdition = row => {
        setCupsFormData(row);

        showCupsForm();
    }

    const handleDeleteVerification = async (cupsId) => {
      showDeleteVerification(cupsId);
    }

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationPerPage={perPage}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handleCupsPageChange}
            onChangeRowsPerPage={handleCupsRowsChange}
            rowStyle={{ alignItems: "stretch" }}
            style={{ display: "flex", alignItems: "center" }}              
        />
    );
}

export default CupsTableList;
/**
 * ServicesTable
 */
/* eslint-disable */
import React, { Fragment } from 'react';
import Modal from '../../../components/general/modal';
import ServiceResourcesDetail from '../ServiceResourcesDetail'
import './style.css'

const SubRow = ({ children, ...props }) => (
  <Fragment>
    {children.map((child, index) => React.cloneElement(child, { ...props, key: index }))}
  </Fragment>
)

class ServicesTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = { isOpenResourcesDetail: false, serviceDetailSelected: null }
  }

  openServiceResourceDetail = (resource) => {
    this.setState({ isOpenResourcesDetail: true, serviceDetailSelected: resource })
  }

  closeServiceResourceDetail = () => {
    this.setState({ isOpenResourcesDetail: false })
  }

  render() {
    const { services, toService, finished, servicesFamilies } = this.props;
    const { isOpenResourcesDetail, serviceDetailSelected } = this.state

    services.sort(function (a, b) {
      if (a.servicefamily != null && b.servicefamily != null) {
        var x = a.servicefamily.toLowerCase();
        var y = b.servicefamily.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      } else {
        return
      }
    });

    let baseParent = null;
    return (
      <div className="table-container">
        {
          serviceDetailSelected && <Modal isOpenModal={isOpenResourcesDetail} title={serviceDetailSelected.name} onClose={() => this.closeServiceResourceDetail()}>
            <div style={{ padding: "50px 20px 20px 50px", textAlign: "center", maxHeight: '90%', overflowY: 'auto' }}>

              <ServiceResourcesDetail serviceId={serviceDetailSelected._id}>
              </ServiceResourcesDetail>

              <button style={{ marginTop: "30px" }} className="button button-sm bg-icon validate-appointments ripple" onClick={() => this.closeServiceResourceDetail()} >
                Cerrar
              </button>

            </div>
          </Modal>
        }
        {
          finished ?
            <Fragment>
              {
                services.length > 0 ?
                  <table className="list users-list">
                    <thead>
                      <tr className="services-list-head-tr">
                        <th style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontSize: '17px' }}>Nombre</th>
                        <th style={{ fontSize: '17px' }}>Duración</th>
                        <th style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        services.map((service, index) => {
                          const hours = Math.floor(Number(service.duration) / 60);
                          const minutes = Number(service.duration) % 60;
                          let service_name;

                          if (baseParent != service.servicefamily) {
                            baseParent = service.servicefamily

                            servicesFamilies.find(data => {
                              if (data._id == service.servicefamily) {
                                service_name = data.name;
                              }
                            })

                            return (
                              <SubRow key={index}>
                                <tr
                                  style={{ backgroundColor: '#34AFDB', color: '#fff', fontWeight: 'bold', height: '40px' }}
                                >
                                  <td style={{ borderRadius: '5px' }} colSpan="3">{service_name}</td>
                                </tr>
                                <tr
                                  className="list-item clickable ripple-title"
                                  key={index}
                                >
                                  <SubRow onClick={() => toService(service._id)}>
                                    <td>{service.name}</td>
                                    <td style={{ textAlign: 'left' }}>
                                      {hours > 0 && `${hours} Hora `}
                                      {minutes} Minutos
                                    </td>
                                  </SubRow>
                                  <td style={{ textAlign: "left" }} onClick={() => this.openServiceResourceDetail(service)}>
                                    <span
                                      className="icon-view large detail"
                                    />
                                  </td>
                                </tr>
                              </SubRow>
                            );
                          }
                          return (
                            <tr
                              className="list-item clickable ripple-title"
                              key={index}
                            >
                              <SubRow onClick={() => toService(service._id)}>
                                <td>{service.name}</td>
                                <td style={{ textAlign: 'left' }}>
                                  {hours > 0 && `${hours} Hora `}
                                  {minutes} Minutos
                                </td>
                              </SubRow>
                              <td style={{ textAlign: "left" }} onClick={() => this.openServiceResourceDetail(service)}>
                                <span
                                  className="icon-view large detail"
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  :
                  <p className="text-center">No se encontraron prestaciones</p>
              }
            </Fragment>
            :
            <p className="text-center">Cargando prestaciones...</p>
        }
      </div>
    );
  }
};

export default ServicesTable;

import gql from 'graphql-tag';

export const GET_EPS = gql`
	query allEps($perPage: Int, $page: Int) {
		allEps(perPage: $perPage, page: $page) {
			data {
				_id
				nombre
				nit
			}
		}
	}
`;

import React, { Component } from 'react';
import TextInputTime from '../../../components/general/textInputTime';
import CheckboxInput from '../../general/checkboxInput';
import Calendar from 'react-calendar/dist/entry.nostyle';
import moment from 'moment';
import {
  to24Format,
  add0ToHour,
  deleteRepetitionOptions
} from '../../../utils/resources/resourcesUtils';
import { toTwelveHourFormat } from '../../../utils/functions';
class EditSeriesModal extends Component {
  state = {
    date: this.props.resourceDay['_d'],
    currentDay: this.props.resourceDay,
    deleteOption: 1,
    showDatesAlert: false,
    error: false,
    initialHour: add0ToHour(
      to24Format(this.props.interval.interval.split('-')[0])
    ),
    finalHour: add0ToHour(
      to24Format(this.props.interval.interval.split('-')[1])
    )
  };

  updateField = (name, { value, aux: day, radioName }) => {
    if (radioName === 'deleteOption') {
      this.setState({ [radioName]: day });
    } else {
      this.setState({ [name]: value });
    }
  };

  editInterval = (
    id,
    initialHour,
    finalHour,
    initialDate,
    finalDate,
    deleteOption
  ) => {
    this.props.editInterval(
      id,
      initialHour,
      finalHour,
      initialDate,
      finalDate,
      deleteOption
    );
    this.props.onCancel();
  };

  validateDates = (initial, final) => {
    if (this.validateDay()) {
      if (initial && final) {
        const initialArray = initial.split(':');
        const finalArray = final.split(':');
        const initialHour = initialArray[0];
        const initialMinute = initialArray[1];
        const finalHour = finalArray[0];
        const finalMinute = finalArray[1];
        const initialTime = moment()
          .hour(initialHour)
          .minutes(initialMinute)
          .seconds('00');
        const finalTime = moment()
          .hour(finalHour)
          .minutes(finalMinute)
          .seconds('00');

        if (initialTime.diff(finalTime) < 0) {
          this.editInterval(
            this.props.interval.serieId,
            toTwelveHourFormat(initial),
            toTwelveHourFormat(final),
            this.props.resourceDay,
            this.state.currentDay,
            this.state.deleteOption
          );
        }
        else {
          this.setState({ showDatesAlert: true });
        }

      } else {
        this.setState({ showDatesAlert: true });
      }
    } else {
      this.setState({ error: true });
    }
  };

  validateDay = () => {
    return this.state.deleteOption === 3
      ? this.state.currentDay.diff(this.props.resourceDay) >= 0
      : true;
  };

  clearErrors = () => {
    this.setState({ error: false, showDatesAlert: false });
  };

  onChange = date => {
    let currentDay = moment(date);
    this.setState({ currentDay });
  };

  render () {
    const {
      deleteOption,
      showDatesAlert,
      date,
      currentDay,
      initialHour,
      finalHour,
      error
    } = this.state;
    const { onCancel } = this.props;
    // console.log(this.props)
    return (
      <div className={'modal-container'}>
        <div className="modal-body-repetition flex f-c a-i-c j-sb text-center">
          <div className="text-center bg-icon password-modal-title ripple-title">
            Editar horario laboral aqui
          </div>
          <p className="modal-section">Elige el nuevo horario laboral</p>
          <span className="header text-bold">Hora</span>
          <div
            className="hours-container flex j-sa a-i-c"
            onFocus={this.removeAlert}
          >
            <div className="flex j-c a-i-c f-w" onFocus={this.clearErrors}>
              <span className="mr-03">Desde</span>
              <TextInputTime
                type="time"
                updateField={this.updateField}
                value={this.state.initialHour}
                name="initialHour"
              />
            </div>
            <div className="flex j-c a-i-c f-w" onFocus={this.clearErrors}>
              <span className="mr-03">Hasta</span>
              <TextInputTime
                type="time"
                updateField={this.updateField}
                value={this.state.finalHour}
                name="finalHour"
              />
            </div>
          </div>
          {showDatesAlert && (
            <p className="error-msg">
              La hora inicial debe ser anterior a la hora de finalización
            </p>
          )}
          <p className="modal-section">
            Para repetir este horario laboral completa las siguientes opciones.
          </p>
          <div className="delete-options text-left flex f-c mb-1">
            {deleteRepetitionOptions.map((option, index) => {
              return (
                <CheckboxInput
                  checked={option.value === deleteOption}
                  type="radio"
                  key={index}
                  id={`option-${index}`}
                  label={option.label}
                  name="deleteOption"
                  value={option.value}
                  updateField={this.updateField}
                />
              );
            })}
          </div>
          {this.state.deleteOption === 3 && (
            <div className="calendar-input" onClick={this.clearErrors}>
              <div className="mb-1">
                <span className="header text-bold mr-03">Hasta</span>
                <input
                  className="ml-03 text-center"
                  type="text"
                  readOnly
                  value={currentDay.format('DD/MM/YYYY')}
                />
              </div>
              <Calendar
                // formatShortWeekday={value =>
                // 	['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]
                // }
                onChange={this.onChange}
                value={date}
              />
            </div>
          )}
          <div className="modal-actions mb-1 mt-1">
            <button className="button button-sm bg-muted" onClick={onCancel}>
              Cancelar
            </button>
            <button
              className="button button-sm bg-icon ripple"
              onClick={() => {
                this.validateDates(initialHour, finalHour);
              }}
            >
              Guardar
            </button>
            {error && (
              <p className="text-center error-msg">
                Debes elegir una fecha posterior
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EditSeriesModal;

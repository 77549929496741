/**
 * ActionsTable
 */
import React from 'react';
import PropTypes from 'prop-types';
import { actionsDictionary } from '../../../utils/users/actions/actionsUtils';
import { formatDate } from '../../../utils/functions';
import './style.css';

const ActionsTable = props => {
  const { header, body, scrolled } = props;
  return (
    <div className="table-container" onScroll={scrolled}>
      {
        body.length > 0 ?
          <table className="list actions-list">
            <thead>
              <tr>
                {
                  header.map((header, index) => <th key={index}>{header}</th>)
                }
              </tr>
            </thead>

            <tbody>
              {
                body.map((action, index) => {
                  let name = '';
                  const date = action.date_created;
                  if (action._user_edit) name = `${action._user_edit.name} ${action._user_edit.lastName}`;
                  else if (action._patient_edit) name = action._patient_edit.fullName;
                  return (
                    <tr className="list-item ripple-title" key={index}>
                      <td>{actionsDictionary[action.action]}</td>
                      <td>{formatDate(date)}</td>
                      <td>{`${action.description}: ${name}`}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          : <p className="text-center">No se encontraron acciones</p>
      }
    </div>
  );
};
ActionsTable.propTypes = {
  body: PropTypes.array,
  header: PropTypes.array,
  scrolled: PropTypes.func
};
export default ActionsTable;

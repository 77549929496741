import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ServicesOptions from '../../servicesModule/servicesOptions';
import CalendarWeek from '../../general/calendarWeek';
import DurationModal from '../durationModal';

export default class UserForm extends Component {
  state = {
    servicesSelected: [],
    durationOpened: false
  };

  updateField = (value, data) => {
    let servicesSelected = this.state.servicesSelected;
    if (_.findIndex(servicesSelected, { _id: data.aux }) > -1) {
      _.remove(servicesSelected, { _id: data.aux });
    } else {
      if (typeof servicesSelected === 'string') servicesSelected = JSON.parse(servicesSelected);
      data = { ...data, _id: data.aux };
      delete data.aux;
      servicesSelected.push(data);
    }
    this.setState({ servicesSelected });
    this.props.servicesSelected(servicesSelected);
  };

  addDuration = (duration, days) => {
    const servicesSelected = [];
    this.state.servicesSelected.forEach(service => {
      servicesSelected.push(
        service._id === this.state.serviceId
          ? { ...service, duration, days }
          : service
      );
    });
    this.setState(
      {
        servicesSelected
      },
      () => {
        this.props.servicesSelected(this.state.servicesSelected);
      }
    );
  };

  onIconClick = (serviceId, duration, repeatedDays) => {
    this.setState({ durationOpened: true, serviceId, duration, repeatedDays });
  };

  closeDuration = () => {
    this.setState({ durationOpened: false });
  };
  componentDidMount = () => {
    this.setState({ servicesSelected: this.props.services });
  };
  render() {
    const { durationOpened, serviceId, duration, repeatedDays } = this.state;
    let {
      sedeSelected = [],
      handleChangeTab,
      currentSede,
      fields,
      getCurrentWeek,
      addSerie,
      deleteSerie,
      onChange,
      services,
      clearErrors,
      editInterval
    } = this.props;
    while (typeof services !== 'object') {
      services = JSON.parse(services);
    }

    if (sedeSelected.length === 0) {
      return null;
    }

    return (
      <Fragment>
        {
          durationOpened &&
          <DurationModal
            onSave={this.addDuration}
            onCancel={this.closeDuration}
            currentId={serviceId}
            duration={duration}
            repeatedDays={repeatedDays}
          />
        }
        <AccordionItem uuid="uniqueItem-presentation" expanded={true}>
          <AccordionItemTitle
            className="accordion__title accordion__title--animated u-position-relative ripple-title"
            onClick={() => { }}
          >
            <span>Prestaciones</span>
          </AccordionItemTitle>
          <AccordionItemBody>
            <ServicesOptions
              selectedServices={services.map(ser => ser._id)}
              selectService={this.updateField}
              servicesSelected={services}
              onIconClick={this.onIconClick}
            />
          </AccordionItemBody>
        </AccordionItem>
        <div className="contentServices flex">
          {
            sedeSelected.map((sede, i) => (
              <div
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={() => handleChangeTab(sede)}
                className={`cs_nav ${sedeSelected.length === 1 && 'tabOne'} ${currentSede._id === sede._id ? 'select ripple-title' : 'ripple-gray'}`}
              >
                <span>{sede.name}</span>
              </div>
            ))
          }
        </div>
        <Accordion accordion={false}>
          {
            currentSede &&
            <AccordionItem uuid="uniqueItem-2" expanded={true}>
              <AccordionItemTitle
                className="accordion_title_user_service accordion__title accordion__title--animated u-position-relative ripple-title"
                onClick={() => { }}
              >
                <span>Calendario laboral</span>
              </AccordionItemTitle>
              <AccordionItemBody>
                <CalendarWeek
                  editInterval={editInterval}
                  currentSede={currentSede}
                  fields={fields}
                  getCurrentWeek={getCurrentWeek}
                  addSerie={addSerie}
                  deleteSerie={deleteSerie}
                  onChange={onChange}
                  clearErrors={clearErrors}
                />
              </AccordionItemBody>
            </AccordionItem>
          }
        </Accordion>
      </Fragment>
    );
  }
}

import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import AppointmentSchedule from '../patientsModule/appointmentSchedule/appointmentSchedule';

const Availability = () => {
  return (
    <Accordion accordion={false}>
      <AppointmentSchedule />
    </Accordion>
  );
};

export default Availability;

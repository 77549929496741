import gql from 'graphql-tag';

export const GET_APPOINTMENTS_SCHEDULE = gql`
	query getAppointmentSchedule(
		$services: String!
		$_sede: ID!
		$start: String!
		$end: String!
		$_resourceTypeWorkSpace: ID!
	) {
		getAppointmentSchedule(
			services: $services
			_sede: $_sede
			start: $start
			end: $end
			_resourceTypeWorkSpace: $_resourceTypeWorkSpace
		) {
			success
			errors {
				path
				message
			}
			data
		}
	}
`;
export const GET_AUTHORIZATION = gql`
	query getAuthorization($document: String!) {
		getAuthorization(document: $document) {
			success
			errors {
				path
				message
			}
			data {
				_id
				authorized{
				  _id
				  names
				  document
				  relationship
				  type_document
				  results
				  hc
				}
			}
		}
	}
`;
export const GET_PATIENTS = gql`
	query getPatients($name: String, $perPage: Int, $page: Int) {
		getPatients(name: $name, perPage: $perPage, page: $page) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				lastName
				fullName
				type_dni
				dni
				phone
				mobile
				flag
			}
		}
	}
`;

export const GET_PATIENT_CORE = gql`
	query getPatientCore($identificacion_query: String) {
		getPatientCore(identificacion_query: $identificacion_query) {
			success
			errors {
				path
				message
			}
			data {
				name
				lastName
				fullName
				type_dni
				dni
				phone
        		mobile
			}
		}
	}
`;

export const GET_PATIENT = gql`
	query getPatient($_id: ID!) {
		getPatient(_id: $_id) {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				lastName
				type_dni
				dni
				birthDay
				email
				phone
				mobile
				observations
				address
				city
				education_level
				gender
				eps {
					_id
					nombre
				}
				active
				mother
				father
				attendant
				profession
				contactPreference
				programType {
					_id
					name
					status
				}
				regimeType
				ethnic
				civil_status
				flag
				photo
				attendant_names
				attending_phone
				attendant_address
				companion
				companion_names
				companion_phone
			}
		}
	}
`;

export const CAN_UPDATE_PATIENT = gql`
	query canUpdatePatient($patientId: String) {
		canUpdatePatient(patientId: $patientId) {
			canUpdate
		}
	}
`;

export const GET_WILDCARD_PATIENTS_DNIS = gql`
	query getWildcardPatientsDnis {
		getWildcardPatientsDnis {
			success
			errors {
				path
				message
			}
			data
		}
	}
`;

import React, { Component } from 'react';
import { PatientConsumer } from '../../../providers/patientProvider';
import ModalPhoto from './modalPhoto';

class PatientNew extends Component {
  render () {
    return (
      <PatientConsumer>{props => <ModalPhoto {...props} />}</PatientConsumer>
    );
  }
}

export default PatientNew;

import React from 'react';
import '../../../icomoon/style.css';
import UsersList from '../../../components/usersModule/usersList';
import { Link } from 'react-router-dom';

const Users = () => {
  return (
    <div>
      <div className="users-title ripple-title">Lista de usuarios</div>
      <UsersList>
        <div className="text-center pt-1 pb-1 flex a-i-c j-c">
          <Link to="/users/new">
            <button className="button ripple save-user button-sm bg-icon" type="submit">
              <span className="icon-resources mr-02" />
              Nuevo usuario
            </button>
          </Link>
        </div>
      </UsersList>
    </div>
  );
};
export default Users;

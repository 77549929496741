/* eslint-disable */
/**
 * Sidebar
 */
import React, { Component } from 'react';
import {
  SHOW_ALERT,
  TOGGLE_SIDEBAR
} from '../../../graphql/types/usersModule/mutations';
import { compose, graphql, withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.css';
import '../../../icomoon/style.css';
import dashboardIcon from '../../../assets/images/sidebar/ic-dashboard.svg';
import specialitiesIcon from '../../../assets/images/sidebar/ic-especialidades.svg';
import institutionsIcon from '../../../assets/images/sidebar/ic-instituciones.svg';

class Sidebar extends Component {
    state = {
      authorizedIcons: [
        { name: 'services', label: 'Prestaciones' },
        { name: 'users', label: 'Usuarios' },
        { name: 'resources', label: 'Recursos' },
        { name: 'patients', label: 'Pacientes' },
        { name: 'medicosremitentes', label: 'Médicos Remitentes' },
        { name: 'loginhistory', label: 'Historial médicos', icon: dashboardIcon },
        { name: 'reports', label: 'Reportes' },
        { name: 'mergeph', label: 'Fusión médicos' },
        { name: 'ampa', label: 'Telemonitoreos' },
        { name: 'availability', label: 'Disponibilidad' },
        { name: 'schedule', label: 'Agendamiento' },
        { name: 'core2', label: 'Core 2' },
        { name: 'core3', label: 'Core 3', link: `https://${ process.env.REACT_APP_URL_CORE3 }/` },
        { name: 'especialidades', label: 'Especialidades', icon: specialitiesIcon },
        { name: 'instituciones', label: 'Instituciones', icon: institutionsIcon },
        { name: 'dashboard', label: 'Dashboard', icon: dashboardIcon },
        { name: 'cups', label: 'CUPS', icon: dashboardIcon },
        {
          name: 'results',
          label: 'Resultados',
          link: `https://${ process.env.REACT_APP_URL_RESULTADOS }/`
        }
      ]
    };

    changePath = path => () => {
      if (!localStorage.canExitEdit) {
        this.props.history.push(`/${path}`);
        if (window.innerWidth <= 780) this.toggleSidebar();
      } else {
        this.props
          .showAlert({
            variables: { status: true }
          })
          .then(() => {})
          .catch(() => {});
      }
    };

    toggleSidebar = async () => {
      const collapsed = localStorage.collapsed === 'true' ? false : true;
      this.props
        .toggleSidebar({
          variables: {
            collapsed
          }
        })
        .then(() => {})
        .catch(() => {});
    };
    render () {
      const path = this.props.location.pathname.split('/')[1];
      const { authorizedIcons } = this.state;
      const collapsed = localStorage.collapsed === 'true' ? false : true;
      const { permissionKey } = this.props;

      return ( <div className = { `sidebar ${collapsed ? 'collapsed' : 'opened'}` }>
        <div className = { 'toggle-icon icon-container text-center' }>
          <i className = "large material-icons clickable"
            onClick = { this.toggleSidebar }
          >
            arrow_forward </i> </div > <div className = "sidebar-icons"> {
          authorizedIcons.map((icon, index) => {
            if (permissionKey.indexOf(icon.name) < 0) return null;
            return !icon.link ? ( <div className = { `sidebar-item ripple flex j-sb a-i-c ${
              !collapsed ? 'show-label clickable' : ''
            } ${path === icon.name ? 'active-item' : ''} ${icon.name}` }
              onClick = {
              () => setTimeout(this.changePath(icon.name), 300)
            }
              key = { index }
                                  >
              <span className = "name"> { icon.label } </span> <div className = { 'icon-container text-center ' } >
                
                { icon.icon ? (
                  <img style={{fill: '#004765'}} src={icon.icon} alt="" />
                ) : (
                  <span id = { index }
                    className = { 'clickable icon-' + icon.name }
                  />
                )}
                </div > </div>
            ) : ( <a href = { icon.link }
              target = "_blank"
              key = { index }
                  >
              <div className = { `sidebar-item ripple flex j-sb a-i-c ${
                !collapsed ? 'show-label clickable' : ''
              } ${path === icon.name ? 'active-item' : ''}` }
                onClick = {
                () => setTimeout(this.changePath(icon.name), 1000)
              }
              >
                <span className = "name"> { icon.label } </span> <div key = { index }
                  className = { `icon-container text-center ${
                    path === icon.name ? 'active-icon' : ''
                  }` }
                                                                 >
                  <span id = { index }
                    className = { 'clickable icon-' + icon.name }
                  /> </div > </div> </a >
            );
          })
        } </div> </div >
      );
    }
}
Sidebar.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  showAlert: PropTypes.func,
  toggleSidebar: PropTypes.func
};
export default compose(
  graphql(TOGGLE_SIDEBAR, { name: 'toggleSidebar' }),
  graphql(SHOW_ALERT, { name: 'showAlert' })
)(withApollo(withRouter(Sidebar)));

/**
 * LibScanner
 */
class LibScanner {

  codeLoad = { error:  1, timeOut: 2, responseData: 0 };
  codeScanner = { beep: 3, light: 4, start: 1, stop: 2, waitData: 5 };

  url='http://localhost';
  puertoHttp='8501';
  mesageError='';

  /**
   * _validateResponse
   * @param {response} string responde fetch
   * @param {method} string method call
   */
  _validateResponse = (response, method = '') => {
    let objReturn = { state: false, data: [], error: '' };

    if (!response){
      console.error(`Error ${method}`, response);	// eslint-disable-line
      return objReturn;
    }

    const arrayResponse = response.split(':');
    if (arrayResponse[0] === 'RS=OK'){
      objReturn = { state: true, data: arrayResponse, error: '' };
    }
    else if (arrayResponse[0] === 'RS=NO'){
      console.error(`Error response ${method}`, arrayResponse[1]);	// eslint-disable-line
      objReturn = { state: false, data: [], error: arrayResponse[1] };
    }
    return objReturn;
  }

  /**
   * _getRequest
   * @param {method} string method apply code scanner
   * @param {idScanner} int integer id scanner
   * @param {idElelement} int integer id element
   * @return {objResponse}
   */
  _getDataRequest = async (method, idScanner, idElement) => {
    const time = new Date().getSeconds();
    let dataUrl = `OP=${this.codeScanner[method]}`;
    let objResponse = {};
    if (idScanner && idElement){ dataUrl += `&DT=${idScanner}:${idElement}`; }

    const urlFetch = `${this.url}:${this.puertoHttp}/HOM${time}Pistola?${dataUrl}`;

    try {
      const request = await fetch(urlFetch, { method: 'GET', headers: {} });
      const response = await request.text();
      objResponse = this._validateResponse(response, method);
    } catch (err) {
      // console.log('Error fetch ' + err);
    }
    return objResponse;
  }

  _onLoadData = async () => {
    const response = { code: this.codeLoad.error, data: {} };
    await this._getDataRequest('start');
    await this._getDataRequest('light', 1, 1);
    await this._getDataRequest('beep', 1, 1);

    const fetchNit = await this._getDataRequest('waitData');
    const { state: fetchNitState, data: fetchNitData, error: fetchNitError } = fetchNit;

    if (!fetchNitState){ console.log(fetchNitError); return false; } // eslint-disable-line
    else if (fetchNitData[1] === '0'){
      this.mesageError = fetchNitData[2];
      response.code = this.codeLoad.timeOut;
    }
    else {
      var arrayIndex = ['response', 'codError', 'dni', 'lastName1', 'lastName2', 'name1', 'name2', 'gender', 'birthDay', 'bloodType', 'typeNit', 'dataBC2D']; // eslint-disable-line
      arrayIndex.forEach((value, index) => response.data[value] = fetchNitData[index]);
    }
    return response;
  }
}

export default LibScanner;

import React, { Component } from "react";
import { context } from "../../providers/physicianLoginHistoryProvider";
import DataTable from 'react-data-table-component';
import dashboardIcon from '../../assets/images/sidebar/ic-dashboard.svg';

class PhysicianLoginHistory extends Component {
    static contextType = context;

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    name: 'Nombre',
                    selector: row => row.physician,
                    sortable: true,
                    sortField: 'physician',
                },
                {
                    name: 'Ultimo inicio de sesión',
                    selector: row => row.date_created,
                    sortable: true,
                    sortField: 'date_created',
                },
                {
                    name: 'Cantidad',
                    selector: row => row.total,
                    sortable: true,
                    sortField: 'total',
                }
            ]
        }
    }

    componentDidMount() {
        this.context.getLoginHistory();
    }

    render() {
        const { columns } = this.state;
        const {
            loginHistoryData,
            loginHistoryTotal,
            pagination: { perPage },
            handlePageChange,
            handleRowsChange,
            handleSort
        } = this.context;

        return (
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <img alt='sicor' src={dashboardIcon} />
                            </div>
                            <div> Historial </div>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="row">
                        <DataTable
                            columns={columns}
                            data={loginHistoryData}
                            pagination
                            paginationPerPage={perPage}
                            paginationServer
                            paginationTotalRows={loginHistoryTotal}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsChange}
                            onSort={handleSort}
                            sortServer
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PhysicianLoginHistory;
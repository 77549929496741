/* eslint-disable */
import React, { Component } from 'react';
import { BarChart, Bar, Tooltip, Text, XAxis, YAxis, ResponsiveContainer } from 'recharts';

class Top5ChartSpecialty extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      title: 'Top 5 Pacientes remitidos por especialidad',
      data: []
    };
  }

  componentDidMount () {
    this.setData();
  }

  setData = (() => {
    let ret = [];
    if (typeof this.props.data !== 'undefined' && this.props.data.length > 0) {
      this.props.data.map((data) => {
        ret.push({
          name: data.specialty,
          value: data.value
        });
      });
    }
    this.setState({ 'data': ret });
  });

  render () {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h5 className='card-title'>{this.state.title}</h5>
          </div>
        </div>
        <br />
        <div className="row mh300">
          {this.state.data.length > 0 ?
            <ResponsiveContainer width={'100%'} height={250} >
              <BarChart data={this.state.data} barCategoryGap='30%' layout="vertical">
                <Text scaleToFit="true" />
                <XAxis type="number" hide />
                <YAxis dataKey="name"
                  type="category"
                  width={150}
                  padding={{ left: 20 }}
                  style={{
                    fontFamily: 'Arial'
                  }}
                />
                <Tooltip />
                <Bar dataKey="value" fill="#16aaff" />
              </BarChart>
            </ResponsiveContainer>
            :
            <span>Sin datos</span>
          }
        </div>
      </div>
    );
  }

}
export default Top5ChartSpecialty;
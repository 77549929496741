import gql from 'graphql-tag';

export const GET_INSTITUTION = gql`
	query getInstitution(
		$filters: FiltersInstitution
		$pagination: PaginationInstitution
		$sort: SortInstitution
	) {
		getInstitution(
			filters: $filters
			pagination: $pagination
			sort: $sort
		) {
			data {
				_id
				name
				city
				address
			}
			errors {
				path
				message
			}
			success
			total
		}
	}
`;

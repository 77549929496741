/*eslint-disable */
import React, { Component } from "react";
import _ from 'lodash';
import { withApollo } from "react-apollo";
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { withRouter } from "react-router-dom";
import { GET_AMPAS_TAKE } from "../../../../graphql/types/ampa/queries";
import logo from '../../../../assets/logo.png';
import signature from '../../../../assets/images/signature-dr-dagnovar.png'
import './ampaReport.css';
import { getAverage, parseTakes } from "../../../../utils/helpers";

class AmpaReport extends Component {

    state = {
        patient: {},
        chart: {
            series: [],
            options: {},
        },
        medicines: [],
        takes: [],
        takesMedia: {},
    }

    componentDidMount() {
        const { ampaId, patientId } = this.props?.match?.params;

        this.getAmpaDetail(ampaId, patientId);
        this.props.getPatientInfo(patientId);
    }

    getAmpaDetail(ampaId, patientId) {
        this.props.client
        .query({
            query: GET_AMPAS_TAKE,
            variables: { _id: ampaId, patient: patientId }
        })
        .then(response => {
            const {
                doctorNotes, 
                enfermera, 
                medicines,
                medico, 
                nursingNotes, 
                Take,
                ...patient 
            } = response.data.getAmpasTake.data;

            if (patient) {
                this.setState({ 
                    doctorNotes, 
                    enfermera, 
                    medico, 
                    medicines,
                    nursingNotes, 
                    patient, 
                    takes: parseTakes(Take), 
                });

                this.mediaParams(Take);

                this.graphFunction(Take);
            }
        })
    }

    getElapsedTime(timestamp) {
        const initialDate = new Date(moment(Number(timestamp)).format("YYYY-MM-DD"));
        const diffDays = Number(moment().diff(moment(initialDate), 'days'));
        const days = diffDays >= 6 ? 6 : diffDays;
        return days;
    }

    mediaParams = (takes) => {
        const sistolic = [];
        const diastolic = [];
        const frequency = [];

        takes.forEach((d) => {
            sistolic.push(d.sistolic);
            diastolic.push(d.diastolic);
            frequency.push(d.frequency);
        });

        this.setState({
            takesMedia: {
                sistolic: getAverage(sistolic),
                diastolic: getAverage(diastolic),
                frequency: getAverage(frequency),
            }
        });
    } 
    
    graphFunction = takes => {
        var dataset = [];
        var dataset2 = [];
        var dataset3 = [];
        var categories1 = []
        let takesMutable = [...takes];
        takesMutable.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
        takes = [...takesMutable];
        takes.map((d, i) => {
            if (d) {                
                dataset.push({ x: moment(d.timestamp, 'x').format(), y: Number(d.diastolic) == 0 ? null : Number(d.diastolic) });
                dataset2.push({ x: moment(d.timestamp, 'x').format(), y: Number(d.sistolic) == 0 ? null : Number(d.sistolic) });
                dataset3.push({ x: moment(d.timestamp, 'x').format(), y: Number(d.frequency) == 0 ? null : Number(d.frequency) });
            }
        });
        let series2 = [
            { name: 'PA Sistólica', data: dataset2 },
            { name: 'PA Diastólica', data: dataset },
            { name: 'Frecuencia Cardiaca', data: dataset3 }
        ]
        let options2 = {
            colors: ["#294863", "#247BA0", "#b50024"],
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: function (timestamp) {
                        return moment(timestamp, 'x').format("DD HH:mm:ss")
                    }
                }
            },
            markers: {
                size: 4,
            },
        }
        this.setState({             
            chart: {
                series: series2, 
                options: options2 
            }
        });            
    }

    render () {
        const { chart, doctorNotes,  enfermera, medicines, patient, medico, nursingNotes, takes, takesMedia } = this.state;
        const { currentPatient } = this.props;

        const elapsedTime = this.getElapsedTime(patient?.dateInitTimestamp);
        const initialDate = moment(patient.dateInit).format('DD/MM/YYYY');
        const finalDate = moment(patient.dateInit).add(5, 'days').format('DD/MM/YYYY');

        return (
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-6">
                        <h1>Telemonitoreo de presión arterial</h1>
                    </div>
                    <div className="col-6">
                        <img id='logo' src={logo} alt="Logo Sicor" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <p id="physician"> 
                            <span>{ currentPatient.name } { currentPatient.lastName } </span>
                            { currentPatient.type_dni } { currentPatient.dni }
                        </p>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-6">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th> Talla </th>
                                    <td> { patient?.size } </td>
                                </tr>
                                <tr>
                                    <th> Peso </th>
                                    <td> { patient?.weight } </td>
                                </tr>
                                <tr>
                                    <th> Bmi </th>
                                    <td> { patient?.bmi } </td>
                                </tr>
                                <tr>
                                    <th> status </th>
                                    <td> { patient?.status } </td>
                                </tr>
                                <tr>
                                    <th> Actividad Fisica </th>
                                    <td> { patient?.physicalActivity } </td>
                                </tr>                         
                                <tr>
                                    <th>Equipo </th>
                                    <td> { patient.nameEquipment } </td>
                                </tr>
                                <tr>
                                    <th>Referencia </th>
                                    <td> { patient.equipmentReference } </td>
                                </tr>
                                <tr>
                                    <th> Días trascurridos </th>
                                    <td> { elapsedTime } </td>
                                </tr>
                                <tr>
                                    <th>Fecha Inicio </th>
                                    <td> { initialDate } </td>
                                </tr>
                                <tr>
                                    <th> Fecha Final </th>
                                    <td> { finalDate } </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th colSpan="2">Sistólica</th>
                                    <th colSpan="2">Diástolica</th>
                                    <th colSpan="2">Frecuencia</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>🌞</td>
                                    <td>🌒</td>
                                    <td>🌞</td>
                                    <td>🌒</td>
                                    <td>🌞</td>
                                    <td>🌒</td>
                                </tr>
                                { takes.map(take => (
                                    <tr key={take.day}>
                                        <td> Día { take.day } </td>                                        
                                        <td> { take.sistolicDay } </td>
                                        <td> { take.sistolicNight } </td>
                                        <td> { take.diastolicDay } </td>
                                        <td> { take.diastolicNight } </td>
                                        <td> { take.frequencyDay } </td>
                                        <td> { take.frequencyNight } </td>
                                    </tr>
                                ))}
                                <tr>
                                    <th> Media </th>
                                    <th colSpan="2"> { takesMedia?.sistolic } </th>
                                    <th colSpan="2"> { takesMedia?.diastolic } </th>
                                    <th colSpan="2"> { takesMedia?.frequency } </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-6">
                    { takes && (
                        <ReactApexChart
                            options={chart.options}
                            series={chart.series}
                            type="line" 
                            height={350} 
                        />
                    )}
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-12">
                        <table className="table table-striped">        
                            <tbody>
                                <tr>
                                    <th> Medico(a) remitente: </th>
                                    <th> Enfermero(a): </th>
                                </tr>
                                <tr>
                                    <td> { medico?.name } { medico?.lastname } </td>
                                    <td> { enfermera?.name } { enfermera?.lastname } </td>
                                </tr>
                            </tbody>                    
                        </table>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-12">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th colSpan={3}> Medicamentos </th>
                                </tr>
                                <tr>
                                    <th> Nombre </th>
                                    <th> Frecuencia </th>
                                    <th> Cantidad </th>
                                </tr>
                                { medicines.map((medicine, i) => (
                                    <tr key={i}>
                                        <td> { medicine?.nameMedicine }  </td>
                                        <td> { medicine?.duration } </td>
                                        <td> { medicine?.doseUnits } </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-6">
                        <h2>Notas de doctor</h2>
                        <p> { doctorNotes } </p>
                    </div>

                    <div className="col-6">
                        <h2>Notas de enfermeria</h2>
                        <p> { nursingNotes } </p>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-12">
                        <p id="signature">
                            <span>Revisión: (Cardiólogo) José Dagnóvar Aristizábal Ocampo</span>
                            <span>Registro: RM 7260-87</span>
                            <img src={signature} />
                            <span>Firmado Digitalmente</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withApollo(withRouter(AmpaReport));
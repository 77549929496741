import React, { Component } from 'react';
import './style.css';

export default class TextInputTime extends Component {
  toggleActive = () => {
    this.setState({ active: true, focus: true });
  };
  render () {
    const {
      updateField,
      name,
      value,
      type
    } = this.props;

    return (
      <div className="form-group">
        <div className="form-inline input-field">
          <input
            onFocus={this.toggleActive}
            type={type}
            onChange={e => {
              updateField(name, { value: e.target.value, aux: null });
            }}
            className="input-box-time"
            value={value}
            name={name}
            id={name}
          />
        </div>
      </div>
    );
  }
}

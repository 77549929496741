export default async (
  {
    _id,
    name,
    lastName,
    mobile,
    type_dni,
    dni,
    phone,
    gender,
    birthDay,
    city,
    education_level,
    mother,
    father,
    profession,
    eps,
    civil_status,

    regimeType,
    // attendant form
    attendant,
    attendant_names,
    attending_phone
  },
  page = ''
) => {
  const errors = [];
  const patientErrors = {};
  let errorMessage = false;

  if (!type_dni || type_dni.trim() === '') {
    errors.push('type_dni');
    patientErrors['type_dni'] = true;
    errorMessage = 'El tipo de documento es necesario';
  }
  if (!dni || dni.trim() === '') {
    errors.push('dni');
    patientErrors['dni'] = true;
    errorMessage = 'El documento es necesario';
  }
  if (!name || name.trim() === '') {
    errors.push('name');
    patientErrors['name'] = true;
    errorMessage = 'EL nombre del paciente es necesario';
  }
  if (!lastName || lastName.trim() === '') {
    errors.push('lastName');
    patientErrors['lastName'] = true;
    errorMessage = 'EL apellido del paciente es necesario';
  }
  if (!mobile && !phone) {
    errors.push('phone');
    patientErrors['phone'] = true;
    errorMessage = 'El celular o el telefono es necesario';
  }

  if (page === '/patients/new') {
    // this page it's new not validate others params
    return {
      errors,
      patientErrors,
      errorMessage
    };
  }
  if (_id) {
    if (!eps || eps.trim() === '') {
      errors.push('eps');
      patientErrors['eps'] = true;
      errorMessage = 'La entidad es necesario';
    }
    if (!gender || gender.trim() === '') {
      errors.push('gender');
      patientErrors['gender'] = true;
      errorMessage = 'El género es necesario';
    }
    if (!birthDay || birthDay.trim() === '') {
      errors.push('birthDay');
      patientErrors['birthDay'] = true;
      errorMessage = 'La fecha de nacimiento es necesario';
    }
    if (!city || city.trim() === '') {
      errors.push('city');
      patientErrors['city'] = true;
      errorMessage = 'La ciudad es necesario';
    }
    if (!education_level || education_level.trim() === '') {
      errors.push('education_level');
      patientErrors['education_level'] = true;
      errorMessage = 'El Grado de escolaridad es necesario';
    }
    if (!mother || mother.trim() === '') {
      errors.push('mother');
      patientErrors['mother'] = true;
      errorMessage = 'El nombre de la madre es necesario';
    }
    if (!father || father === null || father.trim() === '') {
      errors.push('father');
      patientErrors['father'] = true;
      errorMessage = 'El nombre del padre es necesario';
    }
    if (!profession || profession.trim() === '') {
      errors.push('profession');
      patientErrors['profession'] = true;
      errorMessage = 'La profesión es necesario';
    }
    if (!civil_status || civil_status.trim() === '') {
      errors.push('civil_status');
      patientErrors['civil_status'] = true;
      errorMessage = 'El estado civil es necesario';
    }
    if (!regimeType || regimeType.trim() === '') {
      errors.push('regimeType');
      patientErrors['regimeType'] = true;
      errorMessage = 'El tipo de regimen es necesario';
    }

    // attendant
    if (!attendant || attendant.trim() === '') {
      errors.push('attendant');
      patientErrors['attendant'] = true;
      errorMessage = 'El acudiente es necesario';
    }
    if (!attendant_names || attendant_names.trim() === '') {
      errors.push('attendant_names');
      patientErrors['attendant_names'] = true;
      errorMessage = 'El nombre del acudiente es necesario';
    }
    if (!attending_phone || attending_phone.trim() === '') {
      errors.push('attending_phone');
      patientErrors['attending_phone'] = true;
      errorMessage = 'El numerio telefónico del acudiente es necesario';
    }
  }

  return {
    errors,
    patientErrors,
    errorMessage
  };
};

import moment from 'moment';
import React, { Component } from 'react';
import FormInput from '../../../components/general/formInput';
import download from 'downloadjs';

class MakerAndReadPhysiciansReport extends Component {
    state = {
        initDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        isLoading: false
    };

    setField = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    }

    getReport = async () => {
        this.setState({ isLoading: true });

        const { initDate, endDate } = this.state;

        const url = `${ process.env.REACT_APP_URL_AGENDAMIENTO }`
        const response = await fetch(`${url}/report/maker-reader-physicians?${new URLSearchParams({ initDate, endDate })}`)
        const blob = await response.blob();

        download(blob, 'report.xlsx');

        this.setState({ isLoading: false });
    }

    render() {
        const { initDate, endDate, isLoading } = this.state;

        return (
            <div className='my-2'>
                <form>
                    <div className="row">
                        <div className="col">
                            <FormInput
                                type="date"
                                name="initDate"
                                label="Fecha Inicial"
                                style={{width: '100%'}}
                                value={initDate}
                                onChange={this.setField}
                            />
                        </div>
                        <div className="col">
                            <FormInput
                                type="date"
                                name="endDate"
                                label="Fecha Final"
                                style={{width: '100%'}}
                                value={endDate}
                                onChange={this.setField}
                            />
                        </div>
                        <div className="col">
                            <button 
                                type="button" 
                                className="btn btn-primary" 
                                onClick={() => this.getReport()}
                                disabled={isLoading}
                            >
                                {
                                    isLoading ? <span> Generando Reporte ...</span>
                                    : <span> Generar Reporte </span>
                                }
                            </button>
                        </div>
                    </div>                    
                </form>
            </div>
        )
    }
}

export default MakerAndReadPhysiciansReport;
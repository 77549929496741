/* eslint-disable */
import React, { Fragment, Component } from 'react';
import DataTable from 'react-data-table-component';
import '../../dashboard/bootstrapv2.min.css';
import _, { debounce } from 'lodash';
import { withRouter } from "react-router-dom";
import { context } from '../../../providers/physicianProvider';
import Alert from '../../../components/general/alert';
import { roleHavePermission } from '../../../utils/functions';

class Doctors extends Component {

  static contextType = context;

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          name: 'Nombre',
          selector: row => row.fullname,
          sortable: true,
          sortField: 'fullname',
          width: '250px'
        },
        {
          name: 'Especialidad',
          selector: row => row.specialties.join(', '),
          sortable: false,
          width: '220px'
        },
        {
          name: 'Registro medico',
          selector: row => row.medicalRecord,
          sortable: true,
          sortField: 'medicalRecord',
          width: '150px'
        },
        {
          name: 'E-mail',
          selector: row => row.email,
          sortable: true,
          sortField: 'email',
          width: '250px'
        },
        {
          name: 'Celular',
          selector: row => row.mobile,
          sortable: false,
          width: '150px'
        },
        {
          name: 'Teléfono',
          selector: row => row.phone,
          sortable: false,
          width: '150px'
        },
        {
          key: "action",
          text: "Action",
          className: "action",
          width: 100,
          align: "left",
          sortable: false,
          cell: row => (
            <>
              {this.props.user && roleHavePermission(this.props.user, "editarMedico") && (
                <>
                  <button
                    className="btn btn-sm"
                    onClick={() => {
                      this.props.history.push(`/doctor/${row._id}`);
                    }}
                  >
                    <span className="clickable icon-edit"></span>
                  </button>
                  <button
                    className="btn btn-sm"
                    onClick={() => this.handleDeleteVerification(row._id)}
                  >
                    <span className="clickable icon-delete"></span>
                  </button>

                  {row.email && (
                    <button
                      className="btn btn-sm"
                      onClick={() => this.handleSendInvitation(row._id)}
                    >
                      <span className="clickable icon-medicosremitentes_old"></span>
                    </button>
                  )}

                  {row.resultsPlatformInvitation && (
                    <span className="clickable icon-scan_id"></span>
                  )}
                </>
              )}              
            </>
          ),
        }
      ],
      conditionalRowStyles: [
        {
          when: row => !row.confirm,
          style: {
            color: 'red'
          },
        }
      ],
      deleteVerification: false,
      deleteConfirmation: false,
      physicianIdToDelete: null,
      invitationConfirmation: false
    };
  }

  debouncedSearch = debounce(search => {
    this.context.setFilter('search', search)
  }, 300);

  handleSearch = e => {
    this.debouncedSearch(e.target.value);
  }

  handleDeleteVerification = async physicianId => {
    this.setState({
      deleteVerification: true,
      physicianIdToDelete: physicianId
    });
  }

  handleDelete = async () => {
    const response = await this.context.deletePhysician(this.state.physicianIdToDelete)

    if (response) {
      this.context.getPhysicians();

      this.setState({
        deleteVerification: false,
        physicianIdToDelete: null,       
        deleteConfirmation: true
      })
    }
  }
  
  handleCancelDelete = () => {
    this.setState({
      deleteVerification: false,
      physicianIdToDelete: null
    });
  }

  handleAcceptDelete = () => {
    this.setState({
      deleteConfirmation: false
    });
  }

  handleSendInvitation = async physicianId => {
     await this.context.sendResultsPlatformInvitation(physicianId);

    this.context.getPhysicians();

    this.setState({
      invitationConfirmation: true
    });
  }

  handleAcceptInvitation = () => {
    this.setState({
      invitationConfirmation: false
    })
  }

  componentDidMount() {
    this.context.getPhysicians();
  }

  render() {
    const { 
      columns, 
      conditionalRowStyles,
      deleteVerification,
      deleteConfirmation,
      invitationConfirmation
    } = this.state;

    const {
      data,
      total,
      perPage,
      handlePageChange,
      handleRowsChange,
      handleSort,
    } = this.context;

    const myNewTheme = {
      rows: {
        fontSize: '50px',
      },
    };
    
    return (

      <Fragment>
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <input
                name="filter"
                id="filter"
                type="text"
                className="form-control"
                placeholder='Buscar'
                onChange={this.handleSearch}
              />
            </div>
          </div>

          <div className="row mh700 mb-10">
            <DataTable
              columns={columns}
              conditionalRowStyles={conditionalRowStyles}
              customTheme={myNewTheme}
              data={data}
              pagination
              paginationPerPage={perPage}
              paginationServer
              paginationTotalRows={total}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsChange}
              onSort={handleSort}
              sortServer                            
              rowStyle={{ alignItems: "stretch" }}
              style={{ display: "flex", alignItems: "center" }}              
              width={9 * 150}
            />
          </div>
        </div>

        <Alert
          show={deleteVerification}
          title='Estas seguro?'
          message='Es irreversible'
          confirmButtonText='Eliminar'
          confirmButtonAction={() => this.handleDelete()}
          cancelButtonText='Cancelar'
          cancelButtonAction={() => this.handleCancelDelete()}
        />

        <Alert
          show={deleteConfirmation}
          title='Acción exitosa'
          message='Medico eliminado satisfactoriamente'
          confirmButtonText='Aceptar'
          confirmButtonAction={() => this.handleAcceptDelete()}
        />

        <Alert
          show={invitationConfirmation}
          title='Acción exitosa'
          message='Invitación enviada satisfactoriamente'
          confirmButtonText='Aceptar'
          confirmButtonAction={() => this.handleAcceptInvitation()}
        />
      </Fragment>
    );
  }

}

export default withRouter(Doctors);

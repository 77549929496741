import React, { Fragment } from 'react';
import './style.css';
import ConfirmationModal from '../../general/confirmationModal';
import PasswordModal from '../changePasswordModal';
import Popover from 'react-popover';
import File from '../../general/file';
import Service from './service';
import FormWSwitch from '../../general/formSwitch';

const Form = ({
  currentUser,
  formData,
  renderText,
  renderSelect,
  roles,
  positions,
  setPositions,
  errUser,
  popoverProps,
  pathname,
  togglePasswordModal,
  uploadFile,
  showPopover,
  message,
  error,
  success,
  cancelRedirect,
  toggleConfirmationModal,
  createUser,
  clearErrors,
  confirmationModal,
  passwordModal,
  signatureErrors,
  sedes,
  handleSedeSelected,
  sedeSelected,
  servicesSelected,
  fields,
  handleChangeTab,
  currentSede,
  getCurrentWeek,
  addSerie,
  deleteSerie,
  onChange,
  services,
  editInterval,
  handleUpdateSwitchField
}) => {
  const currentRole = roles.filter(role => role._id === currentUser._role);
  let currentPositions = [];
  if (currentRole.length !== 0) {
    currentPositions = setPositions(currentRole[0].position, positions);
  }
  return (
    <div>
      {
        confirmationModal && (
          <ConfirmationModal
            onCancel={toggleConfirmationModal}
            onConfirm={cancelRedirect}
          />
        )
      }
      {
        passwordModal && (
          <PasswordModal
            open={passwordModal}
            onCancel={togglePasswordModal}
            _id={currentUser._id}
          />
        )
      }
      <div className="user-form">
        <div className="user-fields grid-wrapper">
          {formData.map(field => {
            return (
              <Fragment key={field.name}>
                {field.input.name === 'text' && renderText(field)}
                {field.name === '_role' && renderSelect(field, roles)}
                {field.name === 'active' && renderSelect(field, field.input.options)}
              </Fragment>
            );
          })}
          {currentPositions.length > 0 && (
            <Fragment>
              {renderSelect(
                {
                  name: '_position',
                  label: 'Cargo'
                },
                currentPositions
              )}
            </Fragment>
          )}
          {
            currentRole[0] && currentRole[0].role === 'medico' &&
            renderText({
              name: 'medical_record',
              label: 'Registro médico',
              input: { name: 'text', type: 'text' }
            })
          }
          {
            currentRole[0] && (currentRole[0].role === 'medico' || currentRole[0].role === 'enfermera') &&
              <Fragment>
                <div>
                  <div
                    className={`${errUser['signature'] &&
                    'wrong-input'} flex a-i-c signature-container`}
                    onClick={clearErrors}
                  >
                    <File
                      value={currentUser.signature}
                      type="file"
                      name="firma"
                      label="Firma digital"
                      uploadFile={uploadFile}
                    />
                    <Popover {...popoverProps}>
                      <i
                        className="large material-icons ml-03"
                        onClick={showPopover}
                      >
                      info_outline{' '}
                      </i>
                    </Popover>
                  </div>
                  {signatureErrors.length > 0 && (
                    <span className="error-msg">{signatureErrors[0]}</span>
                  )}
                </div>
                {
                  currentRole[0] && currentRole[0].role === 'medico' && (
                    <div className="contentServicesUser">
                      <label className="muted-text active">Sede</label>
                      <div className="flex j-r j-sb">
                        {sedes.map((sede, i) => (
                          <div
                            key={i}
                            className={`check-services ${errUser['sede'] &&
                            'wrong-input'}`}
                            onFocus={clearErrors}
                          >
                            <input
                              checked={ sedeSelected.find(branch => branch._id === sede._id) || false}
                              type="checkbox"
                              name="sede"
                              id={`sede-${i}`}
                              value={sede._id}
                              onChange={e => {
                                handleSedeSelected(sede, e.target.checked);
                              }}
                            />
                            <label htmlFor={`sede-${i}`}>{sede.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                }
              </Fragment>
          }
          {
            currentRole[0] && currentRole[0].role === 'medico' &&
            <div>
              <FormWSwitch
                label='Aprobación de mapas'
                name="canApproveMaps"
                value={currentUser?.canApproveMaps}
                onChange={handleUpdateSwitchField}
              />

              <FormWSwitch
                label='Publicación de mapas'
                name="canPublishMaps"
                value={currentUser?.canPublishMaps}
                onChange={handleUpdateSwitchField}
              />
            </div>
          }
          {
            /(profile)/.test(pathname) && (
              <p
                onClick={togglePasswordModal}
                className="button-msg text-center clickable"
              >
                Cambiar contraseña
              </p>
            )
          }
        </div>
      </div>
      {
        currentRole[0] &&
        currentRole[0].role === 'medico' && (
          <Service
            sedeSelected={sedeSelected}
            servicesSelected={servicesSelected}
            fields={fields}
            handleChangeTab={handleChangeTab}
            currentSede={currentSede}
            getCurrentWeek={getCurrentWeek}
            addSerie={addSerie}
            deleteSerie={deleteSerie}
            onChange={onChange}
            services={services}
            clearErrors={clearErrors}
            editInterval={editInterval}
          />
        )
      }
      <div className="user-form-actions flex f-c a-i-c j-c mb-1 mt-1">
        { error && <p className="text-center error-msg">{message}</p> }
        <div className="text-center flex j-c">
          <button
            type="reset"
            className="button save-user button-sm bg-muted"
            onClick={success ? cancelRedirect : toggleConfirmationModal}
          >
            Cancelar
          </button>
          <button
            className="button ripple save-user button-sm bg-icon"
            type="buttom"
            onClick={createUser}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};
export default Form;

import React, { Component } from 'react';
import CheckboxInput from '../../general/checkboxInput';

class DaysSelection extends Component {
  state = {
    repetitonOptions: [
      { id: 'd1', label: 'Lunes', value: 1 },
      { id: 'd2', label: 'Martes', value: 2 },
      { id: 'd3', label: 'Miércoles', value: 3 },
      { id: 'd4', label: 'Jueves', value: 4 },
      { id: 'd5', label: 'Viernes', value: 5 },
      { id: 'd6', label: 'Sábado', value: 6 },
      { id: 'd0', label: 'Domingo', value: 0 },
      { id: 'd0_6', label: 'Lunes a sábado', value: 7 }
    ]
  };

  render () {
    const { updateField, repeatedDays } = this.props;
    const { repetitonOptions } = this.state;
    return (
      <div className="repetitionDays">
        <span className="header text-bold mb-1">Frecuencia</span>
        <div className="text-left flex f-c f-w check-options mt-1">
          {repetitonOptions.map((day, index) => {
            return (
              <CheckboxInput
                checked={repeatedDays[day.value] || false}
                key={index}
                id={`option-${index}`}
                label={day.label}
                name="repetitionDay"
                value={day.value}
                updateField={updateField}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default DaysSelection;

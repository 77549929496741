/* eslint-disable */
import React, { Component } from 'react';
import DoctorsList from '../../doctor/doctors';
import client from '../../../utils/apollo';
import { withRouter } from 'react-router-dom';
import { context, PhysicianConsumer } from '../../../providers/physicianProvider';
import { GET_SPECIALTY } from '../../../graphql/types/specialty/queries';
import Select2 from 'react-select';
import { PHYSICIAN_STATUS } from '../../../utils/constants';

class Physicians extends Component {

  static contextType = context;

  constructor(props) {
    super(props);

    this.state = {
      specialties: []
    };
  }

  getSpecialties = async () => {
    let specialties = [];

    try {
      const result = await client.query({
        query: GET_SPECIALTY,
        variables: {
          pagination: {
            perPage: 1000
          }
        }
      });

      specialties = result.data.getSpecialty.data;

      specialties = specialties.map(sp => ({
        label: sp.name,
        value: sp._id
      }));

      specialties.unshift({
        label: 'Seleccione',
        value: ''
      });

    } catch (error) {
      specialties = [];
    }

    this.setState({ specialties });
  };

  componentDidMount() {
    this.getSpecialties();
  }

  render() {
    const { specialties } = this.state;
    const { 
      setFilter,
      filters: {
        dateFrom,
        dateTo,
        specialty,
        confirm
      }
    } = this.context;

    return (
      <PhysicianConsumer>
        {props => (
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <i className="icon-medicosremitentes"></i>
                  </div>
                  <div>Medicos remitentes</div>
                </div>
                <div className="page-title-actions">
                  <button 
                    type="button" 
                    data-toggle="tooltip" 
                    title="" 
                    data-placement="bottom" 
                    className="btn-shadow mr-3 btn btn-info" 
                    onClick={() => this.context.export()}
                  >
                    Exportar 
                    <i className="nav-link-icon lnr-inbox"></i>
                  </button>

                  <button 
                    type="button" 
                    data-toggle="tooltip" 
                    title="" 
                    data-placement="bottom" 
                    className="btn-shadow mr-3 btn btn-info" 
                    onClick={() => this.context.exportStickers()}
                  >
                    Exportar Stickers
                  </button>

                  <div className="d-inline-block dropdown">                
                    <button 
                      type="button" 
                      data-toggle="dropdown" 
                      aria-haspopup="true" 
                      aria-expanded="false" 
                      className="btn-shadow btn btn-info"
                      onClick={() => this.props.history.push('/doctor/new')}
                    >
                      Crear Nuevo Medico
                      <span className="btn-icon-wrapper pr-2 opacity-7">
                        <i className="nav-link-icon lnr-inbox"></i>
                      </span>
                    </button>                      
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="cards no-shadow bg-transparent no-border rm-borders mb-3">
                  <div className="cards">
                    <div className="col-md-12 col-lg-12">
                      <div className="row">

                        <div className="mb-2  mb-sm-0 col-sm-3">
                          Desde
                          <input 
                            name="desde" 
                            id="desde" 
                            type="date" 
                            className="form-control" 
                            value={dateFrom} 
                            onChange={e => setFilter('dateFrom', e.target.value)}
                          />
                        </div>

                        <div className="mb-2  mb-sm-0 col-sm-3">
                          Hasta
                          <input 
                            name="hasta" 
                            id="hasta" 
                            type="date" 
                            className="form-control" 
                            value={dateTo} 
                            onChange={e => setFilter('dateTo', e.target.value)}
                          />
                        </div>

                        <div className="col-sm-3">
                          Especialidad
                          <Select2
                            name="specialty"
                            isSearchable={true}
                            isMulti={false}
                            options={specialties}
                            value={specialty}
                            onChange={e => setFilter('specialty', e)}
                          />
                        </div>     

                        <div className="col-sm-3">
                          Estado
                          <select
                            className="form-control" 
                            name="status"
                            onChange={e => setFilter('confirm', e.target.value)}
                            value={confirm}
                          >
                            <option value="">Seleccione</option>
                            {PHYSICIAN_STATUS.map(ps => (
                              <option key={ps.value} value={ps.value}>{ ps.label }</option>
                            ))}
                          </select>
                        </div>                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col">
                      <div className="main-card mb-3 cardz">
                        <DoctorsList user={this.props.user}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PhysicianConsumer>
    );
  }
}

export default withRouter(Physicians);


import React, { Fragment } from 'react';

const SmsResultados = ({ data }) => {
  const { dni, mobile, show, classM } = data;
  return show === true ? (<Fragment>
    <div id="beauti" className={classM}>
      <div className="title-">Resultados</div>
      <span>
        El paciente de identificación: {dni} se le envió un mensaje de whatsapp al móvil: {mobile}
      </span>

    </div>
  </Fragment>) : '';
};
export default SmsResultados;
/**
 * AmpaProvider
 */
/* eslint-disable */
import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo, compose } from 'react-apollo';

import { GET_SERVICE, GET_SERVICES_NAMES } from '../graphql/types/servicesModule/queries';
import { GET_RESOURCE, GET_RESOURCE_TYPES } from '../graphql/types/resourcesModule/queries';
import { CREATE_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from '../graphql/types/servicesModule/mutations';
import { GET_USER_SERVICE } from '../graphql/types/usersModule/queries';
import {
  GET_PATIENT
} from '../graphql/types/patientsModule/queries';
import _ from 'lodash';

const { Consumer, Provider } = createContext({ currentService: false });

class AmpaProvider extends Component {
  selfMountComponent = true;
  state = {
    currentService: undefined,
    errorMessage: '',
    successMessage: '',
    error: false,
    success: false,
    currentServices: [],
    serviceErrors: {},
    hours: 0,
    minutes: 0,
    name: '',
    description: '',
    documents: '',
    recommendations: '',
    disableSubmit: false,
    rooms: [],
    _resourceTypeEquip: false,
    _resourceTypeRooms: false,
    equipos: [],
    nurse: true,
    presentdoctor: null,
    presentdoctorsSelected: [],
    quantity: null,
    timeOut: null
  };

  componentWillUnmount () {
    this.selfMountComponent = false;
  }

  componentDidMount = async () => {
    this.selfMountComponent = true;
    if (!localStorage.getItem('token')) {
      this.props.history.replace('/');
      return false;
    }
    const {
      data: {
        getResourceType: { data: types }
      }
    } = await this.props.client.query({ query: GET_RESOURCE_TYPES });
    const _resourceTypeEquip = _.find(types, { name: 'Equipo' })._id;
    const _resourceTypeRooms = _.find(types, { name: 'Espacio de trabajo' })
      ._id;

    const currentServices = await this.props.client.query({
      query: GET_SERVICES_NAMES,
      variables: {
        name: this.state.name,
        perPage: 3
      }
    });

    // si el componente esta montado
    if (this.selfMountComponent){
      await this.setState({
        currentServices: currentServices.data.getServices.data,
        _resourceTypeEquip,
        _resourceTypeRooms
      });
    }
  };
  getPatient = async _id => {
    // if (_id === '/patients/new' || _id === '/availability') return; // this send page new
    if (!_id) _id = this.props.location.pathname.replace(/(\/patients\/)/, '');

    this.setState({ isLoader: true });
    const response = await this.props.client.query({
      query: GET_PATIENT,
      variables: { _id }
    });

    if (response.data && response.data.getPatient.success) {
      const currentPatient = response.data.getPatient.data;

      const document = currentPatient.dni;
      let { photo } = currentPatient;

      let image = ''
      if(photo != 'null'){
        try {
          photo = photo.replace(/..\//i, '');
          image = await this.findImageB(photo);
          // debugger;
          image = image.data.findImage.data[0].base64 ? image.data.findImage.data[0].base64 : '';
  
        } catch (err) {
          console.log(err)
        }
      }
      // console.log(authorized);
      
      // const appointment = await this.props.client.query({
      //   query: GET_APPOINTMENTS,
      //   variables: { patient: _id, perPage: 3, page: 1, order: 'desc' }
      // });
      // console.log("aqiio")
      // let currentAppointments = [];
      // if (appointment.data.getAppointment.success) {
      //   currentAppointments = appointment.data.getAppointment.data;
      // }
      // bug object eps
      const newObject = Object.assign({}, currentPatient);
     
      if (currentPatient && currentPatient.programType) {
        newObject.programTypePatient = currentPatient.programType;
        newObject.programType =
          typeof currentPatient.programType === 'object'
            ? currentPatient.programType._id
            : currentPatient.programType;
      }
      console.log(this.state.currentPatient, "new date")
      this.setState({
        currentPatient: { ...this.state.currentPatient, ...newObject, image },
        photo: currentPatient
          ? { photo: `${currentPatient.photo}`, camera: false }
          : false
      });
    }
    //debugger
    this.setState({ isLoader: false });
  };
  getCurrentService = async _id => {
    let {
      nurse,
      _resourceTypeRooms,
      _resourceTypeEquip,
      presentdoctor,
      presentdoctorsSelected
    } = this.state;
    if (!_resourceTypeRooms) {
      const {
        data: {
          getResourceType: { data: types }
        }
      } = await this.props.client.query({ query: GET_RESOURCE_TYPES });
      _resourceTypeEquip = _.find(types, { name: 'Equipo' })._id;
      _resourceTypeRooms = _.find(types, { name: 'Espacio de trabajo' })._id;
    }
    const result = await this.props.client.query({
      query: GET_SERVICE,
      variables: {
        _id
      }
    });
    // obtener equipos
    const {
      data: {
        getResource: { data: equipos }
      }
    } = await this.props.client.query({
      query: GET_RESOURCE,
      variables: {
        _resourceType: _resourceTypeEquip,
        services: _id
      }
    });
    // obtener salas
    const {
      data: {
        getResource: { data: rooms }
      }
    } = await this.props.client.query({
      query: GET_RESOURCE,
      variables: {
        _resourceType: _resourceTypeRooms,
        services: _id
      }
    });
    // obtener cargos que dan la prestaci´nn
    let {
      data: {
        getUserService: { data: position }
      }
    } = await this.props.client.query({
      query: GET_USER_SERVICE,
      variables: {
        services: _id
      }
    });
    position = _.uniqWith(position, _.isEqual);
    let p = [];
    position.forEach(item => {
      p = [...p, item._position];
    });
    position = p;
    const service = result.data
      ? result.data.getService.data
      : result.loading
        ? undefined
        : false;
    let currentService = {};
    let hours = 0,
      minutes = 0;
    if (service) {
      let selectetedPositions = JSON.parse(service.position);
      hours = Math.floor(Number(service.duration) / 60);
      minutes = Number(service.duration) % 60;
      nurse = service.nurse;
      presentdoctor = service.presentdoctor;
      presentdoctorsSelected = selectetedPositions ? position.filter( pos => selectetedPositions.indexOf( pos._id ) !==  -1 ) : [];
      currentService = {
        ...service,
        _id,
        hours,
        minutes
      };
    } else {
      currentService = false;
    }

    this.setState({
      ...this.state,
      ...currentService,
      rooms,
      currentService,
      hours,
      minutes,
      position,
      equipos,
      nurse,
      presentdoctor,
      presentdoctorsSelected
    });
  };

  deleteService = async (_id) => {
    const response = await this.props.deleteService({ variables: { _id } });

    if (response && response.data && response.data.deleteService && response.data.deleteService.success) return { state: true };
    return { state: false };
  }

  updateField = (name, { value, aux, radioName }) => {
    localStorage.setItem('canExitEdit', true);
    this.clearErrors();
    if (radioName) { this.setState({ [radioName]: aux }); }
    else {
      this.setState({ [name]: value }, () => {
        if (name === 'name') this.loadServices(value);
      });
    }
  };

  updatePresentDoctorSelected = async (value) => {
    await this.setState({ presentdoctorsSelected: value })
  }

  loadServices = async name => {
    const currentServices = await this.props.client.query({
      query: GET_SERVICES_NAMES,
      variables: {
        name: this.state.name,
        perPage: 3
      }
    });
    this.setState({ currentServices: currentServices.data.getServices.data });
  };

  clearErrors = () => {
    this.setState({
      error: false,
      errorMessage: '',
      serviceErrors: {},
      disableSubmit: false
    });
  };

  validate = ({ name, hours, minutes }) => {
    const errors = [];
    const serviceErrors = {};
    let errorMessage = '';
    if (!name) {
      errors.push('name');
      serviceErrors['name'] = true;
      errorMessage = 'EL nombre de la prestación debe existir';
    }
    if (!hours && !minutes) {
      errors.push('duration');
      serviceErrors['duration'] = true;
      errorMessage = 'La prestación debe tener asociada una duración';
    }
    this.setState({ serviceErrors, errorMessage });
    return errors.length > 0 ? false : true;
  };
  saveService = async () => {
    const {
      _id,
      name,
      nurse,
      hours,
      minutes,
      description,
      documents,
      recommendations,
      presentdoctorsSelected,
      presentdoctor
    } = this.state;

    if (this.validate(this.state)) {
      const { quantity, timeOut } = this.state;
      const duration = (parseInt(hours, 10) * 60 + parseInt(minutes, 10)).toString();
      const variables = {
        name,
        duration,
        documents,
        description,
        recommendations
      };
      if (quantity >= 0) variables.quantity = quantity * 1;
      if (timeOut >= 0) variables.timeOut = timeOut * 1;

      this.setState({ disableSubmit: true });
      let result;
      if (_id) {
        result = await this.props.updateService({
          variables: { _id, nurse, presentdoctor, position: JSON.stringify(presentdoctorsSelected.map(position => position._id)) , ...variables }
        });
        result = result.data.updateService;
      } else {
        result = await this.props.createService({
          variables
        });
        result = result.data.createService;
      }
      localStorage.removeItem('canExitEdit');
      localStorage.removeItem('clickSidebar');
      if (result.success){
        this.setState({
          success: true,
          error: false,
          successMessage: `¡Prestacion ${_id ? 'guardada' : 'creada'} exitosamente!`,
          errorMessage: ''
        });
      }
      else {
        this.setState({
          success: false,
          error: true,
          errorMessage: result.errors[0].message
        });
      }

      if (result.success && _id){
        setTimeout(() => {
          this.setState({
            successMessage: '',
            success: false,
            disableSubmit: false
          });
          this.props.history.push(`/services/${_id}`);
        }, 2000);
      }
      else {
        setTimeout(() => {
          this.setState({ disableSubmit: false });
          this.props.history.push('/services');
        }, 2000);
      }
    } else {
      this.setState({
        success: false,
        error: true,
        successMessage: '',
        disableSubmit: false
      });
    }
  };
  render () {
    return (
      <Provider
        value={{
          ...this.state,
          saveService: this.saveService,
          getPatient: this.getPatient,
          updateField: this.updateField,
          loadServices: this.loadServices,
          clearErrors: this.clearErrors
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

AmpaProvider.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.element,
  createService: PropTypes.func,
  updateService: PropTypes.func,
  deleteService: PropTypes.func
};

export default compose(
  graphql(CREATE_SERVICE, { name: 'createService' }),
  graphql(UPDATE_SERVICE, { name: 'updateService' }),
  graphql(DELETE_SERVICE, { name: 'deleteService' })
)(withRouter(withApollo(AmpaProvider)));
export const AmpaConsumer = Consumer;

/**
 * PatientInfo
 */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion';
import moment from 'moment';
import 'moment/locale/es';
import './style.css';

// my components
import Info from './patientsInfo';
import AppointmentState from '../appointmentState';
import AppointmentCancel from '../appointmentCancel';
import AppointmentsHistory from '../appointmentsHistory';
import AppointmentsAssigned from './appointmentsAssigned';
import AppointmentsReasign from '../appointmentsReasign';
import AppointmentSchedule from '../../../containers/patientsModule/appointmentSchedule/appointmentSchedule';

import BodyContainer from '../../general/bodyContainer';
import FormNotify from '../../../components/general/formNotify';
import Modal from '../../general/modal';
import BillingForm from '../billingForm';

moment.locale('es');

class PatientInfo extends Component {
  indexCardAppointment = 0;
  cantAppointmentHistory = 30;
  stateAppointmentHistory = 'agendada';
  state = {
    actionState: '',
    profileComplete: false,
    valueObservation: '',
    isVisibleHistory: false,
    isVisibleReasign: false,
    appointmentSelect: null,
    isVisibleSchedule: false,
    isVisibleModalNull: false,
    isVisibleModalCancel: false,
    isVisibleAppointment: false,
    idSelectedAppointment: '',
    pageAppointmentHistory: 1
  };

  async componentDidMount() {
    this.props.getPatient();
  }

  createPatientRFunction = () => {
    const { currentPatient, createPatientR } = this.props;

    const { dni, name, lastName, mobile, email } = currentPatient;
    if (dni !== '' && name !== '' && lastName !== '' && mobile !== '' && email !== '') {
      const variables = { dni, email, mobile, name, lastName };
      createPatientR(variables);
    }
  }

  _getStateAppointments = (appointment, index = 0) => {
    this.indexCardAppointment = index;
    const { getStateAppointments } = this.props;
    const { _id = '' } = appointment;

    this.setState({
      isVisibleSchedule: false,
      isVisibleAppointment: true,
      isVisibleModalCancel: false,
      idSelectedAppointment: _id
    });
    getStateAppointments(appointment);
  }

  __onChangeObservation = e => {
    this.setState({
      valueObservation: e
    });
  }

  _onChangeObservation = e => {
    this.setState({
      valueObservation: e.target.value
    });
  }

  _onToggleVisibleModalCancel = (appointment, index = 0) => {
    this.indexCardAppointment = index;
    const { isVisibleModalCancel } = this.state;
    const _id = appointment && appointment._id || '';
    this.setState({
      appointmentSelect: appointment,
      isVisibleSchedule: false,
      isVisibleAppointment: false,
      isVisibleModalCancel: !isVisibleModalCancel,
      idSelectedAppointment: _id
    });
  }

  _onToggleVisibleModalSchedule = () => {
    this.setState({ isVisibleSchedule: false });
  }

  _onToggleVisibleAppointment = () => {
    const { isVisibleAppointment } = this.state;
    this.setState({
      isVisibleAppointment: !isVisibleAppointment,
      isVisibleModalCancel: false
    });
  }

  _onToggleVisibleHistory = () => {
    const pageAppointmentHistory = 1;
    const { currentPatient, getAppointments, patientIsWildcard } = this.props;

    this.setState(
      prevState => ({ 
        isVisibleHistory: !prevState.isVisibleHistory 
      }), 
      async () => {
        const { isVisibleHistory } = this.state;
      
        if (isVisibleHistory) {
          if (patientIsWildcard) {
              getAppointments(
                currentPatient._id,
                pageAppointmentHistory,
                this.cantAppointmentHistory,
                null,
                this.stateAppointmentHistory
              );
      
          } else  {
              getAppointments(
                currentPatient._id,
                pageAppointmentHistory,
                this.cantAppointmentHistory
              );
      
          }
        } else {
          getAppointments(currentPatient._id, 1, 3);
        }
    });
    
    
  }

  _onToggleVisibleReasign = (appointment) => {
    const { isVisibleReasign } = this.state;
    // this.setState({ isVisibleReasign: !isVisibleReasign, appointmentSelect: appointment });
    this.props.history.push('/reasing', { appointment });
  }

  _getAppointmentsHistory = async (
    pageAppointmentHistory,
    service,
    state,
    time,
  ) => {
    const { currentPatient, getAppointments } = this.props;

    const appointments = await getAppointments(
      currentPatient._id,
      pageAppointmentHistory,
      this.cantAppointmentHistory,
      service,
      state,
      time
    );

    return appointments;
  }

  _onToggleVisibleModalNull = (...params) => {
    const { isVisibleModalNull } = this.state;
    this.setState({ isVisibleModalNull: !isVisibleModalNull });

    if (isVisibleModalNull && params && params.length > 0) this._saveStateAppointments(...params);
  }

  /**
   * _saveStateAppointments
   * @param {appointment} object selected appointment
   * @param {state} string new state for save
   * @param {observations} string observations to state
   * @param {isGetState} boolean isQuery get getStateAppointments
   * @param {_idReferringPhysician} string
   * @param {indexAppointmentUpdate} int integer index currentAppointment update
   */
  _saveStateAppointments = (...params) => {
    if (this.state.isVisibleHistory) {
      this.setState({
        valueObservation: '',
        isVisibleAppointment: false,
        isVisibleModalCancel: false
      });
    } else {
      this.setState({ valueObservation: '', isVisibleModalCancel: false });
    }
    this.props.saveStateAppointments(...params, this.indexCardAppointment);
  }

  _handleShowAppointmentSchedule = (actionState = '') => {
    const { handleShowAppointmentSchedule } = this.props;
    this.setState({ isVisibleSchedule: true, appointmentSelect: null, idSelectedAppointment: '', actionState });
    handleShowAppointmentSchedule();
    //console.log('consolas en handle show appointment')
    //console.log(handleShowAppointmentSchedule)
    //console.log(actionState)
  }

  handleBiofirma = () => {
    const url = `https://${process.env.REACT_APP_URL_AUTORIZACION}/autorizar.html`;
    const { currentPatient } = this.props;
    const { name, lastName, type_dni, dni, birthDay, email, attendant_names, mobile } = currentPatient;
    String.prototype.hexEncode = function () {
      var hex, i;

      var result = '';
      for (i = 0; i < this.length; i++) {
        hex = this.charCodeAt(i).toString(16);
        result += ('000' + hex).slice(-4);
      }

      return result;
    };

    var nombres = name;
    var apellidos = lastName;
    var tipo_doc = type_dni;
    var doc = dni;
    var f_nace = birthDay;
    var edad = 10;
    var responsable = attendant_names;
    var celular = mobile;
    const cadena = `identificacion=${doc.trim()}&tipo=${tipo_doc.trim()}&nombres=${nombres.trim()}&apellidos=${apellidos.trim()}&nacimiento=${f_nace.trim()}&edad=${edad}&email=${email.trim()}&responsable=${responsable.trim()}&celular=${celular.trim()}`;
    const cadena_hex = cadena.hexEncode();
    var otherWindow = window.open();
    otherWindow.opener = null;
    otherWindow.location = `https://${process.env.REACT_APP_URL_AUTORIZACION}/autorizacion_v2.html?${cadena_hex}`;
  }

  handleProfileComplete = () => {
    const { profileComplete } = this.state;
    this.setState({ profileComplete: !profileComplete });
  }

  _rescheduleAppointment = (appointment, index) => {
    const { rescheduleAppointment } = this.props;
    this.indexCardAppointment = index;

    this.setState({ isVisibleSchedule: true, idSelectedAppointment: appointment._id });
    rescheduleAppointment(appointment);
  }

  render() {
    const {
      isLoader,
      loadServices,
      currentPatient,
      currentServices,
      patientAppointment,
      currentAppointments,
      appointmentsService,
      getPhysician,
      getPhysicianById,
      saveReferringPhysician,
      savePhysician,
      showAppointmentSchedule,
      currentStateAppointments,
      selectedTypeHeadPatient,
      initCurrentPatient,
      saveAppointementReasign,
      user,
      handleRollbackStateAppointment,
      handleVisibilityResultForm,
      showPatientResultForm,
      showBillingForm,
      handleVisibilityBillingForm
    } = this.props;

    const {
      profileComplete,
      isVisibleReasign,
      isVisibleHistory,
      valueObservation,
      appointmentSelect,
      isVisibleSchedule,
      isVisibleModalNull,
      isVisibleAppointment,
      isVisibleModalCancel,
      indexCardAppointment,
      idSelectedAppointment
    } = this.state;

    const loaderElement = (
      <p className="text-center">
        {currentPatient === undefined ? 'Cargando paciente...' : 'El paciente no existe'}
      </p>
    );

    const show_btn_back = (localStorage.getItem('scheduling_filters')) === null ? false : true;

    return (
      <BodyContainer isLoader={isLoader}>
        <div className="contentInfoPatient">
          {
            currentPatient ?
              <Fragment>

                {
                  show_btn_back &&
                  <div className="button button-md bg-icon mb-2" onClick={() => { this.props.history.replace('/schedule?show_filters=true') }}>
                    <i className="large material-icons clickable">arrow_back </i>
                    Regresar a agendamiento
                  </div>
                }

                <Info
                  handleProfileComplete={this.handleProfileComplete}
                  handleBiofirma={this.handleBiofirma}
                  createPatientRFunction={this.createPatientRFunction}
                  profileComplete={profileComplete}
                  user={this.props.user}
                  {...currentPatient}
                />

                <Accordion accordion={false}>
                  {
                    !isVisibleHistory ?
                      <AccordionItem uuid="uniqueItem-appointment" expanded={true}>

                        <AccordionItemTitle
                          className="accordion__title accordion__title--animated u-position-relative ripple-title"
                          onClick={() => { }}
                        >
                          <span>Citas</span>
                        </AccordionItemTitle>

                        <AccordionItemBody>
                          <div className="flex f-w">
                            <AppointmentsAssigned
                              onVisibleReasign={this._onToggleVisibleReasign}
                              idSelectedAppointment={idSelectedAppointment}
                              rescheduleAppointment={this._rescheduleAppointment}
                              currentAppointments={currentAppointments}
                              appointmentsService={appointmentsService}
                              getStateAppointments={this._getStateAppointments}
                              onVisibleModalCancel={this._onToggleVisibleModalCancel}
                              handleShowAppointmentSchedule={this._handleShowAppointmentSchedule}
                              saveStateAppointments={this._saveStateAppointments}
                              user={user}
                            />
                          </div>

                          <div
                            className="accordion-title-text-history"
                            onClick={this._onToggleVisibleHistory}
                          >
                            Historial completo
                          </div>
                        </AccordionItemBody>

                      </AccordionItem>
                      :
                      <AccordionItem uuid="uniqueItem-appointment" expanded={true}>
                        <AccordionItemTitle
                          className="accordion__title accordion__title--animated u-position-relative ripple-title"
                          onClick={() => { }}
                        >
                          <span>Historial de citas</span>
                          <div
                            className="accordion-title-close close-accordion"
                            onClick={this._onToggleVisibleHistory}
                          >
                            X
                          </div>
                        </AccordionItemTitle>

                        <AccordionItemBody className="accordion-body-history">
                          <div className="flex f-w">
                            <AppointmentsHistory
                              loadServices={loadServices}
                              getAppointments={this._getAppointmentsHistory}
                              currentServices={currentServices}
                              onVisibleReasign={this._onToggleVisibleReasign}
                              appointmentsService={appointmentsService}
                              currentAppointments={currentAppointments}
                              getStateAppointments={this._getStateAppointments}
                              onVisibleModalCancel={this._onToggleVisibleModalCancel}
                              idSelectedAppointment={idSelectedAppointment}
                              rescheduleAppointment={this._rescheduleAppointment}
                              handleShowAppointmentSchedule={this._handleShowAppointmentSchedule}
                            />
                          </div>
                        </AccordionItemBody>

                      </AccordionItem>
                  }
                  {
                    (showAppointmentSchedule && isVisibleSchedule) && (
                      isVisibleHistory ?
                        <FormNotify title='Reagendar Cita' styleModalCenter={{ width: '96%', height: '90vh' }} onCloseNotify={this._onToggleVisibleModalSchedule}>
                          <AppointmentSchedule />
                        </FormNotify>
                        : <AppointmentSchedule />
                    )
                  }
                  {
                    // detail service timeline
                    !isVisibleModalCancel && isVisibleAppointment &&
                    <AppointmentState
                      isModal={isVisibleHistory}
                      onCloseNotify={this._onToggleVisibleAppointment}
                      currentAppointments={currentAppointments}
                      valueObservation={valueObservation}
                      onChangeObservation={this._onChangeObservation}
                      appointmentsService={appointmentsService}
                      toggleVisibleModalNull={this._onToggleVisibleModalNull}
                      saveStateAppointments={this._saveStateAppointments}
                      getPhysician={getPhysician}
                      getPhysicianById={getPhysicianById}
                      saveReferringPhysician={saveReferringPhysician}
                      savePhysician={savePhysician}
                      currentStateAppointments={currentStateAppointments}
                      handleRollbackStateAppointment={handleRollbackStateAppointment}
                      user={user}
                      handleVisibilityResultForm={handleVisibilityResultForm}
                      showPatientResultForm={showPatientResultForm}
                    />
                  }
                  {
                    !isVisibleAppointment && isVisibleModalCancel &&
                    <AppointmentCancel
                      isVisible={isVisibleModalCancel}
                      appointment={appointmentSelect}
                      onCloseNotify={this._onToggleVisibleModalCancel}
                      valueObservation={valueObservation}
                      onChangeObservation={this.__onChangeObservation}
                      indexCardAppointment={indexCardAppointment}
                      toggleVisibleModalNull={this._onToggleVisibleModalNull}
                      saveStateAppointments={this._saveStateAppointments}
                    />
                  }
                  {
                    isVisibleModalNull &&
                    <FormNotify
                      title="Confirmar anular cita"
                      onCloseNotify={this._onToggleVisibleModalNull}
                      styleModalCenter={{ width: 300, height: 210 }}
                      message="Esto es para el caso en que el Paciente no Pueda realizar el procedimiento. Esto es diferente a Cancelar Cita. Desea confirmar?"
                      buttons={[
                        {
                          text: 'Regresar',
                          btnClass: 'btn-muted ripple-gray',
                          onClick: () => this._onToggleVisibleModalNull()
                        },
                        {
                          text: 'Anular cita',
                          btnClass: 'btn-alert ripple-btn-alert',
                          onClick: () =>
                            setTimeout(() => {
                              this._onToggleVisibleModalNull(appointmentsService, 'anulada', valueObservation, true);
                            }, 300)
                        }
                      ]}
                    />
                  }
                  {
                    isVisibleReasign && currentPatient &&
                    <AppointmentsReasign
                      saveAppointementReasign={saveAppointementReasign}
                      initCurrentPatient={initCurrentPatient}
                      appointment={appointmentSelect}
                      currentPatient={currentPatient}
                      patientAppointment={patientAppointment}
                      selectedTypeHeadPatient={selectedTypeHeadPatient}
                    />
                  }

                  <Modal
                    title='Facturación'
                    isOpenModal={showBillingForm}
                    onClose={handleVisibilityBillingForm}
                  >
                    <BillingForm />
                  </Modal>
                </Accordion>
              </Fragment>
              :
              loaderElement
          }
        </div>
      </BodyContainer>
    );
  }
}

export default PatientInfo;

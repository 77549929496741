/**
 * BodyContainer
 */
import React from 'react';
import PropTypes from 'prop-types';

// my components
import FormLoader from '../formLoader';

const BodyContainer = ({ id, isLoader, children }) => {
  const parameters = {};
  if (id) parameters.id = id;
  return (
    <div {...parameters} >
      { isLoader && <FormLoader /> }
      { children }
    </div>
  );
};
BodyContainer.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoader: PropTypes.bool
};
BodyContainer.defaultProps = {
  isLoader: false
};
export default BodyContainer;

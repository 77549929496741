import React, { useContext, useState } from 'react';
import { context } from "../../../providers/patientProvider";
import { NAME_OF_FIELDS_FORM_XENCO, rulesOfXencoForm, validateFields } from '../../../utils/helpers';
import Alert from '../../general/alert';
import FormInput from '../../general/formInput';
import { SelectGeneral } from '../../general/selectGeneral';

const ServiceForm = ({ 
    handleSetInput, 
    handleSetSelect, 
    atentionModalitySelect,
    costCenters
}) => {

    const patientContext = useContext(context);
    const [titleAlert, setTitleAlert] = useState("")
    const [messageAlert, setmessageAlert] = useState("")
    const [isSend, setIsSend] = useState(false)
    const [sendingData, setSendingData] = useState(false)

    const { billingData, appointmentsService, handleVisibilityBillingForm } = patientContext

    const handleSaveBillingData = async (e) => {
        try {
            e.preventDefault();           
            
            const convertedObject = {
                ...billingData,
                tipoDocumentoIdentificacion: billingData?.tipoDocumentoIdentificacion?.value ?? billingData?.tipoDocumentoIdentificacion
            }

            const fieldsNotFill = validateFields({ data: convertedObject, rules: rulesOfXencoForm })
            
            if( fieldsNotFill.length > 0 ){
                setTitleAlert("¡Error!")
                setmessageAlert(`Por favor complete los siguientes campos: ${fieldsNotFill.map(item => NAME_OF_FIELDS_FORM_XENCO[item]).join(', ')}`)
                return setIsSend(true)
            }

            setSendingData(true);

            const response = await patientContext.saveBillingAppoinment({
                id: appointmentsService._id,
                data: convertedObject
            });            

            if( !response?.data?.saveBillingAppointment.success ){
                const dataResponse = 
                    response?.data?.saveBillingAppointment.errors
                    ?.filter(error => error !== null && error !== undefined)
                    .map(item => ({ ...item, path: item.path.replace(/^data\./, '') })); // Quitar el prefijo "data."

                setTitleAlert("¡Ups!")
                setmessageAlert(`Se presentaron los siguientes problemas: 
                                ${dataResponse.map( error => `${NAME_OF_FIELDS_FORM_XENCO[error.path]} ${error.message}`)
                                .join(', ')}
                                `)

                setSendingData(false);                                
                return setIsSend(true);
            }            

            setTitleAlert("Registro éxitoso")
            setmessageAlert("Se completó el registro éxitosamente")
            setIsSend(true)

            setTimeout(() => {
                handleVisibilityBillingForm()
            }, 3000);
        } catch (error) {
            setTitleAlert("Ups!")
            setmessageAlert("Se presentó un problema al enviar los datos a xenco, contacte al administrador")
            setSendingData(false);
            return setIsSend(true);
        }
    }

    return (
        <>
            {
                isSend && (
                    <Alert
                        show={isSend}
                        title={ titleAlert }
                        message={ messageAlert }
                        confirmButtonText='Cerrar'
                        confirmButtonAction={() => setIsSend(false)}
                    />
                )
            }

            <div className='row my-3' style={{ rowGap: '1rem' }}>
                <div className="col-md-6 mt-2">
                    <FormInput
                        label='Fecha inicio atención'
                        name='fechaInicioAtencion'
                        onChange={handleSetInput}
                        type='date'
                        value={billingData?.fechaInicioAtencion}
                    />
                </div>                

                <div className="col-md-6 mt-2">
                    <SelectGeneral
                        label='Modalidad atención'
                        name='modalidadGrupoServicioTecSal'
                        options={atentionModalitySelect ?? []}
                        updateField={handleSetSelect}
                        value={billingData?.modalidadGrupoServicioTecSal}
                    />
                </div>

                <div className="col-md-12 mt-2">
                    <FormInput
                        label='Código de consulta'
                        name='codConsulta'
                        onChange={handleSetInput}
                        value={billingData?.codConsulta}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <FormInput
                        label='Numero de autorización'
                        name='numAutorizacion'
                        onChange={handleSetInput}
                        value={billingData?.numAutorizacion}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <FormInput
                        label='Fecha autorización'
                        name='FechaAutorizacion'
                        onChange={handleSetInput}
                        type='date'
                        value={billingData?.FechaAutorizacion}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <SelectGeneral
                        label='Centro de Costo que ejecuta'
                        name='CentroCostosEjecuta'
                        options={costCenters}
                        updateField={handleSetSelect}
                        value={billingData?.CentroCostosEjecuta}
                    />
                </div>

                <div className="col-md-6 mt-2">
                    <SelectGeneral
                        label='Centro de Costo que solicita'
                        name='CentroCostosSolicita'
                        options={costCenters}
                        updateField={handleSetSelect}
                        value={billingData?.CentroCostosSolicita}
                    />
                </div>

                <div className='d-block'>
                    <button
                        className="button ripple save-user button-sm bg-icon my-2"
                        disabled={sendingData}
                        onClick={handleSaveBillingData}
                    >
                      {sendingData ? 'Enviando...' : 'Enviar'}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ServiceForm;